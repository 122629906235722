/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import { getTips, markTipAsSeen, resetTips } from './utils/tips-utils';

export default (state = [], action) => {
  if (isValidAPICall(action, 'tip')) {
    // Return all on GET
    if (action.type === 'API_READ') {
      return action.payload.data;
    }
  }
  return state;
};

export { getTips, markTipAsSeen, resetTips };
