import React from 'react';
import classNames from 'classnames';

import { Reports, SelectedView } from '../../misc/types';
import {
  flattenEmail,
  getIntentFromNum,
  getViewData,
  maybePluralize
} from '../../misc/utils';
import { Icon, Tag, Tooltip } from '../design-system';

import { RowData } from './DetailedStats';
import { LINE_COLORS } from './ROIChart';

import styles from '../../styles/review-leaderboard-stats.module.scss';

interface LeaderBoardProps {
  isForMarketing: boolean;
  reports: Reports;
  selectedView: SelectedView;
}
interface LeaderData extends RowData {
  dotColor: string;
}

export const LeaderBoard: React.ComponentType<LeaderBoardProps> = React.memo(
  ({ reports, selectedView, isForMarketing }) => {
    const data = getViewData(
      'duration_and_cost_details.attributes.rows.organizers',
      reports,
      selectedView
    );

    // Turn into array + sort the data
    const sortedData: LeaderData[] = [];
    for (const obj in data) {
      sortedData.push({
        slug: obj,
        regexedEmail: flattenEmail(obj),
        data: data[obj],
        dotColor: ''
      });
    }

    // Sort organizers by name, to add color
    sortedData.sort((a, b) => {
      if (a.slug < b.slug) {
        return -1;
      }
      if (a.slug > b.slug) {
        return 1;
      }
      return 0;
    });

    // Add colors
    const finalData: LeaderData[] = sortedData.map((item, index) => {
      return {
        ...item,
        dotColor: LINE_COLORS[index]
      };
    });

    // Sort organizers by score
    finalData.sort((a, b) => {
      return parseInt(b.data.total.percent) - parseInt(a.data.total.percent);
    });

    return (
      <div
        className={classNames(styles.section, styles.leaderboard, {
          [styles.isForMarketing]: isForMarketing
        })}>
        <h5>
          <span>LEADERBOARD</span>
        </h5>
        <div className={styles.description} data-label="description">
          Which organizers are scoring best?
        </div>
        <div className={styles.card} data-label="leaderboard">
          <div className={styles.header}>
            <div>Organizer</div>
            <div className={styles.score}>Avg. score</div>
          </div>
          {finalData.map((organizer, index) => (
            <Organizer key={index} {...organizer} />
          ))}
        </div>
      </div>
    );
  }
);

const Organizer: React.ComponentType<LeaderData> = React.memo(
  ({ data, slug, dotColor }) => {
    const avgScore = parseInt(data.total.percent);
    const name = data.name;

    return (
      <div className={styles.organizer}>
        <div className={styles.image}>
          {data.profile_image ? (
            <img
              alt={slug}
              crossOrigin="anonymous"
              referrerPolicy="no-referrer"
              src={data.profile_image}
            />
          ) : (
            <span className={styles.accountIcon}>
              <Icon color="#FFFFFF" which="people" />
            </span>
          )}
          <div
            className={styles.legendItem}
            style={{ backgroundColor: dotColor }}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.name}>
            <Tooltip content={slug}>{name || slug}</Tooltip>
          </div>
          {data.past_actions && (
            <div className={styles.nudges}>
              <Tooltip content="Number of auto-balance emails (nudges) that were sent to this organizer.">
                {data.past_actions}{' '}
                {maybePluralize(data.past_actions, 'nudge', 'nudges')}
              </Tooltip>
            </div>
          )}
        </div>
        <div className={styles.avgScore}>
          <Tag intent={getIntentFromNum(avgScore, 'text')}>
            {Math.round(avgScore)}
          </Tag>
        </div>
      </div>
    );
  }
);
