import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { differenceWith, isEqual } from 'lodash';

import { usePrevious } from '../../hooks/previous';
import { RootState } from '../../misc/types';

export const NotificationHandler: React.ComponentType = React.memo(() => {
  const { addToast } = useToasts();

  const notifications = useSelector(
    (state: RootState) => state.messages.notifications
  );
  const prevNotifications = usePrevious(notifications);

  const showNotification = useCallback(
    notif => {
      addToast(notif.message, {
        appearance: notif.appearance || 'info',
        autoDismiss:
          notif.autoDismiss === true || notif.autoDismiss === false
            ? notif.autoDismiss
            : true
      });
    },
    [addToast]
  );

  const parseOutNewNotifications = useCallback(
    (allNotif, prevNotif) => {
      const newNotif = differenceWith(allNotif, prevNotif, isEqual);
      for (let i = 0; i < newNotif.length; i++) {
        showNotification(newNotif[i]);
      }
    },
    [showNotification]
  );

  // Trigger when new notifications appear
  useEffect(() => {
    parseOutNewNotifications(notifications, prevNotifications);
  }, [notifications, parseOutNewNotifications, prevNotifications]);

  return null;
});
