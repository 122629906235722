/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { readEndpoint } from 'redux-json-api';

import { log } from '../../misc/utils';

export const getSettingsLabels = () => {
  log('getSettingsLabels()');
  return dispatch => {
    dispatch(readEndpoint('settings_labels'));
  };
};
