import React, { ReactNode, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { find, isEmpty, isEqual, round, sample } from 'lodash';

import { REVIEW_VIEWS } from '../../misc/constants';
import { URLS } from '../../misc/consts';
import {
  DateSelectionProps,
  Goal,
  InviteStatus,
  Member,
  Reports,
  RootState,
  SelectedView,
  User
} from '../../misc/types';
import {
  buildReviewViewText,
  getDateRangeText,
  getViewMetric,
  maybePluralize
} from '../../misc/utils';
import { Tooltip } from '../design-system';

import { FilterText } from './FilterText';
import { Metric } from './Metric';
import { SummaryCosts } from './SummaryCosts';

import styles from '../../styles/review-summary-stats.module.scss';

interface SummaryCostsProps {
  dateSelection: DateSelectionProps;
  isColumnLayout: boolean;
  isForMarketing: boolean;
  memberships: Member[];
  onMakeAPICalls?: () => void;
  reports: Reports;
  selectedView: SelectedView;
  statusFilterData?: InviteStatus[];
  user: User;
}

export const SummaryCard: React.ComponentType<SummaryCostsProps> = React.memo(
  ({
    reports,
    selectedView,
    dateSelection,
    memberships,
    statusFilterData,
    isColumnLayout,
    isForMarketing,
    onMakeAPICalls,
    user
  }) => {
    const { startDate, endDate } = dateSelection;
    const goals = useSelector((state: RootState) => state.goals);

    const benefit = useMemo(() => {
      if (isEmpty(user) || isEmpty(goals)) {
        return '';
      }

      const goalIds = user.attributes.goal_ids;
      if (isEmpty(goalIds)) {
        return '';
      }

      const randomGoal = sample(goalIds) as number;
      const thisGoal = find(goals, function (o) {
        return o.id === randomGoal.toString();
      }) as Goal;

      if (!isEmpty(thisGoal)) {
        return thisGoal.attributes.name;
      }
      return '';
    }, [goals, user]);

    const totalHoursCount = getViewMetric(
      'duration_and_costs.attributes.rows.total.work_hours_spent.spent_hours',
      reports,
      selectedView
    );

    const totalMeetingsCount = getViewMetric(
      'duration_and_costs.attributes.rows.total.meetings',
      reports,
      selectedView
    );

    // Build label values
    const labelText = getDateRangeText(startDate, endDate);

    const totalWorkHours = getViewMetric(
      'duration_and_costs.attributes.rows.total.work_hours',
      reports,
      selectedView
    );

    const percentOfTime = getViewMetric(
      'duration_and_costs.attributes.rows.total.work_hours_spent.percent_of_time',
      reports,
      selectedView
    );

    const hoursLeft = totalWorkHours - totalHoursCount;

    const youVsTeam = isEqual(selectedView, REVIEW_VIEWS.Team) ? (
      <Tooltip
        className={styles.teammateTooltip}
        content={
          <div>
            {memberships &&
              memberships.map((item, index) => (
                <div key={index}>{item.attributes.name}</div>
              ))}
          </div>
        }>
        your team's
      </Tooltip>
    ) : (
      'your'
    );

    const labeledDescriptions = [
      'This week',
      'Last week',
      'This month',
      'Last month'
    ];

    // Maybe add prefix?
    let prefix = 'In';
    if (labeledDescriptions.includes(labelText)) {
      prefix = `${labelText}, in`;
    }

    const summaryDescriptor = (
      <span>
        <strong>
          {prefix} {totalWorkHours} work hours
        </strong>
        , {youVsTeam} {<FilterText statusFilterData={statusFilterData} />}{' '}
        meetings make up...
      </span>
    );

    // Build time label
    let timeLabel = 'time';
    if (labeledDescriptions.includes('week')) {
      timeLabel = 'week';
    } else if (labeledDescriptions.includes('month')) {
      timeLabel = 'month';
    }

    // Build descriptor text
    let descriptorText, statClass;
    if (percentOfTime < 50) {
      const benefitText = benefit ? ` and <span>${benefit}</span>` : '';
      descriptorText = `Looks like you have plenty of time to get work done${
        benefit && benefitText
      }!`;
      statClass = styles.success;
    } else {
      const benefitText = benefit
        ? ` Were you able to <span>${benefit}</span>? <a href="${URLS.Balance.NewBlock}?wbLabel=${benefit}">Create wellness block</a>`
        : '';
      let hoursLeftDesc = `just <strong>${round(hoursLeft)} hours</strong>`;
      if (hoursLeft <= 1) hoursLeftDesc = `that short time`;
      descriptorText = `Can all of ${buildReviewViewText(
        'your work',
        selectedView
      )} get done in ${hoursLeftDesc}?${benefitText}`;
      statClass = styles.danger;
    }

    // Build data block
    const dataToPassThrough = {
      summaryDescriptor,
      totalHoursCount,
      totalMeetingsCount,
      percentOfTime,
      timeLabel,
      statClass,
      selectedView,
      isColumnLayout,
      isForMarketing,
      onMakeAPICalls,
      reports,
      user
    };

    return (
      <>
        {!Number.isNaN(totalMeetingsCount) && (
          <>
            {!isForMarketing && (isColumnLayout || isMobile) ? (
              <PlanVersion {...dataToPassThrough} />
            ) : (
              <ReviewVersion {...dataToPassThrough} />
            )}
          </>
        )}
        {!Number.isNaN(hoursLeft) &&
          isEqual(selectedView, REVIEW_VIEWS.You) && (
            <div
              className={styles.descriptor}
              dangerouslySetInnerHTML={{ __html: descriptorText }}
            />
          )}
      </>
    );
  }
);

interface ViewProps {
  isColumnLayout: boolean;
  isForMarketing: boolean;
  onMakeAPICalls?: () => void;
  percentOfTime: number;
  reports: Reports;
  selectedView: SelectedView;
  statClass: string;
  summaryDescriptor: string | ReactNode;
  timeLabel: string;
  totalHoursCount: number;
  totalMeetingsCount: number;
  user: User;
}

export const ReviewVersion: React.ComponentType<ViewProps> = React.memo(
  ({
    summaryDescriptor,
    totalHoursCount,
    totalMeetingsCount,
    percentOfTime,
    timeLabel,
    statClass,
    selectedView,
    isColumnLayout,
    isForMarketing,
    onMakeAPICalls,
    reports,
    user
  }) => {
    return (
      <div className={styles.card}>
        <div className={styles.title}>{summaryDescriptor}</div>
        <div className={styles.content}>
          <div className={styles.statWrapper}>
            <div className={classNames(styles.stat, statClass)}>
              <Metric
                bottomLabel={`${buildReviewViewText(
                  'of your',
                  selectedView
                )} ${timeLabel}`}
                data={percentOfTime}
                label="%"
                type="num"
              />
            </div>
            <div className={styles.label}>in</div>
          </div>
          <div className={styles.statWrapper}>
            <div className={styles.stat}>
              <Metric
                bottomLabel={maybePluralize(
                  totalMeetingsCount,
                  'meeting',
                  'meetings'
                )}
                data={totalMeetingsCount}
                type="num"
              />
            </div>
            <div className={styles.label}>for</div>
          </div>
          <div className={styles.statWrapper}>
            <div className={styles.stat}>
              <Metric
                bottomLabel={maybePluralize(totalHoursCount, 'hour', 'hours')}
                data={totalHoursCount}
                type="num"
              />
            </div>
            <div className={styles.label}>which is</div>
          </div>
          <SummaryCosts
            isColumnLayout={isColumnLayout}
            isForMarketing={isForMarketing}
            reports={reports}
            selectedView={selectedView}
            user={user}
            onMakeAPICalls={onMakeAPICalls}
          />
        </div>
      </div>
    );
  }
);

export const PlanVersion: React.ComponentType<ViewProps> = React.memo(
  ({
    summaryDescriptor,
    totalHoursCount,
    totalMeetingsCount,
    percentOfTime,
    timeLabel,
    statClass,
    selectedView,
    isColumnLayout,
    onMakeAPICalls,
    reports,
    user
  }) => {
    return (
      <Link
        className={classNames(styles.card, {
          [styles.noClick]: isMobile
        })}
        to={URLS.Review.Default}>
        <Tooltip content="Click to view all meeting performance data">
          <div className={styles.title}>{summaryDescriptor}</div>
          <div className={styles.content}>
            <div className={styles.top}>
              <div className={styles.statWrapper}>
                <div className={classNames(styles.stat, statClass)}>
                  <Metric
                    bottomLabel={`${buildReviewViewText(
                      'of your',
                      selectedView
                    )} ${timeLabel}`}
                    data={percentOfTime}
                    label="%"
                    type="num"
                  />
                </div>
              </div>
              <div className={classNames(styles.statWrapper, styles.meetings)}>
                <div className={styles.stat}>
                  <Metric
                    bottomLabel={maybePluralize(
                      totalMeetingsCount,
                      'meeting',
                      'meetings'
                    )}
                    data={totalMeetingsCount}
                    type="num"
                  />
                </div>
              </div>
              <div className={styles.statWrapper}>
                <div className={styles.stat}>
                  <Metric
                    bottomLabel={maybePluralize(
                      totalHoursCount,
                      'hour',
                      'hours'
                    )}
                    data={totalHoursCount}
                    type="num"
                  />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <SummaryCosts
                isColumnLayout={isColumnLayout}
                reports={reports}
                selectedView={selectedView}
                user={user}
                onMakeAPICalls={onMakeAPICalls}
              />
            </div>
          </div>
        </Tooltip>
      </Link>
    );
  }
);
