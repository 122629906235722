import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { isBrowser } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import classNames from 'classnames';
import { get, isEmpty, isEqual } from 'lodash';
import moment from 'moment';

import { COLORS, REVIEW_VIEWS, URLS } from '../../misc/constants';
import {
  DateSelectionProps,
  Reports,
  RootState,
  SelectedView
} from '../../misc/types';
import { getIntentFromNum } from '../../misc/utils';
import { Tooltip } from '../design-system';

import styles from '../../styles/review-roi-chart-stats.module.scss';

export const LINE_COLORS = [
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#ff8738',
  '#ff6860',
  '#ff5088',
  '#ff4cb0',
  '#da59d4',
  '#9b6bed',
  '#1f78f7',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
  '#ff8738',
  '#ff6860',
  '#ff5088',
  '#ff4cb0',
  '#da59d4',
  '#9b6bed',
  '#1f78f7'
];

const FONT = "'Catamaran', sans-serif";
const FONT_STYLE = {
  color: COLORS.muted,
  font: { family: FONT }
};
const DEFAULT_MIN = 30;
const DEFAULT_MAX = 110;
const CHART_GAP = 5;

interface ROIChartProps {
  dateSelection: DateSelectionProps;
  reports: Reports;
  selectedView: SelectedView;
}

interface TickContext {
  tick: {
    label: string;
  };
}

interface DatasetContext {
  dataIndex: number;
  dataset: {
    backgroundColor: string;
    label?: string;
    type: string;
  };
  formattedValue?: string;
}
interface ScoreRangeProps {
  max: number;
  min: number;
}

export const ROIChart: React.ComponentType<ROIChartProps> = React.memo(
  ({ dateSelection, reports, selectedView }) => {
    const { startDate, endDate } = dateSelection;

    const user = useSelector((state: RootState) => state.user.general);
    const settings = useSelector((state: RootState) => state.user.settings);

    const allNudges = useMemo(() => {
      return get(
        reports,
        `${selectedView.slug}.num_past_actions_by.attributes.rows`,
        undefined
      );
    }, [reports, selectedView.slug]);

    const daysToChart = useMemo(() => {
      const now = moment(startDate);
      const dates = [];

      while (now.isSameOrBefore(moment(endDate))) {
        dates.push(now.format('M/D/YYYY'));
        now.add(1, 'days');
      }
      return dates;
    }, [endDate, startDate]);

    const lineChartData = useMemo(() => {
      const organizers = get(
        reports,
        `${selectedView.slug}.average_scores_by.attributes.rows`,
        undefined
      );
      if (!organizers) {
        return [];
      }

      // Sort organizers
      const sortedOrganizers = Object.keys(organizers).sort();

      // Loop through organizer
      return sortedOrganizers.map((organizer, index) => {
        const scores = organizers[organizer];

        // Loop through days
        const scoresPerDay = daysToChart.map(day => {
          const key = moment(day).format('YYYY-MM-DD');
          const scoreForThisDay = get(scores, `${key}`, null);
          return scoreForThisDay;
        });
        return {
          type: 'line',
          label: organizer,
          data: scoresPerDay,
          backgroundColor: LINE_COLORS[index],
          borderColor: LINE_COLORS[index],
          clip: 10,
          yAxisID: 'y'
        };
      });
    }, [daysToChart, reports, selectedView]);

    const barChartData = useMemo(() => {
      // Loop through days
      const data = daysToChart.map(day => {
        const key = moment(day).format('YYYY-MM-DD');
        const nudgesForThisDay = get(allNudges, `${key}`, 0);
        return nudgesForThisDay;
      });

      return [
        {
          type: 'bar',
          label: 'Nudges',
          data,
          backgroundColor: 'rgba(199, 208, 223, 0.3)',
          borderColor: '#B3C0D4',
          borderWidth: 1,
          yAxisID: 'y1'
        }
      ];
    }, [allNudges, daysToChart]);

    const overallScoreValues = useMemo(() => {
      if (lineChartData.length === 0) {
        return [];
      }

      const avgScores: Array<number | null> = daysToChart.map(() => {
        return null;
      });
      for (let i = 0; i < lineChartData.length; i++) {
        const item = lineChartData[i];
        for (let t = 0; t < item.data.length; t++) {
          const newValue = parseInt(item.data[t]);
          const existingValue = avgScores[t];
          if (existingValue && newValue) {
            avgScores[t] = Math.round((existingValue + newValue) / 2);
          } else if (existingValue && !newValue) {
            avgScores[t] = existingValue;
          } else if (!existingValue && newValue) {
            avgScores[t] = newValue;
          }
        }
      }

      return avgScores;
    }, [daysToChart, lineChartData]);

    const overallScoreData = useMemo(() => {
      if (lineChartData.length === 0) {
        return [];
      }

      const scoreData = overallScoreValues.map(item => {
        return item ? DEFAULT_MAX : null;
      });

      return [
        {
          type: 'scatter',
          label: 'test',
          data: scoreData,
          backgroundColor: LINE_COLORS[0],
          borderColor: LINE_COLORS[0],
          clip: 10,
          yAxisID: 'y'
        }
      ];
    }, [lineChartData, overallScoreValues]);

    const highestNudgeCount = useMemo(() => {
      const nums: number[] = allNudges ? Object.values(allNudges) : [];
      const maxPlusOne = Math.max(...nums) + 1;
      return maxPlusOne;
    }, [allNudges]);

    const scoreRange: ScoreRangeProps = useMemo(() => {
      const organizers = get(
        reports,
        `${selectedView.slug}.average_scores_by.attributes.rows`,
        undefined
      );

      if (!organizers) {
        return { min: DEFAULT_MIN, max: DEFAULT_MAX };
      }

      let allScores: number[] = [];
      for (const key in organizers) {
        const obj: Record<string, string> = organizers[key];
        const values = Object.values(obj).map((item: string) => {
          return parseInt(item);
        });
        allScores = [...allScores, ...values];
      }

      const min = Math.min(...allScores);
      const minMinusOne = min - CHART_GAP;
      const minRounded = Math.floor(minMinusOne / CHART_GAP) * CHART_GAP;

      return {
        max: DEFAULT_MAX,
        min: minRounded < DEFAULT_MIN ? minRounded : DEFAULT_MIN
      };
    }, [reports, selectedView]);

    const chartData = {
      labels: daysToChart,
      datasets: [...overallScoreData, ...lineChartData, ...barChartData]
    };

    const chartOptions = {
      scales: {
        y: {
          alignToPixels: true,
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'Overall score',
            ...FONT_STYLE
          },
          min: scoreRange.min,
          max: scoreRange.max,
          grid: {
            color: COLORS.border,
            borderColor: COLORS.border
          },
          ticks: {
            ...FONT_STYLE,
            callback: (val: number) => {
              if (val <= 100 && val >= 0) {
                return val;
              }
            },
            stepSize: 10
          }
        },
        y1: {
          alignToPixels: true,
          min: 0,
          max: highestNudgeCount,
          type: 'linear',
          display: true,
          position: 'right',
          title: {
            display: !isEmpty(allNudges),
            text: '# Nudges',
            ...FONT_STYLE
          },
          ticks: {
            ...FONT_STYLE,
            beginAtZero: true,
            callback: (val: number) => {
              if (Number.isInteger(val)) {
                return val;
              }
            },
            stepSize: 1
          },
          grid: {
            color: COLORS.border,
            borderColor: COLORS.border,
            drawOnChartArea: false
          }
        },
        x: {
          alignToPixels: true,
          grid: {
            color: COLORS.border,
            borderColor: COLORS.border,
            lineWidth: function (context: TickContext) {
              if (!context || !context.tick) {
                return 1;
              }
              // If is Sunday, make thicker
              const momentObj = moment(`${context.tick.label} 2021`);
              if (momentObj.day() === 0) {
                return 3;
              }
              return 1;
            }
          },
          ticks: {
            callback: (val: number) => {
              const label = daysToChart[val];
              return moment(label).format('ddd, MMM D');
            },
            color: COLORS.muted,
            font: { family: FONT }
          }
        }
      },
      animation: false,
      spanGaps: true,
      tension: 0.3,
      maintainAspectRatio: false,
      interaction: {
        mode: 'nearest',
        intersect: false
      },
      plugins: {
        datalabels: {
          display: (context: DatasetContext) => {
            if (context.dataset.type === 'scatter') {
              if (overallScoreValues[context.dataIndex]) {
                return true;
              }
              return false;
            }
            return 'auto';
          },
          backgroundColor: (context: DatasetContext) => {
            if (context.dataset.type === 'line') {
              return context.dataset.backgroundColor;
            } else if (context.dataset.type === 'scatter') {
              return getIntentFromNum(
                overallScoreValues[context.dataIndex],
                'bkg'
              );
            }
            return '';
          },
          clamp: true,
          color: (context: DatasetContext) => {
            if (context.dataset.type === 'line') {
              return 'white';
            } else if (context.dataset.type === 'scatter') {
              return getIntentFromNum(
                overallScoreValues[context.dataIndex],
                'textColor'
              );
            }
            return 'black';
          },
          padding: (context: DatasetContext) => {
            return context.dataset.type === 'bar'
              ? 0
              : { top: 2, bottom: 2, left: 4, right: 4 };
          },
          borderRadius: 2,
          font: { weight: 'bold', size: 12 },
          formatter: (value: number, context: DatasetContext) => {
            if (context.dataset.type === 'scatter') {
              return overallScoreValues[context.dataIndex] || '';
            }
            return value !== 0 ? Math.round(value) : '';
          }
        },
        tooltip: {
          callbacks: {
            label: (context: DatasetContext) => {
              if (context.dataset.type === 'scatter') {
                return null;
              }
              if (context.dataset.type === 'bar') {
                return `${context.formattedValue} ${context.dataset.label}`;
              }
              return context.dataset.label;
            },
            afterLabel: (context: DatasetContext) => {
              if (context.dataset.type !== 'scatter') {
                return null;
              }
              return `Avg. score: ${overallScoreValues[context.dataIndex]}`;
            }
          },
          caretSize: 0,
          cornerRadius: 2,
          titleFont: { family: FONT },
          bodyFont: { family: FONT },
          backgroundColor: 'rgba(0, 46, 86, 0.95)'
        },
        legend: {
          display: false
        }
      }
    };

    const chartDescription = useMemo(() => {
      const youOrYourTeam = isEqual(selectedView, REVIEW_VIEWS.You)
        ? 'your'
        : 'your teams';
      const howTheText = `The average scores of ${youOrYourTeam} meetings`;

      // If "Do nothing" action
      if (settings) {
        const nonCompliAction =
          settings.attributes.action_settings.non_compliance_action;
        if (nonCompliAction === 'do nothing') {
          return (
            <>
              {howTheText}. Want organizers to improve their meetings (
              <span className={styles.example}>
                <Tooltip
                  content={
                    <div className="exampleScreenshot autoBalanceExample" />
                  }>
                  view example
                </Tooltip>
              </span>
              )?{' '}
              <Link to={URLS.Balance.Default}>
                Change your auto-balance setting
              </Link>{' '}
              to take action.
            </>
          );
        }
      }

      // If has an action

      return `${howTheText} along with the total number of auto-balance emails (nudges) that are sent.`;
    }, [selectedView, settings]);

    const visibilityTooltipContent = useMemo(() => {
      const domain = user?.attributes.domain
        ? `"@${user?.attributes.domain}"`
        : 'your company';
      return `You, your team, and meeting organizers who receive meeting improvement reminder emails from ${domain}.`;
    }, [user]);

    return (
      <div className={classNames(styles.section, styles.roiChartStats)}>
        <h5>
          <span>Avg. Meeting Scores by Organizer</span>
        </h5>
        <div className={styles.description} data-label="description">
          {chartDescription}
        </div>
        <div className={styles.card} data-label="roi-chart">
          {isEqual(selectedView, REVIEW_VIEWS.Team) && isBrowser && (
            <Tooltip
              className={styles.whoCanSeeThis}
              content={visibilityTooltipContent}>
              <div className={styles.text}>Who can see this?</div>
            </Tooltip>
          )}
          <Line
            data={chartData}
            options={chartOptions}
            plugins={[ChartDataLabels]}
          />
        </div>
      </div>
    );
  }
);
