import React from 'react';

import { COLORS } from '../../misc/constants';
import { Callout, Icon } from '../design-system';

import styles from '../../styles/meeting-modal.module.scss';

interface SectionActionsExistingActionViewProps {
  existingActionStatus: string;
}

export const SectionActionsExistingActionView: React.ComponentType<SectionActionsExistingActionViewProps> =
  React.memo(({ existingActionStatus }) => {
    const doNothingStatusText = 'No action will be taken for this meeting.';
    const remindStatusText =
      'The organizer has been reminded that this meeting needs changes.';
    const declineStatusText = `The organizer has been reminded, this meeting may be declined if changes aren't made.`;
    let descText = '';
    switch (existingActionStatus) {
      case 'remind':
        descText = remindStatusText;
        break;
      case 'remind of conflicts':
        descText = remindStatusText;
        break;
      case 'decline conflicts':
        descText = declineStatusText;
        break;
      case 'decline':
        descText = declineStatusText;
        break;
      case 'do nothing':
        descText = doNothingStatusText;
        break;
      default:
        descText = '';
    }

    return (
      <div className={styles.sectionActions}>
        <Callout>
          <Icon className={styles.tick} color={COLORS.success} which="tick" />
          {descText}
        </Callout>
      </div>
    );
  });
