import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { get, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { ErrorState, Loading } from '../../components/design-system';
import { ReviewFilterInfo } from '../../containers/app/Review';
import { useTeamSubscription } from '../../hooks/team-subscription';
import { DIV_IDS, REVIEW_VIEWS } from '../../misc/constants';
import { QUERY_PARAMS, URLS } from '../../misc/consts';
import { DateSelectionProps, RootState } from '../../misc/types';
import { getViewMetric } from '../../misc/utils';
import { ROICalculator } from '../misc/ROICalculator';
import { ResyncButton } from '../plan/ResyncButton';

import { DetailedStats } from './DetailedStats';
import { MiscStats } from './MiscStats';
import { OrganizerStats } from './OrganizerStats';
import { ReportCardStats } from './ReportCardStats';
import { SummaryStats } from './SummaryStats';

import styles from '../../styles/review.module.scss';

interface AllMetricsProps {
  dateSelection: DateSelectionProps;
  filterInfo: ReviewFilterInfo;
  makeAPICalls: () => void;
}

export const AllMetrics: React.ComponentType<AllMetricsProps> = React.memo(
  ({ dateSelection, filterInfo, makeAPICalls }) => {
    const [shouldDownloadParam] = useQueryParam(
      QUERY_PARAMS.DownloadOrganizerStats,
      BooleanParam
    );

    const user = useSelector((state: RootState) => state.user.general);
    const reports = useSelector((state: RootState) => state.reports);
    const wellness_blocks = useSelector(
      (state: RootState) => state.wellness_blocks
    );
    const memberships = useSelector(
      (state: RootState) => state.teams.memberships.data
    );
    const settings_labels = useSelector(
      (state: RootState) => state.settings_labels
    );
    const meeting_info = useSelector((state: RootState) => state.meeting_info);
    const synchronizations = useSelector(
      (state: RootState) => state.user.synchronizations
    );
    const areReportsLoading = useSelector(
      (state: RootState) => state.loading.reports
    );

    const [hasManyMembers] = useTeamSubscription();

    // It total meeting count is zero
    const totalMeetingCount = getViewMetric(
      'duration_and_costs.attributes.rows.total.meetings',
      reports,
      filterInfo.selectedView
    );

    // Make sure user has access to Team view
    if (
      isEqual(filterInfo.selectedView, REVIEW_VIEWS.Team) &&
      // Check for false directly
      hasManyMembers === false &&
      areReportsLoading === 0 &&
      !shouldDownloadParam
    ) {
      return (
        <div className={styles.inner}>
          <ROICalculator
            description={
              <>
                Schedules become far less soul-crushing when your whole team
                uses MeetWell.
                <br />
                <Link to={URLS.Account.TeamMgmt}>
                  Add your team members now
                </Link>
                .
              </>
            }
            isInApp={true}
            title="Aw, crap! Your team isn't here (yet)."
          />
        </div>
      );
    }

    // If loading
    if (areReportsLoading > 0 || Number.isNaN(totalMeetingCount)) {
      if (
        isEmpty(reports) ||
        isEmpty(get(reports, filterInfo.selectedView.slug))
      ) {
        return (
          <div className={styles.inner}>
            <Loading />
          </div>
        );
      }
    }

    if (totalMeetingCount === 0 && areReportsLoading === 0) {
      const hasMeetingsInDBRange = !isEmpty(synchronizations)
        ? moment().isAfter(
            moment(synchronizations?.attributes.earliest_meeting_date)
          )
        : false;
      const title = hasMeetingsInDBRange
        ? 'No stats available'
        : 'Meetings not synced';
      const description = hasMeetingsInDBRange
        ? `You don't have any meetings to evaluate with the filters set. Adjust the date range or re-sync your meetings below.`
        : `You have chosen a date outside of what MeetWell has synced in our database. Re-sync your meetings below.`;
      return (
        <div className={styles.inner}>
          <ErrorState
            action={
              <ResyncButton dateSelection={dateSelection} minimal={false} />
            }
            description={description}
            title={title}
          />
        </div>
      );
    }

    if (!user) {
      return null;
    }

    return (
      <div className={styles.inner} id={DIV_IDS.SecondaryScroll}>
        <div className={styles.inside}>
          <div className={styles.shh} id={DIV_IDS.FullReview}>
            <SummaryStats
              dateSelection={dateSelection}
              memberships={memberships}
              reports={reports}
              selectedView={filterInfo.selectedView}
              statusFilterData={filterInfo.statusFilterData}
              user={user}
              onMakeAPICalls={makeAPICalls}
            />
            {isEqual(filterInfo.selectedView, REVIEW_VIEWS.Team) ? (
              <>
                <OrganizerStats
                  dateSelection={dateSelection}
                  reports={reports}
                  selectedView={filterInfo.selectedView}
                />
                <ReportCardStats
                  dateSelection={dateSelection}
                  filterInfo={filterInfo}
                  reports={reports}
                  selectedView={filterInfo.selectedView}
                  settingsLabels={settings_labels}
                  user={user}
                />
              </>
            ) : (
              <>
                <ReportCardStats
                  dateSelection={dateSelection}
                  filterInfo={filterInfo}
                  reports={reports}
                  selectedView={filterInfo.selectedView}
                  settingsLabels={settings_labels}
                  user={user}
                />
                <OrganizerStats
                  dateSelection={dateSelection}
                  reports={reports}
                  selectedView={filterInfo.selectedView}
                />
              </>
            )}
            <DetailedStats
              meetingInfo={meeting_info}
              reports={reports}
              selectedView={filterInfo.selectedView}
              settingsLabels={settings_labels}
              statusFilterData={filterInfo.statusFilterData}
              user={user}
            />
            <MiscStats
              dateSelection={dateSelection}
              reports={reports}
              selectedView={filterInfo.selectedView}
              settingsLabels={settings_labels}
              wellnessBlocks={wellness_blocks}
            />
          </div>
        </div>
      </div>
    );
  }
);
