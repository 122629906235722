import React, { useCallback } from 'react';
import { get } from 'lodash';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { Icon } from '../../../components/design-system';
import { QUERY_PARAMS } from '../../../misc/consts';
import { Fathom, GOALS } from '../../../misc/fathom';

import { HeroText } from './HeroArea';

import styles from '../../../styles/marketing/get-started-button.module.scss';

interface GetStartedButtonProps {
  heroText: HeroText;
}

export const GetStartedButton: React.ComponentType<GetStartedButtonProps> =
  React.memo(({ heroText }) => {
    const [, setIsSignUpFormOpen] = useQueryParam(
      QUERY_PARAMS.Login,
      BooleanParam
    );

    const [linkedInRefParam] = useQueryParam(
      QUERY_PARAMS.LinkedInRef,
      StringParam
    );

    const _onSignUpFormToggle = useCallback(() => {
      setIsSignUpFormOpen(prevState => !prevState);

      // Fathom tracking
      Fathom.trackGoal(GOALS.Marketing.CTAButton);

      // Check refs
      if (linkedInRefParam) {
        const LIGoal = get(GOALS.Marketing.LinkedIn, linkedInRefParam);
        if (LIGoal) {
          Fathom.trackGoal(LIGoal);
        }
      }
    }, [linkedInRefParam, setIsSignUpFormOpen]);

    return (
      <>
        <p className={styles.getStartedCTAHeader}>{heroText.ctaHeader}</p>
        <div className={styles.getStartedButton} onClick={_onSignUpFormToggle}>
          <div className={styles.sub}>No credit card required</div>
          <div className={styles.text}>
            Try 14 days for free
            <Icon
              className={styles.arrowRightIcon}
              color="#ffffff"
              which="arrow-right"
            />
          </div>
        </div>
      </>
    );
  });
