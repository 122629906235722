import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagsInput from 'react-tagsinput';
import classNames from 'classnames';
import { cloneDeep, isEmpty, isEqual, uniq } from 'lodash';

import { PLAN_TOOLTIPS } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { RootState, User } from '../../misc/types';
import { isEmailValid } from '../../misc/utils';
import { generallyUpdateTeam } from '../../modules/teams';
import { generallyUpdateUser, getUser } from '../../modules/user';
import {
  Alert,
  Button,
  EditingPopover,
  MultiTagInput,
  Tag,
  TextLink,
  Tooltip
} from '../design-system';

import styles from '../../styles/action-info-item.module.scss';
import popoverStyles from '../../styles/popover.module.scss';

interface InfoDomainEnforcementProps {
  user: User;
  which: 'team' | 'user';
}

const SEG_DATA = ['All', 'Some'];

export const InfoDomainEnforcement: React.ComponentType<InfoDomainEnforcementProps> =
  React.memo(({ user, which }) => {
    const tagsInput = useRef<TagsInput>(null);

    const [domainEnforcementType, setDomainEnforcementType] = useState('');
    const [stagedDomainEnforcementType, setStagedDomainEnforcementType] =
      useState('');
    const [domains, setDomains] = useState<string[]>([]);
    const [stagedDomains, setStagedDomains] = useState<string[]>([]);
    const [teamDomainsToRemind, setTeamDomainsToRemind] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);
    const [isAlertDialogVisible, setIsAlertDialogVisible] = useState(false);

    const dispatch = useDispatch();
    const subscription = useSelector(
      (state: RootState) => state.subscriptions.subscription
    );

    useEffect(() => {
      setIsWaitingOnAPI(false);
      setIsEditing(false);

      const { company } = user.attributes;

      // Build domains list
      let allDomainsToRemind = '';
      let teamDomainsToRemind = '';
      let userDomainsToRemind = '';

      if (which === 'user') {
        // For User Balance View
        if (company.domains_allowlist && !isEmpty(company.domains_allowlist)) {
          teamDomainsToRemind = company.domains_allowlist;
          userDomainsToRemind = !isEmpty(user.attributes.domains_allowlist)
            ? `,${user.attributes.domains_allowlist}`
            : '';
          allDomainsToRemind = `${teamDomainsToRemind}${userDomainsToRemind}`;
        } else if (user.attributes.domains_allowlist) {
          allDomainsToRemind = user.attributes.domains_allowlist;
        }
        // For Team Management view
      } else if (which === 'team' && company.domains_allowlist) {
        allDomainsToRemind = company.domains_allowlist;
      }

      const domainEnforcementType = isEmpty(allDomainsToRemind)
        ? 'All'
        : 'Some';
      const domainsToSet = allDomainsToRemind
        ? uniq(allDomainsToRemind.split(','))
        : user.attributes.domain != null
        ? [user.attributes.domain]
        : [];

      setDomainEnforcementType(domainEnforcementType);
      setStagedDomainEnforcementType(domainEnforcementType);
      setTeamDomainsToRemind(teamDomainsToRemind);
      setDomains(domainsToSet);
      setStagedDomains(domainsToSet);
    }, [subscription, user, which]);

    const renderStatus = () => {
      if (domainEnforcementType === 'All') return 'All domains';
      return domains.map((item, key) => (
        <Tag key={key} className={styles.domainTag}>
          <Tooltip content={item}>{item}</Tooltip>
        </Tag>
      ));
    };

    const _toggleEditMode = useCallback(() => {
      setIsEditing(prevState => !prevState);
    }, []);

    const _handleCloseAlertDialog = useCallback(() => {
      setIsAlertDialogVisible(false);
    }, []);

    const _selectNewDomainEnforcementype = useCallback(item => {
      setStagedDomainEnforcementType(item);
    }, []);

    const _handleDomainsChange = useCallback(domains => {
      setStagedDomains(domains);
    }, []);

    const _save = useCallback(async () => {
      let domains = '';

      // Show spinner
      setIsWaitingOnAPI(true);

      // If "Some", validate the domains, if "All", skip ahead
      if (stagedDomainEnforcementType === 'Some') {
        // Make sure whatever is typed into box has turned into a tag
        if (tagsInput.current) {
          await tagsInput.current.accept();
        }

        // Clone staged domains
        let clonedStagedDomains = cloneDeep(stagedDomains);

        // Maybe remove team domains from list
        if (which === 'user' && !isEmpty(teamDomainsToRemind)) {
          const teamDomainsArr = teamDomainsToRemind.split(',');
          clonedStagedDomains = clonedStagedDomains.filter(
            el => !teamDomainsArr.includes(el)
          );
        }

        // Validate (Loop through and test domains)
        for (let i = 0; i < clonedStagedDomains.length; i++) {
          const sampleEmail = `adam@${clonedStagedDomains[i]}`;
          if (!isEmailValid(sampleEmail)) {
            setIsWaitingOnAPI(false);
            return setIsAlertDialogVisible(true);
          }
        }

        // Build string
        domains = clonedStagedDomains.join(',');
      }

      // Post to API
      const data = {
        which: 'domains_allowlist',
        value: domains
      };
      if (which === 'user') {
        dispatch(generallyUpdateUser(data));
      } else if (which === 'team') {
        dispatch(
          generallyUpdateTeam({
            team_id: user.attributes.company.id,
            ...data,
            callback: () => dispatch(getUser())
          })
        );
      }

      // Track goal
      Fathom.trackGoal(GOALS.Balance.AddedDomainsToEnforce);
    }, [
      dispatch,
      stagedDomainEnforcementType,
      stagedDomains,
      teamDomainsToRemind,
      user,
      which
    ]);

    // Disabled button?
    const isSaveButtonDisabled = () => {
      const typeHasntChanged = isEqual(
        domainEnforcementType,
        stagedDomainEnforcementType
      );

      // If 'All'
      if (stagedDomainEnforcementType === 'All') {
        // Type hasn't changed
        return typeHasntChanged;
      }

      // If 'Some'
      if (stagedDomainEnforcementType === 'Some') {
        // No domains, or domains haven't changed
        if (stagedDomains.length === 0 || isEqual(domains, stagedDomains)) {
          return true;
        }

        // If type has changed, enable button
        if (!typeHasntChanged) return false;

        // Good to go
        return false;
      }
    };

    // Maybe build team specific domain list
    const teamDomainsExist = which === 'user' && !isEmpty(teamDomainsToRemind);
    const maybeDisabledTags = teamDomainsExist
      ? teamDomainsToRemind.split(',')
      : [];

    return (
      <div className={styles.item}>
        <h6>Who to enforce?</h6>
        <div className={classNames(styles.section, styles.domainEnforcement)}>
          <Alert
            isVisible={isAlertDialogVisible}
            text="Please enter a valid email domain and try again."
            title="Invalid email domain"
            onConfirm={_handleCloseAlertDialog}
          />
          <div className={styles.label}>
            <Tooltip content={PLAN_TOOLTIPS.Domains}>Domains</Tooltip>
          </div>
          <div className={styles.content}>
            <div className={styles.text}>{renderStatus()}</div>
            <EditingPopover
              className={styles.editWrapper}
              content={
                <>
                  <div className={popoverStyles.segmentedControl}>
                    {!teamDomainsExist &&
                      SEG_DATA.map((item, key) => (
                        <Button
                          key={key}
                          active={item === stagedDomainEnforcementType}
                          centered
                          fill
                          minimal
                          small
                          text={item}
                          onClick={() => _selectNewDomainEnforcementype(item)}
                        />
                      ))}
                  </div>
                  <MultiTagInput
                    addOnBlur={true}
                    addOnPaste={true}
                    disabledTagTooltip="This domain was added by your team."
                    inputProps={{
                      placeholder: 'Hit Enter to add...'
                    }}
                    isDisabled={stagedDomainEnforcementType === 'All'}
                    reference={tagsInput}
                    tagsToDisable={maybeDisabledTags}
                    value={stagedDomains}
                    onChange={_handleDomainsChange}
                  />
                </>
              }
              description={PLAN_TOOLTIPS.Domains}
              disabledText="Please add or edit info"
              innerClassName={classNames(
                popoverStyles.editingPlan,
                popoverStyles.editDomainEnforcement
              )}
              isLoading={isWaitingOnAPI}
              isSaveButtonDisabled={isSaveButtonDisabled()}
              isVisible={isEditing}
              onCancel={_toggleEditMode}
              onSave={_save}
              onToggle={_toggleEditMode}>
              <TextLink
                className={styles.edit}
                isDisabled={isEditing}
                text="Edit"
                onClick={_toggleEditMode}
              />
            </EditingPopover>
          </div>
        </div>
      </div>
    );
  });
