import React, { useCallback, useEffect, useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { URLS } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { Subscription, User } from '../../misc/types';
import { setUserCommunicationsPreference } from '../../modules/user';
import { Button, RadioGroupForm, Tooltip } from '../design-system';
import { CalendarList } from '../misc/CalendarList';
import { ResyncButton } from '../plan/ResyncButton';

import { CalendarPreferences } from './CalendarPreferences';
import { PlanInfo } from './PlanInfo';
import { ReferralURL } from './ReferralURL';
import { ScaryStuff } from './ScaryStuff';
import { SlackPreferences } from './SlackPreferences';

import styles from '../../styles/account.module.scss';

interface AccountViewGeneralProps {
  subscription?: Subscription;
  user?: User;
}

export const AccountViewGeneral: React.ComponentType<AccountViewGeneralProps> =
  React.memo(({ subscription, user }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [emailRemindersPreference, setEmailRemindersPreference] =
      useState('');
    const [emailReportsPreference, setEmailReportsPreference] = useState('');
    const [emailTipsPreference, setEmailTipsPreference] = useState('');
    const [emailIfOrganizerPreference, setEmailIfOrganizerPreference] =
      useState('');
    const [allEmailsPreference, setAllEmailsPreference] =
      useState<boolean>(true);

    useEffect(() => {
      if (user && user.attributes) {
        const {
          email_reminders,
          email_weekly_reports,
          email_tips,
          all_communications,
          email_if_organizer
        } = user.attributes.communication_prefs;

        setEmailRemindersPreference(email_reminders.toString());
        setEmailReportsPreference(email_weekly_reports.toString());
        setEmailTipsPreference(email_tips.toString());
        setEmailIfOrganizerPreference(email_if_organizer.toString());
        setAllEmailsPreference(all_communications);
      }
    }, [user]);

    const _setRemindersCommunicationPreference = useCallback(
      preference => {
        setEmailRemindersPreference(preference);
        dispatch(
          setUserCommunicationsPreference('email_reminders', preference)
        );

        // Track goal
        Fathom.trackGoal(GOALS.Account.ModifiedEmailCommsPref);
      },
      [dispatch]
    );

    const _setReportsCommunicationPreference = useCallback(
      preference => {
        setEmailReportsPreference(preference);
        dispatch(
          setUserCommunicationsPreference('email_weekly_reports', preference)
        );

        // Track goal
        Fathom.trackGoal(GOALS.Account.ModifiedEmailCommsPref);
      },
      [dispatch]
    );

    const _setTipsCommunicationPreference = useCallback(
      preference => {
        setEmailTipsPreference(preference);
        dispatch(setUserCommunicationsPreference('email_tips', preference));

        // Track goal
        Fathom.trackGoal(GOALS.Account.ModifiedEmailCommsPref);
      },
      [dispatch]
    );

    const _setEmailIfOrganizerPreference = useCallback(
      preference => {
        setEmailIfOrganizerPreference(preference);
        dispatch(
          setUserCommunicationsPreference('email_if_organizer', preference)
        );

        // Track goal
        Fathom.trackGoal(GOALS.Account.ModifiedEmailCommsPref);
      },
      [dispatch]
    );

    const _setAllCommunicationPreference = useCallback(() => {
      dispatch(
        setUserCommunicationsPreference(
          'all_communications',
          !allEmailsPreference
        )
      );
      setAllEmailsPreference(prevState => !prevState);

      // Track goal
      Fathom.trackGoal(GOALS.Account.ModifiedEmailCommsPref);
    }, [allEmailsPreference, dispatch]);

    const _handleChangePlanClick = useCallback(() => {
      history.push(URLS.Balance.PlanChooser);
    }, [history]);

    if (!user) {
      return null;
    }

    return (
      <>
        <div className={styles.columns}>
          <div className={styles.column}>
            {subscription && (
              <>
                <div className={styles.section}>
                  <PlanInfo
                    subscription={subscription}
                    onChangePlan={_handleChangePlanClick}
                  />
                </div>
                <div className={styles.section}>
                  <h5>Calendars to balance</h5>
                  <div className={styles.desc}>
                    It may take several minutes to sync and evaluate all of your
                    calendar's events.
                  </div>
                  <CalendarList className={styles.calList} />
                  <ResyncButton minimal={false} />
                </div>
                <ReferralURL user={user} />
              </>
            )}
            {isBrowser && <ScaryStuff user={user} />}
          </div>
          <div className={styles.column}>
            <div className={styles.section}>
              <h5>Integrations</h5>
              <SlackPreferences user={user} />
            </div>
            <div className={styles.section}>
              <h5>Email Communication Preferences</h5>
              <RadioGroupForm
                desc={
                  <>
                    MeetWell can let you know before and/or after an
                    Auto-Balance action is taken on your meetings.{' '}
                    <span className={styles.example}>
                      <Tooltip
                        content={
                          <div className="exampleScreenshot improvementReminders" />
                        }>
                        View example
                      </Tooltip>
                    </span>
                  </>
                }
                options={[
                  {
                    label: (
                      <>
                        Email me <strong>before and after</strong> an action is
                        taken
                      </>
                    ),
                    value: 'pre_and_post'
                  },
                  {
                    label: (
                      <>
                        Only email me <strong>before</strong> an action is taken
                      </>
                    ),
                    value: 'pre_action'
                  },
                  {
                    label: (
                      <>
                        Only email me <strong>after</strong> an action is taken
                      </>
                    ),
                    value: 'post_action'
                  },
                  {
                    label: (
                      <>
                        <strong>Don't email me</strong> when an action is taken
                      </>
                    ),
                    value: 'no_email'
                  }
                ]}
                selectedValue={emailRemindersPreference}
                title="Auto-Balance reminders"
                onChange={_setRemindersCommunicationPreference}
              />
              <RadioGroupForm
                desc={
                  <>
                    Receive an email Monday morning detailing your previous
                    week.{' '}
                    <span className={styles.example}>
                      <Tooltip
                        content={
                          <div className="exampleScreenshot weeklyEmails" />
                        }>
                        View example
                      </Tooltip>
                    </span>
                  </>
                }
                options={[
                  {
                    label: (
                      <>
                        <strong>Send me</strong> weekly meeting quality reports
                      </>
                    ),
                    value: 'true'
                  },
                  {
                    label: (
                      <>
                        <strong>Don't send</strong> weekly meeting quality
                        reports
                      </>
                    ),
                    value: 'false'
                  }
                ]}
                selectedValue={emailReportsPreference}
                title="Weekly reports"
                onChange={_setReportsCommunicationPreference}
              />
              <RadioGroupForm
                desc={`Receive an occasional tip to help utilize MeetWell to it's fullest.`}
                options={[
                  {
                    label: (
                      <>
                        <strong>Send me</strong> periodic tips to learn more
                        about MeetWell
                      </>
                    ),
                    value: 'true'
                  },
                  {
                    label: (
                      <>
                        <strong>Don't send</strong> any tips
                      </>
                    ),
                    value: 'false'
                  }
                ]}
                selectedValue={emailTipsPreference}
                title="Tips and tricks"
                onChange={_setTipsCommunicationPreference}
              />
              <RadioGroupForm
                desc={`Receive Auto-balance reminder emails if meetings you organize don't meet your preferred meeting standards.`}
                options={[
                  {
                    label: (
                      <>
                        <strong>Send me</strong> reminder emails about meetings
                        I organize
                      </>
                    ),
                    value: 'true'
                  },
                  {
                    label: (
                      <>
                        <strong>Don't send</strong> reminder emails about
                        meetings I organize
                      </>
                    ),
                    value: 'false'
                  }
                ]}
                selectedValue={emailIfOrganizerPreference}
                title="Organizer reminders"
                onChange={_setEmailIfOrganizerPreference}
              />
              <Button
                centered
                fill
                text={
                  !allEmailsPreference
                    ? 'Subscribe to all'
                    : 'Unsubscribe from all'
                }
                onClick={_setAllCommunicationPreference}
              />
            </div>
            <div className={styles.section}>
              <h5>Calendar Preferences</h5>
              <CalendarPreferences user={user} />
            </div>
            {isMobile && <ScaryStuff user={user} />}
          </div>
        </div>
      </>
    );
  });
