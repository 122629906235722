import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Interweave from 'interweave';
import { HashtagMatcher, UrlMatcher } from 'interweave-autolink';
import { isEmpty } from 'lodash';

import { Meeting } from '../../misc/types';
import { Icon, TextLink } from '../design-system';

import styles from '../../styles/meeting-modal.module.scss';

interface AgendaItemsProps {
  className?: string;
  event: Meeting;
}

export const SectionDescription: React.ComponentType<AgendaItemsProps> = React.memo(
  ({ event, className }) => {
    const [isCollapsible, setIsCollapsible] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const _toggleCollapse = useCallback(() => {
      setIsCollapsed(prevState => !prevState);
    }, []);

    useEffect(() => {
      const { agenda_items, description } = event.attributes;
      const hasAgendaItems =
        !isEmpty(agenda_items.data) && agenda_items.data.length > 0;

      const shouldCollapse =
        hasAgendaItems || ((description && description.length > 50) as boolean);

      setIsCollapsible(shouldCollapse);
      setIsCollapsed(shouldCollapse);
    }, [event]);

    if (!event.attributes.description) return null;

    const removeBRsBeginningAndEnd = (desc: string) => {
      return desc.replace(/^\s*<br\s*\/?>|<br\s*\/?>\s*$/g, '');
    };

    return (
      <div className={classNames(styles.section, className)}>
        <div
          className={classNames(styles.item, styles.sectionDescription, {
            [styles.collapsed]: isCollapsed
          })}>
          <Icon className={styles.icon} which="description" />
          <div className={styles.text}>
            <Interweave
              content={removeBRsBeginningAndEnd(event.attributes.description)}
              matchers={[new UrlMatcher('url'), new HashtagMatcher('hashtag')]}
              noHtmlExceptMatchers={true}
            />
          </div>
          {isCollapsible && (
            <div className={styles.collapseButton}>
              <TextLink
                className={styles.link}
                text={isCollapsed ? 'Show' : 'Hide'}
                onClick={_toggleCollapse}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
);
