import React, { ReactNode, useCallback } from 'react';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import styles from '../../styles/modal.module.scss';
import fadeTransition from '../../styles/transitions/modal-fade.module.scss';

interface ModalViewProps {
  children?: ReactNode;
  className?: string;
  innerClassName?: string;
  isDismissible: boolean;
  isVisible: boolean;
  miscActions?: ReactNode;
  onClose: () => void;
  position?: string;
}

export const ModalView: React.ComponentType<ModalViewProps> = React.memo(
  ({
    isVisible,
    children,
    className,
    innerClassName,
    position,
    miscActions,
    onClose,
    isDismissible
  }) => {
    const _handleKeyUp = useCallback(
      event => {
        event.stopPropagation();

        if (event.key === 'Escape') {
          onClose && onClose();
        }
      },
      [onClose]
    );

    // ESC keys
    if (isVisible && isDismissible) {
      document.addEventListener('keyup', _handleKeyUp);
    } else {
      document.removeEventListener('keyup', _handleKeyUp);
    }

    return (
      <Portal>
        <CSSTransition
          appear
          classNames={fadeTransition}
          in={isVisible}
          timeout={200}
          unmountOnExit>
          <div
            className={classNames(
              styles.modalView,
              className,
              position === 'float' && styles.floating,
              position === 'drawer' && styles.drawer
            )}>
            {miscActions && miscActions}
            <div className={classNames(styles.inner, innerClassName)}>
              {children && children}
            </div>
            <div
              className={styles.bkg}
              onClick={isDismissible ? onClose : undefined}
            />
          </div>
        </CSSTransition>
      </Portal>
    );
  }
);
