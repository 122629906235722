import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { INTENT } from '../../misc/consts';

import styles from '../../styles/design-system.module.scss';

export interface MenuItemsProps {
  children: ReactNode;
  className?: string;
}

export const MenuItems: React.ComponentType<MenuItemsProps> = React.memo(
  function MenuItems({ className, children }) {
    return (
      <div className={classNames(styles.menuItems, className)}>{children}</div>
    );
  }
);

export interface MenuHeaderProps {
  children: ReactNode;
}

export const MenuHeader: React.ComponentType<MenuHeaderProps> = React.memo(
  function MenuHeader({ children }) {
    return <div className={styles.menuHeader}>{children}</div>;
  }
);

export const MenuDivider: React.ComponentType = () => (
  <div className={styles.menuDivider} />
);

export interface MenuItemProps {
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  intent?: string;
  isDisabled?: boolean;
  onClick: () => void;
}

export const MenuItem: React.ComponentType<MenuItemProps> = React.memo(
  function MenuItem({
    children,
    className,
    intent,
    isDisabled,
    onClick,
    icon
  }) {
    return (
      <div
        className={classNames(styles.menuItem, className, {
          [styles.danger]: intent === INTENT.Danger,
          [styles.disabled]: isDisabled
        })}
        onClick={!isDisabled ? onClick : undefined}>
        {icon && <div className={styles.icon}>{icon}</div>}
        <div className={styles.text}>{children}</div>
      </div>
    );
  }
);
