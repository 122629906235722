/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import {
  acceptMeeting,
  getFutureActions,
  getPastActions
} from './utils/actions-utils';

const initialState = {
  future: [],
  past: []
};

export default (state = initialState, action) => {
  if (isValidAPICall(action, 'future_actions')) {
    return {
      ...state,
      future: action.payload.data
    };
  }

  if (isValidAPICall(action, 'past_actions')) {
    return {
      ...state,
      past: action.payload.data
    };
  }

  return state;
};

export { getFutureActions, getPastActions, acceptMeeting };
