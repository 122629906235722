/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isArray } from 'lodash';

import { WELLNESS_BLOCKS_ALIAS } from '../misc/consts';
import { isValidAPICall } from '../misc/utils';

import {
  createWellnessBlock,
  deleteWellnessBlock,
  getWellnessBlocks,
  updateWellnessBlock
} from './utils/wellness-blocks-utils';

export default (state = [], action) => {
  if (isValidAPICall(action, WELLNESS_BLOCKS_ALIAS)) {
    const thisData = action.payload.data;
    if (action.type === 'API_UPDATED') {
      return state.map(item => (item.id === thisData.id ? thisData : item));
    } else if (action.type === 'API_CREATED') {
      return state.concat([thisData]);
    } else if (action.type === 'API_DELETED') {
      return state.filter(function (item) {
        return item.id !== action.payload.id;
      });
    }

    // If array, replace entire state, else concat
    if (isArray(action.payload.data)) {
      return action.payload.data || [];
    } else {
      return state.concat([thisData]);
    }
  }
  return state;
};

export {
  getWellnessBlocks,
  createWellnessBlock,
  updateWellnessBlock,
  deleteWellnessBlock
};
