import React, { useCallback } from 'react';
import { isBrowser } from 'react-device-detect';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Button, Icon, Tooltip } from '../../components/design-system';
import { ResyncButton } from '../../components/plan/ResyncButton';
import { ChangePageProps } from '../../containers/app/Plan';
import { COLORS } from '../../misc/constants';
import { DATE_FORMAT, URLS } from '../../misc/consts';
import { DateSelectionProps } from '../../misc/types';
import { getDateRangeText } from '../../misc/utils';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from '../../styles/plan.module.scss';

interface SubNavProps {
  dateSelection: DateSelectionProps;
  onChangeThePage: (data: ChangePageProps) => void;
}

export const SubNav: React.ComponentType<SubNavProps> = React.memo(
  ({ dateSelection, onChangeThePage }) => {
    const { visibleDate, startDate, endDate } = dateSelection;
    const history = useHistory();

    const isTodayDisabled =
      getDateRangeText(startDate, endDate) === 'This week';

    const _goToToday = useCallback(() => {
      const newDate = moment();
      onChangeThePage({ date: newDate });
    }, [onChangeThePage]);

    const _goPrev = useCallback(() => {
      const newDate = moment(visibleDate).subtract(1, 'w');
      onChangeThePage({ date: newDate });
    }, [onChangeThePage, visibleDate]);

    const _goNext = useCallback(() => {
      const newDate = moment(visibleDate).add(1, 'w');
      onChangeThePage({ date: newDate });
    }, [onChangeThePage, visibleDate]);

    const _handleNewWellnessBlockClick = useCallback(() => {
      history.push(URLS.Plan.NewBlock);
    }, [history]);

    // If same month, only show month once
    // If different months, show both months
    let endDateFormat = 'Do, YYYY';
    if (!moment(startDate).isSame(endDate, 'month')) {
      endDateFormat = DATE_FORMAT.Default;
    }

    return (
      <div className={styles.subNav}>
        <div className={styles.navBtns}>
          {isBrowser && (
            <Button
              className={styles.todayBtn}
              disabled={isTodayDisabled}
              shouldLookDisabled
              text="View this week"
              onClick={_goToToday}
            />
          )}
          <div className={styles.prevNextBtns}>
            <Tooltip content="Previous week">
              <Button icon={<Icon which="chevron-left" />} onClick={_goPrev} />
            </Tooltip>
            <Tooltip content="Next week">
              <Button icon={<Icon which="chevron-right" />} onClick={_goNext} />
            </Tooltip>
          </div>
          <ResyncButton dateSelection={dateSelection} />
        </div>
        <div className={styles.calendarRange}>
          <div className={styles.text}>
            <Moment format={DATE_FORMAT.NoYear}>{startDate}</Moment>
            <span> - </span>
            <Moment format={endDateFormat}>{endDate}</Moment>
          </div>
        </div>
        <div className={styles.addWellnessBlock}>
          <Button
            icon={<Icon color={COLORS.primary} which="wellness" />}
            text="Add a Wellness block"
            onClick={_handleNewWellnessBlockClick}
          />
        </div>
      </div>
    );
  }
);
