import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, find, get, isEmpty } from 'lodash';

import { Article, RootState } from '../../misc/types';
import { getArticle } from '../../modules/articles';

import styles from '../../styles/article.module.scss';

interface ArticleViewProps {
  order: string;
  renderBy?: 'pull_quote' | 'title';
  tagList?: string;
}

export const ArticleView: React.ComponentType<ArticleViewProps> = React.memo(
  ({ tagList, order, renderBy = 'pull_quote' }) => {
    const dispatch = useDispatch();
    const articles = useSelector((state: RootState) => state.articles);

    useEffect(() => {
      if (!isEmpty(order)) {
        dispatch(getArticle(tagList, order));
      }
    }, [dispatch, order, tagList]);

    const findArticle = useCallback(
      (articles: Article[]) => {
        let thisArticle = null;

        if (tagList) {
          thisArticle = find(articles, function (o) {
            return o.attributes.tag_list.includes(tagList);
          });
        } else if (articles) {
          // Filter out array for 'renderBy
          const filteredArticles = filter(articles, function (o) {
            return !isEmpty(get(o.attributes, renderBy));
          });

          // Grab the first one in array
          thisArticle = filteredArticles[0] as Article;
        }

        return thisArticle;
      },
      [renderBy, tagList]
    );

    const renderArticleByPullQuote = (item: Article) => {
      if (isEmpty(item.attributes.pull_quote)) return null;
      const text = item.attributes.pull_quote.split('--')[0];
      const author = item.attributes.pull_quote.split('--')[1];

      return (
        <div className={styles.pullQuote}>
          <div className={styles.mark}>“</div>
          <div className={styles.text}>{text}</div>
          <div className={styles.author}>
            —{' '}
            <a href={item.attributes.url} target="_new">
              {author}
            </a>
          </div>
        </div>
      );
    };

    const renderArticleByTitle = (item: Article) => {
      if (isEmpty(item.attributes.title)) return null;

      return (
        <a href={item.attributes.url} target="_new">
          {item.attributes.title}
        </a>
      );
    };

    const thisArticle = useMemo(() => findArticle(articles) as Article, [
      articles,
      findArticle
    ]);
    if (isEmpty(thisArticle)) return null;

    if (renderBy === 'pull_quote') {
      return renderArticleByPullQuote(thisArticle);
    } else {
      return renderArticleByTitle(thisArticle);
    }
  }
);
