/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as api } from 'redux-json-api';

import actions from './actions';
import articles from './articles';
import calendars from './calendars';
import goals from './goals';
import invoices from './invoices';
import loading from './loading';
import maybe_wellness_blocks from './maybe-wellness-blocks';
import meeting_info from './meeting-info';
import meetings from './meetings';
import messages from './messages';
import reports from './reports';
import settings_labels from './settings-labels';
import subscriptions from './subscriptions';
import teams from './teams';
import tips from './tips';
import user from './user';
import wellness_blocks from './wellness-blocks';

export default history =>
  combineReducers({
    router: connectRouter(history),
    api,
    actions,
    articles,
    goals,
    invoices,
    loading,
    user,
    teams,
    maybe_wellness_blocks,
    meetings,
    meeting_info,
    wellness_blocks,
    reports,
    calendars,
    messages,
    settings_labels,
    subscriptions,
    tips
  });
