import React from 'react';

import { GetStartedButton } from './GetStartedButton';
import { HeroText } from './HeroArea';

import styles from '../../../styles/marketing/are-you-ready.module.scss';

interface HeroAreaProps {
  heroText: HeroText;
}

export const AreYouReady: React.ComponentType<HeroAreaProps> = React.memo(
  ({ heroText }) => {
    return (
      <section className={styles.areYouReady}>
        <h2 data-header="two">
          Ready for <span>work-life harmony</span>?
        </h2>
        <GetStartedButton heroText={heroText} />
      </section>
    );
  }
);
