/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isEmpty } from 'lodash';

import store from '../store';

const TIMEOUT = 300;

export function makeRequest(request) {
  const checkRequest = () => {
    // Get state and endpint
    const state = store.getState();
    const endpoint = state.api.endpoint || {};

    // Make sure baseURL is set + criteria is met
    if (
      isEmpty(endpoint.axiosConfig.baseURL) // If the baseURL is empty
    ) {
      setTimeout(() => checkRequest(), TIMEOUT); // Try again
    } else {
      request(); // Make request
    }
  };

  // Check the request
  checkRequest();
}
