import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../misc/types';
import { generallyUpdateTeam } from '../../modules/teams';
import { getUser } from '../../modules/user';
import { Alert, Input, TextLink } from '../design-system';
import { AlertProps } from '../design-system/Alert';

import styles from '../../styles/team-mgmt.module.scss';

export const EditTeamName: React.ComponentType = React.memo(() => {
  const user = useSelector((state: RootState) => state.user.general);

  const [alertDialog, setAlertDialog] = useState<AlertProps>({
    isVisible: false,
    title: 'Edit your team name',
    text: ''
  });

  const [teamNameText, setTeamNameText] = useState('');

  const _changeTeamNameText = useCallback(event => {
    setTeamNameText(event.target.value);
  }, []);

  const dispatch = useDispatch();

  const _toggleAlertDialog = useCallback(() => {
    setAlertDialog(prevState => {
      return {
        ...prevState,
        isVisible: !prevState.isVisible
      };
    });
  }, []);

  const _handleEditTeamName = useCallback(async () => {
    if (user) {
      dispatch(
        generallyUpdateTeam({
          team_id: user.attributes.company.id,
          which: 'name',
          value: teamNameText,
          callback: () => dispatch(getUser())
        })
      );
    }
  }, [dispatch, teamNameText, user]);

  useEffect(() => {
    if (user) {
      setTeamNameText(user.attributes.company.name);

      setAlertDialog(prevState => {
        return {
          ...prevState,
          isVisible: false
        };
      });
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <Alert
        {...alertDialog}
        confirmText={'Save name'}
        content={
          <Input
            className={styles.teamNameInput}
            placeholder="Your team name"
            value={teamNameText}
            onChange={_changeTeamNameText}
          />
        }
        isConfirmButtonDisabled={teamNameText === user.attributes.company.name}
        shouldShowCancel={true}
        onCancel={_toggleAlertDialog}
        onConfirm={_handleEditTeamName}
      />
      <div className={styles.teamName}>
        <div>{user.attributes.company.name}</div>
        <TextLink
          className={styles.link}
          text="Edit"
          onClick={_toggleAlertDialog}
        />
      </div>
    </>
  );
});
