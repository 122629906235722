import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { find, isEmpty } from 'lodash';

import { useTeamSubscription } from '../../hooks/team-subscription';
import { PLANS } from '../../misc/constants';
import { URLS } from '../../misc/consts';
import { Plan, Subscription } from '../../misc/types';
import { getPlanToSelect } from '../../misc/utils';
import { updateStripePaymentMethodCheckoutSession } from '../../modules/stripe-sessions';
import { Button } from '../design-system';

import styles from '../../styles/account.module.scss';

interface PlanInfoProps {
  onChangePlan: () => void;
  subscription?: Subscription;
}

export const PlanInfo: React.ComponentType<PlanInfoProps> = React.memo(
  function PlanInfo({ subscription, onChangePlan }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { addToast } = useToasts();
    const [, isAdminOfTeam] = useTeamSubscription();

    const _manageTeam = useCallback(() => {
      history.push(URLS.Account.TeamMgmt);
    }, [history]);

    const maybeShowToast = useCallback(
      response => {
        if (!isEmpty(response.error)) {
          addToast(`Something went wrong. ${response.error.message}`, {
            appearance: 'error',
            autoDismiss: false
          });
        }
      },
      [addToast]
    );

    const _updatePaymentMethod = useCallback(() => {
      dispatch(updateStripePaymentMethodCheckoutSession(maybeShowToast));
    }, [dispatch, maybeShowToast]);

    const getSettingsInfo = useCallback(
      (which: 'name') => {
        const selectedPlan = getPlanToSelect(subscription);
        if (selectedPlan) {
          const attributes: Plan =
            find(PLANS, ['id', selectedPlan.toString()]) || PLANS.Solo;
          return attributes[which];
        }
        return '';
      },
      [subscription]
    );

    return (
      <div className={styles.planInfo}>
        <div className={styles.info}>
          <div className={styles.content}>
            <div className={styles.planLabel}>Your plan</div>
            <div className={styles.name}>{getSettingsInfo('name')}</div>
          </div>
        </div>
        <div className={styles.changePlanBtn}>
          <Button
            centered
            fill
            text="Change your plan"
            onClick={onChangePlan}
          />
        </div>
        {isAdminOfTeam && (
          <>
            <div className={styles.manageTeamBtn}>
              <Button
                centered
                fill
                text="Manage your team"
                onClick={_manageTeam}
              />
            </div>
            <div className={styles.manageTeamBtn}>
              <Button
                centered
                fill
                text="Change payment method"
                onClick={_updatePaymentMethod}
              />
            </div>
          </>
        )}
      </div>
    );
  }
);
