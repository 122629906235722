import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';

import { REVIEW_VIEWS, URLS } from '../../misc/consts';
import {
  DateSelectionProps,
  Reports,
  SelectedView,
  SettingsLabels,
  WellnessBlock
} from '../../misc/types';
import {
  getComparisonText,
  getViewMetric,
  maybePluralize
} from '../../misc/utils';
import { Callout } from '../design-system';
import { ArticleView } from '../misc/ArticleView';

import { Metric } from './Metric';

import styles from '../../styles/review-misc-stats.module.scss';

interface MiscStatsProps {
  dateSelection: DateSelectionProps;
  reports: Reports;
  selectedView: SelectedView;
  settingsLabels: SettingsLabels;
  wellnessBlocks: WellnessBlock[];
}

export const MiscStats: React.ComponentType<MiscStatsProps> = React.memo(
  props => {
    return (
      <div className={classNames(styles.section, styles.miscStats)}>
        <h5>MISC</h5>
        <div className={styles.description}>
          Additional insights into your overall meeting wellness.
        </div>
        <div className={styles.inner}>
          <OvertimeMetric {...props} />
          <AvgAttendeesMetric {...props} />
          <AvgLengthMetric {...props} />
          <WellnessBlockMetric {...props} />
          <ScheduledLastMinMetric {...props} />
        </div>
      </div>
    );
  }
);

interface IndivMetricProps {
  dateSelection: DateSelectionProps;
  reports: Reports;
  selectedView: SelectedView;
  wellnessBlocks?: WellnessBlock[];
}

const OvertimeMetric: React.ComponentType<IndivMetricProps> = React.memo(
  ({ reports, selectedView, dateSelection }) => {
    const { startDate, endDate } = dateSelection;
    const count = getViewMetric(
      'overtime.attributes.total',
      reports,
      selectedView
    );

    const prevSpan = getViewMetric(
      'overtime.attributes.trends.prev_span_average',
      reports,
      selectedView
    );

    const comparisonText = getComparisonText(
      startDate,
      endDate,
      getViewMetric('overtime.attributes.spans_days', reports, selectedView)
    );

    return (
      <div className={styles.miscMetric}>
        <Metric
          comparisonText={comparisonText}
          data={count}
          descriptor="Meetings scheduled outside of normal work hours."
          label={maybePluralize(count, 'hour', 'hours')}
          prevSpan={prevSpan}
          title="Meetings outside 9-5"
          type="num"
        />
      </div>
    );
  }
);

const AvgAttendeesMetric: React.ComponentType<IndivMetricProps> = React.memo(
  ({ reports, selectedView, dateSelection }) => {
    const { startDate, endDate } = dateSelection;

    const count = getViewMetric(
      'average_attendees.attributes.average',
      reports,
      selectedView
    );

    const prevSpan = getViewMetric(
      'average_attendees.attributes.trends.prev_span_average',
      reports,
      selectedView
    );

    const comparisonText = getComparisonText(
      startDate,
      endDate,
      getViewMetric(
        'average_attendees.attributes.spans_days',
        reports,
        selectedView
      )
    );

    return (
      <div className={styles.miscMetric}>
        <Metric
          article={
            <ArticleView
              order="random"
              renderBy="title"
              tagList="max-attendees"
            />
          }
          comparisonText={comparisonText}
          data={count}
          prevSpan={prevSpan}
          title="Avg. attendees"
          type="num"
        />
      </div>
    );
  }
);

const AvgLengthMetric: React.ComponentType<IndivMetricProps> = React.memo(
  ({ reports, selectedView, dateSelection }) => {
    const { startDate, endDate } = dateSelection;

    const count = getViewMetric(
      'duration_and_costs.attributes.rows.total.ave_meeting_duration',
      reports,
      selectedView
    );

    const prevSpan = getViewMetric(
      'duration_and_costs.attributes.trends.totals.prev_span_average',
      reports,
      selectedView
    );

    const comparisonText = getComparisonText(
      startDate,
      endDate,
      getViewMetric(
        'duration_and_costs.attributes.spans_days',
        reports,
        selectedView
      )
    );

    return (
      <div className={styles.miscMetric}>
        <Metric
          comparisonText={comparisonText}
          data={count}
          descriptor="30 minutes is typically plenty for the average meeting."
          label="time"
          prevSpan={prevSpan}
          title="Avg. length"
          type="time"
        />
      </div>
    );
  }
);

const WellnessBlockMetric: React.ComponentType<IndivMetricProps> = React.memo(
  ({ reports, selectedView, dateSelection, wellnessBlocks }) => {
    const { startDate, endDate } = dateSelection;

    // Show a zero state if there are no focus blocks for the User
    if (isEqual(selectedView, REVIEW_VIEWS.You) && isEmpty(wellnessBlocks)) {
      return (
        <div className={styles.miscMetric}>
          <Metric title="Wellness block conflicts" type="num" />
          <Callout className={styles.noWellnessBlocksCallout}>
            <Link className={styles.link} to={URLS.Plan.NewBlock}>
              Create your first Wellness block
            </Link>{' '}
            to see this stat.
          </Callout>
        </div>
      );
    }

    const count = getViewMetric(
      'focus_block_conflict.attributes.total',
      reports,
      selectedView
    );

    const prevSpan = getViewMetric(
      'focus_block_conflict.attributes.trends.prev_span_total',
      reports,
      selectedView
    );

    const comparisonText = getComparisonText(
      startDate,
      endDate,
      getViewMetric(
        'focus_block_conflict.attributes.spans_days',
        reports,
        selectedView
      )
    );

    // Get descriptor
    const label = maybePluralize(count, 'meeting', 'meetings');
    let descriptor = `You attended ${count} ${label} that ${maybePluralize(
      count,
      'was',
      'were'
    )} scheduled over a wellness block.`;
    if (count === 0) {
      descriptor = `No conflicts here! Hopefully you are finding lots of time to focus :)`;
    }

    return (
      <div className={styles.miscMetric}>
        <Metric
          article={
            <ArticleView
              order="random"
              renderBy="title"
              tagList="focus-block"
            />
          }
          comparisonText={comparisonText}
          data={count}
          descriptor={descriptor}
          label={label}
          prevSpan={prevSpan}
          title="Wellness block conflicts"
          type="num"
        />
      </div>
    );
  }
);

const ScheduledLastMinMetric: React.ComponentType<IndivMetricProps> =
  React.memo(({ reports, selectedView, dateSelection }) => {
    const { startDate, endDate } = dateSelection;

    const count = getViewMetric(
      'scheduled_late.attributes.total',
      reports,
      selectedView
    );

    const prevSpan = getViewMetric(
      'scheduled_late.attributes.trends.prev_span_total',
      reports,
      selectedView
    );

    const comparisonText = getComparisonText(
      startDate,
      endDate,
      getViewMetric(
        'scheduled_late.attributes.spans_days',
        reports,
        selectedView
      )
    );

    // Get descriptor
    const label = maybePluralize(count, 'meeting', 'meetings');
    let descriptor = `You attended ${count} ${label} that ${maybePluralize(
      count,
      'was',
      'were'
    )} scheduled last minute.`;
    if (count === 0) {
      descriptor =
        'No issues here! Hopefully you are finding lots of time to get your work done :)';
    }

    return (
      <div className={styles.miscMetric}>
        <Metric
          article={
            <ArticleView
              order="random"
              renderBy="title"
              tagList="last-minute"
            />
          }
          comparisonText={comparisonText}
          data={count}
          descriptor={descriptor}
          label={label}
          prevSpan={prevSpan}
          title="Scheduled last minute"
          type="num"
        />
      </div>
    );
  });
