import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { isNumber, random } from 'lodash';

import { useOnScreen } from '../../../hooks/on-screen';
import { EXAMPLE_DESCRIPTION } from '../../../misc/test-data';
import { getAnimationOffset } from '../../../misc/utilities';

import { FakeBrowser } from './FakeBrowser';
import { MEETING_DATA } from './HowExampleMeeting';

import styles from '../../../styles/marketing/how-example-organizer-update.module.scss';

export const HowExampleOrganizerUpdate: React.ComponentType = React.memo(() => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen<HTMLDivElement>(ref, getAnimationOffset());

  const characters = useMemo(() => EXAMPLE_DESCRIPTION.split(''), []);
  const typings = useMemo(
    () =>
      characters.map(() => {
        return random(15, 100);
      }),
    [characters]
  );

  const [typedText, setTypedText] = useState('');

  useEffect(() => {
    if (isVisible) {
      const loc = EXAMPLE_DESCRIPTION.indexOf(typedText) + typedText.length;
      setTypedText(`${EXAMPLE_DESCRIPTION.slice(0, loc + 1)}`);
    } else {
      setTypedText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      const loc = EXAMPLE_DESCRIPTION.indexOf(typedText) + typedText.length;
      if (isNumber(typings[loc + 1])) {
        setTimeout(() => {
          return setTypedText(`${EXAMPLE_DESCRIPTION.slice(0, loc + 1)}`);
        }, typings[loc]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typedText]);

  return (
    <div ref={ref} className={styles.organizerUpdate}>
      <FakeBrowser elevation="one" title="Google/Outlook Calendar">
        <>
          <div className={styles.calBkg}>
            <div className={styles.meetingInvites}>
              <div className={styles.invit} />
              <div className={styles.invit} />
              <div className={styles.invit} />
              <div className={styles.invit} />
            </div>
          </div>
          <FakeBrowser
            className={styles.editingMeeting}
            elevation="one"
            title="Edit meeting">
            <div className={styles.inviteInner}>
              <div className={styles.inputGroup}>
                <label>Title</label>
                <div className={styles.input}>
                  {MEETING_DATA.Bad.attributes.summary}
                </div>
              </div>
              <div className={styles.inputGroup}>
                <label>Description</label>
                <div className={styles.input}>
                  <div className={styles.typing}>
                    <ReactMarkdown children={typedText} />
                  </div>
                  <div className={styles.ghost}>
                    <ReactMarkdown children={EXAMPLE_DESCRIPTION} />
                  </div>
                </div>
              </div>
            </div>
          </FakeBrowser>
        </>
      </FakeBrowser>
    </div>
  );
});
