/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Emoji from 'react-emoji-render';

import { SEO } from '../../components/misc/SEO';

import styles from '../../styles/marketing/misc.module.scss';

class NeedsPermissionView extends React.Component {
  render() {
    return (
      <>
        <SEO title="Incorrect permissions" />
        <div className={styles.miscPage}>
          <div className={styles.inner}>
            <div className={styles.emoji}>
              <Emoji text=":no_entry:" />
            </div>
            <h1>
              <span>Incorrect permissions!</span>
            </h1>
            <p>
              It looks like you didn't grant MeetWell the correct permissions
              when creating an account. Go to your Google or Outlook account to
              grant full access to your calendar.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default NeedsPermissionView;
