import React, { useEffect, useMemo, useState } from 'react';
import Moment from 'react-moment';
import moment, { Moment as MomentType } from 'moment';

import { DATE_FORMAT } from '../../misc/consts';

interface TimerProps {
  time?: MomentType | number;
  type: string;
}

export const Timer: React.ComponentType<TimerProps> = React.memo(
  ({ type, time }) => {
    const [currentTime, setCurrentTime] = useState(moment());

    useEffect(() => {
      if (type === 'elapsed-from') {
        setInterval(() => {
          setCurrentTime(moment());
        }, 1000);
      }
    }, [type]);

    const dateFormat =
      type === 'elapsed-from' || type === 'elapsed-with'
        ? 'm:ss'
        : DATE_FORMAT.Time;

    const timeToShow = useMemo(() => {
      if (type === 'clock') {
        return currentTime;
      } else if (type === 'elapsed-from' && time) {
        const secondsDiff = moment(currentTime).diff(time, 'seconds');
        return moment().startOf('day').seconds(secondsDiff);
      } else if (type === 'elapsed-with' && time) {
        return moment()
          .startOf('day')
          .seconds(time as number);
      }
    }, [currentTime, time, type]);

    return <Moment format={dateFormat}>{timeToShow}</Moment>;
  }
);
