import React, { useCallback, useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { find } from 'lodash';
import moment from 'moment';
import { StringParam, useQueryParam } from 'use-query-params';

import { useTeamSubscription } from '../../hooks/team-subscription';
import {
  INTENT,
  ONBOARDING_STEPS,
  PLAN_ACTIONS,
  QUERY_PARAMS,
  SOCIAL_LINKS,
  URLS
} from '../../misc/consts';
import { PlanAction, RootState } from '../../misc/types';
import { renderPlanTag } from '../../misc/utilities';
import { getTips, resetTips } from '../../modules/tips';
import { logoutUser, toggleOnboarding } from '../../modules/user';
import {
  Alert,
  Icon,
  MenuDivider,
  MenuItem,
  MenuItems,
  NavIcon,
  Popover,
  Tag,
  Tooltip
} from '../design-system';

import { PreviewReminderModal } from './PreviewReminderModal';
import { Tip } from './Tip';
import { WhoCanSeeThis } from './WhoCanSeeThis';

import styles from '../../styles/header.module.scss';

const LINKS = [
  {
    title: 'Review',
    slug: 'review',
    urlToMatch: URLS.Review.Default,
    onboardingStep: ONBOARDING_STEPS.Review
  },
  {
    title: 'Plan',
    slug: 'plan',
    urlToMatch: URLS.Plan.Default,
    onboardingStep: ONBOARDING_STEPS.Plan
  },
  {
    title: 'Balance',
    slug: 'balance',
    urlToMatch: URLS.Balance.Default,
    onboardingStep: ONBOARDING_STEPS.Balance
  }
];

export const Header: React.ComponentType = React.memo(() => {
  const location = useLocation();
  const history = useHistory();
  const [, isAdminOfTeam] = useTeamSubscription();

  // State
  const [isAccountPopoverVisible, setIsAccountPopoverVisible] = useState(false);
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] =
    useState(false);

  const [onboardingStep] = useQueryParam(QUERY_PARAMS.Onboarding, StringParam);

  // Dispatch and selectors
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user.general);
  const settings = useSelector((state: RootState) => state.user.settings);
  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );

  // Actions
  const _handleAutoBalanceClick = useCallback(() => {
    history.push(URLS.Balance.Action);
  }, [history]);

  const _handleUserInfoClick = useCallback(() => {
    setIsAccountPopoverVisible(prevState => !prevState);
  }, []);

  const _handleAccountClick = useCallback(() => {
    _handleUserInfoClick();
    history.push(URLS.Account.Default);
  }, [_handleUserInfoClick, history]);

  const _handleTeamMgmtClick = useCallback(() => {
    _handleUserInfoClick();
    history.push(URLS.Account.TeamMgmt);
  }, [_handleUserInfoClick, history]);

  const _handleChangePlanClick = useCallback(() => {
    _handleUserInfoClick();
    history.push(URLS.Balance.PlanChooser);
  }, [_handleUserInfoClick, history]);

  const _handleTourClick = useCallback(() => {
    setIsAccountPopoverVisible(prevState => !prevState);

    dispatch(
      resetTips(() => {
        dispatch(
          toggleOnboarding(false, () => {
            dispatch(
              getTips(() => {
                history.push(URLS.Review.Default);
              })
            );
          })
        );
      })
    );
  }, [dispatch, history]);

  const _handleHelpClick = useCallback(() => {
    _handleUserInfoClick();
    history.push('/help');
  }, [_handleUserInfoClick, history]);

  const _handleFAQClick = useCallback(() => {
    _handleUserInfoClick();
    history.push('/faq');
  }, [_handleUserInfoClick, history]);

  const _handleTwitterClick = useCallback(() => {
    _handleUserInfoClick();
    window.open(SOCIAL_LINKS.Twitter, '_blank')?.focus();
  }, [_handleUserInfoClick]);

  const _handleLinkedInClick = useCallback(() => {
    _handleUserInfoClick();
    window.open(SOCIAL_LINKS.LinkedIn, '_blank')?.focus();
  }, [_handleUserInfoClick]);

  const _handleSignOutClick = useCallback(() => {
    setIsConfirmationDialogVisible(prevState => !prevState);
  }, []);

  const _handleSignOut = useCallback(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  // Renderers
  const renderAutoBalanceInfo = () => {
    if (!settings) return null;

    const chosenComplianceAction =
      settings.attributes.action_settings.non_compliance_action;

    const chosenPlanActionDescription = find(PLAN_ACTIONS, function (o) {
      return o.value === chosenComplianceAction;
    }) as PlanAction;

    return (
      <>
        <div
          className={styles.autoBalanceInfo}
          onClick={_handleAutoBalanceClick}>
          <h6>Auto-Balance</h6>
          <div className={styles.text}>
            {chosenPlanActionDescription.type && (
              <div className={styles.actionTag}>
                {renderPlanTag({
                  value: chosenComplianceAction,
                  size: 'small',
                  overrideValue: chosenPlanActionDescription.type
                })}
              </div>
            )}
            <div
              className={styles.label}
              title={chosenPlanActionDescription.label}>
              {chosenPlanActionDescription.label}
            </div>
          </div>
        </div>
        <div className={styles.divider} />
      </>
    );
  };

  const maybeRenderSubStatus = () => {
    if (subscription) {
      if (
        !subscription.attributes.active || // Canceled
        subscription.attributes.status === 'trialing'
      ) {
        const daysLeft = moment(
          subscription.attributes.current_period_end
        ).diff(moment(), 'days');

        const tooltipContent = !subscription.attributes.active ? (
          <>
            {daysLeft !== 0 ? (
              <>You have {daysLeft} days left in your plan.</>
            ) : (
              <>Your plan ends today.</>
            )}
          </>
        ) : (
          <>
            {daysLeft !== 0 ? (
              <>You have {daysLeft} days left in your free trial.</>
            ) : (
              <>Your free trial ends today.</>
            )}
          </>
        );
        return (
          <Tooltip className={styles.countdown} content={tooltipContent}>
            <Tag small={true}>
              {daysLeft !== 0 ? `${daysLeft} days` : `Ending today`}
            </Tag>
          </Tooltip>
        );
      }
    }
    return null;
  };

  const maybeRenderUserInfo = () => {
    if (!user) return null;

    const profileImageAndName = (
      <>
        <div className={styles.image}>
          {user.attributes && user.attributes.profile_image ? (
            <img
              alt={user.attributes.last_name}
              crossOrigin="anonymous"
              referrerPolicy="no-referrer"
              src={user.attributes.profile_image}
            />
          ) : (
            <span className={styles.accountIcon}>
              <Icon color="#FFFFFF" which="people" />
            </span>
          )}
        </div>
        <div className={styles.text}>
          <div className={styles.name}>{user.attributes.first_name}</div>
          <div className={styles.plan}>
            {user.attributes.company.name}
            {maybeRenderSubStatus()}
          </div>
        </div>
      </>
    );

    return (
      <div className={styles.userInfoWrapper}>
        <Alert
          confirmText="Yes, sign me out"
          isVisible={isConfirmationDialogVisible}
          shouldShowCancel
          text="Are you sure you want to sign out?"
          title="Are you sure?"
          onCancel={_handleSignOutClick}
          onConfirm={_handleSignOut}
        />

        {/* Is mobile view? */}
        {isMobile && (
          <div className={styles.userInfo} onClick={_handleAccountClick}>
            {profileImageAndName}
          </div>
        )}

        {/* Is browser view? */}
        {isBrowser && (
          <Popover
            className={styles.userInfoPopover}
            content={
              <MenuItems>
                <MenuItem onClick={_handleAccountClick}>Account</MenuItem>
                {isAdminOfTeam && (
                  <MenuItem onClick={_handleTeamMgmtClick}>
                    Team management
                  </MenuItem>
                )}
                <MenuItem onClick={_handleTourClick}>Take a tour</MenuItem>
                {isAdminOfTeam && (
                  <MenuItem onClick={_handleChangePlanClick}>
                    Change plan...
                  </MenuItem>
                )}
                <MenuDivider />
                <MenuItem onClick={_handleTwitterClick}>
                  Follow us on Twitter
                </MenuItem>
                <MenuItem onClick={_handleLinkedInClick}>
                  Follow us on LinkedIn
                </MenuItem>
                <MenuItem onClick={_handleHelpClick}>
                  Help &amp; support
                </MenuItem>
                <MenuItem onClick={_handleFAQClick}>FAQ</MenuItem>
                <MenuDivider />
                <MenuItem intent={INTENT.Danger} onClick={_handleSignOutClick}>
                  Sign out...
                </MenuItem>
              </MenuItems>
            }
            isVisible={isAccountPopoverVisible}
            position="right"
            onToggle={_handleUserInfoClick}>
            <div className={styles.userInfo} onClick={_handleUserInfoClick}>
              {profileImageAndName}
            </div>
          </Popover>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={classNames(styles.header, styles.appHeader)}>
        <div className={styles.logo}>
          <Link to={URLS.Review.Default} />
        </div>
        <div className={styles.nav}>
          <div className={styles.appNav}>
            {LINKS.map((item, key) => {
              const shouldSelectTab = location.pathname.includes(
                `${URLS.Sub}/${item.slug}`
              );

              return (
                <Tip
                  {...item.onboardingStep}
                  key={key}
                  criteria={onboardingStep === item.onboardingStep.tag}>
                  <Link
                    className={classNames(styles.link, {
                      [styles.active]: shouldSelectTab
                    })}
                    id={item.onboardingStep.childId}
                    to={`${URLS.Sub}/${item.slug}`}>
                    <NavIcon isSelected={shouldSelectTab} which={item.slug} />
                    <div className={styles.text}>{item.title}</div>
                  </Link>
                </Tip>
              );
            })}
          </div>
        </div>
        <div className={styles.rightInfo}>
          {renderAutoBalanceInfo()}
          {maybeRenderUserInfo()}
        </div>
      </div>
      <WhoCanSeeThis />
      <PreviewReminderModal />
    </>
  );
});
