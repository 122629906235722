import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { find, isArray, isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { SEO } from '../../components/misc/SEO';
import { SyncingIndicator } from '../../components/misc/SyncingIndicator';
import { AllMetrics } from '../../components/review/AllMetrics';
import { SubNav } from '../../components/review/SubNav';
import { usePrevious } from '../../hooks/previous';
import { useTeamSubscription } from '../../hooks/team-subscription';
import {
  INVITE_STATUS_OPTIONS,
  REVIEW_VIEWS,
  ReviewViewProps
} from '../../misc/constants';
import { DATE_FORMAT, QUERY_PARAMS, URLS } from '../../misc/consts';
import {
  DateSelectionProps,
  InviteStatus,
  RootState,
  SelectedView
} from '../../misc/types';
import { buildReportingData } from '../../misc/utils';
import * as API from '../../modules/api';
import { getMeetings } from '../../modules/meetings';
import { metricsEvaluate } from '../../modules/reports';
import { getTeamMembers } from '../../modules/teams';
import { getWellnessBlocks } from '../../modules/wellness-blocks';

import styles from '../../styles/review.module.scss';

export interface ReviewFilterInfo {
  dateSelection: DateSelectionProps;
  selectedView: SelectedView;
  statusFilterData: InviteStatus[];
}

export const ReviewView: React.ComponentType = React.memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch<{
    statusFilter: string;
    view: string;
  }>();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  const [startParam] = useQueryParam(QUERY_PARAMS.Start, StringParam);
  const [endParam] = useQueryParam(QUERY_PARAMS.End, StringParam);
  const [shouldDownloadParam] = useQueryParam(
    QUERY_PARAMS.DownloadOrganizerStats,
    BooleanParam
  );

  const user = useSelector((state: RootState) => state.user.general);

  const isSyncingCalendar = useSelector(
    (state: RootState) => state.meeting_info.isSyncing
  );
  const refreshes = useSelector((state: RootState) => state.messages.refreshes);

  const prevRefreshes = usePrevious(refreshes);

  const defaultDateSelection = useMemo(() => {
    return {
      startDate: moment().startOf('week').toDate(),
      endDate: moment().endOf('week').toDate(),
      key: 'selection'
    };
  }, []);

  const [hasManyMembers, , selectedPlan] = useTeamSubscription();

  const _changeThePage = useCallback(
    ({ view, filter = {}, date = {} }) => {
      const currentView = match.params.view || 'you';
      const currentFilter = match.params.statusFilter || '';
      const currentDate =
        startParam && endParam ? `?s=${startParam}&e=${endParam}` : '';

      const nextView = !isEmpty(view) ? view.url : currentView;
      const nextFilter =
        !isEmpty(filter) && isArray(filter) ? filter.join() : currentFilter;
      const nextDate = !isEmpty(date)
        ? `?s=${date.start}&e=${date.end}`
        : currentDate;

      history.push(
        `${URLS.Review.Default}/${nextView}/${nextFilter}${nextDate}`
      );
    },
    [endParam, history, match, startParam]
  );

  const buildSelectedView = useCallback(() => {
    let thisView = REVIEW_VIEWS.You;
    if (match.params.view) {
      thisView = find(REVIEW_VIEWS, function (o) {
        return o.url === match.params.view;
      }) as ReviewViewProps;
    }
    return thisView;
  }, [match]);

  const buildDateSelection = useCallback(() => {
    if (startParam && endParam) {
      // Check if start date exists + end date is after start date
      const isEndAfterStart = endParam
        ? moment(endParam) >= moment(startParam)
        : true;
      const isValidDateParams = !isEmpty(startParam) && isEndAfterStart;

      // Make sure at least a start date exists ("s")
      if (isValidDateParams) {
        return {
          startDate: moment(startParam).toDate(),
          endDate: endParam
            ? moment(endParam).toDate()
            : moment(startParam).toDate() // End date is optional
        };
      } else {
        return defaultDateSelection;
      }
    }
    return defaultDateSelection;
  }, [defaultDateSelection, endParam, startParam]);

  const buildStatusFilterData = useCallback(() => {
    let filterData = [INVITE_STATUS_OPTIONS.All];

    // Only update if filter is in match.params && is a Team report
    if (!isEmpty(match.params) && !isEmpty(match.params.statusFilter)) {
      filterData = [];
      const paramArray = match.params.statusFilter.split(',');

      for (let i = 0; i < paramArray.length; i++) {
        //eslint-disable-next-line
        const thisOption = find(INVITE_STATUS_OPTIONS, function (o) {
          return o.url === paramArray[i];
        }) as InviteStatus;
        if (!isEmpty(thisOption)) {
          filterData.push(thisOption);
        } else {
          // Remove filter from array
          _changeThePage({ filter: [''] });
        }
      }
    }

    return filterData;
  }, [_changeThePage, match]);

  const [filterInfo, setFilterInfo] = useState<ReviewFilterInfo>({
    dateSelection: buildDateSelection(),
    selectedView: buildSelectedView(),
    statusFilterData: buildStatusFilterData()
  });
  const prevFilterInfo = usePrevious(filterInfo);

  const makeReportingAPICalls = useCallback(
    (who: string) => {
      const { startDate, endDate } = filterInfo.dateSelection;
      const data = buildReportingData({
        startDate,
        endDate,
        who,
        user,
        filters: {
          byAttendeeStatus: filterInfo.statusFilterData
        }
      });

      API.makeRequest(() => {
        // [Time spent] Total # of meetings
        // [Report card] Overall number
        // [Report card] % Good
        // [Report card] % Bad
        dispatch(
          metricsEvaluate({
            ...data,
            which: 'averages',
            shouldGetTrends: true
          })
        );

        // [Time spent] Total time
        // [Misc] Avg. length
        // [Time spent] Meeting cost
        dispatch(
          metricsEvaluate({
            ...data,
            which: 'duration_and_costs',
            shouldGetTrends: true
          })
        );

        // [Misc] Meetings outside 9-5
        dispatch(
          metricsEvaluate({
            ...data,
            which: 'overtime',
            shouldGetTrends: true
          })
        );

        // [Misc] Avg. # attendees
        dispatch(
          metricsEvaluate({
            ...data,
            which: 'average_attendees',
            shouldGetTrends: true
          })
        );

        // [Misc] Wellness block conflicts
        dispatch(
          metricsEvaluate({
            ...data,
            which: 'focus_block_conflict',
            shouldGetTrends: true
          })
        );

        // [Misc] Scheduled last minue
        dispatch(
          metricsEvaluate({
            ...data,
            which: 'scheduled_late',
            shouldGetTrends: true
          })
        );

        // [Details] Types, Intentions, and Organizers
        dispatch(
          metricsEvaluate({
            ...data,
            which: 'duration_and_cost_details',
            shouldGetTrends: false
          })
        );

        // [ROI Chart] Status
        dispatch(
          metricsEvaluate({
            ...data,
            which: 'average_scores_by',
            misc: '&group_by_organizer=true'
          })
        );

        dispatch(
          metricsEvaluate({
            ...data,
            which: 'num_past_actions_by'
          })
        );

        // Get meetings
        const start = moment().startOf('week').format(DATE_FORMAT.Hash);
        const end = moment().endOf('week').format(DATE_FORMAT.Hash);
        dispatch(getMeetings({ start, end }));

        ///// Teams only
        if (who === 'team') {
          // [Time spent] User counts
          dispatch(
            metricsEvaluate({
              ...data,
              which: 'user_counts'
            })
          );

          // [Time spent] Team member list
          dispatch(getTeamMembers(user?.attributes.company.id));
        }
      });
    },
    [dispatch, filterInfo, user]
  );

  const makeAPICalls = useCallback(() => {
    const { selectedView } = filterInfo;
    const { slug } = selectedView;

    // Validate calls should be made
    if (isEqual(selectedView, REVIEW_VIEWS.Team)) {
      if (hasManyMembers || shouldDownloadParam) {
        makeReportingAPICalls(slug); // Team
      }
    } else if (isEqual(selectedView, REVIEW_VIEWS.You)) {
      makeReportingAPICalls(slug); // User
      API.makeRequest(() => {
        dispatch(getWellnessBlocks());
      });
    }
  }, [
    filterInfo,
    hasManyMembers,
    shouldDownloadParam,
    makeReportingAPICalls,
    dispatch
  ]);

  // On Mount
  useEffect(() => {
    // Make sure a view is present, or else redirect
    if (isEmpty(match.params)) {
      _changeThePage({ view: REVIEW_VIEWS.You });
    } else if (selectedPlan) {
      makeAPICalls(); // Check if a sub view should be open
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan]);

  // Location update
  useEffect(() => {
    if (location && prevLocation && !isEqual(location, prevLocation)) {
      setFilterInfo({
        dateSelection: buildDateSelection(),
        selectedView: buildSelectedView(),
        statusFilterData: buildStatusFilterData()
      });
    }
  }, [
    buildDateSelection,
    buildSelectedView,
    buildStatusFilterData,
    location,
    prevLocation
  ]);

  // Filter update
  useEffect(() => {
    if (filterInfo && prevFilterInfo && !isEqual(filterInfo, prevFilterInfo)) {
      makeAPICalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterInfo]);

  // Check for websocket update
  useEffect(() => {
    if (
      refreshes &&
      refreshes.length > 0 &&
      !isEqual(refreshes, prevRefreshes)
    ) {
      makeAPICalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshes]);

  return (
    <>
      <SEO title="Review" />
      <SyncingIndicator isVisible={isSyncingCalendar} />
      <div className={styles.reviewView}>
        <SubNav
          dateSelection={filterInfo.dateSelection}
          isBrowser={isBrowser}
          selectedView={filterInfo.selectedView}
          statusFilterData={filterInfo.statusFilterData}
          onChangeThePage={_changeThePage}
        />
        <AllMetrics
          dateSelection={filterInfo.dateSelection}
          filterInfo={filterInfo}
          makeAPICalls={makeAPICalls}
        />
      </div>
    </>
  );
});
