import React from 'react';
import { DateRangePicker } from 'react-date-range';
import { isBrowser } from 'react-device-detect';
import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfWeek
} from 'date-fns';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

interface Range {
  endDate: Date;
  startDate: Date;
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1))
};

const staticRanges = [
  {
    label: 'This Week',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek
    }),
    isSelected(selectedRange: Range) {
      return (
        isSameDay(selectedRange.startDate, defineds.startOfWeek) &&
        isSameDay(selectedRange.endDate, defineds.endOfWeek)
      );
    }
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek
    }),
    isSelected(selectedRange: Range) {
      return (
        isSameDay(selectedRange.startDate, defineds.startOfLastWeek) &&
        isSameDay(selectedRange.endDate, defineds.endOfLastWeek)
      );
    }
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth
    }),
    isSelected(selectedRange: Range) {
      return (
        isSameDay(selectedRange.startDate, defineds.startOfMonth) &&
        isSameDay(selectedRange.endDate, defineds.endOfMonth)
      );
    }
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth
    }),
    isSelected(selectedRange: Range) {
      return (
        isSameDay(selectedRange.startDate, defineds.startOfLastMonth) &&
        isSameDay(selectedRange.endDate, defineds.endOfLastMonth)
      );
    }
  }
];

interface DatePickerProps {
  direction: string;
  maxDate?: Date;
  months: number;
  onChange: (dateSelection: string) => void;
  ranges: Range[];
}

export const DatePicker: React.ComponentType<DatePickerProps> = React.memo(
  ({ ranges, months, direction, maxDate, onChange }) => {
    return (
      <DateRangePicker
        direction={direction}
        inputRanges={[]}
        maxDate={maxDate}
        months={months}
        ranges={ranges}
        showDateDisplay={false}
        staticRanges={isBrowser ? staticRanges : []}
        onChange={onChange}
      />
    );
  }
);
