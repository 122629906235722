import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, sortBy } from 'lodash';

import { Member, User } from '../../misc/types';
import { deleteTeamMember, updateTeamMember } from '../../modules/teams';

import { TeamMemberRow } from './TeamMemberRow';

import styles from '../../styles/team-mgmt.module.scss';

interface TeamMembersProps {
  memberships: Member[];
  user: User;
}

export const TeamMembers: React.ComponentType<TeamMembersProps> = React.memo(
  ({ user, memberships }) => {
    const dispatch = useDispatch();

    const _handleUpdateTeamMember = useCallback(
      (member_id, data) => {
        dispatch(
          updateTeamMember({
            member_id,
            team_id: user.attributes.company.id,
            ...data
          })
        );
      },
      [dispatch, user]
    );

    const _handleDeleteTeamMember = useCallback(
      member => {
        dispatch(deleteTeamMember(member, user.attributes.company.id));
      },
      [dispatch, user]
    );

    // Sort members
    const sortedMembers = memberships
      ? sortBy(memberships, function (member) {
          return member.attributes.name;
        })
      : [];

    return (
      <div className={styles.section}>
        <h5>Team members</h5>
        <div className={styles.table}>
          <div className={styles.header}>
            <span>Name</span>
            <span>Email</span>
            <span>Role</span>
            <span />
          </div>
          {!isEmpty(sortedMembers) &&
            sortedMembers.map((item, index) => (
              <TeamMemberRow
                key={index}
                isCurrentUser={item.attributes.user_id.toString() === user.id}
                member={item}
                onDeleteTeamMember={_handleDeleteTeamMember}
                onUpdateTeamMember={_handleUpdateTeamMember}
              />
            ))}
        </div>
      </div>
    );
  }
);
