/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import { getMaybeWellnessBlocks } from './utils/maybe-wellness-blocks-utils';

export default (state = [], action) => {
  if (isValidAPICall(action, 'maybe_wellness_block')) {
    if (action.type === 'API_READ') {
      return action.payload.data;
    }
  }
  return state;
};

export { getMaybeWellnessBlocks };
