import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { cloneDeep, isEmpty, sortBy } from 'lodash';

import { DELETE_STATUS, INTENT } from '../../misc/consts';
import { ObjectiveItem, SectionViewProps } from '../../misc/types';
import { Button, Tag, Tooltip } from '../design-system';

import styles from '../../styles/meeting-modal.module.scss';

export const SectionObjectivesView: React.ComponentType<SectionViewProps> =
  React.memo(({ event, className, settings }) => {
    const [objectiveItems, setObjectiveItems] = useState<ObjectiveItem[]>([]);

    const SectionHeader = () => {
      const { required_objectives } = settings.attributes.evaluation_settings;

      return (
        <div className={styles.header}>
          <h6>Objectives</h6>
          {required_objectives && (
            <Tag
              intent={
                objectiveItems.length === 0 ? INTENT.Danger : INTENT.Success
              }
              small={true}>
              Required
            </Tag>
          )}
          <div className={styles.how}>
            <Tooltip content="Learn how to format your meeting description to correctly parse your objectives.">
              <Button
                intent="primary"
                minimal={true}
                small={true}
                text="How?"
                onClick={() => window.open('/faq#plan-add-objective', '_blank')}
              />
            </Tooltip>
          </div>
        </div>
      );
    };

    const ListObjectiveItems = () => {
      // Return empty state if none
      if (objectiveItems.length === 0) {
        return <div className={styles.emptyText}>No objectives.</div>;
      }

      // Make sure they're sorted by position
      const sortedObjectiveItems = sortBy(cloneDeep(objectiveItems), [
        function (o) {
          return o.attributes.position;
        }
      ]);

      // Render one item
      const renderItem = (item: ObjectiveItem, key: number) => {
        if (item.status === DELETE_STATUS) return null;
        return (
          <div key={key} className={styles.objectiveItem}>
            <div className={styles.description}>
              <div className={styles.num}>{key + 1})</div>
              <div className={styles.text}>{item.attributes.description}</div>
            </div>
          </div>
        );
      };

      return (
        <>
          <div className={styles.objectiveItems}>
            {sortedObjectiveItems.map((item, key) => renderItem(item, key))}
          </div>
        </>
      );
    };

    // Initialize
    useEffect(() => {
      // Set objective items
      const { objectives } = event.attributes;
      const hasObjectiveItems =
        !isEmpty(objectives.data) && objectives.data.length > 0;
      const itemsToShow = hasObjectiveItems ? objectives.data : [];
      setObjectiveItems(itemsToShow);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    return (
      <div className={classNames(styles.section, className)}>
        <SectionHeader />
        <ListObjectiveItems />
      </div>
    );
  });
