import React, { useCallback, useMemo, useState } from 'react';
import { find, isEmpty } from 'lodash';

import { ROLES } from '../../misc/consts';
import { Member } from '../../misc/types';
import { Button, Dollar, MenuItem, MenuItems, Popover } from '../design-system';

import styles from '../../styles/team-mgmt.module.scss';

interface TeamMemberRowProps {
  isCurrentUser: boolean;
  member: Member;
  onDeleteTeamMember: (member: Member) => void;
  onUpdateTeamMember: (
    member_id: Member['id'],
    data: Record<string, unknown>
  ) => void;
}

export const TeamMemberRow: React.ComponentType<TeamMemberRowProps> =
  React.memo(
    ({ member, isCurrentUser, onDeleteTeamMember, onUpdateTeamMember }) => {
      const [rolePopoverVisible, setRolePopoverVisible] = useState(false);

      const role = useMemo(() => member.attributes.role, [member]);

      const _handleRolePopoverClick = useCallback(() => {
        setRolePopoverVisible(prevState => !prevState);
      }, []);

      const _handleChangeRole = useCallback(
        newRole => {
          setRolePopoverVisible(false);
          onUpdateTeamMember(member.id, { role: newRole });
        },
        [member, onUpdateTeamMember]
      );

      const _handleRemoveTeamMember = useCallback(() => {
        setRolePopoverVisible(false);
        onDeleteTeamMember(member);
      }, [member, onDeleteTeamMember]);

      // Set role vars
      let chosenRole = find(ROLES, function (r) {
        return r.value === role;
      });
      if (isEmpty(role)) chosenRole = ROLES.User;

      return (
        <div className={styles.row}>
          <span className={styles.name}>
            {member.attributes.name} {isCurrentUser && `(you)`}
          </span>
          <span className={styles.email}>{member.attributes.email}</span>
          <span>
            <Popover
              content={
                <MenuItems>
                  {(Object.keys(ROLES) as Array<keyof typeof ROLES>).map(
                    key => (
                      <MenuItem
                        key={key}
                        isDisabled={
                          chosenRole
                            ? chosenRole.value === ROLES[key].value
                            : false
                        }
                        onClick={() => _handleChangeRole(ROLES[key].value)}>
                        {ROLES[key].label}
                      </MenuItem>
                    )
                  )}
                  <MenuItem intent="danger" onClick={_handleRemoveTeamMember}>
                    Remove user
                  </MenuItem>
                </MenuItems>
              }
              isVisible={rolePopoverVisible}
              onToggle={_handleRolePopoverClick}>
              <Button
                disabled={isCurrentUser}
                shouldLookDisabled
                text={chosenRole && chosenRole.label}
                onClick={_handleRolePopoverClick}
              />
            </Popover>
          </span>
          <span>
            <Dollar />5
          </span>
        </div>
      );
    }
  );
