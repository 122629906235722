import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { find, isEmpty, sortBy } from 'lodash';

import { COLORS } from '../../misc/constants';
import { INTENT, WELLNESS_BLOCKS } from '../../misc/consts';
import {
  MaybeWellnessBlock,
  RootState,
  WellnessBlockCategory
} from '../../misc/types';
import { capitalizeFirstLetter } from '../../misc/utils';
import { getMaybeWellnessBlocks } from '../../modules/maybe-wellness-blocks';
import { generallyUpdateMeeting } from '../../modules/meetings';
import { getWellnessBlocks } from '../../modules/wellness-blocks';
import { Button, Tag, Tooltip } from '../design-system';
import { MeetingDate } from '../plan/MeetingDate';

import styles from '../../styles/balance.module.scss';

export const MaybeWellnessBlocks: React.ComponentType = React.memo(
  function MaybeWellnessBlocks() {
    const dispatch = useDispatch();
    const maybeWellnessBlocks = useSelector(
      (state: RootState) => state.maybe_wellness_blocks
    );

    const sortedWellnessBlocks = useMemo(() => {
      return sortBy(maybeWellnessBlocks, [
        o => {
          return o.attributes.summary;
        }
      ]);
    }, [maybeWellnessBlocks]);

    useEffect(() => {
      dispatch(getMaybeWellnessBlocks());
    }, [dispatch]);

    const _takeAction = useCallback(
      (id, action) => {
        dispatch(
          generallyUpdateMeeting(id, 'maybe_wellness_block', action, () => {
            dispatch(getWellnessBlocks());
            dispatch(getMaybeWellnessBlocks());
          })
        );
      },
      [dispatch]
    );

    if (isEmpty(maybeWellnessBlocks)) {
      return null;
    }

    return (
      <div className={styles.maybeWellnessBlocks}>
        <h5>Maybe wellness blocks?</h5>
        <div className={styles.desc}>
          MeetWell has detected that the meetings below may be either a Focus or
          Life block. What do you think?
        </div>
        <div className={styles.card}>
          {sortedWellnessBlocks.map((item, index) => (
            <MaybeBlock key={index} item={item} onTakeAction={_takeAction} />
          ))}
        </div>
      </div>
    );
  }
);

interface MaybeBlockProps {
  item: MaybeWellnessBlock;
  onTakeAction: (id: MaybeWellnessBlock['id'], value: string) => void;
}

const MaybeBlock: React.ComponentType<MaybeBlockProps> = React.memo(
  function MaybeBlock({ item, onTakeAction }) {
    const [isConverting, setIsConverting] = useState(false);
    const [isIgnoring, setIsIgnoring] = useState(false);

    useEffect(() => {
      setIsConverting(false);
      setIsIgnoring(false);
    }, [item]);

    const blockCategory = useMemo(
      () => item.attributes.maybe_wellness_block_category,
      [item]
    );
    const tooltipContent = useMemo(() => {
      const category = find(WELLNESS_BLOCKS, function (o) {
        return o.value === blockCategory;
      }) as WellnessBlockCategory;

      let finalContent = '';
      if (!isEmpty(category)) {
        finalContent = category.description;
      }
      return finalContent;
    }, [blockCategory]);

    const _convertMeeting = useCallback(() => {
      setIsConverting(true);
      onTakeAction(item.id, 'verified_true');
    }, [item, onTakeAction]);

    const _ignoreMeeting = useCallback(() => {
      setIsIgnoring(true);
      onTakeAction(item.id, 'verified_false');
    }, [item, onTakeAction]);

    // Error checking
    if (
      blockCategory !== WELLNESS_BLOCKS.Focus.value &&
      blockCategory !== WELLNESS_BLOCKS.Life.value
    ) {
      return null;
    }

    return (
      <div className={styles.block}>
        <div className={styles.icon}>
          <Tooltip
            className={classNames(styles.iconWrapper, {
              [styles.focus]: blockCategory === WELLNESS_BLOCKS.Focus.value,
              [styles.life]: blockCategory === WELLNESS_BLOCKS.Life.value
            })}
            content={tooltipContent}>
            <div
              className={styles.questionMark}
              style={{
                color:
                  blockCategory === WELLNESS_BLOCKS.Life.value
                    ? COLORS.darkPurple
                    : COLORS.primary
              }}>
              ?
            </div>
          </Tooltip>
        </div>
        <div className={styles.info}>
          <div className={styles.label}>{item.attributes.summary}</div>
          <div className={styles.ruleAndTime}>
            <MeetingDate
              allDay={false}
              endDate={item.attributes.ends_at}
              startDate={item.attributes.starts_at}
            />
            {item.attributes.recurring_meeting && (
              <Tag small={true}>Recurring</Tag>
            )}
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            intent={INTENT.Primary}
            isLoading={isConverting}
            minimal={true}
            small={isBrowser ? false : true}
            text={
              isBrowser
                ? `Convert to ${capitalizeFirstLetter(blockCategory)} block`
                : 'Convert'
            }
            onClick={_convertMeeting}
          />
          <Button
            intent={INTENT.Danger}
            isLoading={isIgnoring}
            minimal={true}
            small={isBrowser ? false : true}
            text="Ignore"
            onClick={_ignoreMeeting}
          />
        </div>
      </div>
    );
  }
);
