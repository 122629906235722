import React from 'react';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  WEBSOCKET_BROKEN,
  WEBSOCKET_CLOSED,
  WEBSOCKET_CONNECT,
  WEBSOCKET_DISCONNECT,
  WEBSOCKET_MESSAGE,
  WEBSOCKET_OPEN,
  WEBSOCKET_SEND
} from '@giantmachines/redux-websocket';

import { log } from '../misc/utils';

const WEBSOCKET_PREFIX = 'REDUX_WEBSOCKET';
export const REDUX_WEBSOCKET_BROKEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_BROKEN}`;
export const REDUX_WEBSOCKET_OPEN = `${WEBSOCKET_PREFIX}::${WEBSOCKET_OPEN}`;
export const REDUX_WEBSOCKET_CLOSED = `${WEBSOCKET_PREFIX}::${WEBSOCKET_CLOSED}`;
export const REDUX_WEBSOCKET_MESSAGE = `${WEBSOCKET_PREFIX}::${WEBSOCKET_MESSAGE}`;
export const REDUX_WEBSOCKET_CONNECT = `${WEBSOCKET_PREFIX}::${WEBSOCKET_CONNECT}`;
export const REDUX_WEBSOCKET_DISCONNECT = `${WEBSOCKET_PREFIX}::${WEBSOCKET_DISCONNECT}`;
export const REDUX_WEBSOCKET_SEND = `${WEBSOCKET_PREFIX}::${WEBSOCKET_SEND}`;

const initialState = {
  notifications: [],
  refreshes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'API_CREATE_FAILED':
    case 'API_READ_FAILED':
    case 'API_UPDATE_FAILED':
    case 'API_RELATIONSHIP_UPDATE_FAILED':
    case 'API_DELETE_FAILED':
    case 'API_RELATIONSHIP_DELETE_FAILED': {
      console.log(action);
      const data =
        action.payload && action.payload.response
          ? action.payload.response.data
          : [];
      const messageData = data && data.resource ? data.resource.message : '';
      const messageToShow = messageData ? (
        messageData
      ) : (
        <>
          Something is not right... our nerds have been notified!{' '}
          <a href="mailto:support@meetwell.app">Contact Support</a> with any
          questions.
        </>
      );
      return {
        ...state,
        notifications: [
          {
            message: messageToShow,
            appearance: 'error',
            autoDismiss: false
          }
        ]
      };
    }
    // falls through

    case REDUX_WEBSOCKET_CONNECT:
    case REDUX_WEBSOCKET_OPEN:
    case REDUX_WEBSOCKET_BROKEN:
    case REDUX_WEBSOCKET_CLOSED:
    case REDUX_WEBSOCKET_SEND:
      return state;
    // falls through

    case REDUX_WEBSOCKET_MESSAGE: {
      const message = JSON.parse(action.payload.message);
      log(message);

      // Add to store if part of "NotificationChannel"
      if (isValidChannelCall(message, 'NotificationChannel')) {
        return {
          ...state,
          notifications: state.notifications.concat([
            {
              data: message,
              message: message.message,
              origin: action.payload.origin,
              timestamp: action.meta.timestamp
            }
          ])
        };
      } else if (isValidChannelCall(message, 'MeetingRefreshChannel')) {
        return {
          ...state,
          refreshes: [
            {
              data: JSON.parse(action.payload.message),
              message: message.message,
              origin: action.payload.origin,
              timestamp: new Date(action.meta.timestamp)
            }
          ]
        };
      }
    }
    // falls through

    default:
      return state;
  }
};

function isValidChannelCall(message, channel) {
  let identifier;
  if (message.identifier) identifier = JSON.parse(message.identifier);
  if (
    identifier &&
    identifier.channel === channel &&
    message.type !== 'confirm_subscription'
  ) {
    return true;
  }
  return false;
}
