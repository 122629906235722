import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cloneDeep, filter, isEmpty, sortBy } from 'lodash';

import { Invite } from '../../misc/types';
import { cancelInvite } from '../../modules/teams';
import { Alert, AlertProps } from '../design-system/Alert';

import { PendingInviteRow } from './PendingInviteRow';

import styles from '../../styles/team-mgmt.module.scss';

interface PendingInvitesProps {
  invites: Invite[];
  makeAPICalls: () => void;
}

export const PendingInvites: React.ComponentType<PendingInvitesProps> = React.memo(
  ({ invites, makeAPICalls }) => {
    const dispatch = useDispatch();

    const _handleToggleAlert = useCallback(() => {
      setAlertDialog(prevState => {
        return {
          ...prevState,
          isVisible: !prevState.isVisible
        };
      });
    }, []);

    const [alertDialog, setAlertDialog] = useState<AlertProps>({
      isVisible: false,
      title: 'Are you sure?',
      text: 'This invite will be revoked.',
      confirmText: 'Yes, cancel invite',
      onConfirm: undefined,
      onCancel: _handleToggleAlert
    });

    const _handleCancelInvite = useCallback(
      (invite_id, callback) => {
        setAlertDialog(prevState => {
          return {
            ...prevState,
            isVisible: true,
            onConfirm: () => {
              dispatch(
                cancelInvite(invite_id, () => {
                  callback && callback();
                  makeAPICalls();
                })
              );
              _handleToggleAlert();
            }
          };
        });
      },
      [_handleToggleAlert, dispatch, makeAPICalls]
    );

    // Clone and filter invites
    let pendingInvites = cloneDeep(invites);
    pendingInvites = filter(pendingInvites, function (invite) {
      return isEmpty(invite.attributes.accepted_at); // filter to where "accepted_at" is null
    });

    if (isEmpty(pendingInvites)) return null;

    // Sort invites
    pendingInvites = sortBy(pendingInvites, function (invite) {
      return invite.attributes.email;
    });

    return (
      <div className={styles.section}>
        <Alert {...alertDialog} />
        <h5>Pending invites</h5>
        <div className={styles.table}>
          <div className={styles.header}>
            <span>Email</span>
            <span>Invited at</span>
            <span />
          </div>
          {pendingInvites.map((item, index) => (
            <PendingInviteRow
              key={index}
              invite={item}
              onCancelInvite={_handleCancelInvite}
            />
          ))}
        </div>
      </div>
    );
  }
);
