import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Emoji from 'react-emoji-render';
import Moment from 'react-moment';
import classNames from 'classnames';
import { get, isEmpty, isEqual } from 'lodash';
import moment from 'moment';

import { useTeamSubscription } from '../../hooks/team-subscription';
import { DATE_FORMAT, REVIEW_VIEWS } from '../../misc/consts';
import { DateSelectionProps, SelectedView } from '../../misc/types';
import { getDateRangeText } from '../../misc/utils';
import { Button, Icon, Popover } from '../design-system';

import { DatePicker } from './DatePicker';
import { ExportButton } from './ExportButton';
import { StatusFilter, StatusFilterData } from './StatusFilter';

import popoverStyles from '../../styles/popover.module.scss';
import styles from '../../styles/review.module.scss';

interface SubNavProps {
  dateSelection: DateSelectionProps;
  isBrowser: boolean;
  onChangeThePage: (data: Record<string, unknown>) => void;
  selectedView: SelectedView;
  statusFilterData: StatusFilterData[];
}

export const SubNav: React.ComponentType<SubNavProps> = React.memo(
  ({
    dateSelection,
    isBrowser,
    selectedView,
    statusFilterData,
    onChangeThePage
  }) => {
    const [hasManyMembers] = useTeamSubscription();
    const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);
    const [stagedDateSelection, setStagedDateSelection] =
      useState<DateSelectionProps>(dateSelection);

    const _handleViewSelect = useCallback(
      newView => {
        onChangeThePage({ view: newView });
      },
      [onChangeThePage]
    );

    useEffect(() => {
      setStagedDateSelection(dateSelection);
    }, [dateSelection]);

    const _handleDateRangeToggle = useCallback(
      ({ startDate, endDate }) => {
        // If is closing
        if (isDateRangeVisible) {
          // Change page if staged selection exists
          const FORMAT = 'YYYY-MM-DD';
          const startDateFormatted = moment(startDate).format(FORMAT);
          const endDateFormatted = moment(endDate).format(FORMAT);
          onChangeThePage({
            date: {
              start: startDateFormatted,
              end: endDateFormatted
            }
          });
        }

        // Toggle
        setIsDateRangeVisible(prevState => !prevState);
      },
      [isDateRangeVisible, onChangeThePage]
    );

    const _handleDateSelect = useCallback(
      sel => {
        const selection = sel.selection ?? sel.range1;
        // Set new date selection
        const startDate = selection.startDate;
        const endDate = selection.endDate;

        setStagedDateSelection({
          key: 'selection',
          startDate,
          endDate
        });

        // Close date range picker unless same day
        if (!moment(startDate).isSame(moment(endDate), 'day')) {
          _handleDateRangeToggle({ startDate, endDate });
        }
      },
      [_handleDateRangeToggle]
    );

    const renderDateRangeText = useMemo(() => {
      const { startDate, endDate } = stagedDateSelection;
      const labelText = getDateRangeText(startDate, endDate);

      // If no range, just show one day
      if (moment(startDate).isSame(moment(endDate), 'day')) {
        return (
          <div className={styles.dateRangeText}>
            <Moment format={DATE_FORMAT.Default}>{startDate}</Moment>
          </div>
        );
      }

      // Show range
      return (
        <div className={styles.dateRangeText}>
          {!isEmpty(labelText) && (
            <div className={styles.labelText}>{labelText}:</div>
          )}
          <Moment format={DATE_FORMAT.Default}>{startDate}</Moment>
          <div className={styles.arrows}>
            <Icon which="comparison-arrows" />
          </div>
          <Moment format={DATE_FORMAT.Default}>{endDate}</Moment>
        </div>
      );
    }, [stagedDateSelection]);

    const BrowserSubNav = useMemo(() => {
      return (
        <div className={styles.subNav}>
          <div className={styles.viewToggles}>
            {Object.keys(REVIEW_VIEWS).map(key => {
              const thisView = get(REVIEW_VIEWS, key);
              if (!thisView.display) return null;
              return (
                <Button
                  key={key}
                  active={isEqual(selectedView, thisView)}
                  icon={<Emoji text={thisView.emoji} />}
                  large
                  minimal
                  text={thisView.label}
                  onClick={() => _handleViewSelect(thisView)}
                />
              );
            })}
          </div>
          <div
            className={classNames(styles.filters, {
              [styles.disabled]:
                isEqual(selectedView, REVIEW_VIEWS.Team) && !hasManyMembers
            })}>
            <StatusFilter
              statusFilterData={statusFilterData}
              onChangeThePage={onChangeThePage}
            />
            <div className={styles.dateRangePicker}>
              <Popover
                content={
                  <DatePicker
                    direction="horizontal"
                    months={2}
                    ranges={[stagedDateSelection]}
                    onChange={_handleDateSelect}
                  />
                }
                isVisible={isDateRangeVisible}
                position="right"
                onToggle={() => _handleDateRangeToggle(stagedDateSelection)}>
                <Button
                  text={renderDateRangeText}
                  onClick={() => _handleDateRangeToggle(stagedDateSelection)}
                />
              </Popover>
            </div>
            <ExportButton stagedDateSelection={stagedDateSelection} />
          </div>
        </div>
      );
    }, [
      _handleDateRangeToggle,
      _handleDateSelect,
      _handleViewSelect,
      isDateRangeVisible,
      hasManyMembers,
      onChangeThePage,
      renderDateRangeText,
      selectedView,
      stagedDateSelection,
      statusFilterData
    ]);

    const MobileSubNav = useMemo(() => {
      return (
        <div className={styles.subNav}>
          <div className={styles.viewToggles}>
            {Object.keys(REVIEW_VIEWS).map(key => {
              const thisView = get(REVIEW_VIEWS, key);
              if (!thisView.display) return null;
              return (
                <Button
                  key={key}
                  active={selectedView.slug === thisView.slug}
                  minimal
                  text={thisView.label}
                  onClick={() => _handleViewSelect(thisView)}
                />
              );
            })}
          </div>
          <div className={styles.dateRangePicker}>
            <Popover
              content={
                <div className={popoverStyles.dateRangePickerPopover}>
                  <DatePicker
                    direction="vertical"
                    months={1}
                    ranges={[stagedDateSelection]}
                    onChange={_handleDateSelect}
                  />
                </div>
              }
              isVisible={isDateRangeVisible}
              onToggle={() => _handleDateRangeToggle(stagedDateSelection)}>
              <Button
                centered
                fill
                minimal
                text={renderDateRangeText}
                onClick={() => _handleDateRangeToggle(stagedDateSelection)}
              />
            </Popover>
          </div>
        </div>
      );
    }, [
      _handleDateRangeToggle,
      _handleDateSelect,
      _handleViewSelect,
      isDateRangeVisible,
      renderDateRangeText,
      selectedView.slug,
      stagedDateSelection
    ]);

    if (isBrowser) {
      return BrowserSubNav;
    }
    return MobileSubNav;
  }
);
