import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { INTENT } from '../../misc/consts';

import styles from '../../styles/design-system.module.scss';

export interface CalloutProps {
  children: ReactNode;
  className?: string;
  intent?: string;
}

export const Callout: React.ComponentType<CalloutProps> = React.memo(
  function Callout({ className, intent, children }) {
    return (
      <div
        className={classNames(styles.callout, className, {
          [styles.caution]: intent === INTENT.Caution
        })}>
        {children}
      </div>
    );
  }
);
