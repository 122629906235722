import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { SEO } from '../../components/misc/SEO';
import { QUERY_PARAMS, REFERRAL_COOKIE_NAME, URLS } from '../../misc/consts';
import { RootState } from '../../misc/types';
import * as API from '../../modules/api';
import {
  generallyUpdateUser,
  getUser,
  setTimeZone,
  setTokenAndConfig
} from '../../modules/user';

export const SessionView: React.ComponentType = React.memo(() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: RootState) => state.user.general);

  const [tokenParam] = useQueryParam(QUERY_PARAMS.Token, StringParam);
  const [isImpersonation] = useQueryParam(QUERY_PARAMS.Token, BooleanParam);

  useEffect(() => {
    // Make API calls
    if (!isImpersonation) {
      API.makeRequest(() => {
        // Set timezone
        dispatch(setTimeZone());

        // Maybe set referral token
        const cookies = new Cookies();
        const referralCookie = cookies.get(REFERRAL_COOKIE_NAME);
        if (referralCookie) {
          dispatch(
            generallyUpdateUser({
              which: 'referred_by',
              value: referralCookie
            })
          );
          cookies.remove(REFERRAL_COOKIE_NAME, { path: '/' });
        }
      });
    }
    dispatch(
      setTokenAndConfig(tokenParam, () => {
        API.makeRequest(() => {
          dispatch(getUser());
        });
      })
    );
  }, [dispatch, isImpersonation, location, tokenParam]);

  useEffect(() => {
    if (!isEmpty(user)) {
      history.push(URLS.Review.Default);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <SEO title="Logging in..." />
      <div className="placeholder-view">
        <h1>Logging in...</h1>
      </div>
    </>
  );
});
