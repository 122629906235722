import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';

import { DATE_FORMAT } from '../../misc/consts';
import { DateSelectionProps } from '../../misc/types';
import { resyncMeetings } from '../../modules/meetings';
import { Alert, Button, Icon, Tooltip } from '../design-system';

interface ResyncButtonProps {
  dateSelection?: DateSelectionProps;
  minimal?: boolean;
}

export const ResyncButton: React.ComponentType<ResyncButtonProps> = React.memo(
  ({ dateSelection, minimal = true }) => {
    const startDate = dateSelection?.startDate;
    const endDate = dateSelection?.endDate;

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [messageData, setMessageData] = useState({
      attributes: {
        message:
          'Re-evaluating your meetings can take several minutes. Are you sure you want to continue?',
        meta: {
          button: 'Yes, start a sync',
          can_sync: true
        }
      }
    });

    const [isMaybeResyncing, setIsMaybeResyncing] = useState(false);

    const thisStartDate = useMemo(
      () => startDate || moment().format(DATE_FORMAT.Hash),
      [startDate]
    );
    const thisEndDate = useMemo(
      () => endDate || moment().format(DATE_FORMAT.Hash),
      [endDate]
    );

    const _setNewMessageData = useCallback(response => {
      setMessageData(response.body.data);
    }, []);

    const _toggleResync = useCallback(() => {
      const start = moment(thisStartDate).format(DATE_FORMAT.Hash);
      const end = moment(thisEndDate).format(DATE_FORMAT.Hash);

      setIsMaybeResyncing(prevState => {
        if (!prevState) {
          dispatch(resyncMeetings({ start, end }, _setNewMessageData));
        }

        return !prevState;
      });
    }, [_setNewMessageData, dispatch, thisEndDate, thisStartDate]);

    const _handleResync = useCallback(() => {
      setIsMaybeResyncing(false);

      const start = moment(thisStartDate).format(DATE_FORMAT.Hash);
      const end = moment(thisEndDate).format(DATE_FORMAT.Hash);

      // Go!
      dispatch(
        resyncMeetings({ start, end, confirmed: true }, () => {
          addToast(
            `Your calendar is being synchronized. It might take few moments.`,
            {
              appearance: 'info',
              autoDismiss: true
            }
          );
        })
      );
    }, [addToast, dispatch, thisEndDate, thisStartDate]);

    return (
      <>
        <Alert
          confirmText={messageData.attributes.meta.button}
          isVisible={isMaybeResyncing}
          shouldShowCancel={messageData.attributes.meta.can_sync}
          text={messageData.attributes.message}
          title={
            messageData.attributes.meta.can_sync
              ? 'Are you sure?'
              : 'Already in progress'
          }
          onCancel={_toggleResync}
          onConfirm={
            messageData.attributes.meta.can_sync ? _handleResync : _toggleResync
          }
        />
        <Tooltip content="Re-sync meetings" isDisabled={!minimal}>
          <Button
            centered={!minimal}
            icon={<Icon which="refresh" />}
            text={minimal ? '' : 'Re-sync meetings'}
            onClick={_toggleResync}
          />
        </Tooltip>
      </>
    );
  }
);
