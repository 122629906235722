/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { readEndpoint } from 'redux-json-api';

import { log } from '../../misc/utils';

export const getCalendars = external => {
  log(`getCalendars(${external})`);

  // Build the 'external' query parameter
  const getExternal = external ? '?external=true' : '';

  return dispatch => {
    dispatch(readEndpoint(`calendars/${getExternal}`));
  };
};

export const getCalendarEventColors = () => {
  log('getCalendarEventColors()');
  return dispatch => {
    dispatch(readEndpoint('event_colors'));
  };
};
