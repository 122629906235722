import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  cloneDeep,
  filter,
  find,
  findIndex,
  forEach,
  isEmpty,
  isEqual
} from 'lodash';
import moment, { Moment } from 'moment';
import { ByWeekday, RRule, rrulestr, Weekday } from 'rrule';
import { StringParam, useQueryParam } from 'use-query-params';

import { usePrevious } from '../../hooks/previous';
import {
  API_REQ_DELAY,
  INTENT,
  QUERY_PARAMS,
  URLS,
  WELLNESS_BLOCKS
} from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { RootState, WellnessBlock } from '../../misc/types';
import { dateRangeOverlaps, formatRRule } from '../../misc/utils';
import {
  createWellnessBlock,
  deleteWellnessBlock,
  updateWellnessBlock
} from '../../modules/wellness-blocks';
import {
  Alert,
  Button,
  Callout,
  ErrorState,
  Input,
  SegmentedControl,
  SelectInput,
  TimeRangePicker,
  Tooltip
} from '../design-system';
import { AlertProps } from '../design-system/Alert';
import { SegmentedControlData } from '../design-system/SegmentedControl';

import { ModalView } from './ModalView';
import { WellnessBlockSuggestions } from './WellnessBlockSuggestions';

import styles from '../../styles/wellness-blocks.module.scss';

const BLOCK_OPTIONS = [
  {
    value: WELLNESS_BLOCKS.Focus.value,
    label: WELLNESS_BLOCKS.Focus.label
  },
  { value: WELLNESS_BLOCKS.Life.value, label: WELLNESS_BLOCKS.Life.label }
];

interface WellnessBlockModalProps {
  isVisible?: boolean;
  onClose: () => void;
}

export const WellnessBlockModal: React.ComponentType<WellnessBlockModalProps> =
  React.memo(({ isVisible = false, onClose }) => {
    const [wbLabelParam] = useQueryParam(QUERY_PARAMS.WBLabel, StringParam);

    const calendars = useSelector((state: RootState) => state.calendars.list);
    const wellness_blocks = useSelector(
      (state: RootState) => state.wellness_blocks
    );

    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch<{
      blockId: string;
    }>();
    const location = useLocation();

    const [isThisVisible, setIsThisVisible] = useState<boolean>(false);
    const [selectedBlock, setSelectedBlock] = useState<WellnessBlock | null>(
      null
    );
    const previousSelectedBlock = usePrevious<WellnessBlock | null>(
      selectedBlock
    );
    const [ruleAndTime, setRuleAndTime] = useState<string | undefined>(
      undefined
    );
    const [category, setCategory] = useState<typeof BLOCK_OPTIONS[0]>(
      BLOCK_OPTIONS[0]
    );
    const [label, setLabel] = useState<string | undefined>(undefined);
    const [canEditDays, setCanEditDays] = useState<boolean>(false);
    const [isTimeRangeValid, setIsTimeRangeValid] = useState<boolean>(true);
    const [segData, setSegData] = useState<SegmentedControlData[]>([]);
    const [fromTime, setFromTime] = useState<string>('');
    const [fromTimeMoment, setFromTimeMoment] = useState<Moment>();
    const [toTime, setToTime] = useState<string>('');
    const [toTimeMoment, setToTimeMoment] = useState<Moment>();
    const [calendarId, setCalendarId] = useState<number | undefined>(undefined);
    const [confirmationDialog, setConfirmationDialog] = useState<AlertProps>({
      isVisible: false,
      title: '',
      text: '',
      onConfirm: undefined
    });

    const _setValues = useCallback(() => {
      let ruleAndTime = '';
      let canEditDays = true;
      const segData = [
        { label: 'Monday', value: 0, isSelected: false },
        { label: 'Tuesday', value: 1, isSelected: false },
        { label: 'Wednesday', value: 2, isSelected: false },
        { label: 'Thursday', value: 3, isSelected: false },
        { label: 'Friday', value: 4, isSelected: false },
        { label: 'Saturday', value: 5, isSelected: false },
        { label: 'Sunday', value: 6, isSelected: false }
      ];

      if (selectedBlock) {
        if (selectedBlock.attributes.rrule) {
          const rrule = rrulestr(selectedBlock.attributes.rrule);
          const isDaily = selectedBlock.attributes.rrule.includes('DAILY');
          ruleAndTime = formatRRule({
            rrule,
            starts_at: selectedBlock.attributes.starts_at,
            ends_at: selectedBlock.attributes.ends_at,
            recurring: selectedBlock.attributes.recurring
          });

          // If daily, select all
          if (isDaily) {
            for (let s = 0; s < segData.length; s++) {
              segData[s].isSelected = true;
            }

            // If weekly, just select the chosen ones
          } else if (!isEmpty(rrule.options.byweekday)) {
            const days = rrule.options.byweekday;
            for (let i = 0; i < days.length; i++) {
              const thisDay = days[i];
              const thisIndex = findIndex(segData, function (o) {
                return o.value === thisDay;
              });
              if (thisIndex > -1) segData[thisIndex].isSelected = true;
            }
          } else {
            canEditDays = false;
          }
        }

        setLabel(selectedBlock.attributes.label);
        setSegData(segData);
        setRuleAndTime(ruleAndTime);
        setCanEditDays(canEditDays);
        setFromTime(
          moment(selectedBlock.attributes.starts_at).format('h:mm a')
        );
        setFromTimeMoment(moment(selectedBlock.attributes.starts_at));
        setToTime(moment(selectedBlock.attributes.ends_at).format('h:mm a'));
        setToTimeMoment(moment(selectedBlock.attributes.ends_at));
        setIsTimeRangeValid(true);
        setCalendarId(selectedBlock.attributes.calendar_id);
        setCategory(
          find(
            BLOCK_OPTIONS,
            o => o.value === selectedBlock.attributes.category
          ) as typeof BLOCK_OPTIONS[0]
        );
      } else {
        setLabel('');
        setSegData(segData);
        setRuleAndTime(ruleAndTime);
        setCanEditDays(canEditDays);
        setFromTime('1:00 pm');
        setFromTimeMoment(moment().set({ hour: 13, minute: 0 }));
        setToTime('2:00 pm');
        setToTimeMoment(moment().set({ hour: 14, minute: 0 }));
        setIsTimeRangeValid(true);
        setCalendarId(
          !isEmpty(calendars) ? parseInt(calendars[0].id) : undefined
        );
        setCategory(BLOCK_OPTIONS[0]);
      }
      setConfirmationDialog({
        isVisible: false,
        title: '',
        text: '',
        shouldShowCancel: false,
        onConfirm: undefined
      });
    }, [calendars, selectedBlock]);

    const _handleCloseConfirmationDialog = useCallback(() => {
      setConfirmationDialog({
        title: '',
        text: '',
        isVisible: false,
        shouldShowCancel: false
      });
    }, []);

    const _showAlertDialog = useCallback(
      (title, text) => {
        setConfirmationDialog({
          title,
          text,
          isVisible: true,
          onConfirm: _handleCloseConfirmationDialog
        });
      },
      [_handleCloseConfirmationDialog]
    );

    const _showModal = useCallback(() => {
      setIsThisVisible(true);
    }, []);

    const _closeModal = useCallback(() => {
      setIsThisVisible(false);
      setTimeout(() => {
        setSelectedBlock(null);
        onClose();
      }, 300);
    }, [onClose]);

    const _handleDeleteBlock = useCallback(() => {
      return setConfirmationDialog({
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this wellness block?',
        isVisible: true,
        shouldShowCancel: true,
        onConfirm: () => {
          dispatch(deleteWellnessBlock(selectedBlock));
          _closeModal(); // Close modal
        }
      });
    }, [_closeModal, dispatch, selectedBlock]);

    const _handleCreateOrUpdateBlock = useCallback(
      (makeWeekly?: boolean) => {
        // Build form data
        const getRRuleString = function () {
          const byweekday: ByWeekday[] = [];
          const rruleDayMapping: Record<number, Weekday> = {
            0: RRule.MO,
            1: RRule.TU,
            2: RRule.WE,
            3: RRule.TH,
            4: RRule.FR,
            5: RRule.SA,
            6: RRule.SU
          };

          const selected = filter(segData, { isSelected: true });
          forEach(selected, function (item) {
            byweekday.push(rruleDayMapping[item.value as number]);
          });

          const rule = new RRule({
            freq: RRule.WEEKLY,
            byweekday
          });

          return rule.toString();
        };

        const formData = {
          id: selectedBlock ? selectedBlock.id : '',
          label,
          // If days can't be edited, then pull rrule from selected block
          rrule:
            canEditDays || makeWeekly
              ? getRRuleString()
              : selectedBlock && selectedBlock.attributes.rrule,
          toTime: toTime && moment(toTimeMoment).format(),
          fromTime: fromTime && moment(fromTimeMoment).format(),
          calendarId,
          category: category && category.value
        };

        // Validate
        if (!formData.label) {
          return _showAlertDialog(undefined, 'Please add a label.');
        } else if (
          isEmpty(formData.rrule) ||
          formData.rrule === 'RRULE:FREQ=WEEKLY' // This string means it's empty
        ) {
          return _showAlertDialog(
            undefined,
            'Please pick one or multiple days.'
          );
        } else if (
          isEmpty(formData.fromTime) ||
          isEmpty(formData.toTime) ||
          !isTimeRangeValid
        ) {
          return _showAlertDialog(
            undefined,
            'Please pick a valid start and end time.'
          );
        } else if (
          dateRangeOverlaps(
            selectedBlock ? selectedBlock.id : '',
            fromTimeMoment,
            toTimeMoment,
            formData.rrule,
            cloneDeep(wellness_blocks)
          )
        ) {
          // Check if time range overlaps
          return _showAlertDialog(
            undefined,
            "Please pick a time range that doesn't overlap with an existing Wellness block."
          );
        }

        // Create Wellness block
        if (!selectedBlock || makeWeekly) {
          dispatch(createWellnessBlock(formData));
          Fathom.trackGoal(GOALS.WellnessBlocks.Created);
        } else {
          dispatch(updateWellnessBlock(formData));
          Fathom.trackGoal(GOALS.WellnessBlocks.Edited);
        }

        // Close modal
        _closeModal();
      },
      [
        _closeModal,
        _showAlertDialog,
        calendarId,
        canEditDays,
        category,
        dispatch,
        fromTime,
        fromTimeMoment,
        isTimeRangeValid,
        label,
        segData,
        selectedBlock,
        toTime,
        toTimeMoment,
        wellness_blocks
      ]
    );

    const _handleGoToSettings = useCallback(() => {
      history.push(URLS.Account.Default);
    }, [history]);

    const _checkIfShouldShow = useCallback(() => {
      // Maybe show focus block deep link
      if (
        location.pathname.includes(URLS.Plan.ViewBlock) ||
        location.pathname.includes(URLS.Balance.ViewBlock)
      ) {
        // If is to create a new one
        if (
          location.pathname.includes(URLS.Plan.NewBlock) ||
          location.pathname.includes(URLS.Balance.NewBlock)
        ) {
          _showModal();
        } else {
          const blockIdToShow = match.params.blockId;
          if (!isEmpty(blockIdToShow)) {
            if (wellness_blocks) {
              const thisBlock = find(wellness_blocks, function (o) {
                return o.id.toString() === blockIdToShow.toString();
              }) as WellnessBlock;
              setSelectedBlock(thisBlock);
              _showModal();
            } else {
              setTimeout(_checkIfShouldShow, API_REQ_DELAY);
            }
          }
        }
      }
    }, [_showModal, location, match, wellness_blocks]);

    const onChangeNameText = useCallback(event => {
      setLabel(event.target.value);
    }, []);

    const onChangeBlockCategory = useCallback(selectedOption => {
      setCategory(selectedOption);
    }, []);

    const onChangeCalendar = useCallback(selectedOption => {
      setCalendarId(selectedOption.value);
    }, []);

    const renderBlockView = () => {
      // Make sure user has a calendar
      if (isEmpty(calendars)) {
        return (
          <ErrorState
            action={
              <Button text="Go to settings" onClick={_handleGoToSettings} />
            }
            description="You must first have a calendar connected before adding or editing Wellness blocks."
            title="No calendar added."
          />
        );
      }

      const watchedCalendars = calendars
        ? calendars.filter(obj => obj.attributes.watching)
        : [];

      const calendarOptions =
        watchedCalendars.length > 0
          ? watchedCalendars.map(item => ({
              value: item.attributes.uuid,
              label: item.attributes.name
            }))
          : [];

      const modalTitle = selectedBlock
        ? 'Editing Wellness block'
        : 'New weekly Wellness block';

      // Only allow blocks to be editable if are recurring or in the future
      let isSavingDisabled = false;
      if (selectedBlock) {
        isSavingDisabled =
          (!selectedBlock.attributes.recurring &&
            moment(selectedBlock.attributes.ends_at).isBefore(moment())) ||
          selectedBlock.attributes.delete_only;
      }

      const disabledReason = selectedBlock?.attributes.delete_only
        ? `You can't edit a converted Wellness block.`
        : `You can't edit a non-recurring Wellness block from the past.`;

      // Maybe render a left label in the segmented control
      let maybeLeftLabel: string | undefined = 'Every';
      if (selectedBlock && !selectedBlock.attributes.recurring) {
        maybeLeftLabel = undefined;
      }

      return (
        <>
          <h2>{modalTitle}</h2>
          {selectedBlock?.attributes.delete_only && (
            <Callout className={styles.disabledCallout} intent={INTENT.Caution}>
              {disabledReason}
              <Tooltip
                className={styles.makeWeeklyBtn}
                content="Create a new weekly wellness block with all of the info below.">
                <Button
                  intent={INTENT.Primary}
                  minimal={true}
                  small={true}
                  text="Make weekly"
                  onClick={() => _handleCreateOrUpdateBlock(true)}
                />
              </Tooltip>
            </Callout>
          )}
          <div className={styles.section}>
            <h5>What for?</h5>
            <Input
              placeholder="Name of block"
              value={label}
              onChange={onChangeNameText}
            />
            <WellnessBlockSuggestions
              label={label}
              setLabel={text => setLabel(text)}
            />
          </div>
          <div className={styles.section}>
            <h5>What kind of block?</h5>
            <SelectInput
              isDisabled={isSavingDisabled}
              options={BLOCK_OPTIONS}
              value={category}
              onChange={onChangeBlockCategory}
            />
          </div>
          <div className={styles.section}>
            <h5>Which days?</h5>
            {ruleAndTime && (
              <div className={styles.ruleAndTime}>{ruleAndTime}</div>
            )}
            <SegmentedControl
              data={segData}
              isDisabled={!canEditDays || isSavingDisabled}
              leftLabel={maybeLeftLabel}
              vertical
              onClick={which => {
                const temp = [...segData];
                temp[which].isSelected = !segData[which].isSelected;
                setSegData(temp);
              }}
            />
          </div>
          <div className={styles.section}>
            <h5>What time?</h5>
            <TimeRangePicker
              fromTime={fromTime}
              isDisabled={isSavingDisabled}
              toTime={toTime}
              onChange={(
                fromTime,
                toTime,
                fromTimeMoment,
                toTimeMoment,
                isValid
              ) => {
                setFromTime(fromTime);
                setToTime(toTime);
                setFromTimeMoment(fromTimeMoment);
                setToTimeMoment(toTimeMoment);
                setIsTimeRangeValid(isValid);
              }}
            />
          </div>
          {calendarOptions.length > 1 && (
            <div className={styles.section}>
              <h5>Which Calendar?</h5>
              <SelectInput
                options={calendarOptions}
                value={calendarOptions[0]}
                onChange={onChangeCalendar}
              />
            </div>
          )}
          <div className={styles.section}>
            <Tooltip content={disabledReason} isDisabled={!isSavingDisabled}>
              <Button
                centered
                disabled={isSavingDisabled}
                intent="primary"
                large
                shouldLookDisabled
                text="Save"
                onClick={() => _handleCreateOrUpdateBlock()}
              />
            </Tooltip>
            {selectedBlock && (
              <>
                <div className={styles.divider} />
                <Button
                  centered
                  className={styles.deleteButton}
                  intent={INTENT.Danger}
                  large
                  text="Delete"
                  onClick={_handleDeleteBlock}
                />
              </>
            )}
          </div>
        </>
      );
    };

    // Initial load
    useEffect(() => {
      setIsThisVisible(false);
      _checkIfShouldShow();
    }, [_checkIfShouldShow]);

    // On location changes
    useEffect(() => {
      _checkIfShouldShow();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // Data load
    useEffect(() => {
      if (!isEqual(previousSelectedBlock, selectedBlock)) {
        _setValues();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousSelectedBlock, selectedBlock]);

    // Check for isVisible changes
    useEffect(() => {
      setIsThisVisible(isVisible);
    }, [isVisible]);

    // For wbLabel query param
    useEffect(() => {
      if (wbLabelParam) {
        setLabel(wbLabelParam);
      }
    }, [wbLabelParam]);

    return (
      <>
        <Alert {...confirmationDialog} />
        <ModalView
          className={styles.wellnessBlocksModal}
          isDismissible={true}
          isVisible={isThisVisible}
          position="drawer"
          onClose={_closeModal}>
          {renderBlockView()}
        </ModalView>
      </>
    );
  });
