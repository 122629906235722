/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import {
  addTeamMember,
  cancelInvite,
  deleteTeamMember,
  generallyUpdateTeam,
  getInvites,
  getTeamMembers,
  getTeams,
  inviteTeamMember,
  saveTeamDenylistOrganizers,
  updateTeamInviteSetting,
  updateTeamMember
} from './utils/teams-utils';

const initialState = {
  list: [],
  memberships: [],
  invites: []
};

export default (state = initialState, action) => {
  if (isValidAPICall(action, 'teams')) {
    const thisData = action.payload.data;
    if (action.type === 'API_UPDATED') {
      // .data is kept around so we can use `meta`, which holds counts
      return {
        ...state,
        list: state.list.map(item =>
          item.id === thisData.id ? thisData : item
        )
      };
    }
    return {
      ...state,
      list: action.payload.data || []
    };
  }

  if (isValidAPICall(action, 'memberships')) {
    const thisData = action.payload.data;
    if (action.type === 'API_UPDATED') {
      // .data is kept around so we can use `meta`, which holds counts
      return {
        ...state,
        memberships: {
          ...state.memberships.data,
          data: state.memberships.data.map(item =>
            item.id === thisData.id ? thisData : item
          )
        }
      };
    } else if (action.type === 'API_WAS_DELETED') {
      // .data is kept around so we can use `meta`, which holds counts
      const member_id = action.payload.data.attributes.user_id;
      return {
        ...state,
        memberships: {
          ...state.memberships.data,
          data: state.memberships.data.filter(function (item) {
            return item.attributes.user_id !== member_id;
          })
        }
      };
    }
    return {
      ...state,
      memberships: action.payload || []
    };
  }

  if (isValidAPICall(action, 'invites')) {
    const thisData = action.payload.data;
    if (action.type === 'API_CREATED') {
      return {
        ...state,
        invites: state.invites.concat([thisData])
      };
    }

    return {
      ...state,
      invites: thisData || []
    };
  }

  return state;
};

export {
  getTeams,
  getTeamMembers,
  addTeamMember,
  updateTeamMember,
  updateTeamInviteSetting,
  generallyUpdateTeam,
  saveTeamDenylistOrganizers,
  deleteTeamMember,
  getInvites,
  inviteTeamMember,
  cancelInvite
};
