import { sample } from 'lodash';

import {
  InviteStatus,
  Operator,
  Plan,
  PreferenceRow,
  SelectedView
} from './types';

export const COST_OF_MW = 5;

const URL_SUBDOMAIN = '/my';
export const URLS = {
  Sub: URL_SUBDOMAIN,
  Review: {
    Default: `${URL_SUBDOMAIN}/review`
  },
  Onboarding: {
    Default: `${URL_SUBDOMAIN}/review/you/?onboarding=`
  },
  Plan: {
    Default: `${URL_SUBDOMAIN}/plan`,
    Meeting: `${URL_SUBDOMAIN}/plan/meeting`,
    ViewBlock: `${URL_SUBDOMAIN}/plan/block`,
    NewBlock: `${URL_SUBDOMAIN}/plan/block/new`
  },
  Balance: {
    Default: `${URL_SUBDOMAIN}/balance`,
    Evaluation: `${URL_SUBDOMAIN}/balance/evaluation`,
    Action: `${URL_SUBDOMAIN}/balance/action`,
    PlanChooser: `${URL_SUBDOMAIN}/balance/action/choose-plan`,
    WellnessBlocks: `${URL_SUBDOMAIN}/balance/wellness-blocks`,
    ViewBlock: `${URL_SUBDOMAIN}/balance/wellness-blocks/block`,
    NewBlock: `${URL_SUBDOMAIN}/balance/wellness-blocks/block/new`
  },
  Account: {
    Default: `${URL_SUBDOMAIN}/account`,
    TeamMgmt: `${URL_SUBDOMAIN}/team-management`
  }
};

export const INTENT = {
  Primary: 'primary',
  Success: 'success',
  Caution: 'caution',
  Warning: 'warning',
  Danger: 'danger'
};

export const COLORS = {
  success: '#1db08c',
  caution: '#fed93a',
  warning: '#ff8a00',
  danger: '#ff3146',
  white: '#ffffff',
  primary: '#1f78f7',
  purple: '#d597d8',
  darkPurple: '#9650A1',
  border: 'rgba(147, 165, 193, 0.18)',
  muted: '#577097'
};

export const OPERATORS: Record<string, Operator> = {
  LessThan: {
    label: 'Less than',
    symbol: '<'
  },
  LessThanEqualTo: {
    label: 'Less than or equal to',
    symbol: '≤'
  },
  GreaterThan: {
    label: 'Greater than',
    symbol: '>'
  },
  GreaterThanEqualTo: {
    label: 'Greater than or equal to',
    symbol: '≥'
  }
};

export const PREFERENCES = <const>[
  'desired_duration',
  'focus_block_conflict',
  'meeting_conflict',
  'last_minute_schedule',
  'required_agenda',
  'required_objectives',
  'required_intention',
  'required_prework',
  'required_outcomes',
  'max_attendees',
  'required_attendee_roles',
  'ignore_all_day_meetings',
  'ignore_all_hands_meetings',
  'required_conference_info'
];

export const PREFERENCE_ROWS: Record<string, PreferenceRow[]> = {
  TimeAndDuration: [
    {
      which: 'desired_duration',
      type: 'stat',
      statType: 'mins',
      operator: OPERATORS.LessThanEqualTo
    },
    {
      which: 'focus_block_conflict'
    },
    {
      which: 'meeting_conflict'
    },
    {
      which: 'last_minute_schedule',
      type: 'stat',
      statType: 'hours',
      operator: OPERATORS.GreaterThan
    }
  ],
  Content: [
    {
      which: 'required_agenda',
      example: 'Review draft quarterly roadmap'
    },
    {
      which: 'required_objectives',
      example: 'Decide on roadmap priorities'
    },
    {
      which: 'required_intention',
      example: 'Brainstorming'
    },
    {
      which: 'required_prework',
      example: 'Links to a doc'
    },
    {
      which: 'required_outcomes',
      example: 'Share with rest of the team.'
    },
    {
      which: 'required_conference_info',
      example: 'Brainstorming'
    }
  ],
  Attendees: [
    {
      which: 'max_attendees',
      type: 'stat',
      statType: 'people',
      operator: OPERATORS.LessThan
    },
    {
      which: 'required_attendee_roles'
    }
  ]
};

export const PLANS: Record<string, Plan> = {
  Trial: {
    amount: 0,
    hideFromDisplay: true,
    id: 'trial-500',
    name: 'Free trial'
  },
  Solo: {
    amount: 5,
    id: 'team-500',
    sub: `For the price of a ${sample([
      'latte',
      '59oz orange juice',
      '2 lb frozen chicken breast',
      'pack of 50 envelopes',
      '20 lb bag of cat litter',
      'pair of nice knitted socks'
    ])}!`,
    name: 'Just you',
    emoji: ':tipping_hand_person:'
  },
  Team: {
    amount: 50,
    id: 'team-5000',
    sub: 'Save $$$ by assembling the whole team!',
    name: 'Your team',
    emoji: ':dancers:'
  }
};

export interface ReviewViewProps {
  display: boolean;
  emoji: string;
  label: string;
  slug: string;
  url: string;
}

export const REVIEW_VIEWS: Record<string, ReviewViewProps> = {
  Team: {
    label: 'Your Team',
    slug: 'team',
    url: 'team',
    emoji: ':factory:',
    display: true
  },
  Groups: {
    label: 'Groups',
    slug: 'group',
    url: 'groups',
    emoji: ':people_with_bunny_ears:',
    display: false
  },
  You: {
    label: 'Just you',
    slug: 'user',
    url: 'you',
    emoji: ':crown:',
    display: true
  }
};

export const INVITE_STATUS_OPTIONS: Record<string, InviteStatus> = {
  All: {
    label: 'All',
    value: '',
    url: '',
    disabled: false,
    intent: '',
    icon: ''
  },
  Accepted: {
    label: 'Accepted',
    value: 'confirmed',
    url: 'accepted',
    icon: 'tick',
    intent: INTENT.Success,
    disabled: false
  },
  Tentative: {
    label: 'Tentative',
    value: 'tentative',
    url: 'tentative',
    icon: 'question',
    disabled: true,
    intent: INTENT.Caution
  },
  NoResponse: {
    label: 'Unresponded',
    value: 'none',
    url: 'unresponded',
    icon: 'question',
    disabled: true,
    intent: INTENT.Warning
  },
  Declined: {
    label: 'Declined',
    value: 'declined',
    url: 'declined',
    disabled: true,
    icon: 'tick',
    intent: INTENT.Danger
  }
};

export const BALANCE_VIEWS: Record<string, SelectedView> = {
  Action: {
    label: 'Auto-balance',
    mobileLabel: 'Auto-balance',
    slug: 'action',
    url: 'action',
    emoji: ':robot_face:',
    display: true
  },
  Evaluation: {
    label: 'Meeting evaluations',
    mobileLabel: 'Evaluations',
    slug: 'evaluation',
    url: 'evaluation',
    emoji: ':graduation_cap:',
    display: true
  },
  WellnessBlocks: {
    label: 'Wellness blocks',
    mobileLabel: 'Blocks',
    slug: 'wellness-blocks',
    url: 'wellness-blocks',
    emoji: ':purple_heart:',
    display: true
  },
  ChoosePlan: {
    label: 'Choose plan',
    slug: 'choose-plan',
    url: 'choose-plan',
    emoji: '',
    display: false
  }
};

export const PAGE_TO_ALLOW_ACCESS = [
  URLS.Account.Default,
  `${URLS.Account.Default}/`
];

export const DIV_IDS = {
  Scroll: 'scrollDiv',
  SecondaryScroll: 'secondaryScrollDiv',
  FullReview: 'review-full',
  ROILeaderBoard: 'roi-leaderboard',
  TeamROI: 'team-roi'
};

export const INTENTION_EMOJIS: Record<string, string> = {
  all_hands: '✋',
  brainstorming: '🧠',
  consensus: '✅',
  information: '💡',
  kickoff: '🏈',
  one_on_one: '🏀',
  planning: '📅',
  retrospective: '⏪',
  standup: '🕴',
  teambuilding: '🎳'
};
