import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { REVIEW_VIEWS } from '../../misc/constants';
import { Fathom, GOALS } from '../../misc/fathom';
import { Reports, SelectedView, User } from '../../misc/types';
import { formatMoney, getViewMetric } from '../../misc/utils';
import { generallyUpdateTeam } from '../../modules/teams';
import { generallyUpdateUser } from '../../modules/user';
import {
  Button,
  Dollar,
  EditingPopover,
  Icon,
  Input,
  Tooltip
} from '../design-system';

import popoverStyles from '../../styles/popover.module.scss';
import styles from '../../styles/review-summary-stats.module.scss';

interface EditSalaryButtonProps {
  onMakeAPICalls?: () => void;
  reports: Reports;
  selectedView: SelectedView;
  user: User;
}

export const EditSalaryButton: React.ComponentType<EditSalaryButtonProps> =
  React.memo(({ selectedView, user, reports, onMakeAPICalls }) => {
    const dispatch = useDispatch();

    const [isEditingSalary, setIsEditingSalary] = useState(false);
    const [stagedNewSalary, setStagedNewSalary] = useState<number>(0);
    const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);

    const _toggleEditSalary = useCallback(() => {
      setIsEditingSalary(prevState => !prevState);
    }, []);

    const _saveSalary = useCallback(() => {
      // Show spinner
      setIsWaitingOnAPI(true);

      // API call
      if (isEqual(selectedView, REVIEW_VIEWS.Team)) {
        dispatch(
          generallyUpdateTeam({
            team_id: user.attributes.company.id,
            which: 'annual_salary',
            value: stagedNewSalary.toString(),
            callback: onMakeAPICalls
          })
        );
      } else if (isEqual(selectedView, REVIEW_VIEWS.You)) {
        dispatch(
          generallyUpdateUser({
            which: 'annual_salary',
            value: stagedNewSalary.toString(),
            callback: onMakeAPICalls
          })
        );
      }

      // Hide popup
      _toggleEditSalary();

      // Track goal
      Fathom.trackGoal(GOALS.Review.ChangedSalary);
    }, [
      _toggleEditSalary,
      dispatch,
      onMakeAPICalls,
      selectedView,
      stagedNewSalary,
      user
    ]);

    const evaluatedSalary = useMemo(
      () =>
        getViewMetric(
          'duration_and_costs.attributes.rows.annual_salary',
          reports,
          selectedView
        ),
      [reports, selectedView]
    );

    useEffect(() => {
      setIsWaitingOnAPI(false);
      setStagedNewSalary(evaluatedSalary);
    }, [evaluatedSalary]);

    // User role description
    let title = `Change your role's average salary`;
    let desc = `For more accurate reporting, update your role's average salary.`;
    let tooltipContent = `Change your role's average salary`;

    // Change if Team
    if (isEqual(selectedView, REVIEW_VIEWS.Team)) {
      title = 'Change average salary';
      desc = `For more accurate reporting, update your team's average salary.`;
      tooltipContent = `Change your team's average salary`;
    }

    return (
      <div className={styles.editSalary}>
        <EditingPopover
          content={
            <>
              <div className={popoverStyles.title}>{title}</div>
              <div
                className={classNames(
                  popoverStyles.description,
                  popoverStyles.leftAligned
                )}>
                {desc}
              </div>
              <div className={popoverStyles.salaryInput}>
                <Dollar className={popoverStyles.dollarSign} />
                <Input
                  className={popoverStyles.salary}
                  placeholder="75,000"
                  value={formatMoney(stagedNewSalary, 0)}
                  onChange={event => {
                    const target = event.target as HTMLInputElement;
                    const newValue = parseInt(target.value.replace(/,/g, '')); // Remove commas
                    setStagedNewSalary(newValue);
                  }}
                />
              </div>
            </>
          }
          disabledText="Please edit the salary"
          innerClassName={classNames(
            popoverStyles.editingPlan,
            popoverStyles.editSalary
          )}
          isLoading={isWaitingOnAPI}
          isSaveButtonDisabled={isEqual(evaluatedSalary, stagedNewSalary)}
          isVisible={isEditingSalary}
          onCancel={_toggleEditSalary}
          onSave={_saveSalary}
          onToggle={_toggleEditSalary}>
          <Tooltip content={tooltipContent}>
            <Button minimal={true} onClick={_toggleEditSalary}>
              <Icon which="settings" />
            </Button>
          </Tooltip>
        </EditingPopover>
      </div>
    );
  });
