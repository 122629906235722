import React, { useCallback, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import * as htmlToImage from 'html-to-image';
import { jsPDF } from 'jspdf';
import moment from 'moment';

import { DIV_IDS } from '../../misc/constants';
import { DATE_FORMAT } from '../../misc/consts';
import { DateSelectionProps } from '../../misc/types';
import { Button, Tooltip } from '../design-system';

import styles from '../../styles/export-button.module.scss';

interface ExportButtonProps {
  stagedDateSelection: DateSelectionProps;
}

export const ExportButton: React.ComponentType<ExportButtonProps> = React.memo(
  ({ stagedDateSelection }) => {
    const { addToast } = useToasts();

    const [isExporting, setIsExporting] = useState(false);

    const _handleExport = useCallback(() => {
      const viewToSave = document.getElementById(DIV_IDS.FullReview);
      if (viewToSave) {
        setIsExporting(true);
        const viewRect = viewToSave.getBoundingClientRect();
        const imgWidth = viewRect.width;
        const imgHeight = viewRect.height;
        const headerHeight = 60;
        htmlToImage
          .toPng(viewToSave, {
            width: imgWidth,
            height: imgHeight
          })
          .then(function (data) {
            // Create doc
            const doc = new jsPDF({
              format: [imgWidth, imgHeight + headerHeight],
              unit: 'px'
            });

            // Add elements
            doc.setFontSize(40);
            const { startDate, endDate } = stagedDateSelection;
            const pdfTitle = `MeetWell Report (${moment(startDate).format(
              DATE_FORMAT.Default
            )} - ${moment(endDate).format(DATE_FORMAT.Default)})`;
            doc.text(pdfTitle, 50, 70);
            doc.addImage(data, 'PNG', 0, headerHeight, imgWidth, imgHeight);

            // Save file
            doc.save(`${pdfTitle}.pdf`);

            // Remove spinner
            setIsExporting(false);

            // Show toast
            addToast(`Download successful!`, {
              appearance: 'success',
              autoDismiss: true
            });
          });
      }
    }, [addToast, stagedDateSelection]);

    return (
      <div className={styles.export}>
        <Tooltip content="Export report to PDF">
          <Button
            isLoading={isExporting}
            text="Export"
            onClick={_handleExport}
          />
        </Tooltip>
      </div>
    );
  }
);
