import React from 'react';
import ReactMarkdown from 'react-markdown';
import { isEmpty, round } from 'lodash';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { INTENT_NUM } from '../../misc/consts';
import { SettingsLabels } from '../../misc/types';
import { getIntentFromNum } from '../../misc/utils';
import { Tooltip } from '../design-system';

import styles from '../../styles/score-item.module.scss';

interface ScoreItemProps {
  count?: number;
  details?: Record<string, string>;
  label: string;
  onlyCount?: boolean;
  points?: number;
  score: number;
  settingsLabels?: SettingsLabels;
  weight?: number;
}

export const ScoreItem: React.ComponentType<ScoreItemProps> = React.memo(
  ({
    label,
    score,
    points,
    weight,
    count,
    settingsLabels,
    details,
    onlyCount
  }) => {
    // Null checking
    if (!settingsLabels || isEmpty(settingsLabels)) return null;

    // Build detail
    let detail;
    if (details) {
      detail = details[label];
    }

    // Build label
    let thisLabel = settingsLabels[label].label;
    if (!isEmpty(settingsLabels[label].positive_label)) {
      if (score >= INTENT_NUM.High) {
        thisLabel = settingsLabels[label].positive_label as string;
      } else {
        thisLabel = settingsLabels[label].negative_label as string;
      }
    }

    return (
      <div className={styles.score}>
        <div className={styles.top}>
          <div className={styles.emoji}>{getIntentFromNum(score, 'emoji')}</div>
          <div className={styles.name}>{thisLabel}</div>
          {onlyCount ? (
            <>
              {!Number.isNaN(count) && (
                <div className={styles.count}>{count}</div>
              )}
            </>
          ) : (
            <>
              {!Number.isNaN(score) && (
                <div className={styles.score}>{Math.round(score)}%</div>
              )}
              {points !== undefined &&
                weight !== undefined &&
                !Number.isNaN(points) &&
                !Number.isNaN(weight) && (
                  <Tooltip
                    className={styles.points}
                    content={
                      <>
                        {round(points, 5)} / {round(weight, 5)}
                        <span>To be precise...</span>
                      </>
                    }>
                    {round(points, 1) !== points && <>+</>}
                    {round(points, 1)}
                    <span>| {round(weight, 1)}</span>
                  </Tooltip>
                )}
            </>
          )}
        </div>
        {detail && (
          <ReactMarkdown
            children={detail}
            className={styles.bottom}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
          />
        )}
      </div>
    );
  }
);
