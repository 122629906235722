import React from 'react';
import Emoji from 'react-emoji-render';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { withToastManager } from 'react-toast-notifications';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';

import { Button, Input, InputGroup } from '../../components/design-system';
import { SEO } from '../../components/misc/SEO';
import { DATE_FORMAT } from '../../misc/consts';
import {
  getMeetingEvaluationByToken,
  organizerPreventDecline
} from '../../modules/meetings';

import styles from '../../styles/marketing/misc.module.scss';

class PreventDeclineView extends React.Component {
  constructor(props) {
    super(props);

    // Parse query string
    const queryParams = queryString.parse(props.location.search);

    this.state = {
      token: queryParams.token || '',
      organizerNotes: ''
    };
  }

  componentDidMount() {
    const { token } = this.state;

    if (!isEmpty(token)) {
      this.props.onGetMeetingEvaluationByToken(token, response => {
        if (!isEmpty(response.body)) {
          this.setState(response.body.data.attributes);
        }
      });
    }
  }

  render() {
    return (
      <>
        <SEO title="Prevent decline" />
        <div className={styles.miscPage}>
          <div className={styles.inner}>
            <div className={styles.emoji}>
              <Emoji text=":hand:" />
            </div>
            <h1>
              Prevent a meeting <span>decline</span>
            </h1>
            <p>
              The meeting "{this.state.summary}" does not meet the invitee's
              standards for valuable meetings. MeetWell will decline the meeting
              if it isn't fixed before{' '}
              <strong>
                <Moment className={styles.dateText} format={DATE_FORMAT.Long}>
                  {this.state.compliance_deadline}
                </Moment>
              </strong>
              .
            </p>
            <h5 className={styles.blue}>
              Improve the meeting by addressing one of these issues:
            </h5>
            {this.renderDetailsNegative()}
            <p>
              Or, you can click "Prevent this decline" below and MeetWell will
              not take any action.
            </p>
            <br />
            <InputGroup className={styles.attendeeNotes}>
              <Input
                placeholder="Notes for the attendee"
                type="text"
                onChange={event => {
                  this.setState({ organizerNotes: event.target.value });
                }}
                onKeyDown={this._onKeyDown}
              />
              <Button
                text="Prevent this decline"
                onClick={this._handleOrganizerPreventMeeting}
              />
            </InputGroup>
          </div>
        </div>
      </>
    );
  }

  renderDetailsNegative = () => {
    const { details_negative } = this.state;
    if (isEmpty(details_negative)) return null;

    return (
      <ul className={styles.reasonsWhy}>
        {Object.keys(details_negative).map((item, key) => {
          return <li key={key}>{details_negative[item]}</li>;
        })}
      </ul>
    );
  };

  _onKeyDown = e => {
    if (e.key === 'Enter') {
      this._handleOrganizerPreventMeeting();
    }
  };

  _handleOrganizerPreventMeeting = () => {
    const { token, organizerNotes } = this.state;
    const { toastManager } = this.props;

    this.props.onOrganizerPreventDecline(token, organizerNotes);

    toastManager.add('The meeting will not be declined.', {
      appearance: 'info',
      autoDismiss: true
    });
  };
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onGetMeetingEvaluationByToken: (token, callback) =>
        getMeetingEvaluationByToken(token, callback),
      onOrganizerPreventDecline: (token, organizerNotes) =>
        organizerPreventDecline(token, organizerNotes)
    },
    dispatch
  );

export default withToastManager(
  connect(null, mapDispatchToProps)(PreventDeclineView)
);
