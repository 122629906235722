import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { EditTeamName } from '../../components/account/EditTeamName';
import { InviteMembers } from '../../components/account/InviteMembers';
import { InviteSettings } from '../../components/account/InviteSettings';
import { Invoices } from '../../components/account/Invoices';
import { PendingInvites } from '../../components/account/PendingInvites';
import { TeamMembers } from '../../components/account/TeamMembers';
import { UpcomingCancel } from '../../components/account/UpcomingCancel';
import { InfoDomainEnforcement } from '../../components/balance/InfoDomainEnforcement';
import { InfoPeopleIgnore } from '../../components/balance/InfoPeopleIgnore';
import { SEO } from '../../components/misc/SEO';
import { ROLES, URLS } from '../../misc/consts';
import { RootState } from '../../misc/types';
import * as API from '../../modules/api';
import { getInvites, getTeamMembers } from '../../modules/teams';

import styles from '../../styles/team-mgmt.module.scss';

export const TeamMgmtView: React.ComponentType = React.memo(() => {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user.general);
  const memberships = useSelector(
    (state: RootState) => state.teams.memberships
  );
  const invites = useSelector((state: RootState) => state.teams.invites);
  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );

  // Mount
  useEffect(() => {
    // Check to make sure user has access
    if (user) {
      const isAdminOfATeam = user.attributes.company.roles.includes(
        ROLES.Admin.value
      );
      const isDeactivated = !isEmpty(user.attributes.deactivated_at);
      if (isAdminOfATeam && !isDeactivated) {
        makeAPICalls();
      } else if (user) {
        window.location.href = URLS.Review.Default;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const makeAPICalls = useCallback(() => {
    if (user) {
      API.makeRequest(() => {
        dispatch(getTeamMembers(user.attributes.company.id));
        dispatch(getInvites());
      });
    }
  }, [dispatch, user]);

  if (!user || !subscription) {
    return null;
  }

  return (
    <>
      <SEO title="Team management" />
      <div className={styles.teamMgmtView}>
        <div className={styles.inner}>
          <UpcomingCancel />
          <h1>
            <span>
              Your team{' '}
              {!isEmpty(memberships.meta) && (
                <>({memberships.meta.member_count})</>
              )}
            </span>
          </h1>
          <div className={styles.group}>
            <div className={styles.column}>
              <TeamMembers memberships={memberships.data} user={user} />
              <PendingInvites invites={invites} makeAPICalls={makeAPICalls} />
            </div>
            <div className={styles.column}>
              <div className={styles.section}>
                <h5>Team name</h5>
                <EditTeamName />
              </div>
              <div className={styles.section}>
                <h5>Invite settings</h5>
                <InviteSettings user={user} />
              </div>
              <div className={styles.section}>
                <h5>
                  Invite members <span>$5 per member</span>
                </h5>
                <InviteMembers makeAPICalls={makeAPICalls} />
              </div>
            </div>
          </div>
          <div className={classNames(styles.group, styles.billingActions)}>
            <div className={styles.column}>
              <h1>
                <span>Auto-Balance defaults</span>
              </h1>
              <div className={styles.section}>
                <p>
                  Defaults for members that have an Auto-Balance action enabled.
                </p>
                <InfoDomainEnforcement user={user} which="team" />
                <InfoPeopleIgnore user={user} which="team" />
              </div>
            </div>
            <div className={styles.column}>
              <h1>
                <span>Billing</span>
              </h1>
              <div className={classNames(styles.section, styles.billing)}>
                <Invoices />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
