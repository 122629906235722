import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { includes, isEmpty } from 'lodash';

import { ADMIN_EMAILS, INTENT, URLS } from '../../misc/consts';
import { User } from '../../misc/types';
import { getTips, resetTips } from '../../modules/tips';
import {
  deleteAccount,
  logoutUser,
  toggleAPIError,
  toggleOnboarding
} from '../../modules/user';
import { Alert, Button, Input } from '../design-system';

import styles from '../../styles/account.module.scss';

const DELETE_MY_ACCOUNT_TEXT = 'DELETE MY ACCOUNT';

interface ScaryStuffProps {
  user: User;
}

export const ScaryStuff: React.ComponentType<ScaryStuffProps> = React.memo(
  ({ user }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [isDeletingAccount, setIsDeletingAccount] = useState(false);
    const [isSigningOut, setIsSigningOut] = useState(false);
    const [confirmDeleteText, setConfirmDeleteText] = useState('');

    const _handleToggleOnboarding = useCallback(() => {
      dispatch(
        resetTips(
          dispatch(
            toggleOnboarding(false, () => {
              dispatch(
                getTips(() => {
                  history.push(URLS.Review.Default);
                })
              );
            })
          )
        )
      );
    }, [dispatch, history]);

    const _handleClearTips = useCallback(() => {
      dispatch(
        resetTips(() => {
          dispatch(getTips());
        })
      );
    }, [dispatch]);

    const _handleToggleNotification = useCallback(() => {
      addToast(
        'This is a long test message that I want to make sure looks good!',
        {
          appearance: 'info',
          autoDismiss: false
        }
      );
    }, [addToast]);

    const _handleToggleErrorNotification = useCallback(() => {
      dispatch(toggleAPIError());
    }, [dispatch]);

    const _handleToggleConfirmationDialog = useCallback(() => {
      setIsDeletingAccount(prevState => !prevState);
    }, []);

    const onChangeDeleteText = useCallback(event => {
      setConfirmDeleteText(event.target.value);
    }, []);

    const _handleDeleteAccount = useCallback(() => {
      dispatch(deleteAccount());
    }, [dispatch]);

    const _handleSignOutClick = useCallback(() => {
      setIsSigningOut(prevState => !prevState);
    }, []);

    const _handleSignOut = useCallback(() => {
      // Logout user
      dispatch(logoutUser());
    }, [dispatch]);

    return (
      <div className={styles.section}>
        <Alert
          confirmText="Yes, sign me out"
          isVisible={isSigningOut}
          shouldShowCancel
          text="Are you sure you want to sign out?"
          title="Are you sure?"
          onCancel={_handleSignOutClick}
          onConfirm={_handleSignOut}
        />
        <Alert
          confirmText="Delete my account"
          content={
            <Input
              className={styles.deleteInput}
              placeholder={`Type: ${DELETE_MY_ACCOUNT_TEXT}`}
              value={confirmDeleteText}
              onChange={onChangeDeleteText}
            />
          }
          isConfirmButtonDisabled={confirmDeleteText !== DELETE_MY_ACCOUNT_TEXT}
          isVisible={isDeletingAccount}
          shouldShowCancel
          text={`Are you sure you want to delete your account? If so, please type "${DELETE_MY_ACCOUNT_TEXT}" in the box below.`}
          title="Are you sure?"
          onCancel={_handleToggleConfirmationDialog}
          onConfirm={_handleDeleteAccount}
        />
        <h5>Scary stuff</h5>
        <Button
          centered
          className={styles.signOutButton}
          fill
          text="Sign out"
          onClick={_handleSignOutClick}
        />
        <Button
          centered
          fill
          intent={INTENT.Danger}
          text="Delete my account and all of my data"
          onClick={_handleToggleConfirmationDialog}
        />
        {!isEmpty(user) && includes(ADMIN_EMAILS, user.attributes.email) && (
          <div className={styles.adminArea}>
            <h5>Admin area</h5>
            <Button
              text="Retrigger onboarding"
              onClick={_handleToggleOnboarding}
            />
            <Button text="Clear all tips" onClick={_handleClearTips} />
            <Button
              text="Show a notification"
              onClick={_handleToggleNotification}
            />
            <Button
              text="Show an error notification"
              onClick={_handleToggleErrorNotification}
            />
          </div>
        )}
      </div>
    );
  }
);
