/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';

import { SEO } from '../../components/misc/SEO';
import MD from '../../markdown/policies/terms.md';

import styles from '../../styles/marketing/misc.module.scss';

export const TermsView = () => {
  const [markdown, setMarkdown] = useState('');
  useEffect(() => {
    fetch(MD)
      .then(response => response.text())
      .then(text => {
        setMarkdown(text);
      });
  }, []);

  return (
    <>
      <SEO title="Terms" />
      <div
        className={classNames(
          styles.subpageView,
          styles.runningText,
          styles.blogPost
        )}>
        <div className={styles.inner}>
          <ReactMarkdown children={markdown} />
        </div>
      </div>
    </>
  );
};
