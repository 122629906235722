import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';

import { useOnScreen } from '../../../hooks/on-screen';
import { getAnimationOffset } from '../../../misc/utilities';

import { MEETING_DATA } from './HowExampleMeeting';

import styles from '../../../styles/marketing/how-example-meeting-invite.module.scss';

interface HowExampleMeetingInviteProps {
  direction: 'good-to-bad' | 'bad-to-good';
}

interface MeetingBlock {
  accepted?: boolean;
  bad?: boolean;
  date?: string;
  empty?: boolean;
  hour?: boolean;
  order?: string;
  title?: string;
}

export const HowExampleMeetingInvite: React.ComponentType<HowExampleMeetingInviteProps> =
  React.memo(({ direction }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen<HTMLDivElement>(ref, getAnimationOffset());

    const MEETINGS: MeetingBlock[][] = useMemo(
      () => [
        [
          {
            hour: true,
            accepted: true,
            title: 'Lunch w/ Pam',
            date: '1 - 2pm'
          },
          {
            order: '1',
            bad: true,
            hour: true,
            empty: direction === 'good-to-bad',
            title: 'Bad meeting',
            date: '2 - 3pm'
          },
          {
            accepted: true,
            title: 'Pretend to be on a call',
            date: '3 - 5pm'
          },
          {
            accepted: true,
            hour: true,
            title: 'Basketball',
            date: '5 - 6pm'
          }
        ],
        [
          {
            bad: direction === 'good-to-bad',
            accepted: direction === 'bad-to-good',
            title: MEETING_DATA.Bad.attributes.summary,
            date: '1 - 3pm'
          },
          {
            accepted: true,
            hour: true,
            title: 'Jim + Michael 1:1',
            date: '3 - 4pm'
          },
          {
            accepted: true,
            hour: true,
            title: 'Call w/ Dunmore HS',
            date: '4 - 5pm'
          },
          {
            order: '2',
            bad: true,
            hour: true,
            empty: direction === 'good-to-bad',
            title: 'Bad meeting',
            date: '5 - 6pm'
          }
        ],
        [
          {
            order: '3',
            bad: true,
            hour: true,
            empty: direction === 'good-to-bad',
            title: 'Bad meeting',
            date: '1 - 2pm'
          },
          {
            order: '4',
            bad: true,
            hour: true,
            empty: direction === 'good-to-bad',
            title: 'Bad meeting',
            date: '2 - 3pm'
          },
          {
            accepted: true,
            title: 'Pretzel Day',
            date: '3 - 5pm'
          },
          {
            accepted: true,
            hour: true,
            title: 'HH @ Poor Richards',
            date: '5 - 6pm'
          }
        ]
      ],
      [direction]
    );

    return (
      <div
        ref={ref}
        className={classNames(styles.meetingInvite, {
          [styles.isVisible]: isVisible,
          [styles.goodToBad]: direction === 'good-to-bad',
          [styles.badToGood]: direction === 'bad-to-good'
        })}>
        <div className={styles.days}>
          {MEETINGS.map((day, key) => {
            return (
              <div key={key} className={styles.day}>
                {day.map((mtg, index) => {
                  return (
                    <div
                      key={index}
                      className={classNames(styles.invite, {
                        [styles.accepted]: mtg.accepted,
                        [styles.bad]: mtg.bad,
                        [styles.hour]: mtg.hour,
                        [styles.empty]: mtg.empty
                      })}
                      data-disappear-order={mtg.order || ''}>
                      {mtg.title && (
                        <div className={styles.title}>{mtg.title}</div>
                      )}
                      {mtg.date && (
                        <div className={styles.date}>{mtg.date}</div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={styles.lines}>
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
          <div className={styles.line} />
        </div>
      </div>
    );
  });
