import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { cloneDeep } from 'lodash';

import { useWindowResize } from '../../../hooks/window-resize';
import { AgendaItem, Meeting } from '../../../misc/types';
import { Icon } from '../../design-system';
import { Timer } from '../Timer';

import styles from '../../../styles/present-mode.module.scss';

const PRESENTATION_START = 0;

interface AgendaItemsProps {
  discussingAgendaItem: number;
  event: Meeting;
  meetingHasCompleted: boolean;
}

interface ActiveAgendaItem extends AgendaItem {
  timeSpent: number;
}

export const AgendaItems: React.ComponentType<AgendaItemsProps> = React.memo(
  ({ event, discussingAgendaItem, meetingHasCompleted }) => {
    const agendaListEl = useRef<HTMLDivElement>(null);
    const [agendaItems, setAgendaItems] = useState<ActiveAgendaItem[]>([]);
    const [agendaWidth, setAgendaWidth] = useState('auto');
    const [discussingOffset, setDiscussingOffset] = useState('0');
    const windowDimensions = useWindowResize();

    const agendaTimer = useRef<number | null>(null);

    ///////////
    // Prep agenda items (add a timer attribute)
    useEffect(() => {
      const incomingItems = [...event.attributes.agenda_items.data];
      if (incomingItems.length > 0) {
        const activeItems = incomingItems.map(item => {
          return { ...item, timeSpent: 0 };
        });
        setAgendaItems(activeItems);
      }
    }, [event]);

    ///////////
    // Set agenda width and offset
    useEffect(() => {
      if (agendaItems.length > 0 && agendaListEl.current) {
        const agendaRect = agendaListEl.current.getBoundingClientRect();
        setAgendaWidth(`${Math.ceil(agendaRect.width)}px`);
        setDiscussingOffset(`-${agendaRect.left}px`);
      }
    }, [agendaItems, windowDimensions]);

    ///////////
    // Interval to set time
    useEffect(() => {
      if (agendaTimer.current) {
        clearInterval(agendaTimer.current); // Make sure it's clear
      }

      if (
        discussingAgendaItem !== PRESENTATION_START &&
        discussingAgendaItem <= event.attributes.agenda_items.data.length
      ) {
        agendaTimer.current = window.setInterval(() => {
          setAgendaItems(prevItems => {
            const itemToUpdate = discussingAgendaItem - 1;
            const newItems = cloneDeep(prevItems);
            newItems[itemToUpdate].timeSpent =
              newItems[itemToUpdate].timeSpent + 1;
            return newItems;
          });
        }, 1000);
      }
    }, [discussingAgendaItem, event]);

    ///////////
    // Render
    ///////////

    if (agendaItems.length === 0) {
      return null;
    }

    const isBeginningOrEnd =
      discussingAgendaItem === PRESENTATION_START ||
      discussingAgendaItem > event.attributes.agenda_items.data.length;

    return (
      <div
        ref={agendaListEl}
        className={styles.agendaItems}
        style={{ width: agendaWidth }}>
        {isBeginningOrEnd && (
          <div className={styles.heading}>
            <h5>Agenda</h5>
          </div>
        )}
        {agendaItems.map((item, index) => {
          const position = index + 1;
          const isDiscussing = discussingAgendaItem === position;
          const isComplete = discussingAgendaItem > position;
          const initialDuration = item.attributes.duration_in_mins
            ? item.attributes.duration_in_mins * 60
            : 0;
          const isDurationDifferent = initialDuration !== item.timeSpent;
          return (
            <div
              key={position}
              className={classNames(styles.item, {
                [styles.discussing]: isDiscussing,
                [styles.complete]: isComplete,
                [styles.meetingComplete]: meetingHasCompleted
              })}
              style={{
                marginLeft: isDiscussing ? discussingOffset : 0
              }}>
              {isDiscussing && (
                <div className={styles.currentIndicator}>
                  <h6>Discussing</h6>
                </div>
              )}
              <div className={styles.title}>
                {!isComplete ? (
                  <>
                    {!isDiscussing ? (
                      <div className={styles.accessory}>{position})</div>
                    ) : null}
                  </>
                ) : (
                  <div className={styles.accessory}>
                    <Icon which="tick" />
                  </div>
                )}
                {item.attributes.description}
              </div>
              <div
                className={classNames(styles.duration, {
                  [styles.isDifferent]: isDurationDifferent,
                  [styles.isShorter]: item.timeSpent < initialDuration,
                  [styles.isLonger]: item.timeSpent > initialDuration
                })}>
                {isDiscussing && (
                  <>
                    <Timer time={item.timeSpent} type="elapsed-with" />
                    {item.attributes.duration_in_mins &&
                      item.attributes.duration_in_mins > 0 && (
                        <span className={styles.slash}>/</span>
                      )}
                  </>
                )}
                <span>
                  {item.attributes.duration_in_mins &&
                  item.attributes.duration_in_mins > 0 ? (
                    <Timer
                      time={item.attributes.duration_in_mins * 60}
                      type="elapsed-with"
                    />
                  ) : (
                    !isDiscussing && <>--</>
                  )}
                </span>
                {isComplete && isDurationDifferent && (
                  <div className={styles.actualTime}>
                    <Timer time={item.timeSpent} type="elapsed-with" />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);
