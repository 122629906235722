/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import {
  deleteSubscription,
  getSubscriptionPlans,
  updateSubscription
} from './utils/subscriptions-utils';

const initialState = {
  plans: [],
  subscription: undefined
};

export default (state = initialState, action) => {
  if (isValidAPICall(action, 'subscription_plans')) {
    return {
      ...state,
      plans: action.payload.data || []
    };
  }

  if (isValidAPICall(action, 'subscriptions')) {
    return {
      ...state,
      subscription: action.payload.data || []
    };
  }

  return state;
};

export { getSubscriptionPlans, updateSubscription, deleteSubscription };
