import React, { useRef } from 'react';
import classNames from 'classnames';

import { ActionInfo } from '../../../components/balance/ActionInfo';
import { useOnScreen } from '../../../hooks/on-screen';
import { EXAMPLE_USER, EXAMPLE_USER_SETTINGS } from '../../../misc/test-data';
import { getAnimationOffset } from '../../../misc/utilities';

import styles from '../../../styles/marketing/how-example-autodecline.module.scss';

export const HowExampleAutoDecline: React.ComponentType = React.memo(() => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen<HTMLDivElement>(ref, getAnimationOffset());

  return (
    <div
      ref={ref}
      className={classNames(styles.autoDecline, {
        [styles.isVisible]: isVisible
      })}>
      <div className={styles.remind}>
        <ActionInfo settings={EXAMPLE_USER_SETTINGS} user={EXAMPLE_USER} />
      </div>
      <div className={styles.decline}>
        <ActionInfo
          settings={{
            ...EXAMPLE_USER_SETTINGS,
            attributes: {
              ...EXAMPLE_USER_SETTINGS.attributes,
              action_settings: {
                ...EXAMPLE_USER_SETTINGS.attributes.action_settings,
                non_compliance_action: 'decline'
              }
            }
          }}
          user={EXAMPLE_USER}
        />
      </div>
    </div>
  );
});
