import React, { ReactNode, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty, round } from 'lodash';

import { URLS } from '../../misc/constants';
import { QUERY_PARAMS } from '../../misc/consts';
import { formatMoney } from '../../misc/utils';
import {
  Button,
  Dollar,
  Icon,
  MenuItem,
  MenuItems,
  Popover,
  Tooltip
} from '../design-system';
import { ShareTextModal } from '../misc/ShareTextModal';

import styles from '../../styles/review-detailed-stats.module.scss';

interface DetailRowProps {
  email?: string;
  hoursCount: number;
  label: ReactNode;
  nudgesCount?: number | null;
  numOfBadMtgs: number;
  numOfGoodMtgs: number;
  showMiscActions?: boolean;
  tooltip?: string;
  totalCost: number;
  totalMtgsCount: number;
}

export const DetailRow: React.ComponentType<DetailRowProps> = React.memo(
  ({
    label,
    tooltip,
    email,
    totalMtgsCount,
    numOfGoodMtgs,
    numOfBadMtgs,
    hoursCount,
    totalCost,
    showMiscActions,
    nudgesCount
  }) => {
    const history = useHistory();

    const [detailRowPopupVisible, setDetailRowPopupVisible] = useState(false);

    const _handleDetailRowPopupClick = useCallback(() => {
      setDetailRowPopupVisible(prevState => !prevState);
    }, []);

    const _handleAddAsVIP = useCallback(() => {
      history.push(`${URLS.Balance.Action}?${QUERY_PARAMS.AddVIP}=${email}`);
    }, [email, history]);

    const textToShare = () => {
      return [
        `I'm using MeetWell to improve my meeting habits and hold myself accountable. Connect your calendar at https://meetwell.app and join me!`
      ].join('\n\n');
    };

    return (
      <div className={styles.row}>
        <Tooltip
          className={styles.type}
          content={tooltip}
          isDisabled={isEmpty(tooltip)}>
          {label}
        </Tooltip>
        <span className={styles.count}>{totalMtgsCount}</span>
        <span className={styles.goodBad}>
          <span className={styles.good}>
            {round((numOfGoodMtgs / totalMtgsCount) * 100, 1)}
            <span>%</span>
          </span>{' '}
          <span className={styles.divider} />{' '}
          <span className={styles.bad}>
            {round((numOfBadMtgs / totalMtgsCount) * 100, 1)}
            <span>%</span>
          </span>
        </span>
        {nudgesCount !== undefined && (
          <span className={styles.nudges}>{nudgesCount}</span>
        )}
        <span className={styles.cost}>
          <Dollar className={styles.dollarSign} />
          {formatMoney(totalCost, 0)}
        </span>
        <span className={styles.hours}>{round(hoursCount, 1)}</span>
        {showMiscActions && (
          <div className={styles.inviteBtn}>
            <Popover
              content={
                <MenuItems>
                  <ShareTextModal
                    buttonText="Invite to MeetWell..."
                    descriptor="We've prepped something for you to share. Copy and
        paste or open it in an email and edit to your liking."
                    sendTo={email}
                    subjectLine="Improving meeting habits"
                    textToShare={textToShare()}
                    type="menuItem"
                  />
                  <MenuItem onClick={_handleAddAsVIP}>Add as a VIP...</MenuItem>
                </MenuItems>
              }
              isVisible={detailRowPopupVisible}
              onToggle={_handleDetailRowPopupClick}>
              <Button
                icon={<Icon which="kebab" />}
                minimal={true}
                small={true}
                onClick={_handleDetailRowPopupClick}
              />
            </Popover>
          </div>
        )}
      </div>
    );
  }
);
