import React from 'react';
import Emoji from 'react-emoji-render';

import { SEO } from '../../components/misc/SEO';

import { AreYouReady } from './components/AreYouReady';
import { FeatureDeepDive } from './components/FeatureDeepDive';
import { HeroArea } from './components/HeroArea';
import { HowItWorks } from './components/HowItWorks';
import { NoTrackingPopup } from './components/NoTrackingPopup';
import { PullQuote } from './components/PullQuote';
import { ReferralTracking } from './components/ReferralTracking';

import styles from '../../styles/marketing/home.module.scss';

const HERO_TEXT = {
  title: (
    <>
      No more
      <Emoji text=":horse:" />
      <Emoji text=":poop:" />
      <br />
      <span>Only good meetings</span>
    </>
  ),
  desc: (
    <>
      MeetWell <u>improves</u> or <u>removes</u> bad meetings on your calendar,
      allowing you to focus on what matters most.
    </>
  ),
  ctaHeader: <>Uncover bad meetings in less than 1 minute.</>
};

export const HomeView: React.ComponentType = React.memo(() => {
  return (
    <>
      <SEO />
      <ReferralTracking />
      <div className={styles.homePage}>
        <NoTrackingPopup />
        <HeroArea heroText={HERO_TEXT} />
        <PullQuote
          author={
            <>
              Steven Rogelberg, author{' '}
              <a href="https://www.stevenrogelberg.com/" target="_new">
                The Surprising Science of Meetings
              </a>
            </>
          }
          noMargin={true}
          text="Bad meetings have just been accepted as a cost of doing business."
        />
        <HowItWorks />
        <PullQuote
          author="Elon Musk, founder of SpaceX"
          text="Excessive meetings are the blight of big companies and almost always get worse over time."
        />
        <FeatureDeepDive />
        <PullQuote
          author="Leslie A. Perlow, Prof. of Leadership, Harvard Business School"
          noMargin={true}
          text={`Every minute spent in a wasteful meeting eats into time for solo work that’s equally essential for creativity and efficiency.`}
        />
        <AreYouReady heroText={HERO_TEXT} />
      </div>
    </>
  );
});
