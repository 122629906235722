import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

export interface InputGroupProps {
  children: ReactNode;
  className?: string;
  hasError?: boolean;
  large?: boolean;
  minimal?: boolean;
  small?: boolean;
}

export const InputGroup: React.ComponentType<InputGroupProps> = React.memo(
  function InputGroup({
    children,
    className,
    hasError = false,
    large = false,
    minimal = false,
    small = false
  }) {
    return (
      <div
        className={classNames(styles.inputGroup, className, {
          [styles.minimal]: minimal,
          [styles.small]: small,
          [styles.large]: large,
          [styles.hasError]: hasError
        })}>
        {children && children}
      </div>
    );
  }
);
