/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { findIndex } from 'lodash';

import { isValidAPICall } from '../misc/utils';

import { getArticle } from './utils/articles-utils';

export default (state = [], action) => {
  if (isValidAPICall(action, 'articles')) {
    if (action.type === 'API_READ') {
      const newVals = action.payload.data.filter(a => {
        const existingLoc = findIndex(state, o => {
          return o.id === a.id;
        });
        return existingLoc === -1;
      });
      return [...state, ...newVals];
    }
  }
  return state;
};

export { getArticle };
