import React, { useCallback } from 'react';
import classNames from 'classnames';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { COLORS } from '../../../misc/constants';
import { INTENT } from '../../../misc/consts';
import { Meeting, Settings, User } from '../../../misc/types';
import { Button, Icon } from '../../design-system';
import { ModalView } from '../../misc/ModalView';

import { PresentView } from './PresentView';

import styles from '../../../styles/present-mode.module.scss';

interface PresentModalProps {
  event: Meeting;
  isForMarketing?: boolean;
  settings: Settings;
  user: User;
}

export const PresentModal: React.ComponentType<PresentModalProps> = React.memo(
  ({ event, settings, user }) => {
    const [isMeetingViewOpen, setIsMeetingViewOpen] = useQueryParam(
      'present',
      BooleanParam
    );
    const _toggleMeetingView = useCallback(() => {
      setIsMeetingViewOpen(prevState => !prevState);
    }, [setIsMeetingViewOpen]);

    return (
      <>
        <Button
          className={styles.presentMeetingBtn}
          icon={<Icon color={COLORS.white} which="present" />}
          intent={INTENT.Primary}
          text="Present"
          onClick={_toggleMeetingView}
        />
        <ModalView
          innerClassName={classNames(
            styles.presentModal,
            styles.mobile,
            styles.overrides
          )}
          isDismissible={true}
          isVisible={isMeetingViewOpen ? true : false}
          position="float"
          onClose={_toggleMeetingView}>
          <PresentView
            event={event}
            settings={settings}
            topRightElement={
              <Button
                icon={<Icon which="cross" />}
                text="Close"
                onClick={_toggleMeetingView}
              />
            }
            user={user}
            onClose={_toggleMeetingView}
          />
        </ModalView>
      </>
    );
  }
);
