import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TagsInput from 'react-tagsinput';
import classNames from 'classnames';
import { cloneDeep, isEmpty, isEqual, uniq } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { PLAN_TOOLTIPS, QUERY_PARAMS } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { RootState, User } from '../../misc/types';
import { isEmailValid } from '../../misc/utils';
import { saveTeamDenylistOrganizers } from '../../modules/teams';
import { getUser, saveUserDenylistOrganizers } from '../../modules/user';
import {
  Alert,
  Button,
  Callout,
  EditingPopover,
  MultiTagInput,
  Tag,
  TextLink,
  Tooltip
} from '../design-system';
import { AlertProps } from '../design-system/Alert';

import styles from '../../styles/action-info-item.module.scss';
import popoverStyles from '../../styles/popover.module.scss';

interface InfoPeopleIgnoreProps {
  user: User;
  which: 'team' | 'user';
}

const SEG_DATA = ['No one', 'Some'];

export const InfoPeopleIgnore: React.ComponentType<InfoPeopleIgnoreProps> =
  React.memo(function InfoPeopleIgnore({ user, which }) {
    const dispatch = useDispatch();
    const subscription = useSelector(
      (state: RootState) => state.subscriptions.subscription
    );

    const tagsInput = useRef<TagsInput>(null);

    const [enforcementType, setEnforcementType] = useState('');
    const [stagedEnforcementType, setStagedEnforcementType] = useState('');
    const [inputValue, setInputValue] = useState<string>('');
    const [organizers, setOrganizers] = useState<string[]>([]);
    const [stagedOrganizers, setStagedOrganizers] = useState<string[]>([]);
    const [teamOrganizersToIgnore, setTeamOrganizersToIgnore] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);
    const [isAlertDialogVisible, setIsAlertDialogVisible] = useState(false);

    ////////////
    // ADDING A VIP DEEP LINK
    ////////////

    const [addVIPParam, setVIPParam] = useQueryParam(
      QUERY_PARAMS.AddVIP,
      StringParam
    );

    const [addVIPDeepLinkDialog, setAddVIPDeepLinkDialog] =
      useState<AlertProps>({
        isVisible: false,
        title: 'Add a VIP?',
        text: ''
      });

    const _closeDeepLinkDialog = useCallback(() => {
      setVIPParam(undefined);
    }, [setVIPParam]);

    useEffect(() => {
      if (addVIPParam) {
        setAddVIPDeepLinkDialog(prevState => {
          return {
            ...prevState,
            isVisible: true,
            text: (
              <div className={styles.addVIPDialog}>
                <Callout>{PLAN_TOOLTIPS.People}</Callout>
                <p className={styles.areYouSure}>
                  Are you sure you want to add <strong>{addVIPParam}</strong> as
                  a VIP?
                </p>
              </div>
            )
          };
        });
      }
    }, [addVIPParam]);

    const _saveDeepLinkDialog = useCallback(() => {
      // Clone staged organizers
      if (addVIPParam) {
        const clonedStagedOrganizers = cloneDeep(stagedOrganizers);
        clonedStagedOrganizers.push(addVIPParam);
        const organizerString = uniq(clonedStagedOrganizers).join(',');

        dispatch(
          saveUserDenylistOrganizers(organizerString, () => {
            dispatch(getUser()); // Get user again to refresh denylist
          })
        );

        _closeDeepLinkDialog();
      }
    }, [_closeDeepLinkDialog, addVIPParam, dispatch, stagedOrganizers]);

    ////////////
    ////////////

    useEffect(() => {
      setIsWaitingOnAPI(false);
      setIsEditing(false);

      const { company } = user.attributes;

      // Build organizer ignore list
      let allOrganizersToIgnore = '';
      let teamOrganizersToIgnore = '';
      let userOrganizersToIgnore = '';
      if (which === 'user') {
        if (
          company.organizer_denylist &&
          !isEmpty(company.organizer_denylist)
        ) {
          teamOrganizersToIgnore = company.organizer_denylist;
          userOrganizersToIgnore = !isEmpty(user.attributes.organizer_denylist)
            ? `,${user.attributes.organizer_denylist}`
            : '';
          allOrganizersToIgnore = `${teamOrganizersToIgnore}${userOrganizersToIgnore}`;
        } else if (user.attributes.organizer_denylist) {
          allOrganizersToIgnore = user.attributes.organizer_denylist;
        }
      } else if (which === 'team' && company.organizer_denylist) {
        allOrganizersToIgnore = company.organizer_denylist;
      }

      const hasSpecifiedOrganizers = !isEmpty(allOrganizersToIgnore);
      const enforcementType = !hasSpecifiedOrganizers ? 'No one' : 'Some';
      const organizersToSet = hasSpecifiedOrganizers
        ? uniq(allOrganizersToIgnore.split(','))
        : [];

      setEnforcementType(enforcementType);
      setStagedEnforcementType(enforcementType);
      setTeamOrganizersToIgnore(teamOrganizersToIgnore);
      setOrganizers(organizersToSet);
      setStagedOrganizers(organizersToSet);
    }, [subscription, user, which]);

    const _toggleEditMode = useCallback(() => {
      setIsEditing(prevState => !prevState);
    }, []);

    const _handleCloseAlertDialog = useCallback(() => {
      setIsAlertDialogVisible(false);
    }, []);

    const _selectNewEnforcementType = useCallback(item => {
      setStagedEnforcementType(item);
    }, []);

    const _handleOrganizersChange = useCallback(organizers => {
      setStagedOrganizers(organizers);
    }, []);

    const _save = useCallback(async () => {
      let organizers = '';

      // Show spinner
      setIsWaitingOnAPI(true);

      // If "Some", validate the organizers, if "No one", skip ahead
      if (stagedEnforcementType === 'Some') {
        // Make sure whatever is typed into box has turned into a tag
        if (tagsInput.current) {
          await tagsInput.current.accept();
        }

        // Clone staged organizers
        let clonedStagedOrganizers = cloneDeep(stagedOrganizers);

        // Maybe remove team organizers from list
        if (which === 'user' && !isEmpty(teamOrganizersToIgnore)) {
          const teamOrganizersArr = teamOrganizersToIgnore.split(',');
          clonedStagedOrganizers = clonedStagedOrganizers.filter(
            el => !teamOrganizersArr.includes(el)
          );
        }

        // Validate (Loop through and test organizer emails)
        for (let i = 0; i < clonedStagedOrganizers.length; i++) {
          if (!isEmailValid(clonedStagedOrganizers[i])) {
            setIsWaitingOnAPI(false);
            return setIsAlertDialogVisible(true);
          }
        }

        // Build string
        organizers = clonedStagedOrganizers.join(',');
      }

      // Post to API
      if (which === 'user') {
        dispatch(
          saveUserDenylistOrganizers(organizers, () => {
            dispatch(getUser()); // Get user again to refresh denylist
          })
        );
      } else if (which === 'team') {
        dispatch(
          saveTeamDenylistOrganizers(organizers, () => {
            dispatch(getUser()); // Get user again to refresh denylist
          })
        );
      }

      // Track goal
      Fathom.trackGoal(GOALS.Balance.AddedPeopleToIgnore);
    }, [
      dispatch,
      stagedEnforcementType,
      stagedOrganizers,
      teamOrganizersToIgnore,
      which
    ]);

    const renderStatus = () => {
      if (enforcementType === 'No one') return 'No one';
      return organizers.map((item, key) => (
        <Tag key={key} className={styles.organizerTag}>
          <Tooltip content={item}>{item}</Tooltip>
        </Tag>
      ));
    };

    const renderEditButton = () => {
      // Disabled button?
      const isSaveButtonDisabled = () => {
        const typeHasntChanged = isEqual(
          enforcementType,
          stagedEnforcementType
        );

        // If 'No one'
        if (stagedEnforcementType === 'No one') {
          // Type hasn't changed
          return typeHasntChanged;
        }

        // If 'Some'
        if (stagedEnforcementType === 'Some') {
          // If the input value is a valid email
          if (isEmailValid(inputValue)) {
            return false;
          }

          // No organizers, or organizers haven't changed
          if (
            stagedOrganizers.length === 0 ||
            isEqual(organizers, stagedOrganizers)
          ) {
            return true;
          }

          // If type has changed, enable button
          if (!typeHasntChanged) return false;

          // Good to go
          return false;
        }
      };

      // Maybe build team specific domain list
      const teamOrganizersIgnoreExist =
        which === 'user' && !isEmpty(teamOrganizersToIgnore);
      const maybeDisabledTags = teamOrganizersIgnoreExist
        ? teamOrganizersToIgnore.split(',')
        : [];

      return (
        <>
          <Alert
            isVisible={isAlertDialogVisible}
            text="Please enter a valid email and try again."
            title="Invalid email"
            onConfirm={_handleCloseAlertDialog}
          />
          <EditingPopover
            className={styles.editWrapper}
            content={
              <>
                <div className={popoverStyles.segmentedControl}>
                  {!teamOrganizersIgnoreExist &&
                    SEG_DATA.map((item, key) => (
                      <Button
                        key={key}
                        active={item === stagedEnforcementType}
                        centered
                        fill
                        minimal
                        small
                        text={item}
                        onClick={() => _selectNewEnforcementType(item)}
                      />
                    ))}
                </div>
                <MultiTagInput
                  addOnBlur={true}
                  addOnPaste={true}
                  disabledTagTooltip="This email was added by your team."
                  inputProps={{
                    placeholder: 'Type an email, hit Enter to add...'
                  }}
                  inputValue={inputValue}
                  isDisabled={stagedEnforcementType === 'No one'}
                  reference={tagsInput}
                  tagsToDisable={maybeDisabledTags}
                  value={stagedOrganizers}
                  onChange={_handleOrganizersChange}
                  onChangeInput={test => setInputValue(test)}
                />
              </>
            }
            description={PLAN_TOOLTIPS.People}
            disabledText="Please add or edit info"
            innerClassName={classNames(
              popoverStyles.editingPlan,
              popoverStyles.editOrganizerEnforcement
            )}
            isLoading={isWaitingOnAPI}
            isSaveButtonDisabled={isSaveButtonDisabled()}
            isVisible={isEditing}
            onCancel={_toggleEditMode}
            onSave={_save}
            onToggle={_toggleEditMode}>
            <TextLink
              className={styles.edit}
              isDisabled={isEditing}
              text="Edit"
              onClick={_toggleEditMode}
            />
          </EditingPopover>
        </>
      );
    };

    return (
      <div className={styles.item}>
        <Alert
          {...addVIPDeepLinkDialog}
          confirmText="Save as VIP"
          shouldShowCancel={true}
          onCancel={_closeDeepLinkDialog}
          onConfirm={_saveDeepLinkDialog}
        />
        <h6>Who is a VIP?</h6>
        <div
          className={classNames(styles.section, styles.organizerEnforcement)}>
          <div className={styles.label}>
            <Tooltip content={PLAN_TOOLTIPS.People}>People</Tooltip>
          </div>
          <div className={styles.content}>
            <div className={styles.text}>{renderStatus()}</div>
            {renderEditButton()}
          </div>
        </div>
      </div>
    );
  });
