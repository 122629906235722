import React, { useCallback, useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import Emoji from 'react-emoji-render';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { find, isEmpty, isEqual } from 'lodash';

import { ShareMeetingCulture } from '../../components/balance/ShareMeetingCulture';
import { BalanceViewAction } from '../../components/balance/ViewAction';
import { BalanceViewEvaluations } from '../../components/balance/ViewEvaluations';
import { BalanceViewWellnessBlocks } from '../../components/balance/ViewWellnessBlocks';
import { Button } from '../../components/design-system';
import { SEO } from '../../components/misc/SEO';
import { WellnessBlockModal } from '../../components/misc/WellnessBlockModal';
import { ChoosePlanModal } from '../../components/onboarding/ChoosePlanModal';
import { usePrevious } from '../../hooks/previous';
import { BALANCE_VIEWS, DIV_IDS } from '../../misc/constants';
import { URLS } from '../../misc/consts';
import { RootState, SelectedView } from '../../misc/types';
import * as API from '../../modules/api';
import { getMaybeWellnessBlocks } from '../../modules/maybe-wellness-blocks';
import { getWellnessBlocks } from '../../modules/wellness-blocks';

import styles from '../../styles/balance.module.scss';

export const BalanceView: React.ComponentType = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const prevLocation = usePrevious(location);

  const user = useSelector((state: RootState) => state.user.general);
  const settings = useSelector((state: RootState) => state.user.settings);

  const settings_labels = useSelector(
    (state: RootState) => state.settings_labels
  );

  const refreshes = useSelector((state: RootState) => state.messages.refreshes);
  const prevRefreshes = usePrevious(refreshes);

  const match = useRouteMatch<{
    subView: string;
    view: string;
  }>();
  const prevMatch = usePrevious(match);

  const [selectedView, setSelectedView] = useState({});
  const [isChoosingPlan, setIsChoosingPlan] = useState(false);

  const makeAPICalls = useCallback(() => {
    API.makeRequest(() => {
      dispatch(getWellnessBlocks());
      dispatch(getMaybeWellnessBlocks());
    });
  }, [dispatch]);

  const buildSelectedView = useCallback(() => {
    let thisView = BALANCE_VIEWS.Action;
    if (!isEmpty(match.params) && !isEmpty(match.params.view)) {
      thisView = find(BALANCE_VIEWS, function (o) {
        return o.url === match.params.view;
      }) as SelectedView;
    }
    return thisView;
  }, [match]);

  const checkForDeepLink = useCallback(() => {
    if (!isEmpty(match.params)) {
      const subView = match.params.subView;
      if (subView === 'choose-plan') {
        setIsChoosingPlan(true);
      }
    }
  }, [match]);

  const _onCloseWellnessBlockModal = () => {
    history.push(URLS.Balance.WellnessBlocks);
  };

  const _handleViewSelect = (nextView: SelectedView) => {
    history.push(`${URLS.Balance.Default}/${nextView.url}`);
  };

  const _toggleChoosePlanModal = () => {
    if (!isChoosingPlan) {
      history.push(URLS.Balance.PlanChooser);
    } else {
      setIsChoosingPlan(false);
      history.push(URLS.Balance.Action);
    }
  };

  // Mount
  useEffect(() => {
    checkForDeepLink(); // Check if a sub view should be open
    makeAPICalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Location update
  useEffect(() => {
    // If new url
    if (!isEqual(prevLocation, location) || !isEqual(prevMatch, match)) {
      setSelectedView(buildSelectedView());
      checkForDeepLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, prevLocation]);

  // Check for websocket update
  useEffect(() => {
    if (
      refreshes &&
      refreshes.length > 0 &&
      !isEqual(refreshes, prevRefreshes)
    ) {
      makeAPICalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshes]);

  if (!user) {
    return null;
  }

  const isChoosePlanModalVisible = !user.attributes || isChoosingPlan;

  return (
    <>
      <SEO title="Balance" />
      <WellnessBlockModal onClose={_onCloseWellnessBlockModal} />
      <div className={styles.balanceView}>
        <ChoosePlanModal
          isVisible={isChoosePlanModalVisible}
          user={user}
          onClose={_toggleChoosePlanModal}
        />
        <div className={styles.subNav}>
          {Object.keys(BALANCE_VIEWS).map(index => {
            const thisView = BALANCE_VIEWS[index];
            if (!thisView.display) return null;
            return (
              <Button
                key={index}
                active={isEqual(selectedView, thisView)}
                icon={<Emoji text={thisView.emoji} />}
                large={isBrowser}
                minimal
                text={isBrowser ? thisView.label : thisView.mobileLabel}
                onClick={() => _handleViewSelect(thisView)}
              />
            );
          })}
          {isBrowser && <ShareMeetingCulture settings={settings} />}
        </div>
        <div className={styles.inside} id={DIV_IDS.SecondaryScroll}>
          <div className={styles.content}>
            {isEqual(selectedView, BALANCE_VIEWS.Evaluation) && (
              <BalanceViewEvaluations
                settings={settings}
                settings_labels={settings_labels}
                user={user}
              />
            )}
            {isEqual(selectedView, BALANCE_VIEWS.Action) && (
              <BalanceViewAction
                settings={settings}
                settings_labels={settings_labels}
                user={user}
              />
            )}
            {isEqual(selectedView, BALANCE_VIEWS.WellnessBlocks) && (
              <BalanceViewWellnessBlocks />
            )}
          </div>
        </div>
      </div>
    </>
  );
});
