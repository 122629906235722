import React from 'react';
import { Link } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import {
  INTENT,
  ONBOARDING_STEPS,
  QUERY_PARAMS,
  URLS
} from '../../misc/consts';
import { Settings, User } from '../../misc/types';
import { Callout } from '../design-system';
import { Tip } from '../misc/Tip';

import { FuturePastActions } from './FuturePastActions';
import { InfoDeadline } from './InfoDeadline';
import { InfoDomainEnforcement } from './InfoDomainEnforcement';
import { InfoPeopleIgnore } from './InfoPeopleIgnore';
import { InfoPlanAction } from './InfoPlanAction';

import styles from '../../styles/action-info.module.scss';

interface ActionInfoProps {
  settings: Settings;
  user: User;
}

export const ActionInfo: React.ComponentType<ActionInfoProps> = React.memo(
  ({ settings, user }) => {
    const [onboardingStep] = useQueryParam(
      QUERY_PARAMS.Onboarding,
      StringParam
    );

    // If 'do nothing'
    const nonCompliAction =
      settings.attributes.action_settings.non_compliance_action;

    const noteText = nonCompliAction.includes('remind')
      ? 'auto-remind organizers for meetings'
      : 'auto-decline meetings';

    return (
      <div className={styles.actionInfo}>
        <div className={styles.card}>
          <div className={styles.details}>
            <InfoPlanAction settings={settings} />
            {nonCompliAction !== 'do nothing' && (
              <>
                <InfoDeadline settings={settings} user={user} />
                <Tip
                  {...ONBOARDING_STEPS.IgnoreEnforce}
                  childId={ONBOARDING_STEPS.IgnoreEnforce.childId}
                  criteria={
                    onboardingStep === ONBOARDING_STEPS.IgnoreEnforce.tag
                  }>
                  <div
                    className={styles.ignoreEnforce}
                    id={`tip-${ONBOARDING_STEPS.IgnoreEnforce.tag}`}>
                    <InfoDomainEnforcement user={user} which="user" />
                    <InfoPeopleIgnore user={user} which="user" />
                  </div>
                </Tip>
              </>
            )}
          </div>
        </div>
        {nonCompliAction !== 'do nothing' && (
          <Callout className={styles.actionNote} intent={INTENT.Caution}>
            <div>
              MeetWell will only {noteText} you have yet to <span>accept</span>{' '}
              or <span>decline</span>, and are <span>marked as required</span>.
            </div>
            <Link className={styles.link} to={URLS.Account.Default}>
              Manage how MeetWell sends emails
            </Link>
          </Callout>
        )}
        <FuturePastActions />
      </div>
    );
  }
);
