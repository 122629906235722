import React from 'react';
import Emoji from 'react-emoji-render';

import { Icon } from '../../../components/design-system';

import { HowExampleAutoDecline } from './HowExampleAutoDecline';
import { HowExampleEmailOrganizer } from './HowExampleEmailOrganizer';
import { HowExampleMeeting } from './HowExampleMeeting';
import { HowExampleMeetingInvite } from './HowExampleMeetingInvite';
import { HowExampleOrganizerUpdate } from './HowExampleOrganizerUpdate';

import styles from '../../../styles/marketing/how-it-works.module.scss';

const HOW_IT_WORKS = [
  {
    emoji: ':envelope_with_arrow:',
    title: "You're invited to a bad meeting",
    desc: 'A 2-hour meeting with no agenda nor objectives? This is totally going to interrupt your already busy day.',
    content: <HowExampleMeetingInvite direction="good-to-bad" />
  },
  {
    emoji: ':scales:',
    title: 'The meeting is evaluated',
    desc: 'MeetWell scores the invite and shows exactly why the meeting is bad. No agenda? No objective? No thank you.',
    content: <HowExampleMeeting which="bad" />
  },
  {
    emoji: ':mailbox_with_mail:',
    title: 'MeetWell emails the organizer',
    desc: 'MeetWell then sends a reminder email to the organizer with instructions on how to make the meeting better.',
    content: <HowExampleEmailOrganizer />
  },
  {
    emoji: ':pencil:',
    title: 'The organizer updates the invite',
    desc: 'Now that the organizer knows how to improve their meeting, they update the invite using the reminder email as a guide.',
    content: <HowExampleOrganizerUpdate />
  },
  {
    emoji: ':chart_increasing:',
    title: 'The meeting is re-evaluated',
    desc: 'When the invite improves, so does the score. Now, this meeting is not only better for you, but for everyone who attends!',
    content: <HowExampleMeeting which="good" />
  },
  {
    emoji: ':person_gesturing_no:',
    title: 'Decline meetings automatically',
    desc: 'What about other bad meetings? Set your Auto-balance setting to “Decline” and your calendar will always be free of unimproved meetings.',
    content: <HowExampleAutoDecline />
  },
  {
    emoji: ':blue_heart:',
    title: 'Enjoy more time for you',
    desc: 'Without bad meetings clogging up your calendar, you can focus on what matters most.',
    content: <HowExampleMeetingInvite direction="bad-to-good" />
  }
];

export const HowItWorks: React.ComponentType = React.memo(() => {
  return (
    <section className={styles.howItWorks}>
      <div className={styles.inner}>
        <div className={styles.top}>
          <h2 data-header="two">
            How does it <span>work?</span>
          </h2>
          <p data-header="desc-large">
            MeetWell makes meeting organizers aware of healthy meeting
            practices, declining invites if they're not improved.
          </p>
        </div>
        <div className={styles.arrows}>
          <Icon which="chevron-down" />
          <Icon which="chevron-down" />
          <div className={styles.downLine} />
        </div>
        <div className={styles.steps}>
          {HOW_IT_WORKS.map((item, index) => {
            return (
              <div key={index} className={styles.step}>
                <div className={styles.content}>
                  <Emoji className={styles.emoji} text={item.emoji} />
                  <div className={styles.connectingLine} />
                  <div className={styles.text}>
                    <h3 data-header="three">{item.title}</h3>
                    <p data-header="desc">{item.desc}</p>
                  </div>
                </div>
                <div className={styles.image}>{item.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
});
