import React from 'react';
import { filter, isEmpty, sortBy } from 'lodash';
import moment from 'moment';

import { PlanViewProps } from '../../containers/app/Plan';
import { DIV_IDS } from '../../misc/constants';
import { Meeting } from '../../misc/types';

import { EmptyState } from './EmptyState';
import { MeetingEvent } from './MeetingEvent';
import { MeetingModal } from './MeetingModal';
import { SubNav } from './SubNav';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from '../../styles/plan.module.scss';

export const MobileView: React.ComponentType<PlanViewProps> = React.memo(
  ({
    APICallsComplete,
    calendars,
    dateSelection,
    meetings,
    isLoading,
    isSyncingCalendar,
    selectedEvent,
    onCloseMeetingModal,
    onViewWellnessBlock,
    onChangeThePage,
    onSelectEvent
  }) => {
    const { startDate, endDate } = dateSelection;

    // Filter to just the date range
    const meetingsToShow = filter(meetings, function (item) {
      return moment(item.attributes.ends_at).isBetween(
        startDate,
        endDate,
        null,
        '[]' // Brackets mean "inclusive"
      );
    });

    // Sort meetings
    const sortedMeetings = sortBy(meetingsToShow, function (dateObj) {
      return new Date(dateObj.attributes.starts_at);
    });

    // Move meetings into separate days
    const groupedByDay: Record<string, Meeting[]> = {};
    if (!isEmpty(meetingsToShow)) {
      for (let i = 0; i < sortedMeetings.length; i++) {
        const thisMeeting = sortedMeetings[i];
        const startsAt = thisMeeting.attributes.starts_at;
        const dayOfWeek = moment(startsAt).format('dddd');
        if (isEmpty(groupedByDay[dayOfWeek])) {
          groupedByDay[dayOfWeek] = [thisMeeting];
        } else {
          groupedByDay[dayOfWeek].push(thisMeeting);
        }
      }
    }

    return (
      <div className={styles.calendarView}>
        <MeetingModal
          event={selectedEvent}
          isVisible={!isEmpty(selectedEvent)}
          onClose={onCloseMeetingModal}
          onViewWellnessBlock={onViewWellnessBlock}
        />
        <SubNav
          dateSelection={dateSelection}
          onChangeThePage={onChangeThePage}
        />
        <div className={styles.calendarAndStats} id={DIV_IDS.SecondaryScroll}>
          <EmptyState
            APICallsComplete={APICallsComplete}
            calendars={calendars}
            dateSelection={dateSelection}
            isLoading={isLoading}
            isSyncingCalendar={isSyncingCalendar}
            meetings={meetings}
          />
          {!isEmpty(sortedMeetings) &&
            Object.keys(groupedByDay).map(day => (
              <div key={day} className={styles.day}>
                <h6>{day}</h6>
                {groupedByDay[day].map((item, key) => (
                  <div
                    key={key}
                    className={styles.eventItem}
                    onClick={() => onSelectEvent(item)}>
                    <MeetingEvent event={item} title="" />
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    );
  }
);
