/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { createAction } from 'redux-actions';
import { createResource, readEndpoint, updateResource } from 'redux-json-api';

import { log } from '../../misc/utils';

const deleteItem = createAction('API_WAS_DELETED');

export const getTeams = () => {
  log('getTeams()');
  return dispatch => {
    dispatch(readEndpoint('teams'));
  };
};

export const getTeamMembers = id => {
  log(`getTeamMembers(${id})`);
  return dispatch => {
    dispatch(readEndpoint(`teams/${id}/members`));
  };
};

export const addTeamMember = (user_id, team_id) => {
  log('addTeamMember()');
  return dispatch => {
    const entity = {
      type: `teams/${team_id}/members`,
      attributes: {
        user_id,
        role: 'user'
      }
    };

    dispatch(createResource(entity));
  };
};

export const updateTeamMember = ({ member_id, team_id, role }) => {
  log('updateTeamMember()');

  return dispatch => {
    const entity = {
      type: `teams/${team_id}/members/${member_id}#update`,
      attributes: {
        role
      }
    };

    dispatch(updateResource(entity));
  };
};

export const updateTeamInviteSetting = (team_id, setting) => {
  log('updateTeamInviteSetting()');
  return dispatch => {
    const entity = {
      type: `teams/${team_id}#update`,
      attributes: {
        license_by: setting
      }
    };

    dispatch(updateResource(entity));
  };
};

export const generallyUpdateTeam = ({ team_id, which, value, callback }) => {
  log('generallyUpdateTeam()');
  return dispatch => {
    const entity = {
      type: `teams/${team_id}#update`,
      attributes: {
        [which]: value
      }
    };

    dispatch(updateResource(entity)).then(callback).catch(callback);
  };
};

export const saveTeamDenylistOrganizers = (emails, callback) => {
  log('saveTeamDenylistOrganizers()');
  return dispatch => {
    const entity = {
      type: `company/denylisted_organizers/bulk`,
      attributes: {
        email_list: emails
      }
    };

    dispatch(createResource(entity)).then(callback).catch(callback);
  };
};

export const deleteTeamMember = (member, team_id) => {
  log('deleteTeamMember()');
  const url = `/teams/${team_id}/members/${member.id}`;
  return dispatch => {
    const request = axios.delete(url);
    request.then(async () => {
      dispatch(deleteItem({ data: member }));
    });
  };
};

export const getInvites = () => {
  log(`getInvites()`);
  return dispatch => {
    dispatch(readEndpoint(`invites`));
  };
};

export const inviteTeamMember = (email, callback) => {
  log('inviteTeamMember()');
  return dispatch => {
    const entity = {
      type: `invites`,
      attributes: {
        email
      }
    };

    dispatch(createResource(entity)).then(callback).catch(callback);
  };
};

export const cancelInvite = (invite_id, callback) => {
  log('cancelInvite()');
  const url = `/invites/${invite_id}`;
  return () => {
    const request = axios.delete(url);
    request.then(async () => {
      callback && callback();
    });
  };
};
