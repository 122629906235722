import React, { useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';

import { usePrevious } from '../../hooks/previous';
import { DATE_FORMAT, INTENT, URLS } from '../../misc/consts';
import { FuturePastAction, RootState } from '../../misc/types';
import { capitalizeFirstLetter } from '../../misc/utils';
import { getFutureActions, getPastActions } from '../../modules/actions';
import { acceptMeeting } from '../../modules/actions';
import * as API from '../../modules/api';
import { Alert, Button, Tag, Tooltip } from '../design-system';

import 'react-tagsinput/react-tagsinput.css';
import styles from '../../styles/action-info.module.scss';

export const FuturePastActions: React.ComponentType = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const actions = useSelector((state: RootState) => state.actions);

  const refreshes = useSelector((state: RootState) => state.messages.refreshes);
  const prevRefreshes = usePrevious(refreshes);

  const [isIgnoringMeeting, setIsIgnoringMeeting] = useState(false);
  const [meetingToIgnore, setMeetingToIgnore] = useState('');

  const makeAPICalls = useCallback(() => {
    API.makeRequest(() => {
      dispatch(getFutureActions());
      dispatch(getPastActions());
    });
  }, [dispatch]);

  useEffect(() => {
    makeAPICalls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check for websocket update
  useEffect(() => {
    if (
      refreshes &&
      refreshes.length > 0 &&
      !isEqual(refreshes, prevRefreshes)
    ) {
      makeAPICalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshes]);

  const _goToMeeting = useCallback(
    meeting_id => {
      history.push(`${URLS.Plan.Meeting}/${meeting_id}`);
    },
    [history]
  );

  const _toggleIgnoreMeeting = useCallback(meeting_id => {
    setIsIgnoringMeeting(prevState => !prevState);
    setMeetingToIgnore(meeting_id);
  }, []);

  const _handleIgnoreMeeting = useCallback(() => {
    dispatch(acceptMeeting(meetingToIgnore));
  }, [dispatch, meetingToIgnore]);

  const renderAction = (
    data: FuturePastAction[],
    which: keyof typeof actions
  ) => {
    if (isEmpty(data)) {
      return <div className={styles.empty}>No actions.</div>;
    }

    const actionTimeTooltipContent = (item: FuturePastAction) => {
      if (which === 'future') {
        if (moment(item.attributes.compliance_deadline).isBefore(moment())) {
          return 'This is queued to happen soon';
        }
        return 'When this will happen';
      }
      return 'When this happened';
    };

    return data.map((item, index) => (
      <div key={index} className={styles.action}>
        <div className={styles.top}>
          <Tag className={styles.tag} small>
            {capitalizeFirstLetter(item.attributes.action)}
          </Tag>
          <span className={styles.organizer}>
            <Tooltip content={item.attributes.organizer_email}>
              {item.attributes.organizer_email}
            </Tooltip>
          </span>
          {which === 'future' && (
            <span className={styles.actions}>
              <Button
                intent={INTENT.Primary}
                minimal
                small
                text="View"
                onClick={() => _goToMeeting(item.attributes.meeting_id)}
              />
              <Button
                intent={INTENT.Danger}
                minimal
                small
                text="Ignore"
                onClick={() => _toggleIgnoreMeeting(item.attributes.meeting_id)}
              />
            </span>
          )}
        </div>
        <div className={styles.bottom}>
          <span className={styles.name}>
            <Tooltip content={item.attributes.meeting_summary}>
              {item.attributes.meeting_summary}
            </Tooltip>
          </span>
          <span className={styles.violations}>
            <Tooltip
              content={
                <ul>
                  {Object.keys(item.attributes.details_negative).map(key => (
                    <li key={key}>{item.attributes.details_negative[key]}</li>
                  ))}
                </ul>
              }>
              {Object.keys(item.attributes.details_negative).length} violations
            </Tooltip>
          </span>
          <Tooltip
            className={styles.time}
            content={actionTimeTooltipContent(item)}>
            <Moment format={DATE_FORMAT.Short} fromNow>
              {item.attributes.compliance_deadline}
            </Moment>
          </Tooltip>
        </div>
      </div>
    ));
  };

  return (
    <>
      <Alert
        confirmText="Yes, I'll attend"
        isVisible={isIgnoringMeeting}
        shouldShowCancel
        text="For an action to be ignored, MeetWell will accept this meeting for you."
        title="Are you sure?"
        onCancel={() => setIsIgnoringMeeting(prevState => !prevState)}
        onConfirm={_handleIgnoreMeeting}
      />
      <div className={styles.futureAndPastActions}>
        <h5>Future actions (next 7 days)</h5>
        <div className={styles.card}>
          {renderAction(actions.future, 'future')}
        </div>
        <h5>Past actions (last 7 days)</h5>
        <div className={styles.card}>{renderAction(actions.past, 'past')}</div>
      </div>
    </>
  );
});
