import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { INTENT } from '../../misc/consts';

import styles from '../../styles/design-system.module.scss';

export interface TagProps {
  children: ReactNode;
  circle?: boolean;
  className?: string;
  icon?: ReactNode;
  intent?: string;
  large?: boolean;
  minimal?: boolean;
  onClick?: () => void;
  small?: boolean;
}

export const Tag: React.ComponentType<TagProps> = React.memo(function Tag({
  intent,
  className,
  minimal = false,
  small = false,
  large = false,
  circle = false,
  children,
  icon,
  onClick
}) {
  return (
    <div
      className={classNames(styles.tag, className, {
        [styles.danger]: intent === INTENT.Danger,
        [styles.caution]: intent === INTENT.Caution,
        [styles.warning]: intent === INTENT.Warning,
        [styles.success]: intent === INTENT.Success,
        [styles.primary]: intent === INTENT.Primary,
        [styles.minimal]: minimal,
        [styles.small]: small,
        [styles.large]: large,
        [styles.circle]: circle
      })}
      onClick={onClick || undefined}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.text}>{children}</div>
    </div>
  );
});
