/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import { PLANS } from '../misc/constants';
import { ROLES } from '../misc/consts';
import { Plan, RootState } from '../misc/types';

export function useTeamSubscription() {
  const [hasManyMembers, setHasManyMembers] = useState<boolean | undefined>(
    undefined
  );
  const [isSolo, setIsSolo] = useState(false);
  const [isTeam, setIsTeam] = useState(false);
  const [isAdminOfTeam, setIsAdminOfTeam] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();

  const user = useSelector((state: RootState) => state.user.general);

  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );

  useEffect(() => {
    if (subscription && subscription.attributes) {
      setHasManyMembers(subscription.attributes.has_many_members);
      setIsSolo(
        subscription.attributes.plan === PLANS.Solo.id ||
          subscription.attributes.plan === PLANS.Trial.id
      );
      setIsTeam(subscription.attributes.plan === PLANS.Team.id);
      setSelectedPlan(
        find(PLANS, p => {
          return subscription.attributes.plan === p.id;
        })
      );
    }

    if (user && user.attributes && user.attributes.company) {
      setIsAdminOfTeam(
        user.attributes.company.roles.includes(ROLES.Admin.value)
      );
    }
  }, [user, subscription]);

  return [hasManyMembers, isAdminOfTeam, selectedPlan, isSolo, isTeam] as const;
}
