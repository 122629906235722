/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import { getInvoices } from './utils/invoices-utils';

export default (state = [], action) => {
  if (isValidAPICall(action, 'invoices')) {
    if (action.type === 'API_READ') {
      return action.payload.data;
    }
  }
  return state;
};

export { getInvoices };
