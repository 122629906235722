import React from 'react';

import { Icon } from '../design-system';

import styles from '../../styles/review-detailed-stats.module.scss';

interface TableHeaderColumnProps {
  className: string;
  isDesc: boolean;
  onClick: () => void;
  showSort: boolean;
  text: string;
}

export const TableHeaderColumn: React.ComponentType<TableHeaderColumnProps> = React.memo(
  ({ className, onClick, text, showSort, isDesc }) => {
    const maybeRenderSort =
      showSort && isDesc ? (
        <Icon
          className={styles.sortIcon}
          color="#577097"
          which="chevron-down"
        />
      ) : showSort && !isDesc ? (
        <Icon className={styles.sortIcon} color="#577097" which="chevron-up" />
      ) : null;
    return (
      <span className={className} onClick={onClick}>
        {text}
        {maybeRenderSort}
      </span>
    );
  }
);
