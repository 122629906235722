import React from 'react';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

export interface TextLinkProps {
  className?: string;
  isDisabled?: boolean;
  onClick: () => void;
  small?: boolean;
  text: string;
}

export const TextLink: React.ComponentType<TextLinkProps> = React.memo(
  function TextLink({
    className,
    isDisabled = false,
    onClick,
    small = false,
    text
  }) {
    return (
      <div
        className={classNames(styles.link, className, {
          [styles.small]: small,
          [styles.isDisabled]: isDisabled
        })}
        onClick={onClick}>
        {text && <div className={styles.text}>{text}</div>}
      </div>
    );
  }
);
