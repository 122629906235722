import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { URLS } from '../../misc/constants';
import { Meeting, Settings } from '../../misc/types';
import { enforceOneMeeting } from '../../modules/meetings';
import { Button, Tooltip } from '../design-system';

import styles from '../../styles/meeting-modal.module.scss';

interface SectionActionsDefaultViewProps {
  actionBeingMade: string;
  event: Meeting;
  onUpdateAction: (action: string) => void;
  settings: Settings;
}

export const SectionActionsDefaultView: React.ComponentType<SectionActionsDefaultViewProps> =
  React.memo(({ actionBeingMade, event, settings, onUpdateAction }) => {
    const dispatch = useDispatch();

    const existingAction =
      settings.attributes.action_settings.non_compliance_action;

    const isRemindAvailable = [
      'do nothing',
      'remind of conflicts',
      'remind',
      'decline',
      'decline conflicts'
    ].includes(existingAction);

    const isDeclineAvailable = ['decline', 'decline conflicts'].includes(
      existingAction
    );

    const isEnforceable = useMemo(
      () => isRemindAvailable || isDeclineAvailable,
      [isDeclineAvailable, isRemindAvailable]
    );

    const isRemindDisabled =
      !isRemindAvailable ||
      (!isEmpty(actionBeingMade) && actionBeingMade !== 'remind');

    const isDeclineDisabled =
      !isDeclineAvailable ||
      (!isEmpty(actionBeingMade) && actionBeingMade !== 'decline');

    const maybeOrganizerEmail = event.attributes.organizer_email
      ? event.attributes.organizer_email.email
      : 'the organizer';

    const _handleEnforceThisMeeting = (action: string) => {
      onUpdateAction(action); // Set spinner
      dispatch(enforceOneMeeting(event.id, action));
    };

    return (
      <div className={styles.sectionActions}>
        <h5>Take action</h5>
        <Tooltip
          content={`No reminder will be sent and the meeting will not be declined.`}>
          <Button
            disabled={isRemindDisabled}
            intent="primary"
            isLoading={
              actionBeingMade === 'remind' || actionBeingMade === 'decline'
            }
            minimal
            shouldLookDisabled
            text="Do nothing"
            onClick={() => _handleEnforceThisMeeting('do nothing')}
          />
        </Tooltip>
        <Tooltip
          content={`Send ${maybeOrganizerEmail} an email letting them know this meeting needs changes.`}>
          <Button
            disabled={isRemindDisabled}
            intent="warning"
            isLoading={actionBeingMade === 'remind'}
            minimal
            shouldLookDisabled
            text="Remind"
            onClick={() => _handleEnforceThisMeeting('remind')}
          />
        </Tooltip>
        <Tooltip
          content={`Remind ${maybeOrganizerEmail}, and potentially decline if the meeting isn't compliant in time.`}>
          <Button
            disabled={isDeclineDisabled}
            intent="danger"
            isLoading={actionBeingMade === 'decline'}
            minimal
            shouldLookDisabled
            text="Remind, then decline"
            onClick={() => _handleEnforceThisMeeting('decline')}
          />
        </Tooltip>
        {!isEnforceable && (
          <div className={styles.explainerTip}>
            <Tooltip content="These manual actions are available on any upgraded paid plan.">
              <div className={styles.explainerText}>
                Why are these disabled?
              </div>
            </Tooltip>
            <Link to={URLS.Balance.PlanChooser}>Upgrade</Link>
          </div>
        )}
      </div>
    );
  });
