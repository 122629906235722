import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { round } from 'lodash';
import moment from 'moment';

import { ReviewFilterInfo } from '../../containers/app/Review';
import { URLS } from '../../misc/consts';
import {
  DateSelectionProps,
  Reports,
  SelectedView,
  SettingsLabels,
  User
} from '../../misc/types';
import {
  buildReviewViewText,
  getComparisonText,
  getGradeFromNum,
  getIntentFromNum,
  getViewData,
  getViewMetric
} from '../../misc/utils';
import { ErrorState, Tag, Tooltip } from '../design-system';

import { Delta } from './Delta';
import { MeetingQualitySection } from './MeetingQualitySection';

import styles from '../../styles/review-report-card-stats.module.scss';

interface ReportCardStatsProps {
  dateSelection: DateSelectionProps;
  filterInfo?: ReviewFilterInfo;
  isColumnLayout?: boolean;
  isForMarketing?: boolean;
  reports: Reports;
  selectedView: SelectedView;
  settingsLabels: SettingsLabels;
  user: User;
}

export const ReportCardStats: React.ComponentType<ReportCardStatsProps> =
  React.memo(
    ({
      reports,
      filterInfo,
      selectedView,
      isColumnLayout = false,
      isForMarketing = false,
      dateSelection,
      settingsLabels,
      user
    }) => {
      const { startDate, endDate } = dateSelection;
      if (!reports) return null;

      // Get and validate overallMetric
      const overallMetric = round(
        getViewMetric('averages.attributes.average', reports, selectedView)
      );

      const renderPlanVersion = (overallMetric: number) => {
        return (
          <Link className={styles.card} to={URLS.Review.Default}>
            <Tooltip content="Click to view all meeting performance data">
              {overallMetric === 0
                ? renderEmptyState()
                : renderStats(overallMetric)}
            </Tooltip>
          </Link>
        );
      };

      const renderReviewVersion = (overallMetric: number) => {
        return (
          <div className={styles.card}>
            {overallMetric === 0
              ? renderEmptyState()
              : renderStats(overallMetric)}
          </div>
        );
      };

      const renderEmptyState = () => {
        return (
          <ErrorState
            description={`You don't have any meetings to evaluate for this week.`}
            inline={true}
            title="No stats available."
          />
        );
      };

      const renderStats = (overallMetric: number) => {
        const averageText = moment(endDate) > moment() ? 'average' : 'averaged';

        return (
          <>
            <div className={classNames(styles.module, styles.moduleOverall)}>
              <div className={styles.title}>
                {isColumnLayout ? (
                  <span>
                    Your meetings <strong>{averageText}</strong>...
                  </span>
                ) : (
                  <span>
                    {buildReviewViewText('your', selectedView, true)} meetings{' '}
                    <strong>{averageText} a score</strong> of...
                  </span>
                )}
              </div>
              <div className={styles.content}>
                <div className={styles.data}>
                  <div
                    className={classNames(styles.metric, {
                      [styles.perfectScore]: overallMetric === 100
                    })}>
                    {overallMetric}
                  </div>
                  <div className={styles.misc}>
                    <span className={styles.total}>
                      <span>/100</span>
                      <Tag
                        className={styles.grade}
                        intent={getIntentFromNum(overallMetric, 'text')}
                        large>
                        {getGradeFromNum(overallMetric)}
                      </Tag>
                    </span>
                    <Delta
                      className={styles.delta}
                      comparisonText={getComparisonText(
                        startDate,
                        endDate,
                        getViewMetric(
                          'duration_and_costs.attributes.spans_days',
                          reports,
                          selectedView
                        )
                      )}
                      data={overallMetric}
                      prevSpan={getViewMetric(
                        'averages.attributes.trends.average.prev_span_total',
                        reports,
                        selectedView
                      )}
                      type="num"
                      whatsGood="up"
                    />
                  </div>
                </div>
                <div className={styles.description}>
                  {getViewData(
                    'averages.attributes.rows.quality_label',
                    reports,
                    selectedView
                  )}
                </div>
              </div>
            </div>
            {!isColumnLayout && (
              <div className={classNames(styles.module, styles.meetingQuality)}>
                <div className={styles.qualitySectionWrapper}>
                  <MeetingQualitySection
                    filterInfo={filterInfo}
                    isForMarketing={isForMarketing}
                    reports={reports}
                    selectedView={selectedView}
                    settingsLabels={settingsLabels}
                    user={user}
                    which="good"
                  />
                  <MeetingQualitySection
                    filterInfo={filterInfo}
                    isForMarketing={isForMarketing}
                    reports={reports}
                    selectedView={selectedView}
                    settingsLabels={settingsLabels}
                    user={user}
                    which="bad"
                  />
                </div>
              </div>
            )}
          </>
        );
      };

      if (Number.isNaN(overallMetric)) {
        return null;
      }

      return (
        <div
          className={classNames(styles.section, styles.reportCardStats, {
            [styles.isColumnLayout]: isColumnLayout,
            [styles.isForMarketing]: isForMarketing
          })}>
          <h5>
            <span>REPORT CARD</span>
            {isColumnLayout && (
              <div className={classNames(styles.module, styles.viewMoreStats)}>
                <Link to={URLS.Review.Default}>View more stats</Link>
              </div>
            )}
          </h5>
          <div className={styles.description} data-label="description">
            How your meetings score along with the distribution of what's good
            and bad.
          </div>
          {isColumnLayout
            ? renderPlanVersion(overallMetric)
            : renderReviewVersion(overallMetric)}
        </div>
      );
    }
  );
