import React from 'react';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

export interface DollarProps {
  className?: string;
}

export const Dollar: React.ComponentType<DollarProps> = React.memo(
  function Dollar({ className }) {
    return <span className={classNames(styles.dollarSign, className)}>$</span>;
  }
);
