import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { INTENT, QUERY_PARAMS } from '../../../misc/consts';
import { Fathom, GOALS } from '../../../misc/fathom';
import { Meeting, Settings, User } from '../../../misc/types';
import { Alert, Button, Tooltip } from '../../design-system';
import { MeetingDate } from '../MeetingDate';
import { SectionAgendaView } from '../SectionAgendaView';
import { SectionAttendees } from '../SectionAttendees';
import { SectionObjectivesView } from '../SectionObjectivesView';
import { SectionPreWorkView } from '../SectionPreworkView';
import { Timer } from '../Timer';

import { PresentInProgress } from './PresentInProgress';

import styles from '../../../styles/present-mode.module.scss';

interface PresentViewProps {
  event: Meeting;
  onClose?: () => void;
  settings: Settings;
  topRightElement?: ReactNode;
  user: User;
}

export const PresentView: React.ComponentType<PresentViewProps> = React.memo(
  ({ event, settings, user, topRightElement, onClose }) => {
    const history = useHistory();
    const handle = useFullScreenHandle();
    const [meetingHasStarted, setMeetingHasStarted] = useState(false);
    const [meetingStartTime, setMeetingStartTime] = useState(moment());
    const [meetingEndTime, setMeetingEndTime] = useState(moment());
    const [meetingHasCompleted, setMeetingHasCompleted] = useState(false);

    const [signUpDialogOpen, setSignUpDialogOpen] = useState(false);

    const [errorDialogText, setErrorDialogText] = useState('');
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const isPreppingMeeting = !meetingHasStarted && !meetingHasCompleted;
    const isInMeeting = meetingHasStarted && !meetingHasCompleted;

    const startMeetingCallback = useCallback(() => {
      setMeetingHasStarted(true);
      setMeetingStartTime(moment());

      // Track goal
      Fathom.trackGoal(GOALS.Meeting.StartedMeetingPresentation);
    }, []);

    const meetingCompletedCallback = useCallback(() => {
      setMeetingHasCompleted(true);
      setMeetingEndTime(moment());

      // Track goal
      Fathom.trackGoal(GOALS.Meeting.EndedMeetingPresentation);
    }, []);

    const endMeetingCallback = useCallback(() => {
      setMeetingHasStarted(false);
      onClose && onClose();
    }, [onClose]);

    const toggleSignUpAlert = useCallback(() => {
      setSignUpDialogOpen(prevState => !prevState);
    }, []);

    const toggleSignInModal = useCallback(() => {
      history.push(`/?${QUERY_PARAMS.Login}=1`);
    }, [history]);

    const toggleErrorAlert = useCallback(text => {
      setErrorDialogText(text);
      setErrorDialogOpen(prevState => !prevState);
    }, []);

    const elapsedTime = useMemo(() => {
      return isPreppingMeeting ? (
        'Not yet started'
      ) : isInMeeting ? (
        <>
          <Timer time={meetingStartTime} type="elapsed-from" /> /{' '}
          {Math.floor(event.attributes.duration / 60)}:00
        </>
      ) : (
        <>
          Meeting ended:{' '}
          <Timer
            time={moment(meetingEndTime).diff(
              meetingStartTime,
              'seconds',
              true
            )}
            type="elapsed-with"
          />
        </>
      );
    }, [
      event.attributes.duration,
      isInMeeting,
      isPreppingMeeting,
      meetingEndTime,
      meetingStartTime
    ]);

    const objectivesList = useMemo(() => {
      if (!isEmpty(event.attributes.objectives.data)) {
        const objectives = event.attributes.objectives.data;
        return (
          <div className={styles.objectives}>
            <label>
              {objectives.length > 1 ? 'Objectives:' : 'Objective:'}
            </label>
            {objectives.map((item, index) => (
              <span key={index}>
                <span>{item.attributes.description}</span>
                {objectives.length > 1 && index !== objectives.length - 1 && (
                  <>,&nbsp;</>
                )}
                {objectives.length > 1 &&
                  index === objectives.length - 1 &&
                  '.'}
              </span>
            ))}
          </div>
        );
      }
      return null;
    }, [event]);

    const topBar = (
      <div className={styles.topBar}>
        <div className={styles.left}>
          <span className={styles.title}>{event.attributes.summary}</span>
          <span className={styles.elapsedTime}>{elapsedTime}</span>
          {isInMeeting && objectivesList}
        </div>
        <div className={styles.center}>
          <Timer type="clock" />
        </div>
        <div className={styles.right}>
          {meetingHasStarted && !isMobile && (
            <>
              <Button
                minimal={true}
                text={!handle.active ? 'Enter full screen' : 'Exit full screen'}
                onClick={!handle.active ? handle.enter : handle.exit}
              />
              {!handle.active && <span className={styles.divider} />}
            </>
          )}
          {topRightElement && !handle.active && topRightElement}
        </div>
      </div>
    );

    const meetingPrep = (
      <div className={styles.meetingPrep}>
        <div className={styles.title}>
          <h1>{event.attributes.summary}</h1>
        </div>
        <div className={styles.miscInfo}>
          <MeetingDate
            allDay={event.attributes.all_day}
            endDate={event.attributes.ends_at}
            startDate={event.attributes.starts_at}
          />
        </div>
        <div className={styles.info}>
          <div className={styles.group}>
            <SectionAttendees event={event} user={user} />
          </div>
          <div className={styles.group}>
            <SectionPreWorkView event={event} settings={settings} user={user} />
          </div>
          <div className={styles.group}>
            <SectionAgendaView event={event} settings={settings} user={user} />
          </div>
          <div className={styles.group}>
            <SectionObjectivesView
              event={event}
              settings={settings}
              user={user}
            />
          </div>
        </div>
        <Tooltip
          content="Agenda items are required to start a meeting"
          isDisabled={event.attributes.agenda_items.data.length > 0}>
          <Button
            centered={true}
            className={styles.startMeetingBtn}
            disabled={event.attributes.agenda_items.data.length === 0}
            intent={INTENT.Primary}
            large={true}
            shouldLookDisabled
            text="Start meeting"
            onClick={startMeetingCallback}
          />
        </Tooltip>
      </div>
    );

    return (
      <FullScreen handle={handle}>
        <div className={styles.presentView}>
          <Alert
            confirmText=""
            isVisible={errorDialogOpen}
            shouldShowCancel={false}
            text={errorDialogText}
            onCancel={toggleErrorAlert}
            onConfirm={toggleErrorAlert}
          />
          <Alert
            confirmText="Sign me up!"
            isVisible={signUpDialogOpen}
            shouldShowCancel={true}
            text="Enjoy this feature and more when you sign up!"
            title="Interested in this?"
            onCancel={toggleSignUpAlert}
            onConfirm={toggleSignInModal}
          />
          {topBar}
          {isPreppingMeeting && meetingPrep}
          {!isPreppingMeeting && (
            <PresentInProgress
              event={event}
              meetingHasCompleted={meetingHasCompleted}
              setMeetingHasCompleted={meetingCompletedCallback}
              onEndMeeting={endMeetingCallback}
            />
          )}
        </div>
      </FullScreen>
    );
  }
);
