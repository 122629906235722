/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { readEndpoint } from 'redux-json-api';

import { log } from '../../misc/utils';

export const getMeetingIntentions = () => {
  log('getMeetingIntentions()');
  return dispatch => {
    dispatch(readEndpoint(`meeting_intentions`));
  };
};
