/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { SEO } from '../../components/misc/SEO';

import styles from '../../styles/marketing/misc.module.scss';

export default class SecurityView extends Component {
  render() {
    return (
      <>
        <SEO title="Security" />
        <div
          className={classNames(
            styles.subpageView,
            styles.runningText,
            styles.blogPost
          )}>
          <div className={styles.inner}>
            <h1>MeetWell Security overview</h1>
            <p>
              Keeping our customers' data protected at all times is our highest
              priority. This security overview provides a high-level overview of
              the security practices put in place to achieve that objective.
              Have questions or feedback? Feel free to reach out to us at{' '}
              <a href="mailto:security@meetwell.app">security@meetwell.app</a>
            </p>
            <h2>Infrastructure</h2>
            <h5>Cloud infrastructure</h5>
            <p>
              All of our services run in the cloud in US-only regions. We don’t
              host or run our own routers, load balancers, DNS servers, or
              physical servers. Our service is built on Amazon Web Services and
              Heroku. They provide strong security measures to protect our
              infrastructure and are compliant with most certifications. You can
              read more about their practices here:
            </p>
            <ul>
              <li>
                <a href="https://aws.amazon.com/security/">AWS</a>
              </li>
              <li>
                <a href="https://www.heroku.com/policy/security">Heroku</a>
              </li>
            </ul>
            <h5>Software infrastructure</h5>
            <p>
              Our software infrastructure is updated regularly with the latest
              security patches.
            </p>
            <h2>Network level security monitoring and protection</h2>
            <p>
              We utilize Heroku Dynos and benefit from Heroku's security and
              isolation strategies. You can read more about their practices
              here:
            </p>
            <ul>
              <li>
                <a href="https://devcenter.heroku.com/articles/dynos#isolation-and-security">
                  Heroku Dyno Isolation
                </a>
              </li>
            </ul>
            <p>
              We monitor and protect our network to make sure no unauthorized
              access is performed using:
            </p>
            <ul>
              <li>
                A Runtime Application Self-Protection (RASP) &amp; In-App WAF
                that monitors and blocks attacks.
              </li>
              <li>
                An Intrusion Detection and/or Prevention technologies (IDS/IPS)
                solution that monitors and blocks attacks.
              </li>
              <li>IP address filtering</li>
            </ul>
            <h2>Data encryption</h2>
            <ul>
              <li>
                Encryption in transit: All data sent to or from our
                infrastructure is encrypted in transit via industry
                best-practices using Transport Layer Security (TLS). You can see
                our SSLLabs report{' '}
                <a href="https://www.ssllabs.com/ssltest/analyze.html?d=meetwell.app">
                  here
                </a>
                .
              </li>
              <li>
                Encryption at rest: All user data (including all calendar event
                data and more broadly, all PII) is encrypted using
                battled-proofed encryption (AES-GCM) in the database.
              </li>
            </ul>
            <h2>Data retention and removal</h2>
            <p>
              Every user can completely delete their data from within the{' '}
              <a href="https://www.meetwell.app/my/account">MeetWell Account</a>
              . All data is then immediately and completely removed from the
              database server. Within 30 days all data will be permanently
              deleted from backups. Read more about our privacy settings at{' '}
              <Link to="/privacy">meetwell.app/privacy</Link>.
            </p>
            <h2>Business continuity and disaster recovery</h2>
            <p>
              We back up all our critical assets and regularly attempt to
              restore the backup to guarantee a fast recovery in case of
              disaster. All our backups are encrypted and retained for a maximum
              of 30 days.
            </p>
            <h2>Constant monitoring</h2>
            <p>
              We have systems dedicated to maintaining your account’s security
              on our systems and monitoring tools we’ve set up to alert us to
              any nefarious activity against our domains. In the unfortunate
              circumstances someone malicious does successfully mount an attack,
              we will immediately notify all affected customers.
            </p>
            <h2>Application security monitoring</h2>
            <ul>
              <li>
                We use a security monitoring solution to get visibility into our
                application security, identify attacks and respond quickly to a
                data breach.
              </li>
              <li>
                We use technologies to monitor exceptions, logs and detect
                anomalies in our applications.
              </li>
              <li>
                We collect and store logs to provide an audit trail of our
                applications activity.
              </li>
              <li>We use trace monitoring in our services layer.</li>
            </ul>
            <h2>Application security protection</h2>
            <ul>
              <li>
                We use a runtime protection system that identifies and blocks
                OWASP Top 10 and business logic attacks in real-time.
              </li>
              <li>
                We use security headers to protect our users from attacks. You
                can check our grade on{' '}
                <a href="https://observatory.mozilla.org/analyze/meetwell.app">
                  Mozilla Observatory
                </a>
                .
              </li>
              <li>
                We use security automation capabilities that automatically
                detect and respond to threats targeting our apps.
              </li>
            </ul>
            <h2>Penetration testing</h2>
            <p>
              We perform periodic penetration tests via a selected third-party
              vendor.
            </p>
            <h2>Secure development</h2>
            <p>
              We develop following security best practices and frameworks (OWASP
              Top 10, SANS Top 25). We use the following best practices to
              ensure the highest level of security in our software:
            </p>
            <ul>
              <li>
                Developers proactively read up on common vulnerabilities and
                threats
              </li>
              <li>We review our code for security vulnerabilities</li>
              <li>
                We monitor and regularly update our dependencies and make sure
                none of them has known vulnerabilities
              </li>
              <li>
                We use Static Application Security Testing (SAST) to detect
                basic security vulnerabilities in our codebase
              </li>
              <li>
                We rely on quarterly third-party penetration tests of our
                applications.
              </li>
            </ul>
            <h2>Responsible disclosure</h2>
            <p>
              You can report vulnerabilities by contacting{' '}
              <a href="mailto:security@meetwell.app">security@meetwell.app</a>.
              Please include a proof of concept. We will respond as quickly as
              possible to your submission and won’t take legal actions if you
              follow the rules.
            </p>
            <ul>
              <li>
                <strong>Coverage</strong>: *.meetwell.app
              </li>
            </ul>
            <h2>User protection</h2>
            <ul>
              <li>
                <strong>Account takeover protection</strong>: We protect our
                users against data breaches by monitoring and blocking brute
                force attacks.
              </li>
              <li>
                <strong>Single sign-on</strong>: Single sign-on (SSO) is the
                only available authentication method (Google or Microsoft 365).
              </li>
            </ul>
            <h2>Compliance</h2>
            <h5>PCI Compliance</h5>
            <p>
              All payment instrument processing is safely outsourced to Stripe
              which is certified as a PCI Level 1 Service Provider. We don’t
              collect any payment information and are therefore not subject to
              PCI obligations.
            </p>
            <h5>GDPR</h5>
            <p>
              We’re compliant to the General Data Protection Regulation (GDPR).
              More can be read about that in our <a href="/terms">terms</a>.
            </p>
            <h5>Data Residency</h5>
            <p>
              All data is hosted in a US region and data is not transferred
              outside of the US. You can read more about our upstream provider's
              practices here:
            </p>
            <ul>
              <li>
                <a href="https://devcenter.heroku.com/articles/heroku-postgresql#data-residency">
                  Heroku Postgres Data Residency
                </a>
              </li>
            </ul>
            <h2>Payment information</h2>
            <p>
              All payment instrument processing is safely outsourced to Stripe
              which is certified as a PCI Level 1 Service Provider. We don’t
              collect any payment information and are therefore not subject to
              PCI obligations.
            </p>
            <h2>Audits and Certifications</h2>
            <p>
              MeetWell's infrastructure subprocessors have obtained third-party
              certifications and compliance audits.
              <ul>
                <li>
                  <a href="https://www.heroku.com/compliance">
                    Heroku - PCI, HIPAA, ISO, and SOC
                  </a>
                </li>
                <li>
                  <a href="https://postmarkapp.com/eu-privacy#security-and-privacy">
                    Postmark - PCI, ISO, and SOC
                  </a>
                </li>
                <li>
                  <a href="https://stripe.com/docs/security/stripe">
                    Stripe - PCI
                  </a>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </>
    );
  }
}
