/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isEmpty, set } from 'lodash';

import { isValidAPICall } from '../misc/utils';

import { getMeetingIntentions } from './utils/meeting-info-utils';

const initialState = {
  categories: {
    recurring: { label: 'Recurring', summary: '' },
    all_day: { label: 'All data', summary: '' }
  },
  intentions: {},
  isSyncing: false
};

export default (state = initialState, action) => {
  ////////
  // Meeting intentions
  if (isValidAPICall(action, 'meeting_intentions')) {
    return {
      ...state,
      ...set(state, `intentions`, action.payload.data.attributes.labels)
    };
  }

  ////////
  // Meeting syncing
  const isSyncingMeetings =
    !isEmpty(action.payload) &&
    !isEmpty(action.payload.finalEndpoint) &&
    action.payload.finalEndpoint.includes('meetings') &&
    action.payload.status === 204;

  // Mark as currently syncing
  if (isSyncingMeetings) {
    return {
      ...state,
      ...set(state, `isSyncing`, true)
    };
  }

  // Mark as no longer syncing
  if (isValidAPICall(action, 'meetings')) {
    return {
      ...state,
      ...set(state, `isSyncing`, false)
    };
  }

  return state;
};

export { getMeetingIntentions };
