import React from 'react';
import Emoji from 'react-emoji-render';
import classNames from 'classnames';

import { ViewEvaluationsCard } from '../../../components/balance/ViewEvaluationsCard';
import { EXAMPLE_USER } from '../../../misc/test-data';

import { OrganizerStatsPreview } from './OrganizerStatsPreview';
import { ReportCardStatsPreview } from './ReportCardStatsPreview';

import styles from '../../../styles/marketing/features-deep-dive.module.scss';

export const FeatureDeepDive: React.ComponentType = React.memo(() => {
  return (
    <section className={styles.featuresDeepDive}>
      <div className={styles.inner}>
        <div className={styles.top}>
          <h2 data-header="two">
            <span>Make your calendar work for you,</span> not against you.
          </h2>
          <p data-header="desc-large">
            MeetWell provides visibility into your calendar's health, improves
            the quality of your meetings, and creates opportunities for focus
            time during the work day.
          </p>
          <div className={styles.divider} />
        </div>
        <div className={styles.list}>
          <div className={classNames(styles.item, styles.imgBtm)}>
            <div className={styles.info}>
              <h3 data-header="three">
                Wellness, measured
                <Emoji text="😀" />
              </h3>
              <p data-header="desc">
                Meeting analytics report on the health of your calendar and
                shows how your time is spent in meetings.
              </p>
            </div>
            <ReportCardStatsPreview />
          </div>
          <div className={classNames(styles.item, styles.imgBtm)}>
            <div className={styles.info}>
              <h3 data-header="three">
                Be the ultimate trendsetter
                <Emoji text=":bar_chart:" />
              </h3>
              <p data-header="desc">
                Track the scores of meetings you attend and organize. See how
                they impact the quality of your week.
              </p>
            </div>
            <OrganizerStatsPreview />
          </div>
          <div className={classNames(styles.item, styles.imgRight)}>
            <div className={styles.info}>
              <h3 data-header="three">Set preferred meeting standards</h3>
              <p data-header="desc">
                Evaluate meetings based on what matters to you. Show organizers
                how you expect meetings to be structured.
              </p>
            </div>
            <div className={classNames(styles.image, styles.evaluationsCard)}>
              <ViewEvaluationsCard user={EXAMPLE_USER} />
              <div className={styles.list}>
                <div className={styles.listItem} />
                <div className={styles.listItem} />
                <div className={styles.listItem} />
                <div className={styles.listItem} />
                <div className={styles.listItem} />
                <div className={styles.listItem} />
                <div className={styles.listItem} />
                <div className={styles.listItem} />
              </div>
            </div>
          </div>
          <div className={classNames(styles.item, styles.imgLeft)}>
            <div className={styles.image} data-which="wellness-blocks" />
            <div className={styles.info}>
              <h3 data-header="three">Uninterrupted “me” time</h3>
              <p data-header="desc">
                <strong>Wellness blocks are untouchable time for you</strong>.
                Set them using color category tools in Google or Outlook.
                Meetings scheduled over a Wellness block are flagged so that
                organizers know to find a better time.
              </p>
            </div>
          </div>
          <div className={classNames(styles.item, styles.imgRight)}>
            <div className={styles.info}>
              <h3 data-header="three">Synced to your calendar</h3>
              <p data-header="desc">
                Get daily average meeting scores sent directly to your Google or
                Outlook calendar.
              </p>
            </div>
            <div className={styles.image} data-which="daily-reports" />
          </div>
        </div>
      </div>
    </section>
  );
});
