import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { isEmpty } from 'lodash';

import { useTeamSubscription } from '../../hooks/team-subscription';
import { COLORS, PLANS } from '../../misc/constants';
import { Fathom, GOALS } from '../../misc/fathom';
import { Plan, Subscription } from '../../misc/types';
import { createStripeCheckoutSession } from '../../modules/stripe-sessions';
import { updateSubscription } from '../../modules/subscriptions';
import { Button, Icon, Tooltip } from '../design-system';

import styles from '../../styles/pricing.module.scss';

interface PricingProps {
  isForMarketing?: boolean;
  onCancel: () => void;
  onGetStarted?: () => void;
  subscription?: Subscription;
  thisPlan: Plan;
}

export const Pricing: React.ComponentType<PricingProps> = React.memo(
  ({
    isForMarketing = false,
    thisPlan,
    subscription,
    onCancel,
    onGetStarted
  }) => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const [, isAdminOfTeam, , isSolo, isTeam] = useTeamSubscription();

    const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);

    useEffect(() => {
      setIsWaitingOnAPI(false);
    }, [subscription]);

    const hasCanceledSubscription = useMemo(
      () => (subscription ? !subscription.attributes.active : false),
      [subscription]
    );

    const _showStripeToast = useCallback(
      response => {
        addToast(`Something went wrong. ${response.error.message}`, {
          appearance: 'error',
          autoDismiss: false
        });
      },
      [addToast]
    );

    const _stripeCheckout = useCallback(() => {
      // Checkout API
      dispatch(createStripeCheckoutSession(thisPlan.id, _showStripeToast));

      // Track goal
      Fathom.trackGoal(GOALS.Teams.NavigatedToStripe);
    }, [_showStripeToast, dispatch, thisPlan]);

    const _stripeUpdate = useCallback(() => {
      // Update API
      dispatch(updateSubscription(thisPlan.id));

      // Track goal
      Fathom.trackGoal(GOALS.Teams.PlanUpdated);
    }, [dispatch, thisPlan]);

    const _selectPlan = useCallback(() => {
      // Show spinner in alert
      setIsWaitingOnAPI(true);

      if (subscription?.attributes.status === 'active') {
        _stripeUpdate();
      } else {
        _stripeCheckout();
      }
    }, [_stripeCheckout, _stripeUpdate, subscription]);

    const _contactUs = useCallback(() => {
      // Show spinner in alert
      window.location.href = 'mailto:support@meetwell.app';
    }, []);

    const renderContent = () => {
      if (isForMarketing && onGetStarted) {
        return (
          <div className={styles.actions}>
            {thisPlan === PLANS.Solo ? (
              <Button
                intent="primary"
                text="Get started"
                onClick={onGetStarted}
              />
            ) : (
              <Button text="Contact us" onClick={_contactUs} />
            )}
          </div>
        );
      }

      // If subscription exists, check if it should show status
      if (
        subscription &&
        subscription?.attributes.status !== 'trialing' &&
        ((thisPlan === PLANS.Solo && isSolo) ||
          (thisPlan === PLANS.Team && isTeam))
      ) {
        return renderExistingSubscriptionForm();
      }

      if (!ifSelectedIsSameExisting) {
        return (
          <div className={styles.actions}>
            {thisPlan === PLANS.Solo ? (
              <Tooltip content={tooltipText()}>
                <Button
                  disabled={isButtonDisabled}
                  isLoading={isWaitingOnAPI}
                  shouldLookDisabled
                  text="Select this plan"
                  onClick={_selectPlan}
                />
              </Tooltip>
            ) : (
              <Button text="Contact us" onClick={_contactUs} />
            )}
          </div>
        );
      }
    };

    const renderExistingSubscriptionForm = () => {
      return (
        <>
          <div className={styles.status}>
            <div className={styles.unlocked}>
              <Icon color={COLORS.primary} which="unlock" />
            </div>
          </div>
          {isAdminOfTeam && (
            <>
              {hasCanceledSubscription ? (
                <Button
                  intent="primary"
                  isLoading={isWaitingOnAPI}
                  minimal
                  text="Re-activate"
                  onClick={_selectPlan}
                />
              ) : (
                <Button
                  intent="danger"
                  minimal
                  text="Cancel"
                  onClick={onCancel}
                />
              )}
            </>
          )}
        </>
      );
    };

    // If selected plan is the same as the existing plan, and it's not about to be canceled
    const ifSelectedIsSameExisting =
      subscription &&
      thisPlan.id === subscription.attributes.plan &&
      subscription.attributes.active &&
      subscription?.attributes.status !== 'trialing';

    // If the selected plan is empty, or is same as existing
    const isButtonDisabled = !isAdminOfTeam || ifSelectedIsSameExisting;

    const tooltipText = () => {
      if (isEmpty(thisPlan)) {
        return 'Please select a plan';
      }
      if (hasCanceledSubscription) {
        return 'Your plan will re-activate';
      }
      if (!isAdminOfTeam) {
        return 'Only admins can choose a plan';
      }
      if (subscription && subscription.attributes.active) {
        // If an upgrade/downgrade in price
        return 'This updated price will be reflected at the next billing date';
      }
      return 'You will be redirected to checkout';
    };

    return <div className={styles.pricing}>{renderContent()}</div>;
  }
);
