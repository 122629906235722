import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  description?: string;
  title?: string;
}

export const SEO: React.ComponentType<SEOProps> = React.memo(
  ({ title, description }) => {
    const thisTitle = title
      ? `${title} | MeetWell`
      : 'MeetWell | Only good meetings';
    const thisDescription =
      description ||
      'MeetWell improves or removes bad meetings on your calendar, allowing your time to be better spent. Uncover your bad meetings in less than 1 minute.';

    return (
      <Helmet>
        {/* <!-- Primary Meta Tags --> */}
        <title>{thisTitle}</title>
        <meta content={thisTitle} name="title" />
        <meta content={thisDescription} name="description" />

        {/* <!-- Open Graph / Facebook --> */}
        <meta content={thisTitle} property="og:title" />
        <meta content={thisDescription} property="og:description" />

        {/* <!-- Twitter --> */}
        <meta content={thisTitle} property="twitter:title" />
        <meta content={thisDescription} property="twitter:description" />
      </Helmet>
    );
  }
);
