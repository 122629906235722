import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { User } from '../../misc/types';
import { Icon } from '../design-system';

import styles from '../../styles/balance-view-evaluations-card.module.scss';

interface ViewEvaluationsCardProps {
  user: User;
}

export const ViewEvaluationsCard: React.ComponentType<ViewEvaluationsCardProps> =
  React.memo(({ user }) => {
    if (isEmpty(user)) {
      return null;
    }

    const teamName = user.attributes.company
      ? user.attributes.company.name
      : '';

    return (
      <div className={styles.card}>
        <Icon className={styles.arrow} which="arrow-right" />
        <div className={styles.summary}>
          <div className={classNames(styles.row, styles.highlight)}>
            <label>
              For my team
              {teamName && (
                <>
                  {' '}
                  at <span className={styles.teamName}>{teamName}</span>
                </>
              )}
              ,
            </label>
          </div>
          <div className={styles.row}>
            <span>an</span>
            <div className={styles.value}>
              <span>A+</span>
            </div>
            <div className={styles.value}>
              <span>100</span>
              <div className={styles.oneHundred}>/100</div>
            </div>
            <span>meeting</span>
          </div>
          <div className={styles.row}>has these qualities...</div>
        </div>
      </div>
    );
  });
