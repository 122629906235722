import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { COLORS } from '../../misc/constants';
import { User } from '../../misc/types';
import { generallyUpdateUser } from '../../modules/user';
import { Button, Checkbox, Icon, RadioGroupForm, Tag } from '../design-system';

import styles from '../../styles/account.module.scss';

interface SlackPreferencesProps {
  user: User;
}

export const SlackPreferences: React.ComponentType<SlackPreferencesProps> =
  React.memo(({ user }) => {
    const dispatch = useDispatch();

    const _goToSlack = useCallback(() => {
      const slackSignInURL = `${process.env.REACT_APP_BASE_URL}/auth/slack/?origin=${window.location.pathname}`;
      window.location.href = slackSignInURL;
    }, []);

    const _toggleSlackDND = useCallback(() => {
      dispatch(
        generallyUpdateUser({
          which: 'dnd_slack',
          value: !user.attributes.dnd_slack
        })
      );
    }, [dispatch, user]);

    return (
      <RadioGroupForm
        desc={`MeetWell can change your Slack status to "Do not disturb" while you're spending time within one of your marked wellness blocks.`}
        miscContent={
          <div className={styles.slackDndDetails}>
            <Checkbox
              disabled={user.attributes.dnd_slack === null}
              disabledText="Add MeetWell to Slack to enable this feature"
              selected={user.attributes.dnd_slack === true}
              text={
                <>
                  <span>Enable "Do not disturb"</span> for Wellness blocks
                </>
              }
              onClick={_toggleSlackDND}
            />
            <div className={styles.status}>
              {user.attributes.dnd_slack === null ? (
                <Button className={styles.slackAddToBtn} onClick={_goToSlack}>
                  <div className={styles.logo} />
                  <div className={styles.text}>Add to Slack</div>
                </Button>
              ) : (
                <div className={styles.added}>
                  <Tag
                    icon={<Icon color={COLORS.success} which="tick" />}
                    intent="success">
                    Added to Slack
                  </Tag>
                  <Button
                    className={styles.manageBtn}
                    small={true}
                    text="Manage"
                    onClick={_goToSlack}
                  />
                </div>
              )}
            </div>
          </div>
        }
        title={`Slack: "Do not disturb" for Wellness blocks`}
      />
    );
  });
