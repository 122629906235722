import React, { useCallback } from 'react';
import Emoji from 'react-emoji-render';

import { Button } from '../../../components/design-system';
import { useLocalStorage } from '../../../hooks/local-storage';
import { INTENT } from '../../../misc/consts';

import styles from '../../../styles/marketing/no-tracking-popup.module.scss';

export const NoTrackingPopup: React.ComponentType = React.memo(() => {
  const [hasSeenNoTracking, setHasSeenNoTracking] = useLocalStorage(
    'MW-hasSeenNoTracking',
    false
  );

  const _handleCloseTracking = useCallback(() => {
    setHasSeenNoTracking(true);
  }, [setHasSeenNoTracking]);

  if (hasSeenNoTracking) {
    return null;
  }

  return (
    <div className={styles.noTrackingPopup}>
      <h2>
        Cookies are for eating <Emoji text=":cookie:" />
      </h2>
      <p data-header="desc">
        We don't believe tracking cookies are required for the best experience.{' '}
        <a href="/blog/manifesto">Read more.</a>
      </p>
      <Button
        centered={true}
        fill={true}
        intent={INTENT.Primary}
        text="Cheers to privacy!"
        onClick={_handleCloseTracking}
      />
    </div>
  );
});
