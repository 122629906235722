import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { isBrowser } from 'react-device-detect';
import Emoji from 'react-emoji-render';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import classNames from 'classnames';
import { get, merge } from 'lodash';
import moment from 'moment';
import { BooleanParam, StringParam, useQueryParam } from 'use-query-params';

import { COLORS, INTENTION_EMOJIS } from '../../misc/constants';
import {
  DATE_FORMAT,
  INTENT,
  ONBOARDING_STEPS,
  QUERY_PARAMS,
  URLS,
  WELLNESS_BLOCKS
} from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import {
  CalendarSource,
  Meeting,
  MeetingIntention,
  MeetingRefresh,
  MeetingScoreItem,
  Progress,
  ProgressData,
  Settings,
  SettingsLabels,
  User
} from '../../misc/types';
import { getCalendarSource } from '../../misc/utilities';
import { getIntentFromNum } from '../../misc/utils';
import * as API from '../../modules/api';
import {
  generallyUpdateMeeting,
  getMeetingEvaluation,
  getMeetingEvaluationProgress,
  getUserSpecificMeetingInfo,
  makeWellnessBlock
} from '../../modules/meetings';
import {
  Alert,
  Button,
  ErrorState,
  Icon,
  Loading,
  MenuDivider,
  MenuHeader,
  MenuItem,
  MenuItems,
  Popover,
  Tag,
  Tooltip
} from '../design-system';
import { AlertProps } from '../design-system/Alert';
import { ScoreItem } from '../misc/ScoreItem';
import { Tip } from '../misc/Tip';

import { PresentModal } from './present-mode/PresentModal';
import { MeetingCost } from './MeetingCost';
import { MeetingDate } from './MeetingDate';
import { SectionActions } from './SectionActions';
import { SectionAgendaView } from './SectionAgendaView';
import { SectionAttendees } from './SectionAttendees';
import { SectionChart } from './SectionChart';
import { SectionDescription } from './SectionDescription';
import { SectionObjectivesView } from './SectionObjectivesView';
import { SectionPreWorkView } from './SectionPreworkView';

import styles from '../../styles/meeting-modal.module.scss';

interface ModalDefaultProps {
  event: Meeting;
  intentions: Record<string, MeetingIntention>;
  isForMarketing?: boolean;
  refreshes?: MeetingRefresh[];
  settings: Settings;
  settings_labels?: SettingsLabels;
  user: User;
}

export const ModalDefault: React.ComponentType<ModalDefaultProps> = React.memo(
  ({
    event,
    user,
    intentions,
    settings,
    settings_labels,
    isForMarketing = false,
    refreshes
  }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [skipEvalParam] = useQueryParam(QUERY_PARAMS.SkipEval, BooleanParam);
    const [convertWBParam] = useQueryParam(QUERY_PARAMS.SkipEval, StringParam);

    const APICallsToMake = useRef({
      evaluation: false,
      userSpecificInfo: false
    });

    const calendarSource: CalendarSource = useMemo(
      () => getCalendarSource(user),
      [user]
    );

    const _handleToggleAlert = useCallback(text => {
      setAlertDialog(prevState => {
        return {
          ...prevState,
          text,
          confirmText: '',
          onConfirm: _handleToggleAlert,
          isVisible: !prevState.isVisible,
          shouldShowCancel: false
        };
      });
    }, []);

    const [alertDialog, setAlertDialog] = useState<AlertProps>({
      isVisible: false,
      text: '',
      shouldShowCancel: false,
      confirmText: '',
      onCancel: _handleToggleAlert,
      onConfirm: _handleToggleAlert
    });

    const _showSignUpAlert = useCallback(() => {
      setAlertDialog(prevState => {
        return {
          ...prevState,
          isVisible: true,
          title: 'Interested in this?',
          confirmText: 'Sign me up!',
          text: 'Enjoy this feature and more when you sign up!',
          shouldShowCancel: true,
          onConfirm: () => {
            history.push(`/?${QUERY_PARAMS.Login}=1`);
          }
        };
      });
    }, [history]);

    const _showConfirmMultipleAttendeesAlert = useCallback(callback => {
      setAlertDialog(prevState => {
        return {
          ...prevState,
          isVisible: true,
          title: (
            <>
              <Emoji text=":warning:" /> Are you sure?
            </>
          ),
          text: 'You’re converting this event into a Wellness block for yourself and on behalf of the other MeetWell users attending.',
          onConfirm: callback,
          confirmText: 'Yes, convert this meeting',
          shouldShowCancel: true
        };
      });
    }, []);

    const [lastRefresh, setLastRefresh] = useState<Date>(new Date());
    const [isOptionsPopoverVisible, setIsOptionsPopoverVisible] =
      useState(false);
    const [APICallsComplete, setAPICallsComplete] = useState(false);
    const [progress, setProgress] = useState<Progress[]>([]);
    const [isInEditMode, setIsInEditMode] = useState(false);
    const [editModeSection, setEditModeSection] = useState<string | null>(null);
    const [detailsPositiveNegative, setDetailsPositiveNegative] = useState<
      Record<string, string>
    >({});

    const isEvaluated = useMemo(() => event.attributes.score !== null, [event]);

    const _setProgress = useCallback(response => {
      setProgress(response.body ? response.body.data : []);
    }, []);

    const _checkIfCallsHaveCompleted = useCallback(which => {
      // Mark call as 'true'
      if (which) {
        APICallsToMake.current = { ...APICallsToMake.current, [which]: true };

        // Check if all calls have been completed
        const isComplete =
          Object.values(APICallsToMake.current).indexOf(false) === -1;
        if (isComplete) {
          setAPICallsComplete(true);
        }
      }
    }, []);

    const _makeAPICalls = useCallback(() => {
      API.makeRequest(() => {
        setAPICallsComplete(false);
        setLastRefresh(new Date());

        dispatch(
          getMeetingEvaluation(event.id, () =>
            _checkIfCallsHaveCompleted('evaluation')
          )
        );
        dispatch(getMeetingEvaluationProgress(event.id, _setProgress));
        dispatch(
          getUserSpecificMeetingInfo(event.id, () =>
            _checkIfCallsHaveCompleted('userSpecificInfo')
          )
        );
      });
    }, [_checkIfCallsHaveCompleted, _setProgress, dispatch, event]);

    const _handleOptionsClick = useCallback(() => {
      setIsOptionsPopoverVisible(prevState => !prevState);
    }, []);

    const _handleMakeWellnessBlock = useCallback(() => {
      const makeAPICalls = () => {
        const start = moment(event.attributes.starts_at).format(
          DATE_FORMAT.Hash
        );
        const end = moment(event.attributes.ends_at).format(DATE_FORMAT.Hash);

        // Make the call
        dispatch(makeWellnessBlock(event.id, start, end));

        // Track goal
        Fathom.trackGoal(GOALS.WellnessBlocks.ConvertedMeeting);
      };

      // Is for Marketing
      if (isForMarketing) {
        return _showSignUpAlert();
      }

      // Show alert if more than one attendee
      if (event.attributes.attendees_count > 1) {
        return _showConfirmMultipleAttendeesAlert(makeAPICalls);
      }

      // Make the calls
      makeAPICalls();
    }, [
      _showConfirmMultipleAttendeesAlert,
      _showSignUpAlert,
      dispatch,
      event,
      isForMarketing
    ]);

    const _handleSkipEvaluation = useCallback(() => {
      // Is for Marketing
      if (isForMarketing) {
        return _showSignUpAlert();
      }

      const skipValue = !event.attributes.skip_evaluation; // Make opposite of existing value

      // API call
      dispatch(generallyUpdateMeeting(event.id, 'skip_evaluation', skipValue));

      // Show toast
      const toastContent = !event.attributes.skip_evaluation
        ? 'This meetings evaluation was skipped.'
        : 'This meeting was re-evaluated.';
      addToast(toastContent, {
        appearance: 'info',
        autoDismiss: true
      });

      // Track goal (if wasn't skipped, say it was skipped)
      if (!event.attributes.skip_evaluation) {
        Fathom.trackGoal(GOALS.Meeting.SkippedEvaluation);
      }
    }, [_showSignUpAlert, addToast, dispatch, event, isForMarketing]);

    const renderTopSection = () => {
      const maybeRenderDeletedTag =
        event.attributes.status === 'deleted' ? (
          <Tag
            className={styles.deletedMeetingTag}
            intent={INTENT.Danger}
            large>
            Deleted
          </Tag>
        ) : null;

      return (
        <div className={styles.sectionTop}>
          <div className={styles.title}>
            <h2>
              {event.attributes.summary}
              {maybeRenderDeletedTag}
            </h2>
            {maybeRenderOptions()}
          </div>
          <div className={styles.miscInfo}>
            <MeetingDate
              allDay={event.attributes.all_day}
              endDate={event.attributes.ends_at}
              startDate={event.attributes.starts_at}
            />
            <div className={styles.metaData}>
              {event.attributes.intention && (
                <div className={styles.intention}>
                  <Tooltip content="The automatically inferred intention from your meeting title and description.">
                    <Emoji
                      text={`${get(
                        INTENTION_EMOJIS,
                        event.attributes.intention
                      )} ${get(intentions, event.attributes.intention).label}`}
                    />
                  </Tooltip>
                </div>
              )}
              {maybeRenderLabels()}
              {event.attributes.cost && (
                <MeetingCost
                  attendees_count={event.attributes.attendees_count}
                  className={styles.meetingCost}
                  cost={event.attributes.cost}
                  duration={event.attributes.duration}
                />
              )}
              {maybeRenderStartMeeting()}
            </div>
          </div>
        </div>
      );
    };

    const maybeRenderLabels = () => {
      if (!event.attributes.all_day && !event.attributes.recurring_meeting) {
        return null;
      }

      return (
        <div className={styles.labels}>
          {event.attributes.recurring_meeting && <Tag>Recurring</Tag>}
          {event.attributes.all_day && <Tag>All day</Tag>}
        </div>
      );
    };

    const maybeRenderStartMeeting = () => {
      if (
        !user ||
        event.attributes.attendees_count === undefined ||
        event.attributes.attendees_count < 2
      ) {
        return null;
      }

      return <PresentModal event={event} settings={settings} user={user} />;
    };

    const maybeRenderOptions = () => {
      const { wellness_blockable } = event.attributes;

      // Only show if is blockable in some way
      if (!wellness_blockable) {
        return null;
      }

      return (
        <div className={styles.options}>
          <Popover
            content={
              <MenuItems>
                <MenuHeader>Wellness blocks</MenuHeader>
                {wellness_blockable && (
                  <MenuItem onClick={_handleMakeWellnessBlock}>
                    Convert to a Wellness block
                  </MenuItem>
                )}
                {(isEvaluated || event.attributes.skip_evaluation) && (
                  <>
                    <MenuDivider />
                    <MenuHeader>Misc</MenuHeader>
                    <MenuItem onClick={_handleSkipEvaluation}>
                      {!event.attributes.skip_evaluation
                        ? 'Skip evaluation'
                        : 'Re-evaluate'}
                    </MenuItem>
                  </>
                )}
              </MenuItems>
            }
            isVisible={isOptionsPopoverVisible}
            onToggle={_handleOptionsClick}>
            <Button
              iconRight={<Icon color={COLORS.primary} which="chevron-down" />}
              intent="primary"
              minimal
              text="Actions"
              onClick={_handleOptionsClick}
            />
          </Popover>
        </div>
      );
    };

    const renderBottomSection = () => {
      return (
        <div className={styles.sectionBottom}>
          <div className={styles.sectionBottomInner}>
            <div className={styles.sectionLeft}>
              <SectionDescription
                className={classNames({
                  [styles.fadeOut]: isInEditMode
                })}
                event={event}
              />
              {maybeRenderLocation()}
              <SectionAttendees
                className={classNames({
                  [styles.fadeOut]: isInEditMode
                })}
                event={event}
                user={user}
              />
              {renderAgendaAndObjectives()}
            </div>
            <div className={styles.sectionRight}>
              {isEvaluated ? renderScore() : renderNonEvalReason()}
            </div>
          </div>
        </div>
      );
    };

    const maybeRenderLocation = () => {
      if (!event.attributes.location) return null;

      return (
        <div
          className={classNames(styles.section, {
            [styles.fadeOut]: isInEditMode
          })}>
          <div className={styles.item}>
            <Icon className={styles.icon} which="location" />
            <div className={styles.text}>{event.attributes.location}</div>
          </div>
        </div>
      );
    };

    const renderAgendaAndObjectives = () => {
      // Don't show if settings are empty
      if (!settings) return null;

      const tipCriteria = APICallsComplete && !isForMarketing;

      return (
        <Tip
          childId={ONBOARDING_STEPS.Meeting.childId}
          content={
            <div>
              <div>
                Agenda items, prework, and objectives can be added from your
                meeting description in {calendarSource.label}. We will organize
                it nicely for you!
              </div>
              <Button
                centered={true}
                className={styles.showMeHow}
                text="Show me how"
                onClick={() => window.open('/faq#plan-add-agenda', '_blank')}
              />
            </div>
          }
          criteria={tipCriteria}
          headerText={ONBOARDING_STEPS.Meeting.headerText}
          nextStep={ONBOARDING_STEPS.Meeting.nextStep}
          steps={ONBOARDING_STEPS.Meeting.steps}
          tag={ONBOARDING_STEPS.Meeting.tag}>
          <div className={styles.agendaAndObjectives} id="tip-agenda-parser">
            <SectionAgendaView
              className={classNames({
                [styles.fadeOut]: isInEditMode && editModeSection !== 'agenda'
              })}
              event={event}
              settings={settings}
              user={user}
            />
            <SectionObjectivesView
              className={classNames({
                [styles.fadeOut]:
                  isInEditMode && editModeSection !== 'objectives'
              })}
              event={event}
              settings={settings}
              user={user}
            />
            <SectionPreWorkView
              className={classNames({
                [styles.fadeOut]: isInEditMode && editModeSection !== 'prework'
              })}
              event={event}
              settings={settings}
              user={user}
            />
          </div>
        </Tip>
      );
    };

    const renderScore = () => {
      let results: MeetingScoreItem[] = [];
      let overallScore = 0;

      // Don't show if no overall score
      if (event.attributes.score === null) {
        return null;
      }

      // Set vars if not empty
      if (event.attributes.results) {
        results = Object.keys(event.attributes.results).map(key => {
          return {
            key,
            ...event.attributes.results[key]
          };
        });
        // Sort organizers by score
        results.sort((a, b) => {
          return a.score - b.score;
        });
        overallScore = Math.round(event.attributes.score);
      }

      const progressData = progress.map((item, index) => {
        return {
          primary: index + 1,
          secondary: item.attributes.score[1],
          date: item.attributes.date
        };
      }) as ProgressData[];

      return (
        <div
          className={classNames(styles.section, {
            [styles.fadeOut]: isInEditMode
          })}>
          <div className={styles.scoreVisual}>
            <div className={styles.scoreNum}>
              <span className={styles.num}>{overallScore}</span>
              <span className={styles.total}>/100</span>
              <span className={styles.desc}>
                {event.attributes.quality_label}
              </span>
              <SectionChart
                overallScore={overallScore}
                progress={progressData}
              />
            </div>
            <div className={styles.scoreBar}>
              <div
                className={styles.colorBar}
                style={{
                  width: `${overallScore}%`,
                  backgroundColor: getIntentFromNum(overallScore, 'color')
                }}
              />
            </div>
          </div>
          <div className={styles.scoreList}>
            <div className={styles.header}>
              <h6>EVALUATIONS</h6>
              <div className={styles.score}>SCORE</div>
              <div className={styles.points}>POINTS</div>
            </div>
            {results.length > 0 &&
              results.map((item, index) => (
                <ScoreItem
                  key={index}
                  details={detailsPositiveNegative}
                  label={item.key || ''}
                  points={item.points}
                  score={item.score}
                  settingsLabels={settings_labels}
                  weight={item.weight}
                />
              ))}
          </div>
          {results && !isForMarketing && (
            <div className={styles.scoreSettings}>
              <Link to={URLS.Balance.Default}>See how meetings are scored</Link>
            </div>
          )}
        </div>
      );
    };

    const renderNonEvalReason = () => {
      const reasoning = () => {
        ////// REASONS
        // Is a focus block (code path doesn't ever arrive here)
        // Is an all-dayer
        // 1 or 0 attendees

        // All day-er
        if (event.attributes.all_day) {
          return 'MeetWell does not score all-day meetings. They are not included in reporting.';
        }

        // If eval skipped
        if (event.attributes.skip_evaluation) {
          return "You've told MeetWell to skip this meetings evaluation.";
        }

        // If maybe wellness block
        if (event.attributes.maybe_wellness_block === 'true') {
          return 'MeetWell does not score meetings that are possibly Wellness blocks.';
        }

        // Attendees count
        const attendeesCount = event.attributes.attendees_count;
        if (attendeesCount === null || !attendeesCount || attendeesCount <= 1) {
          return 'MeetWell only scores meetings with two or more people.';
        }

        // Catch-all
        return 'MeetWell does not score all-day meetings, with only one person, or Wellness blocks.';
      };

      return (
        <ErrorState
          className={styles.noEvalState}
          description={reasoning()}
          title="Score not available."
        />
      );
    };

    useEffect(() => {
      // Only make API calls if this component isn't used on the home page
      if (!isForMarketing) {
        _makeAPICalls();
      } else {
        setAPICallsComplete(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      // Check for deep link
      if (skipEvalParam) {
        setTimeout(() => {
          if (APICallsComplete) {
            _handleSkipEvaluation();
            setTimeout(() => {
              history.push(location.pathname);
            }, 500);
          }
        }, 2000);
      } else if (convertWBParam) {
        setTimeout(() => {
          if (APICallsComplete) {
            if (convertWBParam === WELLNESS_BLOCKS.Focus.value) {
              _handleMakeWellnessBlock();
            }
            setTimeout(() => {
              history.push(location.pathname);
            }, 500);
          }
        }, 2000);
      }
    }, [
      APICallsComplete,
      _handleMakeWellnessBlock,
      _handleSkipEvaluation,
      _makeAPICalls,
      convertWBParam,
      history,
      location,
      skipEvalParam
    ]);

    // Check for websocket update
    useEffect(() => {
      if (
        refreshes &&
        refreshes.length > 0 &&
        refreshes[0].timestamp > lastRefresh
      ) {
        _makeAPICalls();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshes]);

    useEffect(() => {
      if (!user || !event) {
        setIsOptionsPopoverVisible(false);
        setIsInEditMode(false);
        setEditModeSection(null);
        setDetailsPositiveNegative({});
      } else {
        setDetailsPositiveNegative(
          merge(
            event.attributes.details_positive || {},
            event.attributes.details_negative || {},
            event.attributes.details_maybe || {}
          )
        );
      }
    }, [user, event]);

    if (!event) {
      return null;
    }

    const shouldShowSectionActions =
      APICallsComplete && isBrowser && !isForMarketing;

    return (
      <>
        <Alert {...alertDialog} />
        <div
          className={classNames(styles.sectionLoading, {
            [styles.visible]: !APICallsComplete
          })}>
          <Loading />
        </div>
        {renderTopSection()}
        {renderBottomSection()}
        {shouldShowSectionActions && (
          <SectionActions event={event} settings={settings} user={user} />
        )}
      </>
    );
  }
);
