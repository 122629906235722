import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { filter } from 'lodash';
import moment from 'moment';

import { URLS } from '../../misc/consts';
import { DateSelectionProps, RootState } from '../../misc/types';
import { WellnessBlocksList } from '../balance/WellnessBlocksList';

import styles from '../../styles/wellness-blocks.module.scss';

interface ToutWellnessBlocksProps {
  dateSelection: DateSelectionProps;
  onViewWellnessBlock: (id: string) => void;
}

export const ToutWellnessBlocks: React.ComponentType<ToutWellnessBlocksProps> =
  React.memo(({ dateSelection, onViewWellnessBlock }) => {
    const { startDate, endDate } = dateSelection;

    const history = useHistory();
    const wellness_blocks = useSelector(
      (state: RootState) => state.wellness_blocks
    );

    // Filter to just the date range
    /// Hide if not recurring + ends_at is before startDate of this week
    const wellnessBlocks = useMemo(() => {
      return filter(wellness_blocks, function (item) {
        return (
          item.attributes.recurring ||
          moment(item.attributes.ends_at).isBetween(
            startDate,
            endDate,
            null,
            '[]' // Brackets mean "inclusive"
          )
        );
      });
    }, [endDate, startDate, wellness_blocks]);

    const _handleViewAllWellnessBlocksClick = useCallback(() => {
      history.push(URLS.Balance.WellnessBlocks);
    }, [history]);

    const _handleNewWellnessBlockClick = useCallback(() => {
      history.push(URLS.Plan.NewBlock);
    }, [history]);

    return (
      <div className={styles.wellnessBlocksTout}>
        <h5>
          <span>WELLNESS BLOCKS</span>
          <div
            className={styles.addWellnessBlock}
            onClick={_handleViewAllWellnessBlocksClick}>
            View all Wellness blocks
          </div>
        </h5>
        <div className={styles.card}>
          <WellnessBlocksList
            wellnessBlocks={wellnessBlocks}
            onNewWellnessBlock={_handleNewWellnessBlockClick}
            onViewWellnessBlock={onViewWellnessBlock}
          />
        </div>
      </div>
    );
  });
