import React, { useCallback } from 'react';
import Select from 'react-select';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

interface ValueProps {
  label: string;
  value: string;
}

export interface SelectInputProps {
  className?: string;
  isDisabled?: boolean;
  onChange: (selectedOption: ValueProps) => void;
  options: ValueProps[];
  value: ValueProps;
}

export const SelectInput: React.ComponentType<SelectInputProps> = React.memo(
  ({ className, isDisabled = false, options, onChange, value }) => {
    const onSelectChange = useCallback(
      value => {
        onChange(value);
      },
      [onChange]
    );

    return (
      <div
        className={classNames(styles.selectInput, className, {
          [styles.disabled]: isDisabled
        })}>
        <Select
          isDisabled={isDisabled}
          isSearchable={false}
          options={options}
          value={value}
          onChange={onSelectChange}
        />
      </div>
    );
  }
);
