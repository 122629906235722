import reduxWebsocket from '@giantmachines/redux-websocket';
import { routerMiddleware } from 'connected-react-router';
import * as History from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import createRootReducer from './modules';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

// Create the history instance
export const history = History.createBrowserHistory();

// Create the middleware instance
const reduxWebsocketMiddleware = reduxWebsocket({
  onOpen: ws => {
    ws.send(
      JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'NotificationChannel'
        })
      })
    );

    ws.send(
      JSON.stringify({
        command: 'subscribe',
        identifier: JSON.stringify({
          channel: 'MeetingRefreshChannel'
        })
      })
    );
  }
});

const initialState = {};
const enhancers = [];
const middleware = [routerMiddleware(history), thunk, reduxWebsocketMiddleware];

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

// Create the redux store
export default createStore(
  createRootReducer(history), // root reducer with router state
  initialState,
  compose(applyMiddleware(...middleware), ...enhancers)
);
