import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import Emoji from 'react-emoji-render';
import classNames from 'classnames';
import { isEqual, round } from 'lodash';

import { ReviewFilterInfo } from '../../containers/app/Review';
import { INTENT, REVIEW_VIEWS } from '../../misc/consts';
import { Reports, SelectedView, SettingsLabels, User } from '../../misc/types';
import {
  formatMoney,
  getViewData,
  getViewMetric,
  maybePluralize
} from '../../misc/utils';
import { Button, Dollar, Tag, Tooltip } from '../design-system';
import { ScoreItem } from '../misc/ScoreItem';

import { CompliantNonCompliantMeetings } from './CompliantNoncompliantMeetings';

import styles from '../../styles/review-report-card-stats.module.scss';

interface MeetingQualitySectionProps {
  filterInfo?: ReviewFilterInfo;
  isForMarketing: boolean;
  reports: Reports;
  selectedView: SelectedView;
  settingsLabels: SettingsLabels;
  user: User;
  which: string;
}

export const MeetingQualitySection: React.ComponentType<MeetingQualitySectionProps> =
  React.memo(
    ({
      filterInfo,
      which,
      reports,
      selectedView,
      settingsLabels,
      user,
      isForMarketing
    }) => {
      const [isViewingMeetings, setIsViewingMeetings] = useState(false);
      const intent = which === 'good' ? INTENT.Success : INTENT.Danger;

      const _toggleViewAllMeetings = useCallback(() => {
        setIsViewingMeetings(prevState => !prevState);
      }, []);

      // Calculate count of meetings
      const numOfTheseMtgs = getViewMetric(
        `duration_and_costs.attributes.rows.${which}.meetings`,
        reports,
        selectedView
      );

      // Calculate percent
      const totalMtgsCount = getViewMetric(
        `duration_and_costs.attributes.rows.total.meetings`,
        reports,
        selectedView
      );
      const percent = round((numOfTheseMtgs / totalMtgsCount) * 100, 1);

      // Get reasons why
      const reasonsWhy = getViewData(
        `averages.attributes.rows.counts.${which}`,
        reports,
        selectedView
      );

      // Calculate time spent
      const timeSpent = useMemo(() => {
        const duration = getViewMetric(
          `duration_and_costs.attributes.rows.${which}.meeting_duration`,
          reports,
          selectedView
        );

        // Null check
        if (Number.isNaN(duration)) return null;

        const format = (data: number, label: string) => {
          if (data > 1) label = `${label}s`;
          if (!label.includes('min')) label = `${label}, `;
          return `${data} ${label}`;
        };

        const hours = Math.floor(duration / 3600);
        const mins = Math.floor((duration % 3600) / 60);
        const finHrs = hours ? format(hours, 'hr') : '';
        const finMins = format(mins, 'min');

        return `${finHrs}${finMins}`;
      }, [reports, selectedView, which]);

      // Build cost tag
      let maybeCost: string | ReactNode = '';
      const data = getViewMetric(
        `duration_and_costs.attributes.rows[${which}]meeting_cost_total`,
        reports,
        selectedView
      );

      if (!Number.isNaN(data)) {
        maybeCost = (
          <Tooltip
            content={
              <>
                ${formatMoney(data, 0)} was spent on {which} meetings (based on
                a $
                {formatMoney(
                  getViewMetric(
                    'duration_and_costs.attributes.rows.annual_salary',
                    reports,
                    selectedView
                  ),
                  0
                )}{' '}
                salary).
              </>
            }>
            <Tag className={styles.dollarAmt} intent={intent}>
              <Dollar />
              {formatMoney(data, 0)}
            </Tag>
          </Tooltip>
        );
      }

      if (Number.isNaN(totalMtgsCount) || Number.isNaN(percent)) {
        return null;
      }

      const shouldShowViewAllButton =
        isEqual(selectedView, REVIEW_VIEWS.You) &&
        numOfTheseMtgs > 0 &&
        !isForMarketing;

      return (
        <div className={styles.qualitySection} data-intent={intent}>
          {shouldShowViewAllButton && (
            <CompliantNonCompliantMeetings
              filterInfo={filterInfo}
              isVisible={isViewingMeetings}
              reports={reports}
              selectedView={selectedView}
              user={user}
              which={which}
              onClose={_toggleViewAllMeetings}
            />
          )}
          <div
            className={classNames(styles.info, {
              [styles.good]: which === 'good',
              [styles.bad]: which === 'bad'
            })}>
            <div className={styles.label}>
              THE {which} ONES {maybeCost}
            </div>
            <div className={styles.value}>
              <span className={styles.num}>{percent}</span>
              <span className={styles.perc}>%</span>
              <span className={styles.descriptor}>of all meetings</span>
              {shouldShowViewAllButton && (
                <Button
                  className={styles.viewAll}
                  intent={INTENT.Primary}
                  minimal={true}
                  text="View all"
                  onClick={_toggleViewAllMeetings}
                />
              )}
            </div>
            <div className={styles.misc}>
              {numOfTheseMtgs}{' '}
              {maybePluralize(numOfTheseMtgs, 'meeting', 'meetings')}{' '}
              <span>|</span> {timeSpent}
            </div>
          </div>
          {numOfTheseMtgs > 0 ? (
            <div className={styles.items}>
              <div className={styles.header}>
                <h6>
                  What made these <span>{which}</span>?
                </h6>
                <h6># meetings</h6>
              </div>
              {Object.keys(reasonsWhy).map((item, key) => (
                <ScoreItem
                  key={key}
                  count={reasonsWhy[item]}
                  label={item}
                  onlyCount={true}
                  score={which === 'good' ? 100 : 0}
                  settingsLabels={settingsLabels}
                />
              ))}
            </div>
          ) : (
            <div className={styles.noReasons}>
              {which === 'good' ? (
                <Emoji text="🤕 Yikes! No good meetings to report." />
              ) : (
                <Emoji text="🎉 Woohoo no bad meetings!" />
              )}
            </div>
          )}
        </div>
      );
    }
  );
