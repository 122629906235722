import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { COLORS } from '../../misc/constants';

import { Icon, Tooltip } from '.';

import styles from '../../styles/design-system.module.scss';

export interface CheckboxProps {
  disabled?: boolean;
  disabledText?: string;
  onClick?: () => void;
  selected: boolean;
  text?: string | ReactNode;
}

export const Checkbox: React.ComponentType<CheckboxProps> = React.memo(
  ({ disabled = false, disabledText, selected, text, onClick }) => {
    const boxAndText = (
      <>
        <div
          className={classNames(styles.checkbox, {
            [styles.selected]: selected
          })}>
          <Icon color={COLORS.white} which="tick" />
        </div>
        {text && <div className={styles.text}>{text}</div>}
      </>
    );

    return (
      <div
        className={classNames(styles.checkboxWrapper, {
          [styles.disabled]: disabled
        })}
        onClick={onClick && !disabled ? onClick : undefined}>
        {disabled && disabledText ? (
          <Tooltip className={styles.disabledText} content={disabledText}>
            {boxAndText}
          </Tooltip>
        ) : (
          boxAndText
        )}
      </div>
    );
  }
);
