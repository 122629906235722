import React from 'react';
import { isBrowser } from 'react-device-detect';
import classNames from 'classnames';

import { useTeamSubscription } from '../../hooks/team-subscription';
import { PREFERENCE_ROWS } from '../../misc/constants';
import { SETTINGS_BUCKETS } from '../../misc/consts';
import { Settings, SettingsLabels, User } from '../../misc/types';

import { PreferenceRow } from './PreferenceRow';
import { ViewEvaluationsCard } from './ViewEvaluationsCard';

import styles from '../../styles/balance.module.scss';

interface BalanceViewEvaluationsProps {
  settings?: Settings;
  settings_labels: SettingsLabels;
  user: User;
}

export const BalanceViewEvaluations: React.ComponentType<BalanceViewEvaluationsProps> =
  React.memo(function BalanceViewEvaluations({
    user,
    settings,
    settings_labels
  }) {
    const [, isAdminOfTeam] = useTeamSubscription();

    if (!user || !settings) {
      return null;
    }

    const prefRowProps = {
      bucket: SETTINGS_BUCKETS.Evaluation as keyof typeof SETTINGS_BUCKETS,
      canEdit: isAdminOfTeam,
      user,
      settings,
      settings_labels
    };

    const renderHeader = (title: string) => {
      return (
        <div className={styles.header}>
          <h5>{title}</h5>
        </div>
      );
    };

    if (!settings) {
      return null;
    }

    return (
      <>
        <div className={classNames(styles.gradeToMeet, styles.column)}>
          <h3>Your Team evaluations</h3>
          <div className={styles.desc}>
            How MeetWell will evaluate your teams meetings.
          </div>
          <div className={styles.section}>
            <ViewEvaluationsCard user={user} />
          </div>
          {isBrowser && (
            <div className={styles.section}>
              <h5>Meetings to ignore</h5>
              <div className={styles.card}>
                <PreferenceRow
                  showExtras={false}
                  which="ignore_all_day_meetings"
                  {...prefRowProps}
                />
                <PreferenceRow
                  showExtras={false}
                  which="ignore_all_hands_meetings"
                  {...prefRowProps}
                />
              </div>
            </div>
          )}
        </div>
        <div
          className={classNames(
            styles.preferences,
            styles.column,
            styles.eval
          )}>
          <div className={styles.section}>
            {renderHeader('Time & Duration')}
            <div className={styles.inside}>
              {PREFERENCE_ROWS.TimeAndDuration.map((item, index) => (
                <PreferenceRow
                  key={index}
                  operator={item.operator}
                  statType={item.statType}
                  type={item.type}
                  which={item.which}
                  {...prefRowProps}
                />
              ))}
            </div>
          </div>
          <div className={styles.section}>
            {renderHeader('Content')}
            <div className={styles.inside}>
              {PREFERENCE_ROWS.Content.map((item, index) => (
                <PreferenceRow
                  key={index}
                  operator={item.operator}
                  statType={item.statType}
                  type={item.type}
                  which={item.which}
                  {...prefRowProps}
                />
              ))}
            </div>
          </div>
          <div className={styles.section}>
            {renderHeader('Attendees')}
            <div className={styles.inside}>
              {PREFERENCE_ROWS.Attendees.map((item, index) => (
                <PreferenceRow
                  key={index}
                  operator={item.operator}
                  statType={item.statType}
                  type={item.type}
                  which={item.which}
                  {...prefRowProps}
                />
              ))}
            </div>
          </div>
        </div>
      </>
    );
  });
