/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isEmpty } from 'lodash';
import { readEndpoint } from 'redux-json-api';

import { log } from '../../misc/utils';

export const getArticle = (tagList, order) => {
  log('getArticle()');
  if (isEmpty(order)) order = 'position';

  // Build the 'tagged_with' query parameter
  const taggedWith = tagList ? `&tagged_with=${tagList}` : '';

  return dispatch => {
    dispatch(readEndpoint(`articles/?order=${order}${taggedWith}`));
  };
};
