/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory, useParams } from 'react-router-dom';
import FrontMatter from 'front-matter';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { Icon } from '../../components/design-system';
import { SEO } from '../../components/misc/SEO';
import { COLORS } from '../../misc/constants';

import { BlogPostItem } from './blog-post-item';
import { SocialPost } from './SocialPost';

import styles from '../../styles/marketing/misc.module.scss';

export const BlogPost = ({ posts }) => {
  const { page } = useParams();
  const history = useHistory();

  const components = {
    code: ({ children }) => {
      return (
        <SocialPost
          content="inline"
          media="Twitter"
          mediaUrl={children[0]}
          slug="inline"
        />
      );
    }
  };

  const thisPostFM = FrontMatter(
    posts.filter(post => {
      const fm = FrontMatter(post);
      return page === fm.attributes.slug;
    })[0]
  );

  return (
    <>
      <SEO title={`Blog | ${thisPostFM.attributes.title}`} />
      <h5 className={styles.goBack} onClick={() => history.push('/blog')}>
        <Icon color={COLORS.primary} which="chevron-left" /> Read more posts
      </h5>
      {thisPostFM.attributes.type === 'meme' ? (
        <div className={styles.list}>
          <BlogPostItem fm={thisPostFM} index={1} isIndividualPost={true} />
        </div>
      ) : (
        <>
          <h1>{thisPostFM.attributes.title}</h1>
          <ReactMarkdown
            children={thisPostFM.body}
            components={components}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
          />
        </>
      )}
    </>
  );
};
