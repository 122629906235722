import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from '../../../styles/marketing/pull-quote.module.scss';

interface PullQuoteProps {
  author: string | ReactNode;
  noMargin?: boolean;
  text: string | ReactNode;
}

export const PullQuote: React.ComponentType<PullQuoteProps> = React.memo(
  ({ text, author, noMargin = false }) => {
    return (
      <section
        className={classNames(styles.pullQuote, {
          [styles.noMargin]: noMargin
        })}>
        <div className={styles.inner}>
          <div className={styles.mark}>“</div>
          <div className={styles.text}>{text}</div>
          <div className={styles.author}>{author}</div>
        </div>
      </section>
    );
  }
);
