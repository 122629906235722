/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { readEndpoint } from 'redux-json-api';

import { log } from '../../misc/utils';

export const getFutureActions = (data = {}, callback) => {
  const { start, end } = data;
  log('getFutureActions()');

  // Build the 'by_period' query parameter
  const byPeriod =
    start && end
      ? `?by_period[starts_at]=${start}&by_period[ends_at]=${end}`
      : '';

  return dispatch => {
    dispatch(readEndpoint(`future_actions${byPeriod}`))
      .then(callback)
      .catch(callback);
  };
};

export const getPastActions = (data = {}, callback) => {
  const { start, end } = data;
  log('getPastActions()');

  // Build the 'by_period' query parameter
  const byPeriod =
    start && end
      ? `?by_period[starts_at]=${start}&by_period[ends_at]=${end}`
      : '';

  return dispatch => {
    dispatch(readEndpoint(`past_actions${byPeriod}`))
      .then(callback)
      .catch(callback);
  };
};

export const acceptMeeting = meeting_id => {
  log('acceptMeeting()');

  return dispatch => {
    dispatch(readEndpoint(`users/meetings/accept/${meeting_id}`));
  };
};
