import React, { ReactNode } from 'react';
import { isBrowser } from 'react-device-detect';
import { find, forEach, isEmpty } from 'lodash';

import { Icon, Tag } from '../components/design-system';

import { CALENDAR_SOURCES, PLAN_TAGS } from './consts';
import { CalendarSource, User } from './types';
import { capitalizeFirstLetter } from './utils';

export function getCalendarSource(user?: User): CalendarSource {
  if (user) {
    const identity_provider = user.attributes.identity_providers[0];
    const source = find(CALENDAR_SOURCES, function (o) {
      return o.auth === identity_provider;
    });
    return source || CALENDAR_SOURCES[0];
  }
  return CALENDAR_SOURCES[0];
}

interface PlanTagProps {
  overrideValue?: string;
  size: string;
  value: boolean | string | number;
  which?: string;
}

export function renderPlanTag(props: PlanTagProps): ReactNode {
  const { value, size, which, overrideValue } = props;

  let thisValue = value.toString(); // Make sure it's always a string

  // If a which, maybe further parsing
  // Special case all dayers
  if (
    which === 'ignore_all_day_meetings' ||
    which === 'ignore_all_hands_meetings'
  ) {
    if (thisValue === 'true') {
      thisValue = 'ignored';
    } else {
      thisValue = 'not ignored';
    }
  }

  // Get intent
  let intent = '';
  forEach(PLAN_TAGS, function (options, key) {
    if (options.includes(thisValue)) {
      intent = key.toLowerCase();
    }
  });

  const sizeProps = {
    small: size === 'small',
    large: size === 'large'
  };

  // If "Required"
  if (intent === 'required') {
    return (
      <Tag
        icon={<Icon color="#1DB08C" which="tick" />}
        intent="success"
        {...sizeProps}>
        {!isEmpty(overrideValue) ? overrideValue : 'Required'}
      </Tag>
    );
  }

  // If "Not required"
  if (intent === 'notrequired') {
    return (
      <Tag {...sizeProps}>
        {!isEmpty(overrideValue) ? overrideValue : 'Not required'}
      </Tag>
    );
  }

  // Maybe override value?
  let valueToRender = capitalizeFirstLetter(thisValue);
  if (!isEmpty(overrideValue)) {
    valueToRender = overrideValue;
  }

  // Return a default one
  return (
    <Tag intent={!isEmpty(intent) ? intent : undefined} {...sizeProps}>
      {valueToRender}
    </Tag>
  );
}

export function getAnimationOffset(): string {
  if (isBrowser) {
    return '-250px';
  } else {
    return '-150px';
  }
}
