import React, { useMemo } from 'react';
import classNames from 'classnames';

import { COLORS } from '../../misc/constants';
import { INTENT_NUM, WELLNESS_BLOCKS } from '../../misc/consts';
import { Meeting } from '../../misc/types';
import { getIntentFromNum } from '../../misc/utils';
import { Icon, Tag } from '../design-system';

import styles from '../../styles/meeting-event.module.scss';

interface MeetingEventProps {
  event: Meeting;
  title: string;
}

export const MeetingEvent: React.ComponentType<MeetingEventProps> = React.memo(
  ({ event }) => {
    const isWellnessBlock = event.attributes.wellness_block_id !== null;
    const isMaybeWellnessBlock = event.attributes.maybe_wellness_block;
    const isDailyReport = event.attributes.event_report_card_id !== null;
    const blockCategory = useMemo(
      () => event.attributes.wellness_block_category,
      [event]
    );
    const maybeBlockCategory = useMemo(
      () => event.attributes.maybe_wellness_block_category,
      [event]
    );

    // Build title
    const title = useMemo(() => {
      if (isDailyReport) {
        return 'DAILY TOTAL';
      }
      return event.attributes.summary;
    }, [event, isDailyReport]);

    // Build score
    const score = useMemo(() => {
      if (isDailyReport) {
        const hasScoreInTitle = event.attributes.summary.includes('/100');
        return hasScoreInTitle
          ? parseInt(event.attributes.summary.split('/100')[0])
          : 0;
      }
      return event.attributes.score;
    }, [event, isDailyReport]);

    // Build event classes
    const eventClasses = useMemo(() => {
      const classes = [styles.eventWrapper];
      if (isWellnessBlock) {
        if (blockCategory === WELLNESS_BLOCKS.Life.value) {
          classes.push(styles.lifeBlock);
        } else {
          classes.push(styles.focusBlock);
        }
      } else if (score) {
        if (score >= INTENT_NUM.High) {
          classes.push(styles.highQuality);
        } else if (score >= INTENT_NUM.Medium) {
          classes.push(styles.mediumHighQuality);
        } else if (score >= INTENT_NUM.MediumLow) {
          classes.push(styles.mediumLowQuality);
        } else {
          classes.push(styles.lowQuality);
        }
        // Maybe WB styles
      } else if (isMaybeWellnessBlock === 'true') {
        if (maybeBlockCategory === WELLNESS_BLOCKS.Life.value) {
          classes.push(styles.lifeBlock);
          classes.push(styles.maybeLifeBlock);
        } else {
          classes.push(styles.focusBlock);
          classes.push(styles.maybeFocusBlock);
        }
      }

      if (isDailyReport) {
        classes.push(styles.dailyReport);
      }

      return classes;
    }, [
      blockCategory,
      isDailyReport,
      isMaybeWellnessBlock,
      isWellnessBlock,
      maybeBlockCategory,
      score
    ]);

    const iconColor = useMemo(() => {
      if (isWellnessBlock || isMaybeWellnessBlock === 'true') {
        if (
          blockCategory === WELLNESS_BLOCKS.Life.value ||
          maybeBlockCategory === WELLNESS_BLOCKS.Life.value
        ) {
          return COLORS.darkPurple;
        } else {
          return COLORS.primary;
        }
      }
    }, [
      blockCategory,
      isMaybeWellnessBlock,
      isWellnessBlock,
      maybeBlockCategory
    ]);

    return (
      <div className={classNames(eventClasses)} id={`meeting-${event.id}`}>
        <div className={styles.eventInfo}>
          <div className={styles.eventTitle}>
            {isWellnessBlock && <Icon color={iconColor} which="wellness" />}
            {!isWellnessBlock && !score && isMaybeWellnessBlock === 'true' && (
              <Icon color={iconColor} which="question" />
            )}
            {title}
          </div>
          {!isWellnessBlock && score && (
            <div className={styles.eventScoreWrapper}>
              <Tag
                className={styles.eventScore}
                intent={getIntentFromNum(score, 'text')}
                small>
                {Math.round(score)}
              </Tag>
            </div>
          )}
        </div>
        <div className={styles.eventBkg} />
      </div>
    );
  }
);
