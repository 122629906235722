/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useHistory, useLocation } from 'react-router-dom';
import FrontMatter from 'front-matter';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { Button } from '../../components/design-system';
import { SEO } from '../../components/misc/SEO';
import { INTENT } from '../../misc/consts';

import styles from '../../styles/faq.module.scss';

const importAll = r => r.keys().map(r);
const markdownFiles = importAll(
  require.context(`../../markdown/faq`, false, /\.md$/)
);

export const FAQView = () => {
  const location = useLocation();
  const history = useHistory();
  const [questions, setQuestions] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const posts = await Promise.all(
        markdownFiles.map(file => fetch(file.default).then(res => res.text()))
      ).catch(err => console.error(err));

      // Group by category
      let groupedQuestions = {};
      for (var i = 0; i < posts.length; i++) {
        const fm = FrontMatter(posts[i]);
        if (groupedQuestions[fm.attributes.category]) {
          groupedQuestions[fm.attributes.category].push(fm);
        } else {
          groupedQuestions[fm.attributes.category] = [fm];
        }
      }

      setQuestions(groupedQuestions);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (location.hash) {
      const name = location.hash.substring(1);
      const thisElem = document.getElementById(name);
      if (thisElem) {
        thisElem.scrollIntoView();
      }
    }
  });

  const _handleAnchorLinkClick = useCallback(
    slug => {
      history.push(`/faq#${slug}`);
    },
    [history]
  );

  return (
    <>
      <SEO title="FAQ" />
      <div className={styles.faqView}>
        <div className={styles.inner}>
          <h3>FAQ</h3>
          <h1>Have any questions?</h1>
          <div className={styles.divider} />
          <div className={styles.qAndA}>
            {questions &&
              Object.keys(questions).map(key => {
                return (
                  <div key={key} className={styles.section}>
                    <div className={styles.header}>{key}</div>
                    <div className={styles.items}>
                      {questions[key].map((item, index) => {
                        return (
                          <div key={index} className={styles.item}>
                            <div
                              className={styles.anchorLink}
                              id={item.attributes.slug}
                            />
                            <div className={styles.question}>
                              {item.attributes.question}
                            </div>
                            <div className={styles.answer}>
                              <ReactMarkdown
                                children={item.attributes.answer}
                                rehypePlugins={[rehypeRaw, rehypeSanitize]}
                              />{' '}
                              <Button
                                intent={INTENT.Primary}
                                minimal={true}
                                small={true}
                                text="Link"
                                onClick={() =>
                                  _handleAnchorLinkClick(item.attributes.slug)
                                }
                              />
                              {item.body && (
                                <ReactMarkdown
                                  children={item.body}
                                  rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
