/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Emoji from 'react-emoji-render';

import { Button } from '../../components/design-system';
import { SEO } from '../../components/misc/SEO';
import { URLS } from '../../misc/consts';

import styles from '../../styles/marketing/misc.module.scss';

class UnderMaintenanceView extends React.Component {
  render() {
    return (
      <>
        <SEO title="Under maintenance" />
        <div className={styles.miscPage}>
          <div className={styles.inner}>
            <div className={styles.emoji}>
              <Emoji text=":construction_sign:" />
            </div>
            <h1>
              Under maintenace! <span>Stay tuned.</span>
            </h1>
            <p>
              We're working hard to get things back up to speed. Please try
              again in a few minutes.
            </p>
            <Button text="Try again" onClick={this._onTryAgain} />
          </div>
        </div>
      </>
    );
  }

  _onTryAgain = () => {
    window.location = URLS.Review.Default;
  };
}

export default UnderMaintenanceView;
