import React from 'react';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

export interface LoadingProps {
  className?: string;
  inline?: boolean;
  small?: boolean;
}

export const Loading: React.ComponentType<LoadingProps> = React.memo(
  function Loading({ small, inline, className }) {
    return (
      <div
        className={classNames(styles.loading, className, {
          [styles.small]: small,
          [styles.inline]: inline
        })}>
        <svg viewBox="0 0 50 50" x="0px" y="0px">
          <path d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
            <animateTransform
              attributeName="transform"
              attributeType="xml"
              dur="0.6s"
              from="0 25 25"
              repeatCount="indefinite"
              to="360 25 25"
              type="rotate"
            />
          </path>
        </svg>
      </div>
    );
  }
);
