import React, { useCallback, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { Meeting, Settings, User } from '../../misc/types';

import { SectionActionsDefaultView } from './SectionActionsDefaultView';
import { SectionActionsExistingActionView } from './SectionActionsExistingActionView';
import { SectionActionsMaybeWBView } from './SectionActionsMaybeWBView';

interface SectionActionsProps {
  event: Meeting;
  settings: Settings;
  user: User;
}

export const SectionActions: React.ComponentType<SectionActionsProps> =
  React.memo(({ user, event, settings }) => {
    const [actionBeingMade, setActionBeingMade] = useState('');
    const existingActionStatus = event.attributes.non_compliance_action;
    const isMaybeWellnessBlock =
      event.attributes.maybe_wellness_block === 'true';

    const updateActionBeingMade = useCallback(
      action => setActionBeingMade(action),
      []
    );

    ////// Global null checks
    if (!user || !event) return null;

    // If this is maybe a Wellness block?
    if (isMaybeWellnessBlock) {
      return (
        <SectionActionsMaybeWBView
          actionBeingMade={actionBeingMade}
          event={event}
          onUpdateAction={updateActionBeingMade}
        />
      );
    }

    // Don't show if in the past
    const meetingStartsAt = moment.utc(event.attributes.starts_at);
    if (!moment(meetingStartsAt).isSameOrAfter(moment())) {
      return null;
    }

    // Don't show if no overall score
    if (event.attributes.score === null) {
      return null;
    }

    // Don't show if everything is positive
    if (isEmpty(event.attributes.details_negative)) {
      return null;
    }

    // Check if user is organizer
    const userEmail = user.attributes.email;
    const organizerEmail = event.attributes.organizer_email.email;
    if (userEmail === organizerEmail) {
      return null;
    }

    // Domain not enforceable
    const domainsToRemind = `${user.attributes.domains_allowlist},${user.attributes.company.domains_allowlist}`;
    const domainsList = domainsToRemind ? domainsToRemind.split(',') : [];
    if (
      domainsList.length > 0 &&
      !domainsList.includes(event.attributes.organizer_email.domain)
    ) {
      return null;
    }

    // If user has already taken action
    if (existingActionStatus) {
      return (
        <SectionActionsExistingActionView
          existingActionStatus={existingActionStatus}
        />
      );
    }

    // Render the default actions
    return (
      <SectionActionsDefaultView
        actionBeingMade={actionBeingMade}
        event={event}
        settings={settings}
        onUpdateAction={updateActionBeingMade}
      />
    );
  });
