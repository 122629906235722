import React from 'react';
import classNames from 'classnames';

import styles from '../../../styles/marketing/fake-browser.module.scss';

interface FakeBrowserProps {
  className?: string;
  elevation?: 'one' | 'two';
  title: string;
}

export const FakeBrowser: React.ComponentType<FakeBrowserProps> = React.memo(
  ({ className, elevation = 'two', children, title }) => {
    return (
      <div
        className={classNames(styles.fakeBrowser, className, {
          [styles.elevationOne]: elevation === 'one'
        })}>
        <div className={styles.browserHeader}>
          <div className={styles.actions}>
            <span className={styles.close} />
            <span className={styles.min} />
            <span className={styles.big} />
          </div>
          <div className={styles.text}>{title}</div>
        </div>
        <div className={styles.browserInner}>{children}</div>
      </div>
    );
  }
);
