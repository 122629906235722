import React from 'react';
import { useSelector } from 'react-redux';

import { AccountViewGeneral } from '../../components/account/AccountViewGeneral';
import { SEO } from '../../components/misc/SEO';
import { RootState } from '../../misc/types';

import styles from '../../styles/account.module.scss';

export const AccountView: React.ComponentType = React.memo(() => {
  const user = useSelector((state: RootState) => state.user.general);
  const subscription = useSelector(
    (state: RootState) => state.subscriptions.subscription
  );
  return (
    <>
      <SEO title="Account" />
      <div className={styles.accountView}>
        <div className={styles.inner}>
          <h1>
            <span>Account</span>
          </h1>
          <hr />
          <div className={styles.mainContent}>
            <AccountViewGeneral subscription={subscription} user={user} />
          </div>
        </div>
      </div>
    </>
  );
});
