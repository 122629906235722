import React, { useCallback, useState } from 'react';
import Moment from 'react-moment';

import { DATE_FORMAT } from '../../misc/consts';
import { Invite } from '../../misc/types';
import { Button } from '../design-system';

import styles from '../../styles/team-mgmt.module.scss';

interface PendingInviteRowProps {
  invite: Invite;
  onCancelInvite: (id: Invite['id'], callback: () => void) => void;
}

export const PendingInviteRow: React.ComponentType<PendingInviteRowProps> = React.memo(
  function PendingInviteRow({ invite, onCancelInvite }) {
    const [isCanceling, setIsCanceling] = useState(false);

    const _handleCancelInviteClick = useCallback(() => {
      onCancelInvite(invite.id, () => {
        setIsCanceling(true);
      });
    }, [invite, onCancelInvite]);

    return (
      <div className={styles.row}>
        <span>{invite.attributes.email}</span>
        <span>
          <Moment format={DATE_FORMAT.Long}>
            {invite.attributes.created_at}
          </Moment>
        </span>
        <span>
          <Button
            intent="danger"
            isLoading={isCanceling}
            minimal
            text="Cancel"
            onClick={_handleCancelInviteClick}
          />
        </span>
      </div>
    );
  }
);
