import React, { useCallback } from 'react';
import { filter, get, isEmpty } from 'lodash';

import { INVITE_STATUS_OPTIONS } from '../../misc/constants';
import { Button } from '../design-system';

import styles from '../../styles/review.module.scss';

interface StatusFilterProps {
  onChangeThePage: (data: Record<string, unknown>) => void;
  statusFilterData: StatusFilterData[];
}

export interface StatusFilterData {
  label: string;
  url: string;
  value: string;
}

export const StatusFilter: React.ComponentType<StatusFilterProps> = React.memo(
  ({ statusFilterData, onChangeThePage }) => {
    const _handleStatusFilterSelect = useCallback(
      chosenFilter => {
        let newFilterData = [...statusFilterData];

        // If already selected, deselect
        if (newFilterData.includes(chosenFilter)) {
          newFilterData = filter(newFilterData, function (o) {
            return o !== chosenFilter;
          });

          // Now if empty, select "All"
          if (isEmpty(newFilterData)) {
            newFilterData = [INVITE_STATUS_OPTIONS.All];
          }

          // If "All", then reset
        } else if (chosenFilter === INVITE_STATUS_OPTIONS.All) {
          newFilterData = [INVITE_STATUS_OPTIONS.All];

          // If anything else, then build an array
        } else {
          // "All" is in there, remove
          if (newFilterData.includes(INVITE_STATUS_OPTIONS.All)) {
            newFilterData = filter(newFilterData, function (o) {
              return o !== INVITE_STATUS_OPTIONS.All;
            });
          }
          // Concat chosen filter
          newFilterData = newFilterData.concat([chosenFilter]);
        }

        // Loop through and flatten to just value
        const finalFilterData = [];
        for (let i = 0; i < newFilterData.length; i++) {
          finalFilterData[i] = newFilterData[i].url;
        }

        onChangeThePage({ filter: finalFilterData });
      },
      [onChangeThePage, statusFilterData]
    );

    return (
      <div className={styles.statusFilter}>
        <div className={styles.label}>Invite status:</div>
        <div className={styles.options}>
          {Object.keys(INVITE_STATUS_OPTIONS).map(key => {
            const thisOption = get(INVITE_STATUS_OPTIONS, key);
            return (
              <React.Fragment key={key}>
                <Button
                  active={statusFilterData.includes(thisOption)}
                  minimal
                  text={thisOption.label}
                  onClick={() => _handleStatusFilterSelect(thisOption)}
                />
                {thisOption === INVITE_STATUS_OPTIONS.All && (
                  <div className={styles.divider} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }
);
