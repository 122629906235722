import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';

import { PREFERENCE_ROWS, SETTINGS_BUCKETS } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { RootState, Settings } from '../../misc/types';
import { ShareTextModal } from '../misc/ShareTextModal';

interface ShareMeetingCultureProps {
  settings?: Settings;
}

export const ShareMeetingCulture: React.ComponentType<ShareMeetingCultureProps> =
  React.memo(function ShareMeetingCulture({ settings }) {
    const settingsLabels = useSelector(
      (state: RootState) => state.settings_labels
    );

    const evalsToIgnore = useMemo(() => ['focus_block_conflict'], []);

    const filteredPref = useCallback(
      item => {
        const data = settingsLabels[item.which];
        if (
          isEmpty(data) ||
          data.coming_soon ||
          evalsToIgnore.includes(item.which)
        ) {
          return false;
        }
        return true;
      },
      [evalsToIgnore, settingsLabels]
    );

    const renderRow = useCallback(
      item => {
        const data = settingsLabels[item.which];
        let value = get(
          settings,
          `attributes.${SETTINGS_BUCKETS.Evaluation}.${item.which}`
        );
        if (value) {
          value = value.toString();
        }

        if (value === 'true') value = 'Required';
        if (value === 'false') value = 'Not required';
        if (value === 'block') value = 'Prevent';
        if (value === 'allow') value = 'Allow';

        const valueToRender = `${
          item.operator ? `${item.operator.symbol} ` : ''
        }${value}${item.statType ? ` ${item.statType}` : ''}`;

        return `**${data.label}:** ${valueToRender}  \n${data.description}`;
      },
      [settings, settingsLabels]
    );

    const textToShare = useMemo(() => {
      const timeAndDurationPreferences = PREFERENCE_ROWS.TimeAndDuration.filter(
        filteredPref
      ).map(item => renderRow(item));
      const contentPreferences = PREFERENCE_ROWS.Content.filter(
        filteredPref
      ).map(item => renderRow(item));
      const attendeesPreferences = PREFERENCE_ROWS.Attendees.filter(
        filteredPref
      ).map(item => renderRow(item));

      return [
        'Team,',
        `We've all experienced "good meetings" and certainly have had many "bad meetings". Moving forward, we're committing to a higher bar for our meeting culture and have established standards to inspire us.`,
        `In addition to these standards, we will use MeetWell (meetwell.app) to track our meetings and hold us accountable.`,
        `Here is what we believe make an A+ meeting:`,
        '---------',
        ...timeAndDurationPreferences,
        ...contentPreferences,
        ...attendeesPreferences,
        '---------',
        'Connect your calendar at https://meetwell.app and join me in building a healthier meeting culture.',
        'Best,',
        '[YOUR NAME]'
      ].join('\n\n');
    }, [filteredPref, renderRow]);

    const _trackGoal = useCallback(() => {
      Fathom.trackGoal(GOALS.Balance.OpenedShareMeetingCulture);
    }, []);

    if (!settings) {
      return null;
    }

    ///////////
    // Render
    ///////////

    return (
      <div style={{ position: 'absolute', right: '15px' }}>
        <ShareTextModal
          buttonText="Share meeting culture"
          descriptor="We've prepped something for you to share with your team. Copy and
          paste or open it in an email and edit to your liking."
          subjectLine="Our meeting culture"
          textToShare={textToShare}
          onOpen={_trackGoal}
        />
      </div>
    );
  });
