import React from 'react';
import { DefaultToast, ToastProps } from 'react-toast-notifications';

// import classNames from 'classnames';
// import { Icon } from '../design-system';
import styles from '../../styles/notification-toast.module.scss';

interface NotificationToastProps extends ToastProps {
  className?: string;
}

export const NotificationToast: React.ComponentType<NotificationToastProps> = React.memo(
  ({ appearance, children, ...other }) => {
    return (
      <DefaultToast
        appearance={appearance}
        {...other}
        // className={classNames(styles.notificationToast, {
        //   [styles.error]: appearance === 'error'
        // })}
      >
        {/* {appearance !== 'error' && (
          <div className={styles.icon}>
            <Icon which="alert" />
          </div>
        )} */}
        <div className={styles.text}>{children}</div>
      </DefaultToast>
    );
  }
);
