import React from 'react';

import { OrganizerStats } from '../../../components/review/OrganizerStats';
import { REVIEW_VIEWS } from '../../../misc/constants';
import {
  EXAMPLE_DATE_SELECTION,
  EXAMPLE_REPORTS
} from '../../../misc/test-data';

import styles from '../../../styles/marketing/organizer-stats-preview.module.scss';

export const OrganizerStatsPreview: React.ComponentType = React.memo(() => {
  return (
    <div className={styles.organizerStatsPreview}>
      <OrganizerStats
        dateSelection={EXAMPLE_DATE_SELECTION}
        isForMarketing={true}
        reports={EXAMPLE_REPORTS}
        selectedView={REVIEW_VIEWS.You}
      />
    </div>
  );
});
