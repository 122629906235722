/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  cloneDeep,
  filter,
  findIndex,
  forEach,
  isArray,
  isEmpty,
  isNull,
  set,
  unionBy
} from 'lodash';
import moment from 'moment';
import queryString from 'query-string';

import { isValidAPICall } from '../misc/utils';

import {
  connectCalendar,
  createAgendaItem,
  createObjectiveItem,
  createPreWorkItem,
  deleteAgendaItem,
  deleteObjectiveItem,
  deletePreWorkItem,
  disconnectCalendar,
  enforceOneMeeting,
  generallyUpdateMeeting,
  getMeetingEvaluation,
  getMeetingEvaluationByToken,
  getMeetingEvaluationProgress,
  getMeetings,
  getOneMeeting,
  getUserSpecificMeetingInfo,
  makeWellnessBlock,
  organizerPreventDecline,
  resyncMeetings,
  updateAgendaItem,
  updateMeetingMetadata,
  updateObjectiveItem,
  updatePreWorkItem
} from './utils/meetings-utils';

export default (state = [], action) => {
  if (isValidAPICall(action, 'meetings')) {
    const thisData = action.payload.data;

    ///
    /// Single meeting addition
    ///
    const splitEndpoint = action.payload.finalEndpoint
      ? action.payload.finalEndpoint.split('/')
      : [];
    if (splitEndpoint.length === 2) {
      return state.concat([thisData]);
    }

    ///
    /// Evaluate a meeting call
    ///
    else if (
      !isEmpty(action.payload.finalEndpoint) &&
      action.payload.finalEndpoint.includes('evaluation')
    ) {
      const attrsThatCanBeEmpty = ['metadata_importable', 'score'];

      // Loop through and find the null/empty values... pull in from this call
      return [
        ...state.map(item => {
          if (item.id === thisData.id) {
            // Only apply values that are valid
            let dataToAdd = {};
            forEach(thisData.attributes, function (value, key) {
              if (!isNull(value) || attrsThatCanBeEmpty.includes(key)) {
                if (isArray(value)) {
                  if (value.length > 0) {
                    dataToAdd[key] = value;
                  }
                } else {
                  dataToAdd[key] = value;
                }
              }
            });
            return {
              ...item,
              attributes: {
                ...item.attributes,
                ...dataToAdd
              }
            };
          } else {
            return item;
          }
        })
      ];
    }

    ///
    /// Meeting updates
    ///
    else if (action.type === 'API_UPDATED') {
      return [
        ...state.map(item => {
          if (item.id === thisData.id) {
            // Only apply values that are valid
            let validData = {};
            forEach(thisData.attributes, function (value, key) {
              if (!(isEmpty(value) || !value)) validData[key] = value;
            });
            const thisItem = {
              ...item,
              attributes: {
                ...item.attributes,
                ...validData
              }
            };
            return thisItem;
          }
          return item;
        })
      ];
    }

    ///
    /// List meetings call
    ///
    else if (action.payload.finalEndpoint.split('/')[0].includes('meetings')) {
      ////////
      ////////
      // First section here updates incoming data with existing info found in 'evaluate' call
      ////////
      ////////

      //// Add agenda items
      // First check where in existing state agenda_items exist, filter those out
      const existingAgendaItems = filter(state, function (item) {
        return !isEmpty(item.attributes.agenda_items.data);
      });

      // Loop through that filtered array and match id to new meeting ids
      for (var i = 0; i < existingAgendaItems.length; i++) {
        //eslint-disable-next-line
        const thisIndex = findIndex(thisData, function (mtg) {
          return mtg.id === existingAgendaItems[i].id;
        });

        // If there's a match, then add agenda_items to the new data and return
        if (thisIndex > -1) {
          thisData[thisIndex].attributes.agenda_items.data =
            existingAgendaItems[i].attributes.agenda_items.data;
        }
      }

      //// Add objectives
      // First check where in existing state objectives exist, filter those out
      const existingObjectives = filter(state, function (item) {
        return !isEmpty(item.attributes.objectives.data);
      });

      // Loop through that filtered array and match id to new meeting ids
      for (var o = 0; o < existingObjectives.length; o++) {
        //eslint-disable-next-line
        const thisIndex = findIndex(thisData, function (mtg) {
          return mtg.id === existingObjectives[o].id;
        });

        // If there's a match, then add objectives to the new data and return
        if (thisIndex > -1) {
          thisData[thisIndex].attributes.objectives.data =
            existingObjectives[o].attributes.objectives.data;
        }
      }

      //// Add prework
      // First check where in existing state prework exist, filter those out
      const existingPreWork = filter(state, function (item) {
        return !isEmpty(item.attributes.prework_items.data);
      });

      // Loop through that filtered array and match id to new meeting ids
      for (var w = 0; w < existingPreWork.length; w++) {
        //eslint-disable-next-line
        const thisIndex = findIndex(thisData, function (mtg) {
          return mtg.id === existingPreWork[w].id;
        });

        // If there's a match, then add prework to the new data and return
        if (thisIndex > -1) {
          thisData[thisIndex].attributes.prework_items.data =
            existingPreWork[w].attributes.prework_items.data;
        }
      }

      //// Add results
      // First check where in existing state results exist, filter those out
      const existingResults = filter(state, function (item) {
        return !isEmpty(item.attributes.results);
      });

      // Loop through that filtered array and match id to new meeting ids
      for (var s = 0; s < existingResults.length; s++) {
        //eslint-disable-next-line
        const thisIndex = findIndex(thisData, function (mtg) {
          return mtg.id === existingResults[s].id;
        });

        // If there's a match, then add objectives to the new data and return
        if (thisIndex > -1) {
          thisData[thisIndex].attributes.results =
            existingResults[s].attributes.results;
        }
      }

      // If there's already some meetings...
      if (!isEmpty(state)) {
        // Update incoming data
        const clonedState = cloneDeep(state);
        const updatedIncoming = thisData.map(incomingItem => {
          const thisIndex = findIndex(clonedState, function (existingItem) {
            return incomingItem.id === existingItem.id;
          });

          // If this meeting is already in thisData, then apply updates
          if (thisIndex > -1) {
            const newItem = {
              ...incomingItem,
              attributes: {
                ...clonedState[thisIndex].attributes,
                ...incomingItem.attributes
              }
            };
            return newItem;
          } else {
            return incomingItem;
          }
        });

        // Filter out meetings in range
        const queryParams = queryString.parse(
          action.payload.finalEndpoint.split('?')[1]
        );
        const existingOutOfRange = filter(state, item => {
          return !moment(item.attributes.ends_at).isBetween(
            moment(queryParams['by_period[starts_at]']),
            moment(queryParams['by_period[ends_at]']),
            null,
            '[]' // Brackets mean "inclusive"
          );
        });

        // Merge the rest, favoring 'existingOutOfRange'
        return unionBy(existingOutOfRange, updatedIncoming, 'id');
      } else {
        return thisData || [];
      }
    }

    ///
    /// Agenda items
    ///
  } else if (isValidAPICall(action, 'agenda_items')) {
    return state.map(meeting => {
      ///
      // Creating one or many
      if (action.type === 'API_CREATED') {
        const createOne = function (thisData) {
          return [...meeting.attributes.agenda_items.data, { ...thisData }];
        };

        return parseItems('agenda_items.data', action, meeting, createOne);
      }

      ///
      // Updating one or many
      if (action.type === 'API_UPDATED') {
        const updateOne = function (thisData) {
          return meeting.attributes.agenda_items.data.map(item => {
            if (item.id.toString() === thisData.id.toString()) {
              return thisData;
            } else {
              return item;
            }
          });
        };

        return parseItems('agenda_items.data', action, meeting, updateOne);
      }

      ///
      // Deleting one or many
      if (action.type === 'API_WAS_DELETED') {
        const deleteOne = function (thisData) {
          return meeting.attributes.agenda_items.data.filter(function (item) {
            return item.id !== thisData.id;
          });
        };

        return parseItems('agenda_items.data', action, meeting, deleteOne);
      }

      return meeting;
    });

    ///
    /// Objectives
    ///
  } else if (isValidAPICall(action, 'objectives')) {
    return state.map(meeting => {
      ///
      // Creating one or many
      if (action.type === 'API_CREATED') {
        const createOne = function (thisData) {
          return [...meeting.attributes.objectives.data, { ...thisData }];
        };

        return parseItems('objectives.data', action, meeting, createOne);
      }

      ///
      // Updating one or many
      if (action.type === 'API_UPDATED') {
        const updateOne = function (thisData) {
          return meeting.attributes.objectives.data.map(item => {
            if (item.id.toString() === thisData.id.toString()) {
              return thisData;
            } else {
              return item;
            }
          });
        };

        return parseItems('objectives.data', action, meeting, updateOne);
      }

      ///
      // Deleting one or many
      if (action.type === 'API_WAS_DELETED') {
        const deleteOne = function (thisData) {
          return meeting.attributes.objectives.data.filter(function (item) {
            return item.id !== thisData.id;
          });
        };

        return parseItems('objectives.data', action, meeting, deleteOne);
      }

      return meeting;
    });

    ///
    /// User Meetings (user-specific info about the meeting)
    ///
  } else if (isValidAPICall(action, 'user_meetings')) {
    const thisData = action.payload.data;

    // Don't user specific meeting info unless meetings exist in state
    if (isEmpty(state)) {
      return state;
    }

    return state.map(meeting => {
      if (meeting.id.toString() === thisData.attributes.meeting_id.toString()) {
        return {
          ...meeting,
          attributes: {
            ...meeting.attributes,
            non_compliance_action: thisData.attributes.non_compliance_action
          }
        };
      }
      return meeting;
    });
  }

  return state;
};

function parseItems(which, action, meeting, actionOneCallback) {
  const thisData = action.payload.data;
  let finalItems = actionOneCallback(thisData);

  return {
    ...meeting,
    ...set(meeting, `attributes.${which}`, finalItems)
  };
}

export {
  getMeetings,
  getOneMeeting,
  getMeetingEvaluation,
  getMeetingEvaluationProgress,
  resyncMeetings,
  connectCalendar,
  disconnectCalendar,
  createAgendaItem,
  updateAgendaItem,
  deleteAgendaItem,
  createObjectiveItem,
  updateObjectiveItem,
  deleteObjectiveItem,
  createPreWorkItem,
  updatePreWorkItem,
  deletePreWorkItem,
  getUserSpecificMeetingInfo,
  enforceOneMeeting,
  organizerPreventDecline,
  getMeetingEvaluationByToken,
  makeWellnessBlock,
  generallyUpdateMeeting,
  updateMeetingMetadata
};
