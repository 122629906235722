import React, { useMemo } from 'react';
import { get, isEmpty } from 'lodash';

import { MeetingInfo, Reports, SelectedView, User } from '../../misc/types';
import { getViewData } from '../../misc/utils';

import {
  HandleSortType,
  RowData,
  SortDataType,
  SortingData
} from './DetailedStats';
import { DetailRow } from './DetailRow';
import { TableHeaderColumn } from './TableHeaderColumn';

import styles from '../../styles/review-detailed-stats.module.scss';

interface SectionProps {
  meetingInfo: MeetingInfo;
  onHandleSort: HandleSortType;
  onSortData: SortDataType;
  reports: Reports;
  selectedView: SelectedView;
  sorting: SortingData;
  user?: User;
}

export const IntentionSection: React.ComponentType<SectionProps> = React.memo(
  props => {
    return (
      <div className={styles.section}>
        <h5>
          <span>INTENTIONS</span>
        </h5>
        <div className={styles.description}>
          The wellness of your meetings based on their purpose/goals.
        </div>
        <CategoryIntentionCard which="intentions" {...props} />
      </div>
    );
  }
);

export const CategorySection: React.ComponentType<SectionProps> = React.memo(
  props => {
    return (
      <div className={styles.section}>
        <h5>
          <span>RECURRING &amp; ALL-DAY</span>
        </h5>
        <div className={styles.description}>
          Meetings that are either recurring or all-day.
        </div>
        <CategoryIntentionCard which="categories" {...props} />
      </div>
    );
  }
);

interface CategoryIntentionCardProps {
  meetingInfo: MeetingInfo;
  onHandleSort: HandleSortType;
  onSortData: SortDataType;
  reports: Reports;
  selectedView: SelectedView;
  sorting: SortingData;
  which: string;
}

const CategoryIntentionCard: React.ComponentType<CategoryIntentionCardProps> = React.memo(
  ({
    which,
    reports,
    selectedView,
    meetingInfo,
    sorting,
    onSortData,
    onHandleSort
  }) => {
    const data = useMemo(() => {
      if (which === 'categories') {
        return getViewData(
          'duration_and_cost_details.attributes.rows.categories',
          reports,
          selectedView
        );
      } else {
        return getViewData(
          'duration_and_cost_details.attributes.rows.intentions',
          reports,
          selectedView
        );
      }
    }, [reports, selectedView, which]);

    const columns = useMemo(() => {
      return [
        {
          className: styles.type,
          slug: 'name',
          text: which === 'categories' ? 'WHICH' : 'INTENTION'
        },
        {
          className: styles.count,
          slug: 'count',
          text: '#'
        },
        {
          className: styles.goodBad,
          slug: 'goodBad',
          text: 'GOOD/BAD'
        },
        {
          className: styles.cost,
          slug: 'cost',
          text: 'COST'
        },
        {
          className: styles.hours,
          slug: 'hours',
          text: 'HOURS'
        }
      ];
    }, [which]);

    // Turn into array + sort the data
    const sortedData: RowData[] = [];
    for (const obj in data) {
      sortedData.push({
        slug: obj,
        data: data[obj]
      });
    }
    const { where: sortBy, isDesc } = get(sorting, which);
    sortedData.sort((a: RowData, b: RowData) => {
      return onSortData(sortBy, a, b, isDesc);
    });

    return (
      <div className={styles.card}>
        <div className={styles.header}>
          {columns.map((item, index) => (
            <TableHeaderColumn
              key={index}
              className={item.className}
              isDesc={isDesc}
              showSort={sortBy === item.slug}
              text={item.text}
              onClick={() => onHandleSort(which, item.slug)}
            />
          ))}
        </div>
        <div className={styles.rows}>
          {!isEmpty(sortedData) &&
            sortedData.map((item, key) => {
              const section = get(meetingInfo, which);
              if (!section) {
                return null;
              }
              const label = get(section, item.slug).label;
              return (
                <DetailRow
                  key={key}
                  hoursCount={item.data.total.hours}
                  label={label}
                  numOfBadMtgs={item.data.bad.count}
                  numOfGoodMtgs={item.data.good.count}
                  totalCost={item.data.total.cost}
                  totalMtgsCount={item.data.total.count}
                />
              );
            })}
        </div>
        <div className={styles.empty}>
          Your meeting {which} will appear here.
        </div>
      </div>
    );
  }
);
