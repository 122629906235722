import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { PLAN_TOOLTIPS, QUERY_PARAMS } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { ActionSettings, Settings, User } from '../../misc/types';
import { updateUserSettings } from '../../modules/user';
import { EditingPopover, Icon, TextLink, Tooltip } from '../design-system';
import { SegmentedControlData } from '../design-system/SegmentedControl';
import { PreviewReminderParams } from '../misc/PreviewReminderModal';

import styles from '../../styles/action-info-item.module.scss';
import popoverStyles from '../../styles/popover.module.scss';

const PICKER_VALUES: SegmentedControlData[] = [
  { id: '', label: '6', value: 6, isSelected: false },
  { id: '', label: '12', value: 12, isSelected: false },
  { id: '', label: '24', value: 24, isSelected: false },
  { id: '', label: '36', value: 36, isSelected: false },
  { id: '', label: '48', value: 48, isSelected: false },
  { id: '', label: '72', value: 72, isSelected: false }
];

interface InfoDeadlineProps {
  settings: Settings;
  user: User;
}

export const InfoDeadline: React.ComponentType<InfoDeadlineProps> = React.memo(
  ({ settings }) => {
    const dispatch = useDispatch();
    const [existingValue, setExistingValue] =
      useState<ActionSettings['compliance_deadline']>(0);
    const [stagedValue, setStagedValue] =
      useState<ActionSettings['compliance_deadline']>(0);
    const [segData, setSegData] = useState<SegmentedControlData[]>([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);

    useEffect(() => {
      // Reset isVars
      setIsEditing(false);
      setIsWaitingOnAPI(false);

      // Build vals
      const existingValue =
        settings.attributes.action_settings['compliance_deadline'];
      setExistingValue(existingValue);
      setStagedValue(existingValue);

      // Build Seg Data
      const segData = PICKER_VALUES;
      if (!isEmpty(segData)) {
        for (let i = 0; i < segData.length; i++) {
          if (segData[i].value.toString() === existingValue.toString()) {
            segData[i].isSelected = true;
          } else {
            segData[i].isSelected = false;
          }
        }
      }
      setSegData(segData);
    }, [existingValue, settings]);

    const _toggleEditMode = useCallback(() => {
      setIsEditing(prevState => !prevState);
    }, []);

    const _selectValue = useCallback(
      which => {
        const tempData = [...segData];
        for (let i = 0; i < tempData.length; i++) {
          if (i === which) {
            tempData[i].isSelected = true;
          } else {
            tempData[i].isSelected = false;
          }
        }

        setSegData(tempData);
        setStagedValue(tempData[which].value as number);
      },
      [segData]
    );

    const _save = useCallback(async () => {
      // Show spinner
      setIsWaitingOnAPI(true);

      // Post to API
      dispatch(
        updateUserSettings({
          which: 'action_settings',
          value: {
            compliance_deadline: stagedValue
          }
        })
      );

      // Track goal
      Fathom.trackGoal(GOALS.Balance.ModifiedDeadline);
    }, [dispatch, stagedValue]);

    // Disabled button?
    const isSaveButtonDisabled = useCallback(() => {
      return isEqual(existingValue.toString(), stagedValue.toString());
    }, [existingValue, stagedValue]);

    const [, setIsPreviewReminderOpen] = useQueryParam(
      QUERY_PARAMS.PreviewReminder,
      StringParam
    );

    const _showPreviewReminderModal = useCallback(
      param => {
        setIsPreviewReminderOpen(param);
      },
      [setIsPreviewReminderOpen]
    );

    const actionsToBeTaken = useMemo(() => {
      const chosenComplianceAction =
        settings.attributes.action_settings.non_compliance_action;

      // If is Auto-decline
      if (chosenComplianceAction.includes('decline')) {
        return (
          <>
            <div className={styles.action}>
              <Icon which="arrow-right-small" />
              <span>Meeting is declined</span>
            </div>
            <div className={styles.action}>
              <Icon which="add-small" />
              <span>
                <span
                  className={styles.link}
                  onClick={() =>
                    _showPreviewReminderModal(PreviewReminderParams.Warning)
                  }>
                  Warning
                </span>{' '}
                sent to organizer when first invited
              </span>
            </div>
          </>
        );
      }

      // If just Auto-remind
      return (
        <div className={styles.action}>
          <Icon which="arrow-right-small" />
          <span>
            <span
              className={styles.link}
              onClick={() =>
                _showPreviewReminderModal(PreviewReminderParams.Reminder)
              }>
              Reminder
            </span>{' '}
            sent to organizer
          </span>
        </div>
      );
    }, [_showPreviewReminderModal, settings]);

    return (
      <div className={styles.item}>
        <h6>When to take action?</h6>
        <div className={classNames(styles.section, styles.deadlineEnforcement)}>
          <div className={styles.label}>
            <Tooltip content={PLAN_TOOLTIPS.Deadline}>Deadline</Tooltip>
          </div>
          <div className={styles.content}>
            <div className={styles.text}>
              <div className={styles.when}>
                {existingValue} hrs{' '}
                <span className={styles.muted}>before a meeting</span>
              </div>
              {actionsToBeTaken}
            </div>
            <EditingPopover
              className={styles.editWrapper}
              description={PLAN_TOOLTIPS.Deadline}
              innerClassName={classNames(
                popoverStyles.editingPlan,
                popoverStyles.editDeadline
              )}
              isLoading={isWaitingOnAPI}
              isSaveButtonDisabled={isSaveButtonDisabled()}
              isVisible={isEditing}
              segData={segData}
              segProps={{
                rightLabel: 'hours'
              }}
              onCancel={_toggleEditMode}
              onSave={_save}
              onSelectValue={_selectValue}
              onToggle={_toggleEditMode}>
              <TextLink
                className={styles.edit}
                isDisabled={isEditing}
                text="Edit"
                onClick={_toggleEditMode}
              />
            </EditingPopover>
          </div>
        </div>
      </div>
    );
  }
);
