import React from 'react';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { StringParam, useQueryParam } from 'use-query-params';

import { QUERY_PARAMS, REFERRAL_COOKIE_NAME } from '../../../misc/consts';

export const ReferralTracking: React.ComponentType = React.memo(() => {
  const [referralToken] = useQueryParam(QUERY_PARAMS.Referral, StringParam);

  // Set referral token as cookie
  if (referralToken) {
    const cookies = new Cookies();
    cookies.set(REFERRAL_COOKIE_NAME, referralToken, {
      path: '/',
      expires: moment().add(25, 'days').toDate(),
      sameSite: 'lax',
      ...(process.env.REACT_APP_ENVIRONMENT === 'production' && {
        secure: true
      })
    });
  }

  return null;
});
