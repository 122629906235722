/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { find, isArray, isEmpty, isPlainObject, isString } from 'lodash';

const initialState = {
  user: 0,
  meetings: 0,
  wellness_blocks: 0,
  reports: 0,
  calendars: 0,
  subscriptions: 0,
  tips: 0,
  teams: 0
};

const aliases = [
  { reducer: 'user', options: ['users', 'user'] },
  { reducer: 'meetings', options: ['meetings'] },
  { reducer: 'wellness_blocks', options: ['wellness_blocks', 'focus_blocks'] },
  { reducer: 'reports', options: ['reporting', 'reports'] },
  { reducer: 'calendars', options: ['calendars'] },
  { reducer: 'subscriptions', options: ['subscriptions'] },
  { reducer: 'tips', options: ['tips'] },
  { reducer: 'teams', options: ['teams'] }
];

/*
 ***** ONLY MARKING "WILL_READ" AND "READ" ACTIONS
 */

export default (state = initialState, action) => {
  if (action.type === 'API_WILL_READ') {
    const reducer = getReducer(action);
    if (!isEmpty(reducer)) {
      return {
        ...state,
        [reducer]: state[reducer] + 1
      };
    }
    return state;
  }

  if (action.type === 'API_READ') {
    const reducer = getReducer(action);
    if (!isEmpty(reducer)) {
      return {
        ...state,
        [reducer]: state[reducer] - 1
      };
    }
    return state;
  }

  return state;
};

function getReducer(action) {
  // If there is data
  if (!isEmpty(action.payload.data)) {
    if (isPlainObject(action.payload.data)) {
      const possibleAlias = action.payload.data.type;
      const match = find(aliases, function (item) {
        return item.options.includes(possibleAlias);
      });
      if (!isEmpty(match)) return match.reducer;
      return possibleAlias;
    } else if (isArray(action.payload.data)) {
      const possibleAlias = action.payload.data[0].type;
      const match = find(aliases, function (item) {
        return item.options.includes(possibleAlias);
      });
      if (!isEmpty(match)) return match.reducer;
      return possibleAlias;
    }

    return null;

    // If no data, then check finalEndpoint
  } else if (isEmpty(action.payload.data) && isArray(action.payload.data)) {
    let possibleAlias = action.payload.finalEndpoint;
    if (possibleAlias.includes('?')) {
      //eslint-disable-next-line
      possibleAlias = possibleAlias.split(/[/\//?]+/)[0];
    }
    const match = find(aliases, function (item) {
      return item.options.includes(possibleAlias);
    });
    if (!isEmpty(match)) return match.reducer;
    return possibleAlias;

    // Check aliases and split of ? or /
  } else if (!isEmpty(action.payload) && isString(action.payload)) {
    // Match against alias
    //eslint-disable-next-line
    const possibleAlias = action.payload.split(/[/\//?]+/)[0];
    const match = find(aliases, function (item) {
      return item.options.includes(possibleAlias);
    });
    if (!isEmpty(match)) return match.reducer;

    return null;
  }
  return null;
}
