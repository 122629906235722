/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { Dispatch } from 'redux';
import { readEndpoint, updateResource } from 'redux-json-api';

import { TipHighlight } from '../../misc/types';
import { log } from '../../misc/utils';

export const getTips = (callback?: () => void) => (
  dispatch: Dispatch
): void => {
  log('getTip()');

  dispatch(readEndpoint('tips')).then(callback).catch(callback);
};

export const markTipAsSeen = (tip: TipHighlight, callback?: () => void) => (
  dispatch: Dispatch
): void => {
  log('markTipAsSeen()');

  const entity = {
    type: `tips/${tip.id}#update`
  };

  dispatch(updateResource(entity)).then(callback).catch(callback);
};

export const resetTips = (callback?: () => void) => (): void => {
  log('resetTips()');

  axios.delete(`tips/all`).then(async () => {
    if (callback) callback();
  });
};
