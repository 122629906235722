import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { isBrowser } from 'react-device-detect';
import { Portal } from 'react-portal';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { find, isEmpty } from 'lodash';

import { useWindowResize } from '../../hooks/window-resize';
import { INTENT } from '../../misc/consts';
import { RootState, TipHighlight } from '../../misc/types';
import { getTips, markTipAsSeen } from '../../modules/tips';
import { Button, Icon } from '../design-system';

import styles from '../../styles/tip.module.scss';
import fadeTransition from '../../styles/transitions/modal-fade-in.module.scss';

const SPACING = 30;

export interface OnboardingTipStep {
  URL: string;
  label?: string;
  onClick?: () => void;
}

interface TipProps {
  childId: string;
  children: ReactNode;
  className?: string;
  content?: ReactNode;
  criteria: boolean;
  headerText?: string;
  isForView?: boolean;
  isLarge?: boolean;
  nextStep?: OnboardingTipStep;
  position?: string;
  steps?: string[];
  tag: string;
}

interface HighlightRect {
  height: number;
  left: number;
  top: number;
  width: number;
}

export const Tip: React.ComponentType<TipProps> = React.memo(function Tip({
  criteria,
  childId,
  children,
  className,
  tag,
  isForView = false,
  isLarge = false,
  nextStep,
  content,
  headerText,
  steps
}) {
  const history = useHistory();
  const windowDimensions = useWindowResize();
  const apiUpdates = useSelector((state: RootState) => state.api);

  // State
  const [isAvailable, setIsAvailable] = useState(false);
  const [thisElem, setThisElem] = useState<HTMLDivElement | undefined>(
    undefined
  );
  const [highlightRect, setHighlightRect] = useState<HighlightRect>({
    width: 0,
    height: 0,
    left: 0,
    top: 0
  });

  // Dispatch and selectors
  const dispatch = useDispatch();
  const tips = useSelector((state: RootState) => state.tips);

  const thisTip = useMemo(() => {
    return find(tips, function (o) {
      return o.attributes.tag === tag;
    }) as TipHighlight;
  }, [tag, tips]);

  // Any updates
  useEffect(() => {
    if (thisElem) {
      const { width, height, x, y } = thisElem.getBoundingClientRect();
      setHighlightRect({ width, height, left: x, top: y });
    }
  }, [thisElem, windowDimensions, apiUpdates]);

  // Set thisElem
  useEffect(() => {
    setThisElem(document.getElementById(childId) as HTMLDivElement);
  }, [childId]);

  // Load
  useEffect(() => {
    if (
      thisTip &&
      thisElem &&
      !isEmpty(tips) &&
      isEmpty(thisTip.attributes.seen_at)
    ) {
      setIsAvailable(criteria && isBrowser);

      setTimeout(() => {
        const { width, height, x, y } = thisElem.getBoundingClientRect();
        setHighlightRect({ width, height, left: x, top: y });
      }, 1000);
    }
  }, [childId, criteria, highlightRect, isAvailable, thisElem, thisTip, tips]);

  // Actions
  const _handleCloseTip = useCallback(() => {
    setIsAvailable(false);

    // Make API Call
    dispatch(
      markTipAsSeen(thisTip, () => {
        dispatch(getTips());
      })
    );
  }, [dispatch, thisTip]);

  const renderTipInner = () => {
    if (!thisTip) return null;

    // Will add to right
    let left = highlightRect.left + highlightRect.width + SPACING;
    const top = highlightRect.top - SPACING;

    // If too far to right, move to left
    const bodyRect = document.body.getBoundingClientRect();
    if (left + 300 > bodyRect.width) {
      // 300 is the max width of the tip
      left = highlightRect.left - 300 - SPACING;
    }

    return (
      <div
        className={classNames(styles.inner, {
          [styles.forView]: isForView,
          [styles.isLarge]: isLarge
        })}
        style={{ left, top }}>
        <div className={styles.header}>
          <h6 className={styles.label}>
            {headerText ? headerText : `Did you know?`}
          </h6>
          {steps && (
            <div className={styles.steps}>
              Step {steps[0]} of {steps[1]}
            </div>
          )}
        </div>
        <div className={styles.title}>{thisTip.attributes.title}</div>
        <div className={styles.description}>
          {!isEmpty(content) ? content : thisTip.attributes.description}
        </div>
        {nextStep ? (
          <Button
            centered={true}
            fill={true}
            iconRight={<Icon color="#1C2C66" which="arrow-right" />}
            intent={INTENT.Primary}
            text={nextStep.label}
            onClick={() => {
              history.push(nextStep.URL); // Navigate
              nextStep.onClick && nextStep.onClick(); // Trigger optional onClick
              _handleCloseTip(); // Close tip
            }}
          />
        ) : (
          <Button centered fill text="Close" onClick={_handleCloseTip} />
        )}
      </div>
    );
  };

  const renderTip = (
    <CSSTransition
      appear
      classNames={fadeTransition}
      in={isAvailable}
      timeout={200}
      unmountOnExit>
      <div className={classNames(styles.tipView, className)}>
        {renderTipInner()}
        <div className={styles.bkg} onClick={_handleCloseTip} />
      </div>
    </CSSTransition>
  );

  const renderHighlightBox = () => {
    if (!isAvailable) return null;

    return (
      <CSSTransition
        appear
        classNames={fadeTransition}
        in={isAvailable}
        timeout={200}
        unmountOnExit>
        <div
          className={styles.tipHighlight}
          style={{
            width: highlightRect.width + SPACING,
            height: highlightRect.height + SPACING,
            left: highlightRect.left - SPACING / 2,
            top: highlightRect.top - SPACING / 2
          }}
        />
      </CSSTransition>
    );
  };

  return (
    <>
      {isAvailable && (
        <Portal>
          {renderTip}
          {renderHighlightBox()}
        </Portal>
      )}
      <>{children && children}</>
    </>
  );
});
