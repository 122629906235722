import React, { useRef } from 'react';
import Emoji from 'react-emoji-render';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment';

import { useOnScreen } from '../../../hooks/on-screen';
import {
  COMPANY_DOMAIN,
  EXAMPLE_SETTINGS_LABELS,
  EXAMPLE_USER
} from '../../../misc/test-data';
import { getAnimationOffset } from '../../../misc/utilities';

import { MEETING_DATA } from './HowExampleMeeting';

import styles from '../../../styles/marketing/how-example-email-organizer.module.scss';

export const HowExampleEmailOrganizer: React.ComponentType = React.memo(() => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen<HTMLDivElement>(ref, getAnimationOffset());

  const timeRightNow = moment().format('h:mm A');

  const nextMonday = moment()
    .startOf('isoWeek')
    .add(1, 'week')
    .format('dddd, MMMM Do');

  const thisMeetingTime = moment(MEETING_DATA.Bad.attributes.starts_at).format(
    'h:mm a'
  );

  const EMAIL_BODY = `
  Hey there!

  You’ve invited ${EXAMPLE_USER.attributes.first_name} to attend **${MEETING_DATA.Bad.attributes.summary}** on ${nextMonday} @ ${thisMeetingTime}.

  Based on ${EXAMPLE_USER.attributes.first_name}’s meeting preferences, this meeting earned a score of **${MEETING_DATA.Bad.attributes.score}/100**.

  #### Improve your score by addressing the items below:
  `;

  return (
    <div
      ref={ref}
      className={classNames(styles.emailOrganizer, {
        [styles.isVisible]: isVisible
      })}>
      <div className={styles.phone}>
        <div className={styles.screen}>
          <div className={styles.header}>
            <div className={styles.logo} />
            <div className={styles.contact}>
              <div className={styles.name}>MeetWell</div>
              <div className={styles.to}>To: mike@{COMPANY_DOMAIN}</div>
            </div>
            <div className={styles.time}>{timeRightNow}</div>
          </div>
          <div className={styles.email}>
            <div className={styles.scroll}>
              <div className={styles.title}>
                {EXAMPLE_USER.attributes.first_name} needs your help with
                <br />"{MEETING_DATA.Bad.attributes.summary}"
              </div>
              <div className={styles.body}>
                <ReactMarkdown children={EMAIL_BODY} />
                {Object.keys(MEETING_DATA.Bad.attributes.details_negative).map(
                  key => {
                    const name = get(EXAMPLE_SETTINGS_LABELS, key).label;
                    const reason = get(
                      MEETING_DATA.Bad.attributes.details_negative,
                      key
                    );
                    return (
                      <div key={key} className={styles.negativeDetail}>
                        <Emoji text="💔" />
                        <div className={styles.text}>
                          <span className={styles.name}>{name}.</span>{' '}
                          <span className={styles.reason}>{reason}</span>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
