import React from 'react';

import { INVITE_STATUS_OPTIONS } from '../../misc/constants';
import { InviteStatus } from '../../misc/types';
import { Tag } from '../design-system';

import styles from '../../styles/review-summary-stats.module.scss';

interface FilterTextProps {
  statusFilterData?: InviteStatus[];
}

export const FilterText: React.ComponentType<FilterTextProps> = React.memo(
  ({ statusFilterData }) => {
    if (!statusFilterData) {
      return null;
    }

    // If is "All", return nothing
    if (
      statusFilterData.length === 1 &&
      statusFilterData[0] === INVITE_STATUS_OPTIONS.All
    ) {
      return null;
    }

    const textToTag = (option: InviteStatus) => (
      <Tag className={styles.inviteStatusTag} intent={option.intent}>
        {option.label}
      </Tag>
    );

    // If just one selected
    if (statusFilterData.length === 1) {
      return textToTag(statusFilterData[0]);
    }

    // If all are selected, revert to all
    if (
      statusFilterData.length ===
      Object.keys(INVITE_STATUS_OPTIONS).length - 1
    ) {
      return null;
    }

    // If multiple selected
    const textToReturn = [];
    for (let i = 0; i < statusFilterData.length; i++) {
      // If first one, then no comma
      if (i === 0) {
        textToReturn.push(textToTag(statusFilterData[i]));
        // If last one, then add "and"
      } else if (i === statusFilterData.length - 1) {
        if (statusFilterData.length > 2) {
          textToReturn.push(<>, and {textToTag(statusFilterData[i])}</>);
        } else {
          textToReturn.push(<> and {textToTag(statusFilterData[i])}</>);
        }
        // Else just always add a comma
      } else {
        textToReturn.push(<>, {textToTag(statusFilterData[i])}</>);
      }
    }

    return (
      <>
        {textToReturn.map((item, index) => (
          <React.Fragment key={index}>{item}</React.Fragment>
        ))}
      </>
    );
  }
);
