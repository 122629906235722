import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { cloneDeep, isEmpty, sortBy } from 'lodash';

import { DELETE_STATUS, INTENT } from '../../misc/consts';
import { AgendaItem, SectionViewProps } from '../../misc/types';
import { Button, Tag, Tooltip } from '../design-system';

import styles from '../../styles/meeting-modal.module.scss';

export const SectionAgendaView: React.ComponentType<SectionViewProps> =
  React.memo(({ event, className, settings }) => {
    const [agendaItems, setAgendaItems] = useState<AgendaItem[]>([]);

    const SectionHeader = () => {
      const { required_agenda } = settings.attributes.evaluation_settings;

      return (
        <div className={styles.header}>
          <h6>Agenda</h6>
          {required_agenda && (
            <Tag
              intent={agendaItems.length === 0 ? INTENT.Danger : INTENT.Success}
              small={true}>
              Required
            </Tag>
          )}
          <div className={styles.how}>
            <Tooltip content="Learn how to format your meeting description to correctly parse your agenda.">
              <Button
                intent="primary"
                minimal={true}
                small={true}
                text="How?"
                onClick={() => window.open('/faq#plan-add-agenda', '_blank')}
              />
            </Tooltip>
          </div>
        </div>
      );
    };

    const ListAgendaItems = () => {
      // Return empty state if none
      if (agendaItems.length === 0) {
        return <div className={styles.emptyText}>No agenda items.</div>;
      }

      // Make sure they're sorted by position
      const sortedAgendaItems = sortBy(cloneDeep(agendaItems), [
        function (o) {
          return o.attributes.position;
        }
      ]);

      // Render one item
      const renderItem = (item: AgendaItem, key: number) => {
        if (item.status === DELETE_STATUS) return null;
        return (
          <div key={key} className={styles.agendaItem}>
            <div className={styles.description}>
              <div className={styles.num}>{key + 1})</div>
              <div className={styles.text}>{item.attributes.description}</div>
            </div>
            {item.attributes.duration_in_mins && (
              <div className={styles.duration}>
                {item.attributes.duration_in_mins} min
              </div>
            )}
          </div>
        );
      };

      return (
        <div className={styles.agendaItems}>
          {sortedAgendaItems.map((item, key) => renderItem(item, key))}
        </div>
      );
    };

    // Initialize
    useEffect(() => {
      // Set objective items
      const { agenda_items } = event.attributes;
      const hasAgendaItems =
        !isEmpty(agenda_items.data) && agenda_items.data.length > 0;
      const itemsToShow = hasAgendaItems ? agenda_items.data : [];
      setAgendaItems(itemsToShow);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    return (
      <div className={classNames(styles.section, className)}>
        <SectionHeader />
        <ListAgendaItems />
      </div>
    );
  });
