import React, { useCallback, useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import { QUERY_PARAMS } from '../../misc/consts';
import { Tooltip } from '../design-system';

import { ModalView } from './ModalView';

import styles from '../../styles/preview-reminder-modal.module.scss';

export const PreviewReminderParams = {
  Reminder: 'reminder',
  Warning: 'warning',
  None: undefined
};

const PreviewImages = {
  Reminder: '/images/screenshots/organizer_remind.png',
  Warning: '/images/screenshots/organizer_warning.png'
};

export const PreviewReminderModal: React.ComponentType = React.memo(() => {
  const [whichPreviewReminder, setPreviewReminder] = useQueryParam(
    QUERY_PARAMS.PreviewReminder,
    StringParam
  );

  const _togglePreviewReminderModal = useCallback(
    param => {
      setPreviewReminder(param);
    },
    [setPreviewReminder]
  );

  const isVisible = useMemo(() => {
    return (
      whichPreviewReminder === PreviewReminderParams.Reminder ||
      whichPreviewReminder === PreviewReminderParams.Warning
    );
  }, [whichPreviewReminder]);

  const imgURL = useMemo(() => {
    if (whichPreviewReminder === PreviewReminderParams.Reminder) {
      return PreviewImages.Reminder;
    } else if (whichPreviewReminder === PreviewReminderParams.Warning) {
      return PreviewImages.Warning;
    }
  }, [whichPreviewReminder]);

  return (
    <ModalView
      className={styles.previewReminderOuterModal}
      innerClassName={styles.previewReminderModal}
      isDismissible={true}
      isVisible={isVisible}
      position="float"
      onClose={() => _togglePreviewReminderModal(PreviewReminderParams.None)}>
      <div className={styles.header}>
        <h1>
          Example <span>{whichPreviewReminder} preview</span>
        </h1>
        <div className={styles.desc}>
          Here's a preview of the {whichPreviewReminder} email that MeetWell
          will send to organizers.
        </div>
      </div>
      <div className={styles.email}>
        <Tooltip content="This is an example email with placeholder values.">
          <img
            alt={whichPreviewReminder || ''}
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
            src={imgURL}
          />
        </Tooltip>
      </div>
    </ModalView>
  );
});
