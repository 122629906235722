/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import {
  getGoals,
  saveGoals,
  saveGoalsAndToggleOnboarding
} from './utils/goals-utils';

export default (state = [], action) => {
  if (isValidAPICall(action, 'goals')) {
    if (action.type === 'API_READ') {
      return action.payload.data;
    }
  }
  return state;
};

export { getGoals, saveGoals, saveGoalsAndToggleOnboarding };
