import React, { ReactNode } from 'react';
import { Radio, RadioGroup } from 'react-radio-group';

import styles from '../../styles/design-system.module.scss';

export interface RadioGroupFormProps {
  desc: string | ReactNode;
  miscContent?: ReactNode;
  onChange?: (preference: string) => void;
  options?: RadioOptionsProps[];
  selectedValue?: string;
  title: string;
}

interface RadioOptionsProps {
  label: string | ReactNode;
  value: string;
}

export const RadioGroupForm: React.ComponentType<RadioGroupFormProps> = React.memo(
  function RadioGroupForm({
    desc,
    miscContent,
    onChange,
    options,
    selectedValue,
    title
  }) {
    return (
      <div className={styles.radioGroup}>
        <div className={styles.title}>{title}</div>
        <div className={styles.desc}>{desc}</div>
        {options && (
          <RadioGroup
            className={styles.radio}
            selectedValue={selectedValue}
            onChange={onChange}>
            {options.map((item, index) => (
              <label key={index}>
                <Radio value={item.value} />
                <span>{item.label}</span>
              </label>
            ))}
          </RadioGroup>
        )}
        {miscContent && miscContent}
      </div>
    );
  }
);
