import React, { ReactNode, useState } from 'react';
import Emoji from 'react-emoji-render';
import classNames from 'classnames';

import { COST_OF_MW, DIV_IDS } from '../../misc/constants';
import { formatMoney, maybePluralize } from '../../misc/utils';
import { Dollar, Input } from '../design-system';

import styles from '../../styles/roi-calculator.module.scss';

interface ROICalculatorProps {
  description?: string | ReactNode;
  isInApp?: boolean;
  title?: string;
}

export const ROICalculator: React.ComponentType<ROICalculatorProps> =
  React.memo(({ title, description, isInApp = false }) => {
    const [numTeammates, setNumTeammates] = useState<number>(6);
    const [avgSalary, setAvgSalary] = useState<number>(80000);
    const [hrsPerWeek, setHrsPerWeek] = useState<number>(15);

    const costPerEmployeePerHour = avgSalary / 52 / 40; // Salary / Weeks in a year / hours in a week
    const totalCostPerHour = costPerEmployeePerHour * numTeammates;
    const costPerWeekInMtgs = totalCostPerHour * hrsPerWeek;
    const yearlyCostOfMW = numTeammates * COST_OF_MW * 12;
    const payForItselfHour = Math.ceil(yearlyCostOfMW / totalCostPerHour);

    return (
      <div
        className={classNames(styles.roiCalculator, {
          [styles.isInApp]: isInApp
        })}>
        <div className={styles.anchorOffset} id={DIV_IDS.TeamROI} />
        <div className={styles.inner}>
          <div className={styles.top}>
            <h2 className={styles.title}>
              <span>
                {title ? (
                  title
                ) : (
                  <>
                    <Emoji text=":money_bag:" /> Instant ROI for your team
                  </>
                )}
              </span>
            </h2>
            <p>
              {description ? (
                description
              ) : (
                <>
                  When your team spends its valuable time in wasteful meetings,
                  they’re unable to actually get any work done for the company.
                  See the true cost of your team’s busy schedule below.
                </>
              )}
            </p>
            <div className={styles.divider} />
          </div>
          <div className={styles.inputs}>
            <div className={classNames(styles.input, styles.num)}>
              <Input
                placeholder="#"
                type="number"
                value={numTeammates}
                onChange={event => {
                  const target = event.target as HTMLInputElement;
                  setNumTeammates(parseInt(target.value));
                }}
              />
              <div className={styles.title}>Number of teammates</div>
            </div>
            <div className={styles.input}>
              <Input
                placeholder="75,000"
                value={formatMoney(avgSalary, 0)}
                onChange={event => {
                  const target = event.target as HTMLInputElement;
                  const newValue = parseInt(target.value.replace(/,/g, '')); // Remove commas
                  setAvgSalary(newValue);
                }}
              />
              <div className={styles.title}>Avg. annual salary</div>
            </div>
            <div className={classNames(styles.input, styles.num)}>
              <Input
                placeholder="#"
                type="number"
                value={hrsPerWeek}
                onChange={event => {
                  const target = event.target as HTMLInputElement;
                  setHrsPerWeek(parseInt(target.value));
                }}
              />
              <div className={styles.title}>Avg. hrs/week in meetings</div>
            </div>
          </div>
          <div className={styles.results}>
            <p>
              Your team costs{' '}
              <label>
                <Dollar />
                {formatMoney(totalCostPerHour, 0)}/hour
              </label>{' '}
              and spends{' '}
              <label>
                <Dollar />
                {formatMoney(costPerWeekInMtgs, 0)}
                /week
              </label>{' '}
              in meetings.
            </p>
            <p>
              <label>A year of MeetWell</label> ({numTeammates} teammates x{' '}
              <Dollar />
              {COST_OF_MW} x 12 mo = <Dollar />
              {formatMoney(yearlyCostOfMW, 0)}) pays for itself in{' '}
              <label>
                less than {payForItselfHour}{' '}
                {maybePluralize(payForItselfHour, 'hour', 'hours')} saved
              </label>{' '}
              over the entire year.
            </p>
          </div>
        </div>
      </div>
    );
  });
