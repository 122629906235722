import React, { useCallback, useEffect, useState } from 'react';
import Emoji from 'react-emoji-render';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie';
import { StringParam, useQueryParam } from 'use-query-params';

import { Button, Input, InputGroup } from '../../components/design-system';
import { COOKIE_NAME, URLS } from '../../misc/consts';
import { isEmailValid } from '../../misc/utils';
import { setEmailSubscriptionStatus } from '../../modules/user';

import styles from '../../styles/marketing/misc.module.scss';

export const UnsubscribeView: React.ComponentType = React.memo(() => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [email, setEmail] = useState('');

  const [emailParam] = useQueryParam('email', StringParam);

  // Parse query string
  useEffect(() => {
    setEmail(emailParam || '');
  }, [emailParam]);

  const _redirectToAccount = useCallback(() => {
    window.location.href = URLS.Account.Default;
  }, []);

  const _setStatus = useCallback(() => {
    let toastMessage = "Hmm. That email doesn't look right"; // Default to an error

    if (isEmailValid(email)) {
      // Set state and unsubscribe
      dispatch(setEmailSubscriptionStatus(email, 'out'));

      // Build toast message
      toastMessage =
        "Hope we didn't bug you. You won't be getting emails from us anymore.";
    }

    addToast(toastMessage, {
      appearance: 'info',
      autoDismiss: true
    });
  }, [addToast, dispatch, email]);

  const _onKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        _setStatus();
      }
    },
    [_setStatus]
  );

  // If token is in cookies
  const cookies = new Cookies();
  const token = cookies.get(COOKIE_NAME);

  if (!isEmpty(token)) {
    setTimeout(() => {
      _redirectToAccount();
    }, 1200);

    return (
      <div className={styles.miscPage}>
        <div className={styles.inner}>
          <div className={styles.emoji}>
            <Emoji text=":mailbox:" />
          </div>
          <h1>Redirecting you to account settings...</h1>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.miscPage}>
      <div className={styles.inner}>
        <div className={styles.emoji}>
          <Emoji text=":mailbox:" />
        </div>
        <h1>
          Unsubscribe from <span>email notifications</span>
        </h1>
        <p>
          MeetWell sends meeting participants reminders about meetings that
          don't comply with users' preferences.
        </p>
        <p>
          If these emails are disruptive to you, just fill out this form and
          we'll stop emailing you.
        </p>
        <br />
        <InputGroup>
          <Input
            placeholder="Email address"
            type="text"
            value={email}
            onChange={event => {
              const target = event.target as HTMLInputElement;
              setEmail(target.value);
            }}
            onKeyDown={_onKeyDown}
          />
          <Button text="Unsubscribe" onClick={_setStatus} />
        </InputGroup>
      </div>
    </div>
  );
});
