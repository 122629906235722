import React from 'react';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { PLAN_HELPER_TIPS } from '../../misc/consts';
import { Icon, Tooltip } from '../design-system';

import styles from '../../styles/help-tooltip.module.scss';

interface HelpTooltipProps {
  className?: string;
  setting: keyof typeof PLAN_HELPER_TIPS;
}

export const HelpTooltip: React.ComponentType<HelpTooltipProps> = React.memo(
  function HelpTooltip({ setting, className }) {
    const thisSetting = PLAN_HELPER_TIPS[setting];
    if (isEmpty(thisSetting)) return null;

    return (
      <div className={classNames(styles.help, className)}>
        <Tooltip content={thisSetting}>
          <Icon which="help" />
        </Tooltip>
      </div>
    );
  }
);
