import React, { ReactNode } from 'react';
import Emoji from 'react-emoji-render';
import classNames from 'classnames';

import { GetStartedButton } from './GetStartedButton';

import styles from '../../../styles/marketing/hero-area.module.scss';

export interface HeroText {
  ctaHeader: ReactNode;
  desc: ReactNode;
  title: ReactNode;
}

interface HeroAreaProps {
  heroText: HeroText;
}

export const HeroArea: React.ComponentType<HeroAreaProps> = React.memo(
  ({ heroText }) => {
    return (
      <section className={styles.heroArea}>
        <h1 data-header="one">
          <label>{heroText.title}</label>
        </h1>
        <div className={styles.desc}>{heroText.desc}</div>
        <GetStartedButton heroText={heroText} />
        <div className={styles.supercharge}>
          <Emoji text=":high_voltage:" />
          <label>Supercharging your calendar</label>
          <Emoji text=":high_voltage:" />
        </div>
        <div className={styles.worksWith}>
          <div className={classNames(styles.option, styles.google)}>
            <div className={styles.logo} />
            <div className={styles.text}>Google</div>
          </div>
          <span>+</span>
          <div className={classNames(styles.option, styles.microsoft)}>
            <div className={styles.logo} />
            <div className={styles.text}>Microsoft</div>
          </div>
        </div>
      </section>
    );
  }
);
