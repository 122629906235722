import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

export interface QuoteProps {
  className?: string;
  small?: boolean;
  sub?: ReactNode;
  text: string;
}

export const Quote: React.ComponentType<QuoteProps> = React.memo(
  ({ text, sub, small = false, className }) => {
    return (
      <div
        className={classNames(styles.quote, className, {
          [styles.small]: small
        })}>
        <div className={styles.inner}>
          <div className={styles.mark}>“</div>
          <div className={styles.text}>{text}</div>
        </div>
        {sub && <div className={styles.sub}>{sub}</div>}
      </div>
    );
  }
);
