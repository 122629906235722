import React, { useMemo } from 'react';
import { Chart, curveStepAfter } from 'react-charts';
import Moment from 'react-moment';
import classNames from 'classnames';

import { DATE_FORMAT, INTENT } from '../../misc/consts';
import { ProgressData } from '../../misc/types';
import { getIntentFromNum } from '../../misc/utils';

import styles from '../../styles/spark-line.module.scss';

interface SectionChartProps {
  overallScore: number;
  progress: ProgressData[];
}

interface DatumProps {
  originalDatum: {
    date: string;
  };
  secondary: string;
}

export const SectionChart: React.ComponentType<SectionChartProps> = React.memo(
  ({ progress, overallScore }) => {
    const chartData = useMemo(
      () => [
        {
          label: 'Meeting evaluations',
          data: progress
        }
      ],
      [progress]
    );

    const intent = getIntentFromNum(overallScore, 'text');

    const chartAxes = useMemo(
      () => [
        {
          primary: true,
          type: 'linear',
          position: 'bottom',
          showGrid: false,
          show: false
        },
        { type: 'linear', position: 'left', showGrid: false, show: false }
      ],
      []
    );

    const series = useMemo(
      () => ({
        showPoints: true,
        curve: curveStepAfter
      }),
      []
    );

    const tooltip = useMemo(
      () => ({
        render: ({ datum }: Record<string, DatumProps>) => {
          return <CustomTooltip {...{ datum }} />;
        }
      }),
      []
    );

    if (!progress) return null;

    return (
      <div
        className={classNames(styles.chart, {
          [styles.danger]: intent === INTENT.Danger,
          [styles.caution]: intent === INTENT.Caution,
          [styles.warning]: intent === INTENT.Warning,
          [styles.success]: intent === INTENT.Success
        })}>
        <Chart
          axes={chartAxes}
          data={chartData}
          series={series}
          tooltip={tooltip}
        />
      </div>
    );
  }
);

interface CustomTooltipProps {
  datum: DatumProps;
}

export const CustomTooltip: React.ComponentType<CustomTooltipProps> = React.memo(
  ({ datum }) => {
    if (!datum) return null;

    return (
      <div
        style={{
          color: 'white',
          pointerEvents: 'none',
          fontSize: 14,
          padding: '0 3px'
        }}>
        <div>{datum.secondary}</div>
        {datum.originalDatum.date && (
          <>
            <div style={{ opacity: 0.7, fontSize: 12 }}>
              <Moment format={DATE_FORMAT.Short}>
                {datum.originalDatum.date}
              </Moment>
            </div>
            <div style={{ opacity: 0.7, fontSize: 12 }}>
              <Moment format={DATE_FORMAT.Time}>
                {datum.originalDatum.date}
              </Moment>
            </div>
          </>
        )}
      </div>
    );
  }
);
