/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import { getSettingsLabels } from './utils/settings-labels-utils';

export default (state = {}, action) => {
  if (isValidAPICall(action, 'settings_labels')) {
    return action.payload.data.attributes.list || {};
  }
  return state;
};

export { getSettingsLabels };
