/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { createAction } from 'redux-actions';
import { readEndpoint, updateResource } from 'redux-json-api';

import { log } from '../../misc/utils';
const deleteItem = createAction('API_WAS_DELETED');

export const getSubscriptionPlans = () => {
  log('getSubscriptionPlans()');
  return dispatch => {
    dispatch(readEndpoint('subscription_plans'));
  };
};

export const getInvoices = () => {
  log('getInvoices()');
  return dispatch => {
    dispatch(readEndpoint('invoices'));
  };
};

export const updateSubscription = plan => {
  log('updateSubscription()');
  return dispatch => {
    const entity = {
      type: `subscription#update`,
      attributes: {
        key: plan
      }
    };

    dispatch(updateResource(entity));
  };
};

export const deleteSubscription = callback => {
  log('deleteSubscription()');

  return dispatch => {
    const request = axios.delete('/subscription');
    request.then(async response => {
      dispatch(deleteItem(response.data));
      callback && callback();
    });
  };
};
