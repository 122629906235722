import React from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import { REVIEW_VIEWS } from '../../misc/consts';
import { Meeting, Reports, RootState } from '../../misc/types';
import { ReportCardStats } from '../review/ReportCardStats';
import { SummaryStats } from '../review/SummaryStats';

import { MeetingDate } from './MeetingDate';

import styles from '../../styles/meeting-modal.module.scss';

interface ModalDailyReportProps {
  event: Meeting;
}

export const ModalDailyReport: React.ComponentType<ModalDailyReportProps> =
  React.memo(({ event }) => {
    const user = useSelector((state: RootState) => state.user.general);
    const memberships = useSelector(
      (state: RootState) => state.teams.memberships.data
    );
    const settings_labels = useSelector(
      (state: RootState) => state.settings_labels
    );

    if (!event || !user) {
      return null;
    }

    // Build reporting data
    const desc = event.attributes.description || '';
    const descArray = desc.split(' ');

    // Build count and time
    const meetingCount = descArray[4];
    const spentHours = parseInt(descArray[12].replace(/[{()}]/g, ''));
    const workHours = 8;
    const percentOfTime = (spentHours / workHours) * 100;

    // Build score
    const score = event.attributes.summary.split('/100')[0];

    // Build reporting data
    const date = moment(event.attributes.starts_at);
    const DUMMY_DATA: Reports = {
      user: {
        duration_and_costs: {
          attributes: {
            rows: {
              total: {
                meetings: Number(meetingCount),
                work_hours: workHours,
                work_hours_spent: {
                  spent_hours: spentHours,
                  percent_of_time: percentOfTime
                }
              }
            }
          }
        },
        averages: {
          attributes: {
            average: Number(score)
          }
        }
      },
      team: {}
    };

    return (
      <>
        <div className={styles.sectionTop}>
          <div className={styles.title}>
            <h2>
              Daily report for&nbsp;
              <Moment format="dddd">{event.attributes.starts_at}</Moment>
            </h2>
          </div>
          <MeetingDate
            allDay={event.attributes.all_day}
            endDate={event.attributes.ends_at}
            startDate={event.attributes.starts_at}
          />
        </div>
        <div className={classNames(styles.sectionBottom, styles.dailyReport)}>
          <SummaryStats
            dateSelection={{ endDate: date.toDate(), startDate: date.toDate() }}
            isColumnLayout={true}
            memberships={memberships}
            reports={DUMMY_DATA}
            selectedView={REVIEW_VIEWS.You}
            user={user}
          />
          <ReportCardStats
            dateSelection={{ endDate: date.toDate(), startDate: date.toDate() }}
            isColumnLayout={true}
            reports={DUMMY_DATA}
            selectedView={REVIEW_VIEWS.You}
            settingsLabels={settings_labels}
            user={user}
          />
        </div>
      </>
    );
  });
