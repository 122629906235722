import moment from 'moment';

import {
  Calendar,
  Invite,
  Meeting,
  MeetingIntention,
  Member,
  Reports,
  Settings,
  SettingsLabels,
  Subscription,
  User
} from './types';

export const COMPANY_DOMAIN = 'dundermifflin.com';

export const EXAMPLE_USER: User = {
  id: '15',
  type: 'users',
  attributes: {
    affiliate_token: 'lksjdflskdjflsdkf',
    annual_salary: 85000,
    email: `jim@${COMPANY_DOMAIN}`,
    domain: COMPANY_DOMAIN,
    first_name: 'Jim',
    last_name: 'Halpert',
    profile_image:
      'https://lh5.googleusercontent.com/-VSSFNiivKrI/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucmDhgoj5LTfo9S6jKqxVORitj2U4Q/photo.jpg',
    time_zone: 'America/Los_Angeles',
    onboarded: true,
    identity_providers: ['google_oauth2'],
    subscription_plan: 'team-500',
    daily_report_card_events: true,
    created_at: '',
    deactivated_at: null,
    deactivated_reason: null,
    dnd_slack: true,
    company: {
      domains_allowlist: 'dundermifflin.com',
      id: 3,
      name: 'Dunder Mifflin',
      license_by: 'domain',
      organizer_denylist: '',
      roles: ['billing_admin']
    },
    organizer_denylist: '',
    domains_allowlist: '',
    communication_prefs: {
      all_communications: true,
      email_tips: true,
      email_weekly_reports: true,
      email_reminders: 'no_email',
      email_if_organizer: false
    },
    wellness_block_color_preference: 'blue',
    goal_ids: [13]
  }
};

export const EXAMPLE_USER_SETTINGS: Settings = {
  id: '15',
  type: 'user_settings',
  attributes: {
    action_settings: {
      meeting_conflict: 'block',
      focus_block_conflict: 'block',
      non_compliance_action: 'remind of conflicts',
      last_minute_schedule: 6,
      compliance_deadline: 12,
      max_attendees: 12,
      desired_duration: 45,
      required_agenda: true,
      required_conference_info: true,
      required_intention: false,
      required_objectives: false,
      required_prework: false,
      required_outcomes: false,
      required_role_leader: false,
      required_role_time_keeper: false,
      required_role_recorder: false,
      required_attendee_roles: false,
      take_action_attendees: 'any',
      take_action_content: 'any',
      take_action_time: 'any'
    },
    default_action_settings: {
      meeting_conflict: 'block',
      focus_block_conflict: 'block',
      non_compliance_action: 'do nothing',
      last_minute_schedule: 6,
      compliance_deadline: 6,
      max_attendees: 12,
      desired_duration: 90,
      required_agenda: true,
      required_conference_info: true,
      required_intention: false,
      required_objectives: false,
      required_prework: false,
      required_outcomes: false,
      required_role_leader: false,
      required_role_time_keeper: false,
      required_role_recorder: false,
      required_attendee_roles: false,
      take_action_attendees: 'any',
      take_action_content: 'any',
      take_action_time: 'any'
    },
    evaluation_settings: {
      meeting_conflict: 'block',
      focus_block_conflict: 'block',
      last_minute_schedule: 24,
      max_attendees: 6,
      desired_duration: 45,
      required_agenda: true,
      required_conference_info: true,
      required_intention: false,
      required_objectives: true,
      required_prework: false,
      required_outcomes: false,
      required_role_leader: false,
      required_role_time_keeper: false,
      required_role_recorder: false,
      required_attendee_roles: false,
      ignore_all_day_meetings: true,
      ignore_all_hands_meetings: true
    },
    default_evaluation_settings: {
      meeting_conflict: 'block',
      focus_block_conflict: 'block',
      last_minute_schedule: 24,
      max_attendees: 8,
      desired_duration: 60,
      required_agenda: true,
      required_conference_info: true,
      required_intention: false,
      required_objectives: false,
      required_prework: false,
      required_outcomes: false,
      required_role_leader: false,
      required_role_time_keeper: false,
      required_role_recorder: false,
      required_attendee_roles: false,
      ignore_all_day_meetings: true,
      ignore_all_hands_meetings: true
    }
  }
};

export const EXAMPLE_INVITE: Invite = {
  id: '2',
  type: 'invites',
  attributes: {
    team_id: 1,
    user_id: 6,
    email: 'adam@unspokenapp.com',
    created_at: '2020-06-30T19:57:51.907Z',
    accepted_at: '2020-07-25T05:24:43.765Z'
  }
};

export const EXAMPLE_SUBSCRIPTION: Subscription = {
  id: '40',
  type: 'subscriptions',
  attributes: {
    stripe_id: 'sub_HWPao0Z5UbllOT',
    status: 'active',
    active: true,
    created_at: '2020-06-24T00:46:33.570Z',
    current_period_start: '2020-06-24T00:46:30.000Z',
    current_period_end: '2020-07-24T00:46:30.000Z',
    has_many_members: true,
    amount: 400,
    category: 'team',
    plan: 'team-400',
    product_id: 'team'
  }
};

export const EXAMPLE_MEMBER: Member = {
  id: '1',
  type: 'memberships',
  attributes: {
    user_id: 1,
    name: 'Michael Scott',
    email: `michael@${COMPANY_DOMAIN}`,
    role: 'billing_admin',
    profile_image: 'https://avatars0.githubusercontent.com/u/6749?s=460&v=4'
  }
};

export const EXAMPLE_MEMBERS: Member[] = [
  EXAMPLE_MEMBER,
  {
    id: '2',
    type: 'memberships',
    attributes: {
      user_id: 2,
      name: 'Pam Beesley',
      email: `pam@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: '/images/profiles/pam.png'
    }
  },
  {
    id: '3',
    type: 'memberships',
    attributes: {
      user_id: 3,
      name: 'Jim Halpert',
      email: `jim@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: '/images/profiles/jim.png'
    }
  },
  {
    id: '4',
    type: 'memberships',
    attributes: {
      user_id: 4,
      name: 'Dwight Schrute',
      email: `dwight@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: '/images/profiles/dwight.png'
    }
  },
  {
    id: '5',
    type: 'memberships',
    attributes: {
      user_id: 5,
      name: 'Angela Martin',
      email: `angela@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: '/images/profiles/angela.png'
    }
  },
  {
    id: '6',
    type: 'memberships',
    attributes: {
      user_id: 6,
      name: 'Kelly Kapoor',
      email: `kelly@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: 'https://avatars0.githubusercontent.com/u/6749?s=460&v=4'
    }
  },
  {
    id: '7',
    type: 'memberships',
    attributes: {
      user_id: 7,
      name: 'Kevin Malone',
      email: `kevin@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: 'https://avatars0.githubusercontent.com/u/6749?s=460&v=4'
    }
  },
  {
    id: '8',
    type: 'memberships',
    attributes: {
      user_id: 8,
      name: 'Ryan Howard',
      email: `ryan@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: '/images/profiles/ryan.png'
    }
  },
  {
    id: '9',
    type: 'memberships',
    attributes: {
      user_id: 9,
      name: 'Meredith Palmer',
      email: `meredith@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: '/images/profiles/meredith.png'
    }
  },
  {
    id: '10',
    type: 'memberships',
    attributes: {
      user_id: 10,
      name: 'Oscar Martinez',
      email: `oscar@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: 'https://avatars0.githubusercontent.com/u/6749?s=460&v=4'
    }
  },
  {
    id: '11',
    type: 'memberships',
    attributes: {
      user_id: 11,
      name: 'Stanley Hudson',
      email: `stanley@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: '/images/profiles/stanley.png'
    }
  },
  {
    id: '12',
    type: 'memberships',
    attributes: {
      user_id: 12,
      name: 'Phyllis Vance',
      email: `phyllis@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: 'https://avatars0.githubusercontent.com/u/6749?s=460&v=4'
    }
  },
  {
    id: '13',
    type: 'memberships',
    attributes: {
      user_id: 13,
      name: 'Creed Bratton',
      email: `creed@${COMPANY_DOMAIN}`,
      role: 'billing_admin',
      profile_image: 'https://avatars0.githubusercontent.com/u/6749?s=460&v=4'
    }
  }
];

export const EXAMPLE_DESCRIPTION = `
Agenda:

- Michael to define “Pipeline” - 5 min
- Review previous quarter’s sales performance - 35 min
- Compare pipeline to current sales goals - 55 min
- Identify key account opportunities - 20 min
- Q&A - 5 min

Objective:

- Identify which key accounts will help us reach Q3 sales goals
`;

export const EXAMPLE_MEETING: Meeting = {
  id: '6256',
  type: 'meetings',
  attributes: {
    all_day: false,
    summary: 'Scranton branch pipeline review',
    description: EXAMPLE_DESCRIPTION,
    starts_at: moment()
      .startOf('isoWeek')
      .add(1, 'week')
      .set('hour', 13)
      .set('minute', 0)
      .set('seconds', 0)
      .format(),
    ends_at: moment()
      .startOf('isoWeek')
      .add(1, 'week')
      .set('hour', 14)
      .set('minute', 0)
      .set('seconds', 0)
      .format(),
    intention: 'planning',
    location: null,
    cost: 450,
    non_compliance_action: null,
    organizer_email: {
      email: `mike@${COMPANY_DOMAIN}`,
      domain: COMPANY_DOMAIN
    },
    duration: 3600,
    status: 'confirmed',
    prework_items: { data: [] },
    attendees: [
      {
        email: `mike@${COMPANY_DOMAIN}`,
        domain: COMPANY_DOMAIN,
        optional: null,
        role: 'organizer'
      },
      {
        email: `jim@${COMPANY_DOMAIN}`,
        domain: COMPANY_DOMAIN,
        optional: null
      },
      {
        email: `dwight@${COMPANY_DOMAIN}`,
        domain: COMPANY_DOMAIN,
        optional: null
      },
      {
        email: `pam@${COMPANY_DOMAIN}`,
        domain: COMPANY_DOMAIN,
        optional: null
      }
    ],
    attendees_count: 5,
    maybe_wellness_block: 'false',
    maybe_wellness_block_category: 'life',
    wellness_block_id: 5,
    wellness_block_category: 'life',
    wellness_blockable: false,
    agenda_items: {
      data: [
        {
          id: '1332',
          type: 'agenda_items',
          attributes: {
            id: 1332,
            owner_name: null,
            owner_id: null,
            description: 'Michael to define “Pipeline”',
            duration_in_mins: 5,
            time_spent_in_secs: null,
            started_at: null,
            ended_at: null,
            meeting_id: 6256,
            position: 2
          }
        },
        {
          id: '1334',
          type: 'agenda_items',
          attributes: {
            id: 1334,
            owner_name: null,
            owner_id: null,
            description: 'Review previous quarter’s sales performance',
            duration_in_mins: 35,
            time_spent_in_secs: null,
            started_at: null,
            ended_at: null,
            meeting_id: 6256,
            position: 3
          }
        },
        {
          id: '1336',
          type: 'agenda_items',
          attributes: {
            id: 1336,
            owner_name: null,
            owner_id: null,
            description: 'Compare pipeline to current sales goals',
            duration_in_mins: 55,
            time_spent_in_secs: null,
            started_at: null,
            ended_at: null,
            meeting_id: 6256,
            position: 4
          }
        },
        {
          id: '1337',
          type: 'agenda_items',
          attributes: {
            id: 1337,
            owner_name: null,
            owner_id: null,
            description: 'Identify key account opportunities',
            duration_in_mins: 20,
            time_spent_in_secs: null,
            started_at: null,
            ended_at: null,
            meeting_id: 6256,
            position: 5
          }
        },
        {
          id: '1338',
          type: 'agenda_items',
          attributes: {
            id: 1338,
            owner_name: null,
            owner_id: null,
            description: 'Q&A',
            duration_in_mins: 5,
            time_spent_in_secs: null,
            started_at: null,
            ended_at: null,
            meeting_id: 6256,
            position: 6
          }
        }
      ]
    },
    compliance_deadline: '2020-08-31T03:30:00.000Z',
    details_negative: {},
    details_positive: {
      meeting_conflict: 'There are no conflicts with any other meetings.',
      focus_block_conflict: 'No Focus Block conflict. Stuff can get done!',
      required_agenda: 'A time-boxed agenda!',
      required_objectives:
        'Nice! Objectives help attendees justify spending their valuable time as well as prepare for and best engage in a meeting.',
      last_minute_schedule:
        'Nicely done. Invitees have plenty of time to prepare for this meeting.',
      max_attendees: 'The number of attendees is acceptable.',
      desired_duration: 'This is the right length of time.'
    },
    edit_permission: true,
    event_report_card_id: null,
    objectives: {
      data: [
        {
          id: '334',
          type: 'objectives',
          attributes: {
            meeting_id: 6256,
            description: 'Make sure we are making progress',
            position: 0
          }
        }
      ]
    },
    recurring_meeting: true,
    score: 100,
    results: {
      desired_duration: {
        points: 6,
        weight: 6,
        score: 100
      },
      focus_block_conflict: {
        points: 21,
        weight: 21,
        score: 100
      },
      last_minute_schedule: {
        points: 16,
        weight: 16,
        score: 100
      },
      max_attendees: { points: 6, weight: 6, score: 100 },
      meeting_conflict: {
        points: 16,
        weight: 16,
        score: 100
      },
      required_agenda: {
        points: 21,
        weight: 21,
        score: 100
      },
      required_objectives: {
        points: 11,
        weight: 11,
        score: 100
      }
    },
    quality_label: 'Way to keep it 💯!'
  }
};

export const EXAMPLE_CALENDAR_LIST_ITEM: Calendar = {
  id: '6',
  type: 'calendars',
  attributes: {
    uuid: 'adam@rrdp.llc',
    name: 'MeetingCop',
    primary: false,
    watching: true,
    source: 'google'
  }
};

export const EXAMPLE_REPORTS: Reports = {
  team: {
    overtime: {
      id: '7dd1a24a-07cc-4b6c-a9f2-2479ea50960b',
      type: 'reports',
      attributes: {
        name: 'Overtime',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {},
        trends: null,
        average: 0,
        total: 0
      }
    },
    focus_block_conflict: {
      id: 'c4ccecdd-6289-4f3b-bc70-2e93d22cac2a',
      type: 'reports',
      attributes: {
        name: 'Focus block conflict',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {},
        trends: {
          prev_span_total: 0,
          prev_span_average: 0,
          total_delta: 0,
          percent_growth: 0,
          trending: 'flat'
        },
        average: 0,
        total: 0
      }
    },
    scheduled_late: {
      id: 'df7f3988-e185-4945-aa06-bb2cfacd6a05',
      type: 'reports',
      attributes: {
        name: 'Scheduled late',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {},
        trends: {
          prev_span_total: 0,
          prev_span_average: 0,
          total_delta: 0,
          percent_growth: 0,
          trending: 'flat'
        },
        average: 0,
        total: 0
      }
    },
    averages: {
      id: 'b4bcca69-859f-4789-a811-79233a9ac63e',
      type: 'reports',
      attributes: {
        name: 'Evaluation averages and totals',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          totals: {
            compliant: 5,
            non_compliant: 18,
            duration: 70200
          },
          counts: {
            good: {
              required_agenda: 120,
              required_objectives: 93,
              focus_block_conflict: 78,
              max_attendees: 68,
              last_minute_schedule: 53
            },
            bad: {
              required_agenda: 345,
              max_attendees: 289,
              required_objectives: 234,
              last_minute_schedule: 117,
              focus_block_conflict: 83
            }
          },
          quality_label:
            'This letter grade of D could stand for Decline. Consider it!'
        },
        trends: {
          average: {
            prev_span_total: 67.15,
            prev_span_average: 3.1075,
            total_delta: 2.5891304347826107,
            percent_growth: 4.165937948161884,
            trending: 'down'
          }
        },
        average: 64.73913043478261,
        total: 23
      }
    },
    average_attendees: {
      id: 'e37b7791-78c9-4d82-9e47-07905fa4ea24',
      type: 'reports',
      attributes: {
        name: 'Attendees',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {},
        trends: {
          prev_span_total: 91,
          prev_span_average: 4.55,
          total_delta: 24,
          percent_growth: 26.373626373626376,
          trending: 'up'
        },
        average: 5,
        total: 115
      }
    },
    duration_and_costs: {
      id: '1f33d74f-2292-45a0-80e5-a8cd4dca2ee4',
      type: 'reports',
      attributes: {
        name: 'Duration and Costs Summary',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          annual_salary: 82000,
          estimated_hourly_pay: 41,
          good: {
            meetings: 139,
            meeting_duration: 357192,
            attendee_total: 5,
            ave_attendee_per_meeting: 0,
            meeting_cost_total: 11740,
            meeting_cost_per_hour: 41,
            ave_cost_per_meeting: 41
          },
          bad: {
            meetings: 492,
            meeting_duration: 1266408,
            attendee_total: 18,
            ave_attendee_per_meeting: 0,
            meeting_cost_total: 43788,
            meeting_cost_per_hour: 41,
            ave_cost_per_meeting: 33.02777777777778
          },
          total: {
            meetings: 631,
            meeting_duration: 39300,
            ave_meeting_duration: 3052,
            attendee_total: 23,
            ave_attendee_per_meeting: 3052,
            meeting_cost_total: 55528,
            meeting_cost_per_hour: 73.23664122137404,
            ave_cost_per_meeting: 88.83333333333333,
            work_hours: 520,
            work_hours_spent: {
              spent_hours: 451,
              percent_of_time: 86.7
            }
          }
        },
        trends: {
          totals: {
            prev_span_total: 38700,
            prev_span_average: 4837.5,
            total_delta: -37900.5,
            percent_growth: -97.93410852713178,
            trending: 'down'
          },
          good: {
            prev_span_total: 8100,
            prev_span_average: 1012.5,
            total_delta: -8100,
            percent_growth: -100,
            trending: 'down'
          },
          bad: {
            prev_span_total: 46800,
            prev_span_average: 5850,
            total_delta: -46800,
            percent_growth: -100,
            trending: 'down'
          }
        },
        average: 88.83333333333333,
        total: 799.5
      }
    },
    num_past_actions_by: {
      id: 'b9aa2bdf-fd8a-4158-bd33-5df9228a2c3b',
      type: 'reports',
      attributes: {
        name: 'Past actions grouped by day',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          '2021-07-11': 12,
          '2021-07-12': 9,
          '2021-07-13': 5,
          '2021-07-14': 7,
          '2021-07-15': 3,
          '2021-07-16': 1
        },
        trends: null,
        average: 0,
        total: 37
      }
    },
    user_counts: {
      id: '6fd76084-c392-4217-8cac-63c8575a5e5c',
      type: 'reports',
      attributes: {
        name: 'Team User Count',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          evaluatable_users: 3,
          total_users: 4
        },
        trends: null,
        average: 0,
        total: 0
      }
    },
    average_scores_by: {
      id: '72887035-9f0c-4109-b3f3-30437e816e80',
      type: 'reports',
      attributes: {
        name: 'Average scores grouped by day',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          [EXAMPLE_MEMBERS[3].attributes.email]: {
            // Dwight
            '2021-07-12': '100.0',
            '2021-07-13': '100.0',
            '2021-07-14': '100.0',
            '2021-07-15': '100.0',
            '2021-07-16': '100.0'
          },
          [EXAMPLE_MEMBERS[4].attributes.email]: {
            // Angela
            '2021-07-12': '94.0',
            '2021-07-14': '88.0',
            '2021-07-15': '86.0',
            '2021-07-16': '100.0'
          },
          [EXAMPLE_MEMBERS[1].attributes.email]: {
            // Pam
            '2021-07-12': '90.0',
            '2021-07-13': '78.0',
            '2021-07-14': '88.0',
            '2021-07-15': '86.0',
            '2021-07-16': '92.0'
          },
          [EXAMPLE_MEMBERS[2].attributes.email]: {
            // Jim
            '2021-07-12': '67.0',
            '2021-07-14': '75.0',
            '2021-07-16': '76.0'
          },
          [EXAMPLE_MEMBERS[8].attributes.email]: {
            // Meredith
            '2021-07-12': '58.0',
            '2021-07-13': '66.0',
            '2021-07-15': '74.0',
            '2021-07-16': '76.0'
          },
          [EXAMPLE_MEMBERS[10].attributes.email]: {
            // Stanley
            '2021-07-12': '48.0',
            '2021-07-13': '52.0',
            '2021-07-15': '54.0',
            '2021-07-16': '60.0'
          },
          [EXAMPLE_MEMBERS[7].attributes.email]: {
            // Ryan
            '2021-07-12': '38.0',
            '2021-07-13': '34.0',
            '2021-07-14': '36.0',
            '2021-07-15': '66.0',
            '2021-07-16': '82.0'
          }
        },
        trends: null,
        average: 0,
        total: 0
      }
    },
    duration_and_cost_details: {
      id: '146c48ae-1afb-4711-8695-c5c71137f032',
      type: 'reports',
      attributes: {
        name: 'Duration and Costs Details',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          organizers: {
            [EXAMPLE_MEMBERS[1].attributes.email]: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 10.5,
                count: 14,
                percent: 100
              },
              total: {
                hours: 10.5,
                count: 14,
                cost: 430.5,
                percent: '87'
              },
              id: 60,
              name: EXAMPLE_MEMBERS[1].attributes.name,
              profile_image: EXAMPLE_MEMBERS[1].attributes.profile_image,
              past_actions: null
            },
            [EXAMPLE_MEMBERS[2].attributes.email]: {
              good: {
                hours: 2,
                count: 1,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 2,
                count: 1,
                cost: 82,
                percent: '73.0'
              },
              id: 11,
              name: EXAMPLE_MEMBERS[2].attributes.name,
              profile_image: EXAMPLE_MEMBERS[2].attributes.profile_image,
              past_actions: 4
            },
            [EXAMPLE_MEMBERS[3].attributes.email]: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 1,
                count: 1,
                percent: 100
              },
              total: {
                hours: 1,
                count: 1,
                cost: 41,
                percent: '100.0'
              },
              id: 25,
              name: EXAMPLE_MEMBERS[3].attributes.name,
              profile_image: EXAMPLE_MEMBERS[3].attributes.profile_image,
              past_actions: null
            },
            [EXAMPLE_MEMBERS[4].attributes.email]: {
              good: {
                hours: 3,
                count: 4,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 3,
                count: 4,
                cost: 123,
                percent: '92.0'
              },
              id: 16,
              name: EXAMPLE_MEMBERS[4].attributes.name,
              profile_image: EXAMPLE_MEMBERS[4].attributes.profile_image,
              past_actions: null
            },
            [EXAMPLE_MEMBERS[8].attributes.email]: {
              good: {
                hours: 3,
                count: 4,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 3,
                count: 4,
                cost: 123,
                percent: '69.0'
              },
              id: 16,
              name: EXAMPLE_MEMBERS[8].attributes.name,
              profile_image: EXAMPLE_MEMBERS[8].attributes.profile_image,
              past_actions: 7
            },
            [EXAMPLE_MEMBERS[7].attributes.email]: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 10.5,
                count: 14,
                percent: 100
              },
              total: {
                hours: 10.5,
                count: 14,
                cost: 430.5,
                percent: '51'
              },
              id: 60,
              name: EXAMPLE_MEMBERS[7].attributes.name,
              profile_image: EXAMPLE_MEMBERS[7].attributes.profile_image,
              past_actions: 17
            },
            [EXAMPLE_MEMBERS[10].attributes.email]: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 10.5,
                count: 14,
                percent: 100
              },
              total: {
                hours: 10.5,
                count: 14,
                cost: 430.5,
                percent: '58'
              },
              id: 60,
              name: EXAMPLE_MEMBERS[10].attributes.name,
              profile_image: EXAMPLE_MEMBERS[10].attributes.profile_image,
              past_actions: 9
            }
          },
          categories: {
            recurring: {
              good: {
                hours: 3,
                count: 4,
                percent: 22.22222222222222
              },
              bad: {
                hours: 10.5,
                count: 14,
                percent: 77.77777777777779
              },
              total: {
                hours: 13.5,
                count: 18,
                cost: 553.5
              }
            }
          },
          intentions: {
            planning: {
              good: {
                hours: 2.25,
                count: 3,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 2.25,
                count: 3,
                cost: 92.25
              }
            },
            unassigned: {
              good: {
                hours: 2.75,
                count: 2,
                percent: 11.76470588235294
              },
              bad: {
                hours: 11.5,
                count: 15,
                percent: 88.23529411764706
              },
              total: {
                hours: 14.25,
                count: 17,
                cost: 584.25
              }
            }
          }
        },
        trends: null,
        average: 0,
        total: 0
      }
    }
  },
  user: {
    averages: {
      id: 'e6356b04-214e-4916-9aee-1ae11c9d30da',
      type: 'reports',
      attributes: {
        name: 'Evaluation averages and totals',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          totals: {
            compliant: 5,
            non_compliant: 18,
            duration: 70200
          },
          counts: {
            good: {
              required_agenda: 12,
              required_objectives: 9,
              focus_block_conflict: 7,
              max_attendees: 6,
              last_minute_schedule: 5
            },
            bad: {
              required_agenda: 14,
              max_attendees: 13,
              required_objectives: 9,
              last_minute_schedule: 7,
              focus_block_conflict: 5
            }
          },
          quality_label:
            'This letter grade of D could stand for Decline. Consider it!'
        },
        trends: {
          average: {
            prev_span_total: 67.15,
            prev_span_average: 3.1075,
            total_delta: 2.5891304347826107,
            percent_growth: 4.165937948161884,
            trending: 'down'
          }
        },
        average: 64.73913043478261,
        total: 23
      }
    },
    overtime: {
      id: '98cefe2c-9560-4791-815b-116734671f40',
      type: 'reports',
      attributes: {
        name: 'Overtime',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {},
        trends: null,
        average: 0,
        total: 0
      }
    },
    scheduled_late: {
      id: '09f6585b-acb3-46cf-8ad6-e298510869b2',
      type: 'reports',
      attributes: {
        name: 'Scheduled late',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {},
        trends: {
          prev_span_total: 0,
          prev_span_average: 0,
          total_delta: 0,
          percent_growth: 0,
          trending: 'flat'
        },
        average: 0,
        total: 0
      }
    },
    focus_block_conflict: {
      id: 'dd3ad235-1b97-46ac-ad55-1262dbf6491f',
      type: 'reports',
      attributes: {
        name: 'Focus block conflict',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {},
        trends: {
          prev_span_total: 0,
          prev_span_average: 0,
          total_delta: 0,
          percent_growth: 0,
          trending: 'flat'
        },
        average: 0,
        total: 0
      }
    },
    average_attendees: {
      id: 'f8ea860f-637f-45a5-9fd7-62936fb92b40',
      type: 'reports',
      attributes: {
        name: 'Attendees',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {},
        trends: {
          prev_span_total: 3,
          prev_span_average: 3,
          total_delta: 6,
          percent_growth: 200,
          trending: 'up'
        },
        average: 4.5,
        total: 9
      }
    },
    duration_and_costs: {
      id: '987ad63a-294b-4ffd-884c-5551b0da2b82',
      type: 'reports',
      attributes: {
        name: 'Duration and Costs Summary',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          annual_salary: 80000,
          estimated_hourly_pay: 85,
          good: {
            meetings: 5,
            meeting_duration: 29131,
            attendee_total: 1,
            ave_attendee_per_meeting: 0,
            meeting_cost_total: 316,
            meeting_cost_per_hour: 85,
            ave_cost_per_meeting: 63.75
          },
          bad: {
            meetings: 16,
            meeting_duration: 93268,
            attendee_total: 1,
            ave_attendee_per_meeting: 0,
            meeting_cost_total: 992,
            meeting_cost_per_hour: 85,
            ave_cost_per_meeting: 85
          },
          total: {
            meetings: 21,
            meeting_duration: 6300,
            ave_meeting_duration: 3150,
            attendee_total: 2,
            ave_attendee_per_meeting: 3150,
            meeting_cost_total: 1308,
            meeting_cost_per_hour: 38,
            ave_cost_per_meeting: 62,
            work_hours: 40,
            work_hours_spent: {
              spent_hours: 34,
              percent_of_time: 85
            }
          }
        },
        trends: {
          totals: {
            prev_span_total: 2700,
            prev_span_average: 2700,
            total_delta: -2551.25,
            percent_growth: -94.49074074074075,
            trending: 'down'
          },
          good: {
            prev_span_total: 2700,
            prev_span_average: 2700,
            total_delta: -2700,
            percent_growth: -100,
            trending: 'down'
          },
          bad: {
            prev_span_total: 0,
            prev_span_average: 0,
            total_delta: 0,
            percent_growth: 0,
            trending: 'flat'
          }
        },
        average: 74.375,
        total: 148.75
      }
    },
    average_scores_by: {
      id: 'c1fe2dff-1d70-4a97-bb5a-1b02a5452f3c',
      type: 'reports',
      attributes: {
        name: 'Average scores grouped by day',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          [EXAMPLE_MEMBERS[3].attributes.email]: {
            // Dwight
            '2021-07-12': '100.0',
            '2021-07-13': '100.0',
            '2021-07-14': '100.0',
            '2021-07-15': '100.0',
            '2021-07-16': '100.0'
          },
          [EXAMPLE_MEMBERS[4].attributes.email]: {
            // Angela
            '2021-07-12': '94.0',
            '2021-07-14': '88.0',
            '2021-07-15': '86.0',
            '2021-07-16': '100.0'
          },
          [EXAMPLE_MEMBERS[1].attributes.email]: {
            // Pam
            '2021-07-12': '90.0',
            '2021-07-13': '78.0',
            '2021-07-14': '88.0',
            '2021-07-15': '86.0',
            '2021-07-16': '92.0'
          },
          [EXAMPLE_MEMBERS[2].attributes.email]: {
            // Jim
            '2021-07-12': '67.0',
            '2021-07-14': '75.0',
            '2021-07-16': '76.0'
          },
          [EXAMPLE_MEMBERS[8].attributes.email]: {
            // Meredith
            '2021-07-12': '58.0',
            '2021-07-13': '66.0',
            '2021-07-15': '74.0',
            '2021-07-16': '76.0'
          },
          [EXAMPLE_MEMBERS[10].attributes.email]: {
            // Stanley
            '2021-07-12': '48.0',
            '2021-07-13': '52.0',
            '2021-07-15': '54.0',
            '2021-07-16': '60.0'
          },
          [EXAMPLE_MEMBERS[7].attributes.email]: {
            // Ryan
            '2021-07-12': '38.0',
            '2021-07-13': '34.0',
            '2021-07-14': '36.0',
            '2021-07-15': '66.0',
            '2021-07-16': '82.0'
          }
        },
        trends: null,
        average: 0,
        total: 0
      }
    },
    num_past_actions_by: {
      id: 'e626c24a-a802-4b7c-8b25-a89ab7be3d32',
      type: 'reports',
      attributes: {
        name: 'Past actions grouped by day',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          '2021-07-11': 12,
          '2021-07-12': 9,
          '2021-07-13': 5,
          '2021-07-14': 7,
          '2021-07-15': 3,
          '2021-07-16': 1
        },
        trends: null,
        average: 0,
        total: 37
      }
    },
    duration_and_cost_details: {
      id: '45fdc2f0-d87d-4275-a9c6-a33aba3d0458',
      type: 'reports',
      attributes: {
        name: 'Duration and Costs Details',
        starts_at: '2021-07-11T00:00:00.000-07:00',
        ends_at: '2021-07-17T23:59:59.999-07:00',
        spans_days: 7,
        rows: {
          organizers: {
            [EXAMPLE_MEMBERS[1].attributes.email]: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 10.5,
                count: 14,
                percent: 100
              },
              total: {
                hours: 10.5,
                count: 14,
                cost: 430.5,
                percent: '87'
              },
              id: 60,
              name: EXAMPLE_MEMBERS[1].attributes.name,
              profile_image: EXAMPLE_MEMBERS[1].attributes.profile_image,
              past_actions: null
            },
            [EXAMPLE_MEMBERS[2].attributes.email]: {
              good: {
                hours: 2,
                count: 1,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 2,
                count: 1,
                cost: 82,
                percent: '73.0'
              },
              id: 11,
              name: EXAMPLE_MEMBERS[2].attributes.name,
              profile_image: EXAMPLE_MEMBERS[2].attributes.profile_image,
              past_actions: 4
            },
            [EXAMPLE_MEMBERS[3].attributes.email]: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 1,
                count: 1,
                percent: 100
              },
              total: {
                hours: 1,
                count: 1,
                cost: 41,
                percent: '100.0'
              },
              id: 25,
              name: EXAMPLE_MEMBERS[3].attributes.name,
              profile_image: EXAMPLE_MEMBERS[3].attributes.profile_image,
              past_actions: null
            },
            [EXAMPLE_MEMBERS[4].attributes.email]: {
              good: {
                hours: 3,
                count: 4,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 3,
                count: 4,
                cost: 123,
                percent: '92.0'
              },
              id: 16,
              name: EXAMPLE_MEMBERS[4].attributes.name,
              profile_image: EXAMPLE_MEMBERS[4].attributes.profile_image,
              past_actions: null
            },
            [EXAMPLE_MEMBERS[8].attributes.email]: {
              good: {
                hours: 3,
                count: 4,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 3,
                count: 4,
                cost: 123,
                percent: '69.0'
              },
              id: 16,
              name: EXAMPLE_MEMBERS[8].attributes.name,
              profile_image: EXAMPLE_MEMBERS[8].attributes.profile_image,
              past_actions: 7
            },
            [EXAMPLE_MEMBERS[7].attributes.email]: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 10.5,
                count: 14,
                percent: 100
              },
              total: {
                hours: 10.5,
                count: 14,
                cost: 430.5,
                percent: '51'
              },
              id: 60,
              name: EXAMPLE_MEMBERS[7].attributes.name,
              profile_image: EXAMPLE_MEMBERS[7].attributes.profile_image,
              past_actions: 17
            },
            [EXAMPLE_MEMBERS[10].attributes.email]: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 10.5,
                count: 14,
                percent: 100
              },
              total: {
                hours: 10.5,
                count: 14,
                cost: 430.5,
                percent: '58'
              },
              id: 60,
              name: EXAMPLE_MEMBERS[10].attributes.name,
              profile_image: EXAMPLE_MEMBERS[10].attributes.profile_image,
              past_actions: 9
            }
          },
          categories: {
            recurring: {
              good: {
                hours: 0.75,
                count: 1,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 0.75,
                count: 1,
                cost: 63.75
              }
            }
          },
          intentions: {
            planning: {
              good: {
                hours: 0.75,
                count: 1,
                percent: 100
              },
              bad: {
                hours: 0,
                count: 0,
                percent: 0
              },
              total: {
                hours: 0.75,
                count: 1,
                cost: 63.75
              }
            },
            kickoff: {
              good: {
                hours: 0,
                count: 0,
                percent: 0
              },
              bad: {
                hours: 1,
                count: 1,
                percent: 100
              },
              total: {
                hours: 1,
                count: 1,
                cost: 85
              }
            }
          }
        },
        trends: null,
        average: 0,
        total: 0
      }
    }
  }
};

export const EXAMPLE_SETTINGS_LABELS: SettingsLabels = {
  focus_block_conflict: {
    label: 'Wellness block conflict',
    negative_label: 'Wellness block conflicts',
    positive_label: 'No wellness block conflicts',
    description:
      'Meeting scheduled over the untouchable time reserved for you, and you only.',
    weight: 0.2
  },
  meeting_conflict: {
    label: 'Meeting conflict',
    negative_label: 'Meeting conflicts',
    positive_label: 'No meeting conflicts',
    description:
      'Meeting scheduled over one already accepted -- or one that the invitee created themselves.',
    weight: 0.15
  },
  last_minute_schedule: {
    label: 'Last-minute scheduling',
    negative_label: 'Scheduled last minute',
    positive_label: 'Scheduled on-time',
    description: 'Hours ahead of time that a meeting must be scheduled.',
    weight: 0.15
  },
  compliance_deadline: {
    label: 'Deadline',
    description:
      "Hours ahead of a meeting before it's considered 'not compliant'."
  },
  non_compliance_action: {
    label: 'Action to take',
    description: 'Action when meeting requirements are not met before deadline.'
  },
  max_attendees: {
    label: 'Number of attendees',
    negative_label: 'Too many people',
    positive_label: 'Good amount of people',
    description:
      'Max # of people in a meeting. Ignored for "all hands" and all-day meetings.',
    weight: 0.05
  },
  desired_duration: {
    label: 'Ideal meeting duration',
    negative_label: 'Meeting is too long',
    positive_label: 'Good meeting duration',
    description: 'Meeting length goal.',
    weight: 0.05
  },
  required_agenda: {
    label: 'Agenda',
    negative_label: 'Missing an agenda',
    positive_label: 'Has an agenda',
    description: 'Time-boxed items to discuss.',
    weight: 0.2
  },
  required_intention: {
    label: 'Intention',
    negative_label: 'Missing an intention',
    positive_label: 'Has an intention',
    description: 'The reason for meeting.',
    weight: 0.06
  },
  required_conference_info: {
    label: 'Video conferencing',
    negative_label: 'Missing video conferencing details',
    positive_label: 'Has video conferencing details',
    description: 'Video conferencing details for remote attendees.',
    weight: 0.02
  },
  required_objectives: {
    label: 'Objectives',
    negative_label: 'Missing objective(s)',
    positive_label: 'Has objective(s)',
    description: 'Goals of the meeting.',
    weight: 0.1
  },
  required_prework: {
    label: 'Pre-work',
    negative_label: 'Pre-work is needed',
    positive_label: 'Pre-work is done',
    description: 'Tasks and/or associated materials to address beforehand.',
    weight: null
  },
  required_outcomes: {
    label: 'Outcomes',
    negative_label: 'Missing outcomes',
    positive_label: 'Has outcomes',
    description: 'Post-meeting notes with next steps.',
    weight: null,
    coming_soon: true
  },
  required_role_leader: {
    label: 'Assigned leader',
    negative_label: 'Missing an assigned leader',
    positive_label: 'A leader is assigned',
    description: 'To lead the meeting.',
    weight: null,
    coming_soon: true
  },
  required_role_time_keeper: {
    label: 'Assigned Time-keeper',
    negative_label: 'Missing an assigned time-keeper',
    positive_label: 'A time-keeper is assigned',
    description: 'To keep it on track.',
    weight: null,
    coming_soon: true
  },
  required_role_recorder: {
    label: 'Assigned Note-taker',
    negative_label: 'Missing an assigned note-taker',
    positive_label: 'A note-taker is assigned',
    description: 'To capture parking lot items and outcomes.',
    weight: null,
    coming_soon: true
  },
  required_attendee_roles: {
    label: 'Assign all roles',
    negative_label: 'Missing assigned meeting roles',
    positive_label: 'Meeting roles are assigned',
    description: 'All of the attendee roles are need to be assigned.',
    weight: null,
    coming_soon: true
  },
  ignore_all_day_meetings: {
    label: 'All-day meetings',
    description: 'Do not evaluate all day meetings.'
  },
  ignore_all_hands_meetings: {
    label: 'All-hands meetings',
    description: 'Do not evaluate all hands meetings.'
  }
};

export const EXAMPLE_INTENTIONS: Record<string, MeetingIntention> = {
  all_hands: { label: 'All hands', summary: 'Placeholder' },
  brainstorming: { label: 'Brainstorming', summary: 'Placeholder' },
  consensus: { label: 'Decision making', summary: 'Placeholder' },
  information: { label: 'Info sharing', summary: 'Placeholder' },
  kickoff: { label: 'Kick-off', summary: 'Placeholder' },
  one_on_one: { label: 'One on one', summary: 'Placeholder' },
  planning: { label: 'Planning', summary: 'Placeholder' },
  retrospective: { label: 'Retrospective', summary: 'Placeholder' },
  standup: { label: 'Stand up', summary: 'Placeholder' },
  teambuilding: { label: 'Team building', summary: 'Placeholder' }
};

export const EXAMPLE_DATE_SELECTION = {
  endDate: moment('2021-07-17T23:59:59.999-07:00').toDate(),
  startDate: moment('2021-07-11T00:00:00.000-07:00').toDate()
};
