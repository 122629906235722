import React from 'react';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import { RootState } from '../../misc/types';

import styles from '../../styles/wellness-blocks.module.scss';

interface WellnessBlockSuggestionsProps {
  label?: string;
  setLabel: (text: string) => void;
}

export const WellnessBlockSuggestions: React.ComponentType<WellnessBlockSuggestionsProps> =
  React.memo(({ label, setLabel }) => {
    const user = useSelector((state: RootState) => state.user.general);
    const goals = useSelector((state: RootState) => state.goals);

    const chosenGoals = user?.attributes.goal_ids
      ? user?.attributes.goal_ids.map(item => {
          const thisGoal = find(goals, ['id', item.toString()]);
          return thisGoal;
        })
      : [];

    if (!chosenGoals || label) {
      return null;
    }

    return (
      <div className={styles.wbSuggestions}>
        <div className={styles.label}>Ideas:</div>
        <div className={styles.suggs}>
          {chosenGoals.map((item, key) => {
            if (item) {
              return (
                <div
                  key={key}
                  className={styles.suggestion}
                  onClick={() => setLabel(item?.attributes.name)}>
                  {item?.attributes.name}
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  });
