/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { loadStripe } from '@stripe/stripe-js/pure';
import { createResource, updateResource } from 'redux-json-api';

import { log } from '../../misc/utils';

export const createStripeCheckoutSession = (plan, callback) => {
  log('createStripeSession()');
  return dispatch => {
    const entity = {
      type: 'stripe_checkout/sessions',
      attributes: {
        key: plan,
        success_url: window.location.pathname + window.location.search
      }
    };

    dispatch(createResource(entity)).then(async response => {
      loadStripe.setLoadParameters({ advancedFraudSignals: false });
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

      stripe
        .redirectToCheckout({
          sessionId: response.data.id
        })
        .then(callback);
    });
  };
};

export const updateStripePaymentMethodCheckoutSession = (plan, callback) => {
  log('updatePaymentMethodStripeSession()');
  return dispatch => {
    const entity = {
      type: `stripe_checkout/sessions#update`,
      attributes: {
        success_url: window.location.pathname
      }
    };

    dispatch(updateResource(entity)).then(async response => {
      loadStripe.setLoadParameters({ advancedFraudSignals: false });
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

      stripe
        .redirectToCheckout({
          sessionId: response.data.id
        })
        .then(callback);
    });
  };
};
