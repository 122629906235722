/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useCallback } from 'react';
import { isBrowser } from 'react-device-detect';
import { NavLink, useHistory } from 'react-router-dom';
import Sticky from 'react-sticky-el';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { ModalView } from '../../components/misc/ModalView';
import { QUERY_PARAMS } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';

import styles from '../../styles/marketing/marketing-header.module.scss';

export const MarketingHeader = () => {
  const history = useHistory();
  const [isSignUpFormOpen, setIsSignUpFormOpen] = useQueryParam(
    QUERY_PARAMS.Login,
    BooleanParam
  );

  const _onSignUpFormToggle = useCallback(() => {
    setIsSignUpFormOpen(prevState => {
      if (!prevState) {
        Fathom.trackGoal(GOALS.Marketing.LoginSignupButton);
      }
      return !prevState;
    });
  }, [setIsSignUpFormOpen]);

  const signUpForm = () => {
    if (!isSignUpFormOpen) return null;

    return (
      <div className={styles.requestAcctForm}>
        <iframe
          src="https://chuckphillips.wufoo.com/forms/z1t4nohl08qh4tw"
          title="description"></iframe>
      </div>
    );
  };

  return (
    <>
      <ModalView
        innerClassName={styles.requestAcctFormModal}
        isDismissible={true}
        isVisible={isSignUpFormOpen}
        onClose={_onSignUpFormToggle}>
        {signUpForm()}
      </ModalView>
      <div className={styles.navWrapper}>
        <Sticky
          className={styles.nav}
          scrollElement={'#scrollDiv'}
          stickyClassName={styles.isSticky}>
          <div className={styles.inner}>
            <div className={styles.logo} onClick={() => history.push('/')} />
            <div className={styles.loginSignup}>
              <NavLink
                activeClassName={styles.selected}
                className={styles.button}
                to="/blog/manifesto">
                Manifesto
              </NavLink>
              <NavLink
                activeClassName={styles.selected}
                className={styles.button}
                exact
                to="/blog">
                Blog
              </NavLink>
              <NavLink
                activeClassName={styles.selected}
                className={styles.button}
                exact
                to="/pricing">
                Pricing
              </NavLink>
              {isBrowser && <div className={styles.divider} />}
              <div className={styles.button} onClick={_onSignUpFormToggle}>
                {isBrowser ? 'Log in / Sign up' : 'Sign up'}
              </div>
            </div>
          </div>
        </Sticky>
      </div>
    </>
  );
};
