import React from 'react';

import { ReportCardStats } from '../../../components/review/ReportCardStats';
import { INVITE_STATUS_OPTIONS, REVIEW_VIEWS } from '../../../misc/constants';
import {
  EXAMPLE_DATE_SELECTION,
  EXAMPLE_REPORTS,
  EXAMPLE_SETTINGS_LABELS,
  EXAMPLE_USER
} from '../../../misc/test-data';

import styles from '../../../styles/marketing/report-card-stats-preview.module.scss';

export const ReportCardStatsPreview: React.ComponentType = React.memo(() => {
  return (
    <div className={styles.reportCardStatsPreview}>
      <ReportCardStats
        dateSelection={EXAMPLE_DATE_SELECTION}
        filterInfo={{
          dateSelection: EXAMPLE_DATE_SELECTION,
          selectedView: REVIEW_VIEWS.You,
          statusFilterData: [INVITE_STATUS_OPTIONS.All]
        }}
        isForMarketing={true}
        reports={EXAMPLE_REPORTS}
        selectedView={REVIEW_VIEWS.You}
        settingsLabels={EXAMPLE_SETTINGS_LABELS}
        user={EXAMPLE_USER}
      />
    </div>
  );
});
