/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isEmpty } from 'lodash';
import { readEndpoint } from 'redux-json-api';

import { log } from '../../misc/utils';

export const metricsEvaluate = data => {
  const {
    which,
    who,
    start,
    end,
    shouldGetTrends = false,
    filter,
    byAttendeeStatus = [],
    misc
  } = data;
  log(`metricsEvaluate(${who}: ${which})`);

  // Build the 'by_period' query parameter
  const byPeriod =
    start && end
      ? `?by_period[starts_at]=${start}&by_period[ends_at]=${end}`
      : '';

  // Build the 'with_trends' query parameter
  const withTrends = shouldGetTrends ? '&with_trends=true' : '';

  // Build the 'by_attendee_status' query parameter
  const attendeeStatus = !isEmpty(byAttendeeStatus)
    ? `&by_attendee_status=${byAttendeeStatus}`
    : '';

  // Build the 'with_category/with_intention/by_organizer' query parameter
  const miscFilter = misc ? misc : '';

  // Build the 'with_category/with_intention/by_organizer' query parameter
  const catIntenOrgFilter = filter ? filter : '';

  // NOTE: with_category/intention/by_organizer must always be the last query param so Redux doesn't break

  // Make the call
  return dispatch => {
    dispatch(
      readEndpoint(
        `reporting/${who}/evaluations/${which}${byPeriod}${withTrends}${attendeeStatus}${miscFilter}${catIntenOrgFilter}`
      )
    );
  };
};
