/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import FrontMatter from 'front-matter';
import { isEmpty, sortBy } from 'lodash';

import { SEO } from '../../components/misc/SEO';

import { BlogList } from './blog-list';
import { BlogPost } from './blog-post';

import styles from '../../styles/marketing/misc.module.scss';

const importAll = r => r.keys().map(r);
const markdownFiles = importAll(
  require.context(`../../markdown/blog-posts`, true, /\.md$/)
);

export const BlogView = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const { page } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const posts = await Promise.all(
        markdownFiles.map(file => fetch(file.default).then(res => res.text()))
      ).catch(err => console.error(err));

      // Sort posts
      const sortedPosts = sortBy(posts, function (post) {
        const fm = FrontMatter(post);
        return new Date(fm.attributes.date);
      }).reverse();
      setBlogPosts(sortedPosts);
    };
    fetchData();
  }, []);

  return (
    <>
      <SEO title="Blog" />
      <div
        className={classNames(
          styles.subpageView,
          styles.runningText,
          styles.blogPost
        )}>
        <div className={styles.inner}>
          {isEmpty(page) ? (
            <BlogList posts={blogPosts} />
          ) : (
            <BlogPost posts={blogPosts} />
          )}
        </div>
      </div>
    </>
  );
};
