import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Emoji from 'react-emoji-render';
import { Portal } from 'react-portal';
import { useLocation } from 'react-router-dom';
import { forOwn } from 'lodash';

import { URLS } from '../../misc/consts';
import { Tooltip } from '../design-system';

import styles from '../../styles/who-can-see-this.module.scss';

interface ViewProps {
  desc: string;
  exact: boolean;
  path: string;
}

interface VisibilityProps {
  emoji: string;
  prefix: string;
  text: string;
  views: ViewProps[];
}

const VISIBILITY: Record<string, VisibilityProps> = {
  OnlyYou: {
    emoji: ':person_in_lotus_position:',
    text: 'Only you',
    prefix: 'Only you can see',
    views: [
      {
        path: `${URLS.Onboarding.Default}goals`,
        exact: false,
        desc: 'what goals you choose.'
      },
      {
        path: URLS.Review.Default,
        exact: true,
        desc: 'your Review stats.'
      },
      {
        path: URLS.Review.You,
        exact: false,
        desc: 'your Review stats.'
      },
      {
        path: URLS.Plan.Default,
        exact: true,
        desc: 'your meetings.'
      },
      {
        path: URLS.Plan.ViewBlock,
        exact: true,
        desc: 'your wellness blocks.'
      },
      {
        path: URLS.Plan.NewBlock,
        exact: true,
        desc: 'your wellness blocks.'
      },
      {
        path: URLS.Balance.Default,
        exact: true,
        desc: 'your Auto-balance preferences.'
      },
      {
        path: URLS.Balance.Action,
        exact: true,
        desc: 'your Auto-balance preferences.'
      },
      {
        path: URLS.Balance.WellnessBlocks,
        exact: false,
        desc: 'your wellness blocks.'
      },
      {
        path: URLS.Account.Default,
        exact: true,
        desc: 'your account info.'
      }
    ]
  },
  YourTeam: {
    emoji: ':family_woman_woman_girl_boy:',
    text: 'Your team',
    prefix: 'Everyone on your team can see',
    views: [
      {
        path: URLS.Balance.Evaluation,
        exact: true,
        desc: `the Teams' evaluation preferences.`
      },
      {
        path: URLS.Review.Team,
        exact: false,
        desc: `the Teams' stats.`
      }
    ]
  },
  ThoseInvited: {
    emoji: ':dancers:',
    text: 'Those invited',
    prefix: 'Only those invited can see',
    views: [
      { path: URLS.Plan.Meeting, exact: false, desc: 'this meeting detail.' }
    ]
  },
  OnlyAdmins: {
    emoji: ':robot_face:',
    text: 'Only admins',
    prefix: 'Only admins can see',
    views: [
      {
        path: URLS.Account.TeamMgmt,
        exact: true,
        desc: 'the team management info.'
      }
    ]
  }
};

export const WhoCanSeeThis: React.ComponentType = React.memo(() => {
  const location = useLocation();

  const [thisVisibility, setThisVisibility] = useState<
    VisibilityProps | undefined
  >(undefined);
  const [thisView, setThisView] = useState<ViewProps | undefined>(undefined);

  useEffect(() => {
    const path = location.pathname + location.search;
    let newVisibility = undefined;
    let newView = undefined;
    forOwn(VISIBILITY, obj => {
      // Check out views
      for (let i = 0; i < obj.views.length; i++) {
        const thisView = obj.views[i];
        if (
          thisView.exact &&
          (path === thisView.path || path === `${thisView.path}/`)
        ) {
          newVisibility = obj;
          newView = thisView;
          break;
        } else if (!thisView.exact && path.includes(thisView.path)) {
          newVisibility = obj;
          newView = thisView;
          break;
        }
      }
    });
    setThisVisibility(newVisibility);
    setThisView(newView);
  }, [location]);

  if (!thisVisibility || !thisView || isMobile) {
    return null;
  }

  return (
    <Portal>
      <Tooltip content={`${thisVisibility.prefix} ${thisView.desc}`}>
        <div className={styles.wcstFloater}>
          <div className={styles.label}>Who can see this?</div>
          <div>
            <Emoji text={`${thisVisibility.emoji} ${thisVisibility.text}`} />
          </div>
        </div>
      </Tooltip>
    </Portal>
  );
});
