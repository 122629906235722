import React from 'react';
import TagsInput from 'react-tagsinput';
import classNames from 'classnames';

import { Tooltip } from '.';

import 'react-tagsinput/react-tagsinput.css';
import styles from '../../styles/design-system.module.scss';

export interface MultiTagInputProps {
  addOnBlur: boolean;
  addOnPaste: boolean;
  disabledTagTooltip?: string;
  inputProps: TagsInput.InputProps;
  inputValue?: string;
  isDisabled?: boolean;
  onChange: (data: string[]) => void;
  onChangeInput?: (value: string) => void;
  reference: React.RefObject<TagsInput>;
  tagsToDisable?: string[];
  value: string[];
}

export const MultiTagInput: React.ComponentType<MultiTagInputProps> =
  React.memo(
    ({
      addOnBlur,
      addOnPaste,
      disabledTagTooltip,
      inputProps,
      inputValue,
      isDisabled = false,
      onChange,
      onChangeInput,
      reference,
      tagsToDisable,
      value
    }) => {
      return (
        <TagsInput
          ref={reference}
          addOnBlur={addOnBlur}
          addOnPaste={addOnPaste}
          className={classNames(styles.multiTagInput, {
            [styles.disabled]: isDisabled
          })}
          inputProps={inputProps}
          inputValue={inputValue}
          onlyUnique={true}
          renderTag={({
            tag,
            key,
            disabled,
            onRemove,
            classNameRemove,
            getTagDisplayValue,
            ...other
          }) => {
            const isTagDisabled = tagsToDisable
              ? tagsToDisable.includes(tag)
              : false;

            if (isTagDisabled) {
              return (
                <Tooltip key={key} content={disabledTagTooltip}>
                  <span is-disabled="true" {...other}>
                    {getTagDisplayValue(tag)}
                  </span>
                </Tooltip>
              );
            }

            return (
              <span key={key} {...other}>
                {getTagDisplayValue(tag)}
                {!disabled && (
                  <div
                    className={classNameRemove}
                    onClick={() => onRemove(key)}
                  />
                )}
              </span>
            );
          }}
          value={value}
          onChange={onChange}
          onChangeInput={onChangeInput}
        />
      );
    }
  );
