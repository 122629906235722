import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';

import { usePrevious } from '../../hooks/previous';
import { CalendarSource, RootState } from '../../misc/types';
import { getCalendarSource } from '../../misc/utilities';
import * as API from '../../modules/api';
import { getCalendars } from '../../modules/calendars';
import { connectCalendar, disconnectCalendar } from '../../modules/meetings';

import { CalendarListItem } from './CalendarListItem';

import styles from '../../styles/calendar-list.module.scss';

interface CalendarListProps {
  className?: string;
  onClickAddCalendar?: () => void;
}

export const CalendarList: React.ComponentType<CalendarListProps> = React.memo(
  ({ className, onClickAddCalendar }) => {
    const dispatch = useDispatch();

    // Get from state
    const user = useSelector((state: RootState) => state.user.general);
    const calendars = useSelector((state: RootState) => state.calendars.list);
    const refreshes = useSelector(
      (state: RootState) => state.messages.refreshes
    );
    const prevRefreshes = usePrevious(refreshes);

    const calendarSource: CalendarSource = useMemo(
      () => getCalendarSource(user),
      [user]
    );

    const makeAPICalls = useCallback(
      (forceRefresh?: boolean) => {
        if (calendars.length > 0 && !forceRefresh) return;

        // Make API call if there are no cals
        API.makeRequest(() => {
          dispatch(getCalendars(true));
        });
      },
      [dispatch, calendars]
    );

    const _handleConnectCalendar = useCallback(
      (cal, callback) => {
        // Maybe trigger pass in pop
        if (onClickAddCalendar) {
          onClickAddCalendar();
        }

        // Connect via API
        dispatch(connectCalendar(calendarSource.url, cal, callback));
      },
      [calendarSource, dispatch, onClickAddCalendar]
    );

    const _handleDisconnectCalendar = useCallback(
      (cal, callback) => {
        // Connect via API
        dispatch(disconnectCalendar(calendarSource.url, cal, callback));
      },
      [calendarSource, dispatch]
    );

    // Initial load
    useEffect(() => {
      makeAPICalls(true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Check for websocket update
    useEffect(() => {
      if (
        refreshes &&
        refreshes.length > 0 &&
        !isEqual(refreshes, prevRefreshes)
      ) {
        makeAPICalls(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshes]);

    if (isEmpty(calendars)) {
      return (
        <div className={styles.calendarListLoading}>Loading calendars...</div>
      );
    }

    return (
      <div className={classNames(styles.calendarList, className)}>
        <ul>
          {calendars.map((item, key) => (
            <CalendarListItem
              key={key}
              item={item}
              onConnect={_handleConnectCalendar}
              onDisconnect={_handleDisconnectCalendar}
            />
          ))}
        </ul>
      </div>
    );
  }
);
