import React from 'react';
import classNames from 'classnames';
import { filter, find, get, isEmpty } from 'lodash';

import { COLORS, INVITE_STATUS_OPTIONS } from '../../misc/constants';
import { Attendee, InviteStatus, Meeting, User } from '../../misc/types';
import { capitalizeFirstLetter, maybePluralize } from '../../misc/utils';
import { Icon, Tag, Tooltip } from '../design-system';

import styles from '../../styles/meeting-modal.module.scss';

interface SectionAttendeesProps {
  className?: string;
  event: Meeting;
  user: User;
}

export const SectionAttendees: React.ComponentType<SectionAttendeesProps> = React.memo(
  ({ className, event, user }) => {
    const count = event.attributes.attendees_count;
    if (!count) return null;

    const organizer = filter(event.attributes.attendees, o => {
      return o.role === 'organizer' && o.email !== user.attributes.email;
    });

    const thisUser = filter(event.attributes.attendees, o => {
      return o.email === user.attributes.email;
    });

    const everyoneElse = filter(event.attributes.attendees, o => {
      return o.role !== 'organizer' && o.email !== user.attributes.email;
    });

    const attendees = [...organizer, ...thisUser, ...everyoneElse];

    const renderAttendee = (item: Attendee, index: number) => {
      // If user
      let maybeInviteStatusTag = null;
      let maybeYouMarker = null;
      if (item.email === user.attributes.email) {
        const inviteStatus = find(INVITE_STATUS_OPTIONS, function (o) {
          return o.value === event.attributes.status;
        }) as InviteStatus;

        if (!isEmpty(inviteStatus)) {
          maybeInviteStatusTag = (
            <div className={styles.inviteStatusTag}>
              <Tooltip
                content={`You are marked as "${inviteStatus.label}" for this meeting`}>
                <Tag
                  className={styles.inviteStatus}
                  intent={inviteStatus.intent}
                  small>
                  <Icon
                    color={get(COLORS, inviteStatus.intent)}
                    which={inviteStatus.icon}
                  />
                </Tag>
              </Tooltip>
            </div>
          );
        }

        maybeYouMarker = <span className={styles.youMarker}>(you)</span>;
      }

      // If anyone else
      return (
        <div key={index} className={styles.attendee} title={item.email}>
          {maybeInviteStatusTag}
          <span className={styles.email}>{item.email}</span>
          {maybeYouMarker}
          {item.role && (
            <span className={styles.role}>
              {capitalizeFirstLetter(item.role)}
            </span>
          )}
          {item.optional && <span className={styles.optional}>Optional</span>}
        </div>
      );
    };

    return (
      <div className={classNames(styles.section, className)}>
        <div className={classNames(styles.item, styles.sectionAttendees)}>
          <Icon className={styles.icon} which="people" />
          <div className={styles.attendeeInfo}>
            <div className={styles.text}>
              {count} {maybePluralize(count, 'person', 'people')}
            </div>
            <div className={styles.attendeeList}>
              {count > 5 && <div className={styles.gradient} />}
              <div className={styles.inner}>
                {attendees.map((item, index) => renderAttendee(item, index))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
