import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { cloneDeep, get, isEmpty, isEqual } from 'lodash';

import { PREFERENCES } from '../../misc/constants';
import { IGNORE_VAR, SETTINGS_BUCKETS } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { Operator, Settings, SettingsLabels } from '../../misc/types';
import { renderPlanTag } from '../../misc/utilities';
import { capitalizeFirstLetter } from '../../misc/utils';
import { getSettingsLabels } from '../../modules/settings-labels';
import { updateUserSettings } from '../../modules/user';
import {
  Alert,
  EditingPopover,
  Icon,
  Tag,
  TextLink,
  Tooltip
} from '../design-system';
import { SegmentedControlData } from '../design-system/SegmentedControl';

import { HelpTooltip } from './HelpTooltip';

import popoverStyles from '../../styles/popover.module.scss';
import styles from '../../styles/preference-row.module.scss';

const ALLOW_BLOCK_LABELS = [
  {
    label: renderPlanTag({ value: 'allow', size: 'small' }),
    value: 'allow',
    isSelected: false,
    isRecommended: false
  },
  {
    label: renderPlanTag({ value: 'block', size: 'small' }),
    value: 'block',
    isSelected: false,
    isRecommended: false
  }
];

const TRUE_FALSE_LABELS = [
  {
    label: renderPlanTag({ value: 'true', size: 'small' }),
    value: 'true',
    isSelected: false,
    isRecommended: false
  },
  {
    label: renderPlanTag({ value: 'false', size: 'small' }),
    value: 'false',
    isSelected: false,
    isRecommended: false
  }
];

export const OPTIONS = {
  desired_duration: [
    { label: '15', value: '15', isSelected: false, isRecommended: false },
    { label: '30', value: '30', isSelected: false, isRecommended: false },
    { label: '45', value: '45', isSelected: false, isRecommended: false },
    { label: '60', value: '60', isSelected: false, isRecommended: false },
    { label: '75', value: '75', isSelected: false, isRecommended: false },
    { label: '90', value: '90', isSelected: false, isRecommended: false },
    { label: '120', value: '120', isSelected: false, isRecommended: false }
  ],
  focus_block_conflict: ALLOW_BLOCK_LABELS,
  required_conference_info: TRUE_FALSE_LABELS,
  meeting_conflict: ALLOW_BLOCK_LABELS,
  last_minute_schedule: [
    { label: '6', value: '6', isSelected: false, isRecommended: false },
    { label: '12', value: '12', isSelected: false, isRecommended: false },
    { label: '24', value: '24', isSelected: false, isRecommended: false },
    { label: '36', value: '36', isSelected: false, isRecommended: false },
    { label: '48', value: '48', isSelected: false, isRecommended: false },
    { label: '60', value: '60', isSelected: false, isRecommended: false },
    { label: '72', value: '72', isSelected: false, isRecommended: false },
    { label: '84', value: '84', isSelected: false, isRecommended: false }
  ],
  ignore_all_day_meetings: [
    {
      label: renderPlanTag({
        value: 'true',
        size: 'small',
        which: 'ignore_all_day_meetings'
      }),
      value: 'true',
      isSelected: false,
      isRecommended: false
    },
    {
      label: renderPlanTag({
        value: 'false',
        size: 'small',
        which: 'ignore_all_day_meetings'
      }),
      value: 'false',
      isSelected: false,
      isRecommended: false
    }
  ],
  ignore_all_hands_meetings: [
    {
      label: renderPlanTag({
        value: 'true',
        size: 'small',
        which: 'ignore_all_hands_meetings'
      }),
      value: 'true',
      isSelected: false,
      isRecommended: false
    },
    {
      label: renderPlanTag({
        value: 'false',
        size: 'small',
        which: 'ignore_all_hands_meetings'
      }),
      value: 'false',
      isSelected: false,
      isRecommended: false
    }
  ],
  required_agenda: TRUE_FALSE_LABELS,
  required_objectives: TRUE_FALSE_LABELS,
  required_intention: TRUE_FALSE_LABELS,
  required_prework: TRUE_FALSE_LABELS,
  required_outcomes: TRUE_FALSE_LABELS,
  required_attendee_roles: TRUE_FALSE_LABELS,
  max_attendees: [
    {
      label: 'Ignore',
      value: IGNORE_VAR,
      isSelected: false,
      isRecommended: false
    },
    { label: '2', value: '2', isSelected: false, isRecommended: false },
    { label: '4', value: '4', isSelected: false, isRecommended: false },
    { label: '6', value: '6', isSelected: false, isRecommended: false },
    { label: '8', value: '8', isSelected: false, isRecommended: false },
    { label: '10', value: '10', isSelected: false, isRecommended: false },
    { label: '12', value: '12', isSelected: false, isRecommended: false },
    { label: '15', value: '15', isSelected: false, isRecommended: false },
    { label: '20', value: '20', isSelected: false, isRecommended: false }
  ]
};

interface PreferenceRowProps {
  bucket: keyof typeof SETTINGS_BUCKETS;
  canEdit?: boolean;
  example?: string;
  operator?: Operator;
  settings: Settings;
  settings_labels: SettingsLabels;
  showExtras?: boolean;
  statType?: string;
  type?: 'default' | 'stat';
  which: typeof PREFERENCES[number];
}

export const PreferenceRow: React.ComponentType<PreferenceRowProps> = React.memo(
  function PreferenceRow({
    settings,
    bucket,
    which,
    type = 'default',
    example,
    settings_labels,
    showExtras = true,
    operator,
    statType,
    canEdit = true
  }) {
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = useState(false);
    const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);
    const [
      isShowingContactAdminAlert,
      setIsShowingContactAdminAlert
    ] = useState(false);

    const [existingValue, setExistingValue] = useState<string | number>('');
    const [stagedValue, setStagedValue] = useState<string | number>('');
    const [segData, setSegData] = useState<SegmentedControlData[]>([]);

    useEffect(() => {
      // Reset isVars
      setIsEditing(false);
      setIsWaitingOnAPI(false);

      let existingValue = get(settings, `attributes.${bucket}.${which}`);
      if (existingValue === null) existingValue = IGNORE_VAR;
      let defaultValue = get(settings, `attributes.default_${bucket}.${which}`);
      if (defaultValue === null) defaultValue = IGNORE_VAR;

      // Build Seg Data
      const segData = cloneDeep(OPTIONS[which]);
      if (segData) {
        for (let i = 0; i < segData.length; i++) {
          let segDataValue = segData[i].value;
          if (segDataValue === null) segDataValue = IGNORE_VAR;

          // Get selected
          if (segDataValue.toString() === existingValue.toString()) {
            segData[i].isSelected = true;
          } else {
            segData[i].isSelected = false;
          }

          // Get recommended (default)
          if (segDataValue.toString() === defaultValue.toString()) {
            segData[i].isRecommended = true;
          } else {
            segData[i].isRecommended = false;
          }
        }
      }

      setSegData(segData);
      setExistingValue(existingValue);
      setStagedValue(existingValue);
    }, [bucket, settings, which]);

    const renderValue = () => {
      const data = settings_labels[which];
      const isComingSoon = data.coming_soon || false;

      const renderTag = () => {
        if (isComingSoon) {
          return (
            <Tag minimal small>
              Coming soon
            </Tag>
          );
        }

        return renderPlanTag({ value: existingValue, size: 'small', which });
      };

      const tooltipContent = operator
        ? `${operator.label} ${existingValue} ${statType}`
        : '';

      const weight =
        data.weight && !Number.isNaN(data.weight)
          ? Math.round(data.weight * 100)
          : undefined;

      return (
        <div className={styles.value}>
          <Tooltip
            className={styles.choice}
            content={tooltipContent}
            isDisabled={isEmpty(operator)}>
            {showExtras && maybeRenderDelta()}
            {type === 'stat' && existingValue !== IGNORE_VAR ? (
              <>
                <div className={styles.num}>
                  {operator && <span>{operator.symbol}</span>}
                  {existingValue}
                </div>
                {statType && <div className={styles.type}>{statType}</div>}
              </>
            ) : (
              renderTag()
            )}
          </Tooltip>
          {!isComingSoon && (
            <>
              {/* Only show weight if is evaluation bucket */}
              {bucket === SETTINGS_BUCKETS.Evaluation &&
                weight !== undefined &&
                !Number.isNaN(weight) && (
                  <div
                    className={classNames(styles.weight, {
                      [styles.none]: weight === 0
                    })}>
                    {weight}
                    <span>/100</span>
                  </div>
                )}
              {renderEditButton()}
            </>
          )}
        </div>
      );
    };

    const maybeRenderDelta = () => {
      // Get default value
      let defaultValue = get(settings, `attributes.default_${bucket}.${which}`);
      if (defaultValue === null) defaultValue = IGNORE_VAR;

      // Check if there's a user chosen value
      if (isEqual(existingValue, defaultValue)) return null;

      // Show delta
      const parsedValue = (value: string) => {
        value = value.toString();
        if (value === 'true') return 'Required';
        if (value === 'false') return 'Not required';
        return capitalizeFirstLetter(value);
      };
      const tooltipText = `You changed the default from "${parsedValue(
        defaultValue
      )}" to "${parsedValue(existingValue.toString())}".`;

      return (
        <div className={styles.delta}>
          <Tooltip content={tooltipText}>
            {type === 'stat'
              ? defaultValue
              : renderPlanTag({ value: defaultValue, size: 'small', which })}
          </Tooltip>
        </div>
      );
    };

    const renderEditButton = () => {
      // Disabled button?
      const isSaveButtonDisabled = () => {
        let existingVal = existingValue;
        let stagedVal = stagedValue;
        if (existingVal === null) existingVal = IGNORE_VAR;
        if (stagedVal === null) stagedVal = IGNORE_VAR;
        return isEqual(existingVal.toString(), stagedVal.toString());
      };

      return (
        <EditingPopover
          className={styles.editWrapper}
          innerClassName={popoverStyles.editingPlanSetting}
          isLoading={isWaitingOnAPI}
          isSaveButtonDisabled={isSaveButtonDisabled()}
          isVisible={isEditing}
          segData={segData}
          segProps={{
            leftLabel: operator ? operator.symbol : undefined,
            rightLabel: statType || undefined,
            tag: {
              text:
                bucket === SETTINGS_BUCKETS.Action ? 'Default' : 'Recommended',
              check: 'isRecommended'
            }
          }}
          onCancel={_toggleEditMode}
          onSave={_save}
          onSelectValue={_selectValue}
          onToggle={_toggleEditMode}>
          <TextLink
            isDisabled={isEditing}
            text="Edit"
            onClick={_toggleEditMode}
          />
        </EditingPopover>
      );
    };

    const renderSubRoles = () => {
      return (
        <div className={styles.subRoles}>
          {renderSubRole('required_role_leader')}
          {renderSubRole('required_role_time_keeper')}
          {renderSubRole('required_role_recorder')}
        </div>
      );
    };

    const renderSubRole = (setting: string) => {
      const data = settings_labels[setting];
      const isComingSoon = data.description.includes('||');
      const renderTag = (
        <Tag circle className={styles.status}>
          <Icon which="cross" />
        </Tag>
      );

      return (
        <div className={styles.subRole}>
          {renderTag}
          <div className={styles.info}>
            <div className={styles.title}>{data.label}</div>
            <div className={styles.description}>
              {!isComingSoon
                ? data.description
                : data.description.split('||')[1]}
            </div>
          </div>
        </div>
      );
    };

    const _toggleEditMode = useCallback(() => {
      if (canEdit) {
        setIsEditing(prevState => !prevState);
      } else {
        // On Team plan, but not an admin
        setIsShowingContactAdminAlert(prevState => !prevState);
      }
    }, [canEdit]);

    const _selectValue = useCallback(
      which => {
        const tempData = [...segData];
        for (let i = 0; i < tempData.length; i++) {
          if (i === which) {
            tempData[i].isSelected = true;
          } else {
            tempData[i].isSelected = false;
          }
        }

        setSegData(tempData);
        setStagedValue(tempData[which].value);
      },
      [segData]
    );

    const _save = async () => {
      // Show spinner
      setIsWaitingOnAPI(true);

      // Post to API
      dispatch(
        updateUserSettings({
          which: bucket,
          value: {
            [which]: stagedValue
          },
          callback: () => dispatch(getSettingsLabels())
        })
      );

      // Track goal
      if (bucket === SETTINGS_BUCKETS.Action) {
        Fathom.trackGoal(GOALS.Balance.ModifiedActionSetting);
      } else if (bucket === SETTINGS_BUCKETS.Evaluation) {
        Fathom.trackGoal(GOALS.Balance.ModifiedEvaluationSetting);
      }
    };

    const data = settings_labels[which];
    if (isEmpty(data)) return null;

    return (
      <>
        <Alert
          isVisible={isShowingContactAdminAlert}
          text="Editing settings is only available for MeetWell administrators. Please contact your billing admin to request changes."
          title="🔒 Locked! You cannot make changes."
          onConfirm={_toggleEditMode}
        />
        <div className={styles.row} data-type={type}>
          <div className={styles.main}>
            <div className={styles.content}>
              <div className={styles.top}>
                <div className={styles.name}>{data.label}</div>
                <HelpTooltip className={styles.help} setting={which} />
              </div>
              {showExtras && (
                <div className={styles.description}>{data.description}</div>
              )}
              {example && <div className={styles.example}>{example}</div>}
            </div>
            {renderValue()}
          </div>
          {which === 'required_attendee_roles' && renderSubRoles()}
        </div>
      </>
    );
  }
);
