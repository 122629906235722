import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { isMobile } from 'react-device-detect';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { useWindowResize } from '../../hooks/window-resize';

import styles from '../../styles/popover.module.scss';
import fadeTransition from '../../styles/transitions/modal-fade.module.scss';

// For typing reasons, will use a number throughout until the render
const AUTO_NUM = 99999999999;

export interface PopoverProps {
  children: ReactNode;
  className?: string;
  content: ReactNode;
  isVisible: boolean;
  onRefreshPosition?: number;
  onToggle: () => void;
  position?: 'left' | 'right';
}

export const Popover: React.ComponentType<PopoverProps> = React.memo(
  function Popover({
    isVisible,
    children,
    content,
    className,
    position = 'left',
    onToggle,
    onRefreshPosition
  }) {
    const windowDimensions = useWindowResize();

    const wrapperRef = useRef<HTMLDivElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    // State
    // const affix = random(0, 99999999)
    const [popoverBound, setPopoverBound] = useState({
      leftPos: 0,
      topPos: 0,
      rightPos: AUTO_NUM,
      width: AUTO_NUM,
      height: AUTO_NUM
    });

    // Actions
    const _handleClickOutside = useCallback(
      event => {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
          onToggle();
        }
      },
      [onToggle]
    );

    const _setPositioning = useCallback(() => {
      // See if exists
      if (wrapperRef.current && popoverRef.current) {
        const refRect = wrapperRef.current.getBoundingClientRect();
        const bodyRect = document.body.getBoundingClientRect();
        const popoverRect = popoverRef.current.getBoundingClientRect();

        // Build measurements (left is position:left)
        let leftPos = refRect.x;
        let topPos = refRect.y + refRect.height - 1; // The "1" is for the box-shadow
        let rightPos = popoverBound.rightPos;
        const width = popoverBound.width;
        let height = popoverBound.height;
        const heightToMeasure = isMobile
          ? bodyRect.height - 80
          : bodyRect.height;

        // Position right
        if (position === 'right') {
          leftPos = AUTO_NUM;
          rightPos = bodyRect.width - (refRect.x + refRect.width);
        }

        // If is too far to the right, move to the left
        if (leftPos !== AUTO_NUM) {
          if (leftPos + popoverRect.width > bodyRect.width) {
            leftPos = leftPos - popoverRect.width + refRect.width;
          }
        }

        // If is too far to the bottom, move to the top
        if (topPos + popoverRect.height > heightToMeasure) {
          topPos = topPos - popoverRect.height;
        }

        // If too far to the top, move back to bottom, and set a height
        if (topPos <= 0) {
          topPos = refRect.y + refRect.height;
          height = heightToMeasure - topPos - 60; // The "60" is for padding from the bottom
        }

        // Maybe set state
        if (
          !isEqual(leftPos, popoverBound.leftPos) ||
          !isEqual(topPos, popoverBound.topPos) ||
          !isEqual(rightPos, popoverBound.rightPos) ||
          !isEqual(width, popoverBound.width) ||
          !isEqual(height, popoverBound.height)
        ) {
          setPopoverBound({ leftPos, topPos, rightPos, width, height });
        }
      }
    }, [popoverBound, position]);

    // Set the positioning
    useEffect(() => {
      _setPositioning();
    }, [_setPositioning, isVisible, windowDimensions]);

    // Check if maybe should update
    useEffect(() => {
      _setPositioning();
    }, [_setPositioning, onRefreshPosition]);

    const getRenderedValue = useCallback(value => {
      if (value === AUTO_NUM) {
        return 'auto';
      }
      return value;
    }, []);

    return (
      <span
        ref={wrapperRef}
        className={classNames(styles.popoverWrapper, className)}>
        {children}
        <CSSTransition
          appear
          classNames={fadeTransition}
          in={isVisible}
          timeout={200}
          unmountOnExit>
          <Portal>
            <div className={styles.popoverPortal} onClick={_handleClickOutside}>
              <div
                ref={popoverRef}
                className={styles.popover}
                style={{
                  left: getRenderedValue(popoverBound.leftPos),
                  top: getRenderedValue(popoverBound.topPos),
                  right: getRenderedValue(popoverBound.rightPos),
                  width: getRenderedValue(popoverBound.width),
                  height: getRenderedValue(popoverBound.height)
                }}>
                {content}
              </div>
            </div>
          </Portal>
        </CSSTransition>
      </span>
    );
  }
);
