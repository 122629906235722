import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import { INTENT } from '../../misc/consts';

import { Button } from '.';

import styles from '../../styles/alert.module.scss';
import fadeTransition from '../../styles/transitions/modal-fade.module.scss';

const TIMEOUT = 200;

export interface AlertProps {
  cancelText?: string;
  className?: string;
  confirmText?: string;
  content?: ReactNode;
  intent?: string;
  isConfirmButtonDisabled?: boolean;
  isDismissible?: boolean;
  isLoading?: boolean;
  isVisible: boolean;
  onCancel?: (text?: string) => void;
  onConfirm?: (text?: string) => void;
  shouldHideConfirm?: boolean;
  shouldShowCancel?: boolean;
  text?: string | ReactNode;
  title?: string | ReactNode;
}

export const Alert: React.ComponentType<AlertProps> = React.memo(
  function Alert({
    isVisible = false,
    isDismissible = true,
    shouldHideConfirm = false,
    title,
    text,
    className,
    content,
    confirmText,
    cancelText,
    intent,
    onCancel,
    onConfirm,
    shouldShowCancel,
    isConfirmButtonDisabled = false,
    isLoading = false
  }) {
    const [isThisVisible, setIsThisVisible] = useState(false);

    useEffect(() => {
      setIsThisVisible(isVisible);
    }, [isVisible]);

    const _handleClickCancel = useCallback(() => {
      setIsThisVisible(false);
      setTimeout(() => {
        onCancel && onCancel();
      }, TIMEOUT);
    }, [onCancel]);

    const _handleClickConfirm = useCallback(() => {
      setIsThisVisible(false);
      setTimeout(() => {
        onConfirm && onConfirm();
      }, TIMEOUT);
    }, [onConfirm]);

    const _handleKeyUp = useCallback(
      event => {
        if (event.key === 'Escape') {
          _handleClickCancel();
        }
      },
      [_handleClickCancel]
    );

    const bkgClick = useMemo(
      () => (onCancel ? _handleClickCancel : _handleClickConfirm),
      [_handleClickCancel, _handleClickConfirm, onCancel]
    );

    // ESC keys
    if (isVisible && isDismissible) {
      document.addEventListener('keyup', _handleKeyUp);
    } else {
      document.removeEventListener('keyup', _handleKeyUp);
    }

    return (
      <Portal>
        <CSSTransition
          appear
          classNames={fadeTransition}
          in={isThisVisible}
          timeout={TIMEOUT}
          unmountOnExit>
          <div className={classNames(styles.alertWrapper, className)}>
            <div
              className={classNames(styles.alert, {
                [styles.visible]: isThisVisible
              })}>
              {title && <div className={styles.alertTitle}>{title}</div>}
              {text && <div className={styles.alertText}>{text}</div>}
              {content && <div className={styles.content}>{content}</div>}
              {shouldShowCancel || !shouldHideConfirm ? (
                <div className={styles.actions}>
                  {shouldShowCancel && (
                    <Button
                      centered
                      fill
                      text={cancelText || 'Cancel'}
                      onClick={_handleClickCancel}
                    />
                  )}
                  {!shouldHideConfirm && (
                    <Button
                      centered
                      disabled={isConfirmButtonDisabled}
                      fill
                      intent={intent || INTENT.Primary}
                      isLoading={isLoading}
                      shouldLookDisabled
                      text={confirmText || 'Okay'}
                      onClick={_handleClickConfirm}
                    />
                  )}
                </div>
              ) : null}
            </div>
            <div
              className={styles.bkg}
              onClick={isDismissible ? bkgClick : undefined}
            />
          </div>
        </CSSTransition>
      </Portal>
    );
  }
);
