import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { SOCIAL_LINKS, URLS } from '../../misc/consts';

import styles from '../../styles/footer.module.scss';

export const Footer: React.ComponentType = React.memo(() => {
  const location = useLocation();
  if (location.pathname.includes(URLS.Sub)) return null;

  return (
    <div
      className={classNames(styles.footer, {
        [styles.home]: location.pathname === '/'
      })}>
      <div className={styles.left}>
        <div className={styles.copyright}>
          © 2021 Rock n Roll Dance Party, LLC. All rights reserved.
        </div>
        <div className={styles.links}>
          <Link className={styles.link} to="/privacy">
            Privacy
          </Link>
          <Link className={styles.link} to="/security">
            Security
          </Link>
          <Link className={styles.link} to="/terms">
            Terms
          </Link>
          <Link className={styles.link} to="/help">
            Help
          </Link>
          <Link className={styles.link} to="/faq">
            FAQ
          </Link>
          <Link className={styles.link} to="/referrals">
            Referrals
          </Link>
          <a
            className={styles.link}
            href={SOCIAL_LINKS.Twitter}
            rel="noreferrer"
            target="_blank">
            Twitter
          </a>
          <a
            className={styles.link}
            href={SOCIAL_LINKS.LinkedIn}
            rel="noreferrer"
            target="_blank">
            LinkedIn
          </a>
        </div>
      </div>
      {/* <div className={styles.right}>
        <a
          className={styles.sqreen}
          href="https://www.sqreen.com/?utm_source=badge"
          rel="noopener noreferrer"
          target="_blank"
          title="Realtime application protection">
          Sqreen | Runtime Application Protection
        </a>
      </div> */}
    </div>
  );
});
