/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  createResource,
  deleteResource,
  readEndpoint,
  updateResource
} from 'redux-json-api';

import { WELLNESS_BLOCKS_ALIAS } from '../../misc/consts';
import { log } from '../../misc/utils';

export const getWellnessBlocks = data => {
  log('getWellnessBlocks()');

  // Build the 'by_period' query parameter
  let byPeriod = '';
  if (data) {
    const { start, end } = data;
    byPeriod =
      start && end
        ? `?by_period[starts_at]=${start}&by_period[ends_at]=${end}`
        : '';
  }

  return dispatch => {
    dispatch(readEndpoint(`${WELLNESS_BLOCKS_ALIAS}${byPeriod}`));
  };
};

export const createWellnessBlock = data => {
  log('createWellnessBlock()');
  const { toTime, fromTime, label, calendarId, rrule, category } = data;
  return dispatch => {
    const entity = {
      type: WELLNESS_BLOCKS_ALIAS,
      attributes: {
        label,
        category,
        calendar_id: calendarId,
        rrule,
        starts_at: fromTime,
        ends_at: toTime
      }
    };

    dispatch(createResource(entity));
  };
};

export const updateWellnessBlock = data => {
  log('updateWellnessBlock()');
  const { id, rrule, toTime, fromTime, label, calendarId, category } = data;
  return dispatch => {
    const entity = {
      type: `${WELLNESS_BLOCKS_ALIAS}/${id}#update`,
      attributes: {
        label,
        category,
        calendar_id: calendarId,
        rrule,
        starts_at: fromTime,
        ends_at: toTime
      }
    };

    dispatch(updateResource(entity));
  };
};

export const deleteWellnessBlock = data => {
  log('deleteWellnessBlock()');
  return dispatch => {
    dispatch(deleteResource(data));
  };
};
