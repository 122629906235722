import React, { useEffect } from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { DATE_FORMAT } from '../../misc/consts';
import { Invoice, RootState } from '../../misc/types';
import * as API from '../../modules/api';
import { getInvoices } from '../../modules/invoices';

import styles from '../../styles/invoices.module.scss';

export const Invoices: React.ComponentType = React.memo(() => {
  const dispatch = useDispatch();
  const invoiceList = useSelector((state: RootState) => state.invoices);

  const renderInvoice = (item: Invoice) => {
    const description = item.attributes.description;

    return (
      <div key={item.id} className={styles.row}>
        <div className={styles.date}>
          <Moment format={DATE_FORMAT.Short} unix>
            {item.attributes.period_start}
          </Moment>
        </div>
        <div className={styles.what}>{description}</div>
        <div className={styles.download}>
          {item.attributes.invoice_pdf && (
            <a href={item.attributes.invoice_pdf}>Download</a>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    API.makeRequest(() => {
      dispatch(getInvoices());
    });
  }, [dispatch]);

  if (!isEmpty(invoiceList)) {
    return (
      <div className={styles.invoices}>
        <div className={styles.header}>
          <span>Date</span>
          <span>What</span>
          <span />
        </div>
        {invoiceList.map(item => renderInvoice(item))}
      </div>
    );
  }

  return (
    <div className={classNames(styles.invoices, styles.empty)}>
      Your invoices will appear here.
    </div>
  );
});
