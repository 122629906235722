import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { findIndex } from 'lodash';
import moment from 'moment';

import { DATE_FORMAT } from '../../misc/consts';

import { Button, Icon, Popover } from '.';

import styles from '../../styles/design-system.module.scss';
import popoverStyles from '../../styles/popover.module.scss';

export interface TimeRangePickerProps {
  fromTime: string;
  isDisabled?: boolean;
  onChange: (
    thisFromTime: string,
    thisToTime: string,
    fromTimeMoment: moment.Moment,
    toTimeMoment: moment.Moment,
    isValid: boolean
  ) => void;
  toTime: string;
}

export const TimeRangePicker: React.ComponentType<TimeRangePickerProps> =
  React.memo(({ fromTime, toTime, onChange, isDisabled }) => {
    const [isFromTimePopoverOpen, setIsFromTimePopoverOpen] = useState(false);
    const [isToTimePopoverOpen, setIsToTimePopoverOpen] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [hasToTimeChanged, setHasToTimeChanged] = useState(false);

    const [thisFromTime, setThisFromTime] = useState(fromTime || '7:00 am');
    const [thisToTime, setThisToTime] = useState(toTime || '9:00 am');

    useEffect(() => {
      if (fromTime) {
        setThisFromTime(fromTime);
      }
      if (toTime) {
        setThisToTime(toTime);
      }
    }, [fromTime, toTime]);

    const times = useMemo(() => {
      // Build Times array
      const TIMES = [];
      const TIME_FORMAT = 'h:mm a';
      for (let hour = 0; hour < 24; hour++) {
        TIMES.push(moment({ hour }).format(TIME_FORMAT));
        TIMES.push(
          moment({
            hour,
            minute: 15
          }).format(TIME_FORMAT)
        );
        TIMES.push(
          moment({
            hour,
            minute: 30
          }).format(TIME_FORMAT)
        );
        TIMES.push(
          moment({
            hour,
            minute: 45
          }).format(TIME_FORMAT)
        );
      }

      return TIMES;
    }, []);

    const _toggleFromTimePopover = useCallback(() => {
      setIsFromTimePopoverOpen(prevState => !prevState);
    }, []);

    // If now open, scroll to
    useEffect(() => {
      if (isFromTimePopoverOpen) {
        const timeElement = document.getElementById(`fromTime-${thisFromTime}`);
        if (timeElement) {
          timeElement.scrollIntoView({
            block: 'center',
            behavior: 'auto'
          });
        }
      }
    }, [isFromTimePopoverOpen, thisFromTime]);

    const _toggleToTimePopover = useCallback(() => {
      setIsToTimePopoverOpen(prevState => !prevState);
      setHasToTimeChanged(true);
    }, []);

    // If now open, scroll to
    useEffect(() => {
      if (isToTimePopoverOpen) {
        const timeElement = document.getElementById(`toTime-${thisToTime}`);
        if (timeElement) {
          timeElement.scrollIntoView({
            block: 'center',
            behavior: 'auto'
          });
        }
      }
    }, [isToTimePopoverOpen, thisToTime]);

    const _onChange = useCallback(
      (fromTimeMoment, toTimeMoment) => {
        onChange(
          thisFromTime,
          thisToTime,
          fromTimeMoment,
          toTimeMoment,
          isValid
        );
      },
      [isValid, onChange, thisFromTime, thisToTime]
    );

    const _validateFields = useCallback(() => {
      const today = moment().format(DATE_FORMAT.Short);
      const fromTimeMoment = moment(today + ' ' + thisFromTime);
      const toTimeMoment = moment(today + ' ' + thisToTime);

      // Compare
      if (moment(fromTimeMoment).isSameOrAfter(toTimeMoment)) {
        setIsValid(false);
        _onChange(fromTimeMoment, toTimeMoment);
      } else {
        setIsValid(true);
        _onChange(fromTimeMoment, toTimeMoment);
      }
    }, [_onChange, thisFromTime, thisToTime]);

    const _handleSelect = useCallback(
      (which, item) => {
        if (which === 'fromTime') {
          // If user has yet to choose toTime, change to hour later
          if (!hasToTimeChanged) {
            const hourLaterIndex =
              findIndex(times, function (o) {
                return o === item;
              }) + 4;
            const hourLater = times[hourLaterIndex];
            setThisFromTime(item);
            setThisToTime(hourLater);
          } else {
            setThisFromTime(item);
          }
          _toggleFromTimePopover();
        } else {
          setThisToTime(item);
          _toggleToTimePopover();
        }
      },
      [_toggleFromTimePopover, _toggleToTimePopover, hasToTimeChanged, times]
    );

    const renderPopoverContent = (which: string) => {
      const valueToMatch = which === 'fromTime' ? thisFromTime : thisToTime;
      return (
        <div className={popoverStyles.timeRangePicker}>
          {times.map((item, index) => (
            <div
              key={index}
              className={popoverStyles.option}
              id={`${which}-${item}`}>
              <Button
                active={item === valueToMatch}
                fill={true}
                minimal={true}
                text={item}
                onClick={() => _handleSelect(which, item)}
              />
            </div>
          ))}
        </div>
      );
    };

    useEffect(() => {
      _validateFields();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thisFromTime, thisToTime]);

    return (
      <div
        className={classNames(styles.timeRangePicker, {
          [styles.disabled]: isDisabled
        })}>
        <div className={styles.input}>
          <Popover
            className={classNames(styles.picker, {
              [styles.invalid]: !isValid
            })}
            content={renderPopoverContent('fromTime')}
            isVisible={isFromTimePopoverOpen}
            onToggle={_toggleFromTimePopover}>
            <div className={styles.time} onClick={_toggleFromTimePopover}>
              {thisFromTime}
            </div>
          </Popover>
          <Icon className={styles.icon} which="comparison-arrows" />
          <Popover
            className={classNames(styles.picker, {
              [styles.invalid]: !isValid
            })}
            content={renderPopoverContent('toTime')}
            isVisible={isToTimePopoverOpen}
            onToggle={_toggleToTimePopover}>
            <div className={styles.time} onClick={_toggleToTimePopover}>
              {thisToTime}
            </div>
          </Popover>
        </div>
      </div>
    );
  });
