import React, { useCallback, useState } from 'react';
import { get } from 'lodash';

import {
  InviteStatus,
  MeetingInfo,
  Reports,
  SelectedView,
  SettingsLabels,
  User
} from '../../misc/types';

import { CategorySection, IntentionSection } from './CategoryIntentionSection';
import { OrganizerSection } from './OrganizerSection';

import styles from '../../styles/review-detailed-stats.module.scss';

interface DetailedStatsProps {
  meetingInfo: MeetingInfo;
  reports: Reports;
  selectedView: SelectedView;
  settingsLabels: SettingsLabels;
  statusFilterData: InviteStatus[];
  user: User;
}

interface SortingDetails {
  isDesc: boolean;
  where: string;
}

export interface SortingData {
  categories: SortingDetails;
  intentions: SortingDetails;
  organizers: SortingDetails;
}

export interface RowData {
  data: {
    bad: {
      count: number;
    };
    good: {
      count: number;
    };
    id: string;
    name: string;
    past_actions?: number | null;
    profile_image?: string | null;
    total: {
      cost: number;
      count: number;
      hours: number;
      percent: string;
    };
  };
  regexedEmail?: string;
  slug: string;
}

export type HandleSortType = (which: string, where: string) => void;
export type SortDataType = (
  sortBy: string,
  a: RowData,
  b: RowData,
  isDesc: boolean
) => number;

export const DetailedStats: React.ComponentType<DetailedStatsProps> =
  React.memo(({ reports, selectedView, meetingInfo, user }) => {
    const [sorting, setSorting] = useState<SortingData>({
      categories: {
        where: 'count',
        isDesc: true
      },
      intentions: {
        where: 'count',
        isDesc: true
      },
      organizers: {
        where: 'count',
        isDesc: true
      }
    });

    const _sortData = useCallback((sortBy, a, b, isDesc) => {
      if (sortBy && a && b) {
        switch (sortBy) {
          case 'name':
            return isDesc
              ? ('' + a.slug).localeCompare(b.slug)
              : ('' + b.slug).localeCompare(a.slug);
          case 'count':
            return isDesc
              ? b.data.total.count - a.data.total.count
              : a.data.total.count - b.data.total.count;
          case 'goodBad':
            return isDesc
              ? b.data.good.percent - a.data.good.percent
              : a.data.good.percent - b.data.good.percent;
          case 'cost':
            return isDesc
              ? b.data.total.cost - a.data.total.cost
              : a.data.total.cost - b.data.total.cost;
          case 'hours':
            return isDesc
              ? b.data.total.hours - a.data.total.hours
              : a.data.total.hours - b.data.total.hours;
          default:
            return isDesc
              ? b.data.total.count - a.data.total.count
              : a.data.total.count - b.data.total.count;
        }
      }
      return 0;
    }, []);

    const _handleSort: HandleSortType = useCallback(
      (which, where) => {
        // If "where" is new, make sure isDesc is always true
        const isNewWhere = get(sorting, which).where !== where;
        setSorting(prevState => {
          return {
            ...prevState,
            [which]: {
              where,
              isDesc: isNewWhere ? true : !get(prevState, which).isDesc
            }
          };
        });
      },
      [sorting]
    );

    const sectionProps = {
      reports,
      selectedView,
      meetingInfo,
      sorting,
      onHandleSort: _handleSort,
      onSortData: _sortData,
      user
    };

    return (
      <div className={styles.detailedStats}>
        <div className={styles.categoryIntentions}>
          <IntentionSection {...sectionProps} />
          <CategorySection {...sectionProps} />
        </div>
        <OrganizerSection {...sectionProps} />
      </div>
    );
  });
