import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { ConnectedRouter } from 'connected-react-router';
import { QueryParamProvider } from 'use-query-params';

import { NotificationToast } from './components/misc/NotificationToast';
import { ScrollToTop } from './components/misc/ScrollToTop';
import { App } from './containers/App';
import store, { history } from './store';

import 'sanitize.css/sanitize.css';
import './styles/index.scss';

const target = document.querySelector('#root');

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ToastProvider components={{ Toast: NotificationToast }}>
          <ScrollToTop />
          <App />
        </ToastProvider>
      </QueryParamProvider>
    </ConnectedRouter>
  </Provider>,
  target
);
