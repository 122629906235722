import React from 'react';
import classNames from 'classnames';

import { formatMoney, maybePluralize } from '../../misc/utils';
import { Dollar, Tooltip } from '../design-system';

import styles from '../../styles/meeting-cost.module.scss';

interface MeetingCostProps {
  attendees_count: number;
  className: string;
  cost: number;
  duration: number;
}

export const MeetingCost: React.ComponentType<MeetingCostProps> = React.memo(
  ({ attendees_count, className, cost, duration }) => {
    if (!cost) {
      return null;
    }

    const durationFormatted = Math.floor(duration / 60);
    const costTooltipContent = (
      <>
        The cost of a {durationFormatted} min meeting with {attendees_count}{' '}
        {maybePluralize(attendees_count, 'person', 'people')}.
        <span>(change base salary in Review)</span>
      </>
    );

    return (
      <Tooltip
        className={classNames(styles.meetingCost, className)}
        content={costTooltipContent}>
        <div className={styles.meetingCostInner}>
          <Dollar />
          {formatMoney(cost, 0)}
        </div>
      </Tooltip>
    );
  }
);
