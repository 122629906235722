import React, { useCallback, useEffect, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import Emoji from 'react-emoji-render';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';

import { useTeamSubscription } from '../../hooks/team-subscription';
import { COLORS, PLANS } from '../../misc/constants';
import { DATE_FORMAT, INTENT } from '../../misc/consts';
import { Plan, RootState } from '../../misc/types';
import * as API from '../../modules/api';
import {
  deleteSubscription,
  getSubscriptionPlans
} from '../../modules/subscriptions';
import { getUser } from '../../modules/user';
import { UpcomingCancel } from '../account/UpcomingCancel';
import { Alert, Icon, Tag, Tooltip } from '../design-system';
import { AlertProps } from '../design-system/Alert';

import { Pricing } from './Pricing';

import styles from '../../styles/choose-plan.module.scss';

interface Detail {
  text: string;
  tooltip?: string;
}

const PLAN_DETAILS: Record<string, Detail[]> = {
  Learn: [
    { text: 'Individual meeting evaluations', tooltip: 'evaluations' },
    {
      text: 'Daily, Weekly, and Monthly awareness reports',
      tooltip: 'reports'
    },
    {
      text: 'Daily report scores synced to your Google or Outlook calendar',
      tooltip: 'dailyReports'
    },
    { text: 'Weekly email reports with progress', tooltip: 'weeklyEmails' },
    {
      text: 'Betterment ideas to encourage high quality meetings',
      tooltip: 'bettermentIdeas'
    }
  ],
  'Take Action': [
    {
      text: 'Create Wellness blocks for reporting and automatic preservation',
      tooltip: 'wellnessBlocks'
    },
    {
      text: 'Manually send improvement reminders to meeting organizers',
      tooltip: 'manualAction'
    },
    {
      text: 'Automatic meeting improvement reminders based on your settings',
      tooltip: 'improvementReminders'
    },
    {
      text: 'Automatically decline meetings based on your settings',
      tooltip: 'autoDeclineMeeting'
    },
    {
      text: 'Personalize how your meetings will be evaluated',
      tooltip: 'personalize'
    }
  ],
  Together: [
    { text: 'Set team meeting standards' },
    { text: 'Understand the impact of meetings on your team' },
    { text: 'Daily, Weekly, and Monthly awareness reports for your team' },
    { text: 'Compare progress with your teammates' }
  ]
};

interface ChoosePlanProps {
  isForMarketing?: boolean;
  onGetStarted?: () => void;
}

export const ChoosePlan: React.ComponentType<ChoosePlanProps> = React.memo(
  ({ isForMarketing = false, onGetStarted }) => {
    const dispatch = useDispatch();
    const [, , selectedPlan, ,] = useTeamSubscription();

    const user = useSelector((state: RootState) => state.user.general);
    const subscription = useSelector(
      (state: RootState) => state.subscriptions.subscription
    );

    const _handleCancelAlert = useCallback(() => {
      setAlertDialog(prevState => {
        return {
          ...prevState,
          isVisible: false,
          onConfirm: undefined
        };
      });
    }, []);

    const [alertDialog, setAlertDialog] = useState<AlertProps>({
      isVisible: false,
      title: '',
      text: '',
      onConfirm: undefined,
      onCancel: _handleCancelAlert,
      shouldShowCancel: true
    });

    const _showCancelAlert = useCallback(() => {
      // Confirm action
      const onConfirm = () => {
        // Downgrade or delete
        dispatch(
          deleteSubscription(() => {
            dispatch(getUser()); // Get user again to refresh subscription
          })
        );

        // Hide alert
        _handleCancelAlert();
      };

      setAlertDialog(prevState => {
        return {
          ...prevState,
          title: 'Are you sure you want to cancel your subscription?',
          text: (
            <>
              Your existing subscription will be canceled.{' '}
              <strong>
                You{' '}
                {isEqual(selectedPlan, PLANS.Team) && 'and your team members '}
                will still have access until{' '}
                {moment(subscription.attributes.current_period_end).format(
                  DATE_FORMAT.NoYear
                )}
              </strong>
              .<br />
              <br />
              You can re-activate any time.
            </>
          ),
          confirmText: 'Yes, cancel my subscription',
          cancelText: 'Nevermind',
          onConfirm,
          isVisible: true
        };
      });
    }, [_handleCancelAlert, dispatch, selectedPlan, subscription]);

    const renderPlan = (index: string, item: Plan) => {
      if (item.hideFromDisplay) {
        return null;
      }
      return (
        <div key={index} className={styles.plan}>
          <div className={styles.planTooltipWrapper}>
            <div className={styles.inner}>
              {renderPlanTop(item)}
              {renderPricingDetails(item)}
            </div>
          </div>
        </div>
      );
    };

    const renderPlanTop = (item: Plan) => {
      return (
        <div className={styles.top}>
          <div className={styles.info}>
            <div className={styles.name}>
              <Emoji text={item.emoji} /> {item.name}
            </div>
            {!selectedPlan && isEmpty(user?.attributes.deactivated_reason) && (
              <div className={styles.description}>
                <Tag intent="primary">+ 14 day free trial</Tag>
              </div>
            )}
          </div>
          <Pricing
            isForMarketing={isForMarketing}
            subscription={subscription}
            thisPlan={item}
            onCancel={_showCancelAlert}
            onGetStarted={onGetStarted}
          />
        </div>
      );
    };

    const renderPricingDetails = (item: Plan) => {
      return (
        <div className={styles.pricingDesc}>
          <div className={styles.details}>
            {isEqual(item, PLANS.Solo) ? (
              <div className={styles.price}>
                <span>$</span>
                <div className={styles.num}>{item.amount}</div>
                <label>per month</label>
              </div>
            ) : (
              <div className={styles.price}>
                <span>Customized pricing</span>
              </div>
            )}
            <div className={styles.sub}>{item.sub}</div>
          </div>
        </div>
      );
    };

    const renderListDetails = () => {
      return (
        <div className={styles.listDetails}>
          {Object.keys(PLAN_DETAILS).map(key => {
            return (
              <div key={key} className={styles.listGroup}>
                <div className={styles.groupHeader}>{key}</div>
                {PLAN_DETAILS[key].map((item, index) => {
                  return (
                    <div key={index} className={styles.listItem}>
                      <Icon color={COLORS.success} which="tick" />
                      <span>{item.text}</span>
                      {item.tooltip && isBrowser && (
                        <div className={styles.help}>
                          <Tooltip
                            content={
                              <div
                                className={classNames(
                                  'exampleScreenshot',
                                  item.tooltip
                                )}
                              />
                            }>
                            <Icon which="help" />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    };

    useEffect(() => {
      API.makeRequest(() => {
        dispatch(getSubscriptionPlans());
      });
    }, [dispatch]);

    return (
      <>
        <Alert {...alertDialog} intent={INTENT.Danger} />
        <div
          className={classNames(styles.choosePlan, {
            [styles.isForMarketing]: isForMarketing
          })}>
          <div className={styles.header}>
            <span>Our plans</span>
          </div>
          <UpcomingCancel hideActions={true} />
          <div className={styles.plans}>
            {Object.keys(PLANS).map(index => renderPlan(index, PLANS[index]))}
          </div>
          <div className={styles.header}>
            <span>What you'll get</span>
          </div>
          {renderListDetails()}
        </div>
      </>
    );
  }
);
