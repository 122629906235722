import React, { useCallback } from 'react';
import Emoji from 'react-emoji-render';
import { useHistory } from 'react-router';

import { Button } from '../../components/design-system';
import { SEO } from '../../components/misc/SEO';

import styles from '../../styles/marketing/misc.module.scss';

export const MissingView: React.ComponentType = React.memo(() => {
  const history = useHistory();

  const _onGoHome = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <>
      <SEO title="Missing" />
      <div className={styles.miscPage}>
        <div className={styles.inner}>
          <div className={styles.emoji}>
            <Emoji text=":person_shrugging:" />
          </div>
          <h1>
            Oh no! <span>Are you lost?</span>
          </h1>
          <p>
            You've found a rogue page IRL... are you breaking the law right now?
          </p>
          <Button text="Go home" onClick={_onGoHome} />
        </div>
      </div>
    </>
  );
});
