/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { readEndpoint } from 'redux-json-api';

import { log } from '../../misc/utils';

export const getMaybeWellnessBlocks = () => {
  log('getMaybeWellnessBlocks()');
  return dispatch => {
    dispatch(readEndpoint('maybe_wellness_blocks'));
  };
};
