/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Emoji from 'react-emoji-render';
import ReactMarkdown from 'react-markdown';
import { useHistory } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';

import { Button, Icon, Tag } from '../../components/design-system';
import { COLORS } from '../../misc/constants';
import { INTENT } from '../../misc/consts';

import { SocialPost } from './SocialPost';

import styles from '../../styles/marketing/misc.module.scss';

export const BlogPostItem = ({ index, fm, isIndividualPost }) => {
  const history = useHistory();

  const isMeme = fm.attributes.type === 'meme';
  const emoji = !isMeme ? ':thought_balloon:' : ':hatching_chick:';
  const label = !isMeme ? 'post' : 'meme';
  const tagList = fm.attributes.tags.split(',');

  return (
    <div key={index} className={styles.post}>
      <div className={styles.heading}>
        <Emoji text={emoji} />
        <div className={styles.label}>{label}</div>
      </div>
      {!isMeme ? (
        <>
          <h2 onClick={() => history.push(`/blog/${fm.attributes.slug}`)}>
            {fm.attributes.title}
          </h2>
          <ReactMarkdown
            children={fm.attributes.description}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
          />
        </>
      ) : (
        <>
          <SocialPost
            content={fm.attributes.title}
            media={fm.attributes.media}
            mediaMisc={fm.attributes.mediaMisc}
            mediaUrl={fm.attributes.mediaUrl}
            slug={fm.attributes.slug}
          />
          <ReactMarkdown
            children={fm.body}
            rehypePlugins={[rehypeRaw, rehypeSanitize]}
          />
        </>
      )}
      <div className={styles.bottom}>
        {tagList.length > 0 && (
          <div className={styles.tagList}>
            {tagList.map((item, index) => (
              <Tag key={index}>{item}</Tag>
            ))}
          </div>
        )}
        {!isIndividualPost && (
          <Button
            iconRight={<Icon color={COLORS.primary} which="arrow-right" />}
            intent={INTENT.Primary}
            large={true}
            minimal={true}
            text={isMeme ? 'View' : 'Read more'}
            onClick={() => history.push(`/blog/${fm.attributes.slug}`)}
          />
        )}
      </div>
    </div>
  );
};
