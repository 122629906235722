import React, { useCallback } from 'react';
import classNames from 'classnames';
import { find } from 'lodash';

import { WELLNESS_BLOCKS } from '../../misc/consts';
import { Meeting, WellnessBlockCategory } from '../../misc/types';
import { Button } from '../design-system';

import { MeetingDate } from './MeetingDate';

import styles from '../../styles/meeting-modal.module.scss';

interface ModalWellnessBlockProps {
  event: Meeting;
  onClose: () => void;
  onViewWellnessBlock: (id: string) => void;
}

export const ModalWellnessBlock: React.ComponentType<ModalWellnessBlockProps> = React.memo(
  ({ event, onClose, onViewWellnessBlock }) => {
    const _handleEditWellnessBlock = useCallback(() => {
      onClose(); // Close modal

      // Open editing modal
      setTimeout(() => {
        if (event.attributes.wellness_block_id?.toString()) {
          onViewWellnessBlock(event.attributes.wellness_block_id.toString());
        }
      }, 200);
    }, [event, onClose, onViewWellnessBlock]);

    if (!event) return null;

    const blockInfo = find(WELLNESS_BLOCKS, function (o) {
      return o.value === event.attributes.wellness_block_category;
    }) as WellnessBlockCategory;

    return (
      <>
        <div className={styles.sectionTop}>
          <div className={styles.title}>
            <h2>{event.attributes.summary}</h2>
          </div>
          <div className={styles.miscInfo}>
            <MeetingDate
              allDay={event.attributes.all_day}
              endDate={event.attributes.ends_at}
              startDate={event.attributes.starts_at}
            />
          </div>
        </div>
        <div className={styles.sectionBottom}>
          <div className={classNames(styles.section, styles.isWellnessBlock)}>
            <div>{blockInfo.description}</div>
            <Button
              intent="primary"
              minimal
              text={`Edit this ${blockInfo.value} block`}
              onClick={_handleEditWellnessBlock}
            />
          </div>
        </div>
      </>
    );
  }
);
