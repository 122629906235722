import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Calendar } from '../../misc/types';
import { Button, Tag } from '../design-system';

import styles from '../../styles/calendar-list.module.scss';

interface CalendarListItemProps {
  item: Calendar;
  onConnect: (uuid: string, callback?: () => void) => void;
  onDisconnect: (uuid: string, callback?: () => void) => void;
}

export const CalendarListItem: React.ComponentType<CalendarListItemProps> = React.memo(
  ({ item, onConnect, onDisconnect }) => {
    const [isLoading, setIsLoading] = useState(false);

    const isWatching = useMemo(() => {
      return item.attributes.watching;
    }, [item]);

    const _handleOnConnect = useCallback(() => {
      setIsLoading(true);
      onConnect(item.attributes.uuid);
    }, [item, onConnect]);

    const _handleOnDisconnect = useCallback(() => {
      setIsLoading(true);
      onDisconnect(item.attributes.uuid);
    }, [item, onDisconnect]);

    useEffect(() => {
      setIsLoading(false);
    }, [item]);

    return (
      <li>
        <div className={styles.info}>
          <div className={styles.name} title={item.attributes.name}>
            {item.attributes.name}
          </div>
          {isWatching && (
            <Tag intent="success" small>
              Added
            </Tag>
          )}
        </div>
        {!isWatching ? (
          <Button
            disabled={isLoading}
            isLoading={isLoading}
            text="Add"
            onClick={_handleOnConnect}
          />
        ) : (
          <Button
            disabled={isLoading}
            intent="danger"
            isLoading={isLoading}
            text="Remove"
            onClick={_handleOnDisconnect}
          />
        )}
      </li>
    );
  }
);
