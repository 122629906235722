/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { readEndpoint } from 'redux-json-api';

import { log } from '../../misc/utils';

export const getInvoices = () => {
  log('getInvoices()');
  return dispatch => {
    dispatch(readEndpoint('invoices'));
  };
};
