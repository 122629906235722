import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

export interface ErrorStateProps {
  action?: string | ReactNode;
  className?: string;
  description: string | ReactNode;
  emoji?: string;
  inline?: boolean;
  title: string | ReactNode;
}

export const ErrorState: React.ComponentType<ErrorStateProps> = React.memo(
  ({ className, inline, emoji, title, description, action }) => {
    return (
      <div
        className={classNames(styles.errorStateWrapper, className, {
          [styles.inline]: inline
        })}>
        <div className={styles.errorState}>
          <div className={styles.title}>
            {emoji && <span>{emoji}</span>}
            {title}
          </div>
          <div className={styles.description}>{description}</div>
          {action && <div className={styles.action}>{action}</div>}
        </div>
      </div>
    );
  }
);
