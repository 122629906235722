import React, { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import TagsInput from 'react-tagsinput';
import { cloneDeep } from 'lodash';

import { Fathom, GOALS } from '../../misc/fathom';
import { isEmailValid } from '../../misc/utils';
import { inviteTeamMember } from '../../modules/teams';
import { Alert, Button, MultiTagInput } from '../design-system';
import { AlertProps } from '../design-system/Alert';

import styles from '../../styles/team-mgmt.module.scss';

export interface InviteMembersProps {
  makeAPICalls?: () => void;
}

export const InviteMembers: React.ComponentType<InviteMembersProps> = React.memo(
  function InviteMembers({ makeAPICalls }) {
    const tagsInput = useRef<TagsInput>(null);
    const [alertDialog, setAlertDialog] = useState<AlertProps>({
      isVisible: false,
      title: '',
      text: '',
      onConfirm: undefined
    });
    const [stagedMembersToInvite, setStagedMembersToInvite] = useState<
      string[]
    >([]);
    const [isInviting, setIsInviting] = useState(false);
    const dispatch = useDispatch();

    const hideAlertDialog = useCallback(() => {
      setAlertDialog(prevState => {
        return {
          ...prevState,
          isVisible: false
        };
      });
    }, []);

    const handleInviteMembers = useCallback(async () => {
      setIsInviting(true); // Show spinner

      // Make sure whatever is typed into box has turned into a tag
      if (tagsInput.current) {
        await tagsInput.current.accept();
      }

      // Validate (Loop through and test organizer emails)
      const beingInvited = cloneDeep(stagedMembersToInvite);
      for (let i = 0; i < beingInvited.length; i++) {
        if (!isEmailValid(beingInvited[i])) {
          setIsInviting(false); // Hide spinner
          return setAlertDialog({
            ...alertDialog,
            isVisible: true,
            title: 'Invalid email',
            text: 'Please enter a valid email and try again.',
            confirmText: 'Okay',
            shouldShowCancel: false,
            onConfirm: hideAlertDialog,
            onCancel: hideAlertDialog
          });
        }
      }

      // Loop through and post to API
      for (let d = 0; d < beingInvited.length; d++) {
        dispatch(
          inviteTeamMember(beingInvited[d], () => {
            makeAPICalls && makeAPICalls();
          })
        );
      }

      // Track goal
      Fathom.trackGoal(GOALS.Teams.InvitedMember);

      // Reset
      setTimeout(() => {
        setIsInviting(false);
        setStagedMembersToInvite([]);
      }, 1000);
    }, [
      alertDialog,
      dispatch,
      hideAlertDialog,
      makeAPICalls,
      stagedMembersToInvite
    ]);

    // Disabled button?
    const isSaveButtonDisabled = () => {
      // No emails, or emails haven't changed
      if (stagedMembersToInvite.length === 0) {
        return true;
      }
      return false;
    };

    return (
      <>
        <Alert {...alertDialog} />
        <MultiTagInput
          addOnBlur={true}
          addOnPaste={true}
          inputProps={{
            placeholder: 'Type an email, hit Enter to add...'
          }}
          reference={tagsInput}
          value={stagedMembersToInvite}
          onChange={members => setStagedMembersToInvite(members)}
        />
        <div className={styles.actions}>
          <Button
            centered
            disabled={isSaveButtonDisabled()}
            fill
            intent="primary"
            isLoading={isInviting}
            shouldLookDisabled
            text="Invite"
            onClick={handleInviteMembers}
          />
        </div>
      </>
    );
  }
);
