import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { findIndex, isEmpty, isEqual } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { usePrevious } from '../../hooks/previous';
import { PAGE_TO_ALLOW_ACCESS, URLS } from '../../misc/constants';
import { ONBOARDING_STEPS, QUERY_PARAMS } from '../../misc/consts';
import { RootState } from '../../misc/types';
import { Button, Icon } from '../design-system';
import { CalendarList } from '../misc/CalendarList';
import { ModalView } from '../misc/ModalView';

import { ChooseGoals, ChooseGoalsHandle } from './ChooseGoals';

import styles from '../../styles/welcome.module.scss';

interface WelcomeView {
  centered?: boolean;
  className: string;
  desc?: string;
  nextBtn: string;
  render: ReactNode;
  title: string[];
  which: string;
}

export const WelcomeModal: React.ComponentType = React.memo(() => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const history = useHistory();

  const [onboardingStep] = useQueryParam(QUERY_PARAMS.Onboarding, StringParam);

  const goalChooser = useRef<ChooseGoalsHandle>(null);

  const user = useSelector((state: RootState) => state.user.general);
  const calendars = useSelector((state: RootState) => state.calendars.list);

  const [isVisible, setIsVisible] = useState(false);
  const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);

  const VIEWS: WelcomeView[] = useMemo(() => {
    return [
      {
        which: 'intro',
        title: ['Welcome to', 'MeetWell!'],
        className: styles.introView,
        centered: true,
        nextBtn: 'Choose your calendars',
        render: (
          <div className={styles.intro}>
            <div className={styles.badge} />
            <p>
              <span>It’s time to defend your time.</span> Find out which
              meetings are worth joining, and decline the rest. MeetWell shows
              organizers what’s needed if they want you to attend.
            </p>
            <p>
              <span>Ready to create more time?</span>
            </p>
          </div>
        )
      },
      {
        which: 'calendars',
        title: ['Choose your', 'calendars'],
        desc: 'Take your pick. Select which calendars MeetWell can begin analyzing and evaluating. It may take several minutes.',
        className: styles.calendarView,
        nextBtn: 'Set your goals',
        render: (
          <div className={styles.calendars}>
            <CalendarList />
          </div>
        )
      },
      {
        which: 'goals',
        title: ['More time for', 'you.'],
        desc: `What will you do more of when you achieve the perfect work-life balance? MeetWell will use these to motivate you toward a healthier daily life.`,
        className: styles.goalsView,
        nextBtn: 'Take a tour',
        render: (
          <div className={styles.goals}>
            <ChooseGoals ref={goalChooser} />
          </div>
        )
      }
    ];
  }, []);

  const modalViews = useMemo(() => {
    return VIEWS.map(view => view.which);
  }, [VIEWS]);

  const [selectedView, setSelectedView] = useState(VIEWS[0]);

  const _goNext = useCallback(() => {
    const currentStep = findIndex(VIEWS, function (o) {
      return o.which === selectedView.which;
    });
    const nextStep = VIEWS[currentStep + 1];
    history.push(`${URLS.Onboarding.Default}${nextStep.which}`);
  }, [VIEWS, history, selectedView.which]);

  const _goPrev = useCallback(() => {
    const currentStep = findIndex(VIEWS, function (o) {
      return o.which === selectedView.which;
    });
    const prevStep = VIEWS[currentStep - 1];
    history.push(`${URLS.Onboarding.Default}${prevStep.which}`);
  }, [VIEWS, history, selectedView.which]);

  const _closeOnboarding = useCallback(() => {
    setIsVisible(false);
    setIsWaitingOnAPI(false);
    setSelectedView(VIEWS[0]);

    setTimeout(() => {
      history.push(
        `${URLS.Review.Default}?${QUERY_PARAMS.Onboarding}=${ONBOARDING_STEPS.Review.tag}`
      );
    }, 500);
  }, [VIEWS, history]);

  const _completeOnboarding = useCallback(() => {
    // Show spinner on button
    setIsWaitingOnAPI(true);

    // Save the goals and toggle onboarding
    if (goalChooser.current) {
      goalChooser.current._saveGoalsAndToggleOnboarding();
    }

    // Close the modal
    setTimeout(() => {
      _closeOnboarding();
    }, 1500);
  }, [_closeOnboarding]);

  const buildSelectedView = useCallback(() => {
    let thisView = selectedView;
    let thisIndex;

    if (onboardingStep) {
      thisIndex = findIndex(VIEWS, function (o) {
        return o.which === onboardingStep;
      });
      if (thisIndex >= 0) thisView = VIEWS[thisIndex];
    }
    return thisView;
  }, [VIEWS, onboardingStep, selectedView]);

  const _checkForOnboarding = useCallback(() => {
    // If the user flag 'onboarded' is false && the user is not in a "page to ignore"
    // OR
    // If the user flag 'onboarded' is true && an onboarding param is in the url
    const onboardingInModal =
      onboardingStep && modalViews.includes(onboardingStep);
    if (
      (!user?.attributes.onboarded &&
        !PAGE_TO_ALLOW_ACCESS.includes(location.pathname)) ||
      (user?.attributes.onboarded && onboardingInModal)
    ) {
      const selectedView = buildSelectedView();
      setIsVisible(true);
      setSelectedView(selectedView);
      history.push(`${URLS.Onboarding.Default}${selectedView.which}`);
    }
  }, [
    buildSelectedView,
    history,
    location.pathname,
    modalViews,
    onboardingStep,
    user?.attributes.onboarded
  ]);

  const renderWelcomeView = () => {
    if (isVisible !== true || !user) return null;

    const isNextBtnDisabled = () => {
      // Check for calendars chosen
      if (selectedView.which === 'calendars') {
        const watchedCalendars = !isEmpty(calendars)
          ? calendars.filter(obj => obj.attributes.watching)
          : [];
        if (watchedCalendars.length === 0) return true;
      }

      return false;
    };

    return (
      <div className={classNames(styles.welcomeView, selectedView.className)}>
        <div
          className={classNames(styles.header, {
            [styles.centered]: selectedView.centered
          })}>
          <h1>
            {selectedView.title[0]} <span>{selectedView.title[1]}</span>
          </h1>
          {selectedView.desc && (
            <div className={styles.desc}>{selectedView.desc}</div>
          )}
        </div>
        <div className={styles.content}>{selectedView.render}</div>
        <div className={styles.actions}>
          {selectedView.which !== VIEWS[0].which && (
            <Button large text="Back" onClick={_goPrev} />
          )}
          {selectedView.which !== VIEWS[VIEWS.length - 1].which && (
            <Button
              className={styles.nextBtn}
              disabled={isNextBtnDisabled()}
              intent="primary"
              large
              shouldLookDisabled
              text={
                <>
                  <span>Next:</span>
                  {selectedView.nextBtn}
                </>
              }
              onClick={_goNext}>
              <Icon
                className={styles.arrowRightIcon}
                color="#1C2C66"
                which="arrow-right"
              />
            </Button>
          )}
          {selectedView.which === VIEWS[VIEWS.length - 1].which && (
            <Button
              className={styles.nextBtn}
              intent="primary"
              isLoading={isWaitingOnAPI}
              large
              text={
                <>
                  <span>Next:</span>
                  {selectedView.nextBtn}
                </>
              }
              onClick={_completeOnboarding}>
              <Icon
                className={styles.arrowRightIcon}
                color="#1C2C66"
                which="arrow-right"
              />
            </Button>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (user) {
      // Maybe display if not already
      if (!isVisible) {
        _checkForOnboarding();
      }

      // Change view when location params change
      if (isVisible && !isEqual(prevLocation, location)) {
        setSelectedView(buildSelectedView());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, prevLocation, user]);

  return (
    <ModalView
      className={classNames(styles.welcomeModal, {
        [selectedView.className]: !isEmpty(selectedView)
      })}
      innerClassName={styles.welcomeModalInner}
      isDismissible={false}
      isVisible={isVisible}
      onClose={_closeOnboarding}>
      {renderWelcomeView()}
    </ModalView>
  );
});
