import React, { useCallback } from 'react';
import Emoji from 'react-emoji-render';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { DATE_FORMAT, INTENT, URLS } from '../../misc/consts';
import { RootState } from '../../misc/types';
import { Button, Tag } from '../design-system';

import styles from '../../styles/team-mgmt.module.scss';

interface UpcomingCancelProps {
  hideActions?: boolean;
}

export const UpcomingCancel: React.ComponentType<UpcomingCancelProps> =
  React.memo(({ hideActions = false }) => {
    const history = useHistory();

    const user = useSelector((state: RootState) => state.user.general);
    const subscription = useSelector(
      (state: RootState) => state.subscriptions.subscription
    );

    const _handleReactivatePlan = useCallback(() => {
      history.push(URLS.Balance.PlanChooser);
    }, [history]);

    const hasCanceledSubscription = subscription
      ? !subscription.attributes.active
      : false;

    if (hasCanceledSubscription) {
      return (
        <div className={classNames(styles.section, styles.upcomingCancel)}>
          <Emoji className={styles.warningEmoji} text=":warning:" />
          <div className={styles.warningDesc}>
            Your plan has been cancelled, but you still have access until{' '}
            <strong>
              {moment(subscription.attributes.current_period_end).format(
                DATE_FORMAT.NoYear
              )}
            </strong>
            .
          </div>
          <Tag intent="danger" minimal small>
            {moment(subscription.attributes.current_period_end).diff(
              moment(),
              'days'
            )}{' '}
            days left
          </Tag>
          {!hideActions && (
            <Button
              className={styles.reactivateButton}
              intent={INTENT.Primary}
              minimal
              text="Re-activate plan"
              onClick={_handleReactivatePlan}
            />
          )}
        </div>
      );
    }

    const hasDeactivated = !isEmpty(user?.attributes.deactivated_at);
    const deactivationReason = user?.attributes.deactivated_reason;

    if (hasDeactivated && deactivationReason === 'trial_expired') {
      return (
        <div className={classNames(styles.section, styles.upcomingCancel)}>
          <Emoji className={styles.warningEmoji} text=":warning:" />
          <div className={styles.warningDesc}>
            Your free trial has ended, please choose a plan below to continue.
          </div>
        </div>
      );
    }

    return null;
  });
