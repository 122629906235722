/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { AnyAction, Dispatch } from 'redux';
import { createAction } from 'redux-actions';
import { createResource, readEndpoint, updateResource } from 'redux-json-api';

import { APIResponse, BaseItem } from '../../misc/types';
import { log } from '../../misc/utils';
const deleteItem = createAction('API_WAS_DELETED');

export const getMeetings = (
  data: Record<string, unknown>,
  callback?: () => void
): ((dispatch: Dispatch) => void) => {
  const { start, end } = data;
  log('getMeetings()');

  // Build the 'by_period' query parameter
  const byPeriod =
    start && end
      ? `?by_period[starts_at]=${start}&by_period[ends_at]=${end}`
      : '';

  return (dispatch: Dispatch) => {
    dispatch(readEndpoint(`meetings${byPeriod}`))
      .then(callback)
      .catch(callback);
  };
};

export const getOneMeeting = (
  id: string,
  callback: (event: APIResponse) => void
): ((dispatch: Dispatch) => void) => {
  log('getOneMeeting()');

  return (dispatch: Dispatch) => {
    dispatch(readEndpoint(`meetings/${id}`))
      .then(callback)
      .catch(callback);
  };
};

export const getMeetingEvaluation = (
  meeting_id: string,
  callback: () => void
): ((dispatch: Dispatch) => void) => {
  log('getMeetingEvaluation()');

  return (dispatch: Dispatch) => {
    dispatch(readEndpoint(`meetings/${meeting_id}/evaluation`))
      .then(callback)
      .catch(callback);
  };
};

export const getMeetingEvaluationProgress = (
  meeting_id: string,
  callback: (response?: []) => void
): ((dispatch: Dispatch) => void) => {
  log('getMeetingEvaluationProgress()');

  return (dispatch: Dispatch) => {
    dispatch(readEndpoint(`meetings/${meeting_id}/evaluation/progress`))
      .then(callback)
      .catch(callback);
  };
};

export const resyncMeetings = (
  data: Record<string, unknown>,
  callback: (response: Record<string, unknown>) => void
): ((dispatch: Dispatch) => void) => {
  const { start, end, confirmed } = data;
  log('resyncMeetings()');

  // Build the 'by_period' query parameter
  const byPeriod =
    start && end
      ? `?by_period[starts_at]=${start}&by_period[ends_at]=${end}`
      : '';

  // Build the 'confirmed' query parameter
  const isConfirmed = confirmed ? '&confirmed=true' : '';

  return (dispatch: Dispatch) => {
    dispatch(readEndpoint(`meetings/sync${byPeriod}${isConfirmed}`))
      .then(async (response: Record<string, unknown>) => {
        callback && callback(response);
      })
      .catch(callback);
  };
};

export const connectCalendar = (
  which: string,
  cal: string,
  callback: () => void
): ((dispatch: Dispatch) => void) => {
  log(`connectCalendar(${which}, ${cal})`);

  return (dispatch: Dispatch) => {
    const entity = {
      type: `${which}/webhooks_setup`,
      attributes: {
        calendar_uuid: cal
      }
    };

    dispatch(createResource(entity)).then(callback).catch(callback);
  };
};

export const disconnectCalendar = (
  which: string,
  cal: string,
  callback: () => void
): ((dispatch: Dispatch) => void) => {
  log(`disconnectCalendar(${which}, ${cal})`);

  return (dispatch: Dispatch) => {
    const entity = {
      type: `${which}/webhooks_stop`,
      attributes: {
        calendar_uuid: cal
      }
    };

    dispatch(createResource(entity)).then(callback).catch(callback);
  };
};

export const createAgendaItem = (data: BaseItem) => (
  dispatch: Dispatch
): AnyAction => {
  log('createAgendaItem()');

  const entity = {
    type: `meetings/${data.attributes.meeting_id}/agenda_items`,
    attributes: data.attributes
  };

  return dispatch(createResource(entity));
};

export const updateAgendaItem = (data: BaseItem) => (
  dispatch: Dispatch
): AnyAction => {
  log('updateAgendaItem()');

  const entity = {
    type: `meetings/${data.attributes.meeting_id}/agenda_items/${data.id}#update`,
    attributes: data.attributes
  };

  return dispatch(updateResource(entity));
};

export const deleteAgendaItem = (data: BaseItem) => (
  dispatch: Dispatch
): void => {
  log('deleteAgendaItem()');
  const url = `/meetings/${data.attributes.meeting_id}/agenda_items/${data.id}`;

  const request = axios.delete(url);
  request.then(async () => {
    dispatch(deleteItem({ data }));
  });
};

export const createObjectiveItem = (data: BaseItem) => (
  dispatch: Dispatch
): AnyAction => {
  log('createObjectiveItem()');

  const entity = {
    type: `meetings/${data.attributes.meeting_id}/objectives`,
    attributes: data.attributes
  };

  return dispatch(createResource(entity));
};

export const updateObjectiveItem = (data: BaseItem) => (
  dispatch: Dispatch
): AnyAction => {
  log('updateObjectiveItem()');

  const entity = {
    type: `meetings/${data.attributes.meeting_id}/objectives/${data.id}#update`,
    attributes: data.attributes
  };

  return dispatch(updateResource(entity));
};

export const deleteObjectiveItem = (data: BaseItem) => (
  dispatch: Dispatch
): void => {
  log('deleteObjectiveItem()');
  const url = `/meetings/${data.attributes.meeting_id}/objectives/${data.id}`;

  const request = axios.delete(url);
  request.then(async () => {
    dispatch(deleteItem({ data }));
  });
};

export const createPreWorkItem = (data: BaseItem) => (
  dispatch: Dispatch
): AnyAction => {
  log('createPreWorkItem()');

  const entity = {
    type: `meetings/${data.attributes.meeting_id}/prework_items`,
    attributes: data.attributes
  };

  return dispatch(createResource(entity));
};

export const updatePreWorkItem = (data: BaseItem) => (
  dispatch: Dispatch
): AnyAction => {
  log('updatePreWorkItem()');

  const entity = {
    type: `meetings/${data.attributes.meeting_id}/prework_items/${data.id}#update`,
    attributes: data.attributes
  };

  return dispatch(updateResource(entity));
};

export const deletePreWorkItem = (data: BaseItem) => (
  dispatch: Dispatch
): void => {
  log('deletePreWorkItem()');
  const url = `/meetings/${data.attributes.meeting_id}/prework_items/${data.id}`;

  const request = axios.delete(url);
  request.then(async () => {
    dispatch(deleteItem({ data }));
  });
};

export const getUserSpecificMeetingInfo = (
  meeting_id: string,
  callback: () => void
): ((dispatch: Dispatch) => void) => {
  log('getUserSpecificMeetingInfo()');

  return (dispatch: Dispatch) => {
    dispatch(readEndpoint(`users/meetings/${meeting_id}`))
      .then(callback)
      .catch(callback);
  };
};

export const enforceOneMeeting = (
  meeting_id: string,
  action: string
): ((dispatch: Dispatch) => void) => {
  log('enforceOneMeeting()');

  return (dispatch: Dispatch) => {
    const entity = {
      type: `users/meetings/${meeting_id}#update`,
      attributes: {
        non_compliance_action: action
      }
    };

    dispatch(updateResource(entity));
  };
};

export const organizerPreventDecline = (
  token: string,
  organizerNotes: string
): ((dispatch: Dispatch) => void) => {
  log('organizerPreventDecline()');

  return (dispatch: Dispatch) => {
    const entity = {
      type: `${process.env.REACT_APP_BASE_URL}/api/v1/users/meetings/prevent_decline/${token}#update`,
      attributes: {
        organizer_notes: organizerNotes
      }
    };

    dispatch(updateResource(entity));
  };
};

export const getMeetingEvaluationByToken = (
  token: string,
  callback: () => void
): ((dispatch: Dispatch) => void) => {
  log('getMeetingEvaluationByToken()');

  return (dispatch: Dispatch) => {
    dispatch(
      readEndpoint(
        `${process.env.REACT_APP_BASE_URL}/api/v1/users/meetings/details/${token}`
      )
    )
      .then(callback)
      .catch(callback);
  };
};

export const makeWellnessBlock = (
  meeting_id: string,
  start: string,
  end: string
): ((dispatch: Dispatch) => void) => {
  log('makeWellnessBlock()');

  // Build the 'by_period' query parameter
  const byPeriod =
    start && end
      ? `?by_period[starts_at]=${start}&by_period[ends_at]=${end}`
      : '';

  return (dispatch: Dispatch) => {
    dispatch(
      readEndpoint(`meetings/${meeting_id}/make_wellness_block${byPeriod}`)
    );
  };
};

export const generallyUpdateMeeting = (
  meeting_id: string,
  which: string,
  value: string | boolean,
  callback?: () => void
) => (dispatch: Dispatch): void => {
  log('generallyUpdateMeeting()');

  const entity = {
    type: `meetings/${meeting_id}#update`,
    attributes: {
      [which]: value
    }
  };

  dispatch(updateResource(entity))
    .then(callback && callback)
    .catch(callback && callback);
};

export const updateMeetingMetadata = ({
  import_action,
  source_meeting_id,
  meeting_id
}: Record<string, string>): ((dispatch: Dispatch) => void) => {
  log('updateMeetingMetadata()');

  return (dispatch: Dispatch) => {
    const sourceMeetingId = `?source_meeting_id=${source_meeting_id}`;
    const maybeImportAction = import_action
      ? `&import_action=${import_action}`
      : '';
    dispatch(
      readEndpoint(
        `meetings/${meeting_id}/metadata${sourceMeetingId}${maybeImportAction}`
      )
    );
  };
};
