import React, { useCallback, useEffect, useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { PREFERENCE_ROWS } from '../../misc/constants';
import { SETTINGS_BUCKETS } from '../../misc/consts';
import {
  GroupActionSettings,
  Settings,
  SettingsLabels,
  User
} from '../../misc/types';
import { getSettingsLabels } from '../../modules/settings-labels';
import { updateUserSettings } from '../../modules/user';
import { Icon } from '../design-system';
import {
  Button,
  MenuHeader,
  MenuItem,
  MenuItems,
  Popover
} from '../design-system';

import { ActionInfo } from './ActionInfo';
import { PreferenceRow } from './PreferenceRow';

import styles from '../../styles/balance.module.scss';

interface ShareTextModalProps {
  settings?: Settings;
  settings_labels: SettingsLabels;
  user: User;
}

export const BalanceViewAction: React.ComponentType<ShareTextModalProps> =
  React.memo(({ user, settings, settings_labels }) => {
    if (!user || !settings) {
      return null;
    }

    const prefRowProps = {
      bucket: SETTINGS_BUCKETS.Action as keyof typeof SETTINGS_BUCKETS,
      user,
      settings,
      settings_labels
    };

    return (
      <>
        <div className={classNames(styles.preferences, styles.column)}>
          <h3>Your personal Auto-Balance preferences</h3>
          <div className={styles.desc}>
            How MeetWell will decide to take action on meetings you have yet to
            accept or decline, and are marked as required.
          </div>
          {isMobile && <ActionInfo settings={settings} user={user} />}
          <div className={styles.section}>
            <SectionHeader
              settings={settings}
              title="Time &amp; duration"
              which="take_action_time"
            />
            <div className={styles.inside}>
              {PREFERENCE_ROWS.TimeAndDuration.map((item, index) => (
                <PreferenceRow
                  key={index}
                  operator={item.operator}
                  statType={item.statType}
                  type={item.type}
                  which={item.which}
                  {...prefRowProps}
                />
              ))}
            </div>
          </div>
          <div className={styles.section}>
            <SectionHeader
              settings={settings}
              title="Content"
              which="take_action_content"
            />
            <div className={styles.inside}>
              {PREFERENCE_ROWS.Content.map((item, index) => (
                <PreferenceRow
                  key={index}
                  operator={item.operator}
                  statType={item.statType}
                  type={item.type}
                  which={item.which}
                  {...prefRowProps}
                />
              ))}
            </div>
          </div>
          <div className={styles.section}>
            <SectionHeader
              settings={settings}
              title="Attendees"
              which="take_action_attendees"
            />
            <div className={styles.inside}>
              {PREFERENCE_ROWS.Attendees.map((item, index) => (
                <PreferenceRow
                  key={index}
                  operator={item.operator}
                  statType={item.statType}
                  type={item.type}
                  which={item.which}
                  {...prefRowProps}
                />
              ))}
            </div>
          </div>
        </div>
        {isBrowser && (
          <div className={classNames(styles.actionToTake, styles.column)}>
            <Icon className={styles.arrow} which="arrow-right" />
            <ActionInfo settings={settings} user={user} />
          </div>
        )}
      </>
    );
  });

const HEADER_PREFERENCES = {
  any: {
    value: 'any',
    content: (
      <>
        Take action when <u>any of these</u> preferences aren't met.
      </>
    )
  },
  all: {
    value: 'all',
    content: (
      <>
        Only take action when <u>all of these</u> preferences aren't met.
      </>
    )
  }
};

interface SectionHeaderProps {
  settings: Settings;
  title: string;
  which: keyof GroupActionSettings;
}

const SectionHeader: React.ComponentType<SectionHeaderProps> = React.memo(
  function SectionHeader({ settings, title, which }) {
    const dispatch = useDispatch();

    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [option, setOption] = useState(HEADER_PREFERENCES.any);

    useEffect(() => {
      const chosenValue: keyof typeof HEADER_PREFERENCES = settings.attributes
        .action_settings[which] as keyof typeof HEADER_PREFERENCES;
      const thisOption = HEADER_PREFERENCES[chosenValue];
      setOption(thisOption);
    }, [settings, which]);

    const _handlePopoverToggle = useCallback(() => {
      setIsPopoverVisible(prevState => !prevState);
    }, []);

    const _handleSelection = useCallback(
      item => {
        setOption(item);
        _handlePopoverToggle();

        // Post to API
        dispatch(
          updateUserSettings({
            which: SETTINGS_BUCKETS.Action,
            value: {
              [which]: item.value
            },
            callback: () => dispatch(getSettingsLabels())
          })
        );
      },
      [_handlePopoverToggle, dispatch, which]
    );

    return (
      <div className={styles.header}>
        <h5>{title}</h5>
        <Popover
          content={
            <MenuItems>
              <MenuHeader>Group preferences</MenuHeader>
              {Object.values(HEADER_PREFERENCES).map((item, index) => {
                const isSelected = option === item;
                return (
                  <MenuItem
                    key={index}
                    icon={<Icon which={isSelected ? 'tick' : 'blank'} />}
                    isDisabled={isSelected}
                    onClick={() => _handleSelection(item)}>
                    {item.content}
                  </MenuItem>
                );
              })}
            </MenuItems>
          }
          isVisible={isPopoverVisible}
          onToggle={_handlePopoverToggle}>
          <Button
            className={styles.groupPreferences}
            iconRight={<Icon which="chevron-down" />}
            minimal={true}
            small={true}
            text={option.content}
            onClick={_handlePopoverToggle}
          />
        </Popover>
      </div>
    );
  }
);
