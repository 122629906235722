import React, { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';

import { QUERY_PARAMS } from '../../misc/consts';
import { User } from '../../misc/types';
import { Button, Input, InputGroup } from '../design-system';

import styles from '../../styles/account.module.scss';

interface ReferralURLProps {
  user: User;
}

export const ReferralURL: React.ComponentType<ReferralURLProps> = React.memo(
  ({ user }) => {
    const { addToast } = useToasts();

    const referralLink = `https://meetwell.app/?${QUERY_PARAMS.Referral}=${user.attributes.affiliate_token}`;

    const _handleFocus = useCallback(evt => {
      evt.target.select();
    }, []);

    const _copyToClipboard = useCallback(() => {
      navigator.clipboard.writeText(referralLink);
      addToast('Copied to clipboard!', {
        appearance: 'success',
        autoDismiss: true
      });
    }, [addToast, referralLink]);

    return (
      <div className={styles.section}>
        <h5>Referral URL</h5>
        <div className={styles.desc}>
          For every paying customer you refer with the url below, you'll get a
          $2 / month commission.{' '}
          <a href="/referrals" rel="noreferrer" target="_blank">
            Learn more
          </a>
        </div>
        <div className={styles.desc}>
          Use this URL where ever you talk about MeetWell.
        </div>
        <InputGroup>
          <Input
            value={referralLink}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onChange={() => {}}
            onClick={_handleFocus}
          />
          <Button text="Copy" onClick={_copyToClipboard} />
        </InputGroup>
      </div>
    );
  }
);
