/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import Cookies from 'universal-cookie';

import { Loading } from '../components/design-system';
import { Footer } from '../components/misc/Footer';
import { Header } from '../components/misc/Header';
import { NotificationHandler } from '../components/misc/NotificationHandler';
import { WelcomeModal } from '../components/onboarding/WelcomeModal';
import { usePrevious } from '../hooks/previous';
import { DIV_IDS, PAGE_TO_ALLOW_ACCESS } from '../misc/constants';
import { COOKIE_NAME, URLS } from '../misc/consts';
import { RootState } from '../misc/types';
import { isInApp } from '../misc/utils';
import { getCalendars } from '../modules/calendars';
import { getGoals } from '../modules/goals';
import { getMeetingIntentions } from '../modules/meeting-info';
import { getSettingsLabels } from '../modules/settings-labels';
import { getTips } from '../modules/tips';
import {
  getSubscription,
  getSynchronizations,
  getUser,
  getUserSettings,
  logoutUser,
  setTokenAndConfig
} from '../modules/user';

import { AccountView } from './app/Account';
import { BalanceView } from './app/Balance';
import { MissingView } from './app/Missing';
import { PlanView } from './app/Plan';
import { ReviewView } from './app/Review';
import { SessionView } from './app/Session';
import { TeamMgmtView } from './app/TeamMgmt';
import { BlogView } from './marketing/blog';
import { DpaView } from './marketing/dpa';
import { FAQView } from './marketing/faq';
import HelpView from './marketing/help';
import { HomeView } from './marketing/Home';
import { MarketingHeader } from './marketing/marketing-header';
import NeedsPermissionView from './marketing/needs-permission';
import PreventDeclineView from './marketing/prevent-decline';
import { PricingView } from './marketing/Pricing';
import { PrivacyView } from './marketing/privacy';
import { ReferralsView } from './marketing/Referrals';
import SecurityView from './marketing/security';
import { TermsView } from './marketing/terms';
import UnderMaintenanceView from './marketing/under-maintenance';
import { UnsubscribeView } from './marketing/Unsubscribe';

import styles from '../styles/app.module.scss';

export const App: React.ComponentType = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state: RootState) => state.user.general);
  const userStatus = useSelector((state: RootState) => state.user.status);
  const prevUser = usePrevious(user);

  const location = useLocation();
  const prevLocation = usePrevious(location);

  const isLoading = useSelector((state: RootState) => {
    return state.api.isCreating === 0 &&
      state.api.isReading === 0 &&
      state.api.isDeleting === 0 &&
      state.api.isUpdating === 0
      ? false
      : true;
  });

  const isInAppState = useMemo(() => isInApp(location), [location]);

  useEffect(() => {
    /////
    // Check if in store, if not check cookies and get user info
    /////

    // If token is in cookies
    const cookies = new Cookies();
    const token = cookies.get(COOKIE_NAME);

    // If there is a token and in app view
    if (!isEmpty(token) && location.pathname.includes(URLS.Sub)) {
      dispatch(
        setTokenAndConfig(token, () => {
          dispatch(getUser());
        })
      );
    } else {
      // Redirect if not on a marketing page
      if (isInApp(location)) {
        history.push('/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /////
    // Call appropriate endpoints if user has a subscription
    /////

    // If there is a token and in app view
    // Only call if prevUser is undefined, and 'user' is not
    // Which makes sure the below only is called once
    if (
      user &&
      prevUser === undefined &&
      isEmpty(user.attributes.deactivated_at)
    ) {
      dispatch(getUserSettings());
      dispatch(getCalendars());
      dispatch(getSettingsLabels());
      dispatch(getGoals());
      dispatch(getSubscription());
      dispatch(getMeetingIntentions());
      dispatch(getTips());
      dispatch(getSynchronizations());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const _checkForDeactivation = useCallback(() => {
    if (
      user &&
      !isEmpty(user.attributes.deactivated_at) &&
      !PAGE_TO_ALLOW_ACCESS.includes(location.pathname)
    ) {
      history.push(URLS.Balance.PlanChooser);
    }
  }, [history, location, prevLocation, user]);

  useEffect(() => {
    /////
    // Redirect if user is deactivated
    /////
    _checkForDeactivation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    /////
    // Redirect if user is deactivated
    /////
    if (
      location &&
      prevLocation &&
      !isEqual(location.pathname, prevLocation.pathname)
    ) {
      _checkForDeactivation();
    }
  }, [location]);

  useEffect(() => {
    // Check error statuses
    if (userStatus !== undefined) {
      switch (userStatus) {
        /////
        // Log out if unauthorized and signature has expired
        case 401:
          dispatch(logoutUser());
          break;

        /////
        // Incorrect permission for calendar access
        case 422:
          dispatch(logoutUser({ url: '/needs-permission' }));
          break;

        ////
        // If under maintenance, redirect to page
        case 503:
          history.push('/maintenance');
          break;

        default:
          console.error(`Unhandled error: ${userStatus}`);
      }
    }
  }, [dispatch, history, userStatus]);

  const maybeRenderLoadingIndicator = () => {
    return (
      <div
        className={classNames(styles.loadingIndicator, {
          [styles.visible]: isLoading
        })}>
        <div className={styles.indicator}>
          <Loading small />
        </div>
        <div className={styles.text}>Loading...</div>
      </div>
    );
  };

  return (
    <div
      className={classNames('wrapper', {
        ['in-app']: isInAppState, //eslint-disable-line
        ['is-mobile']: isMobile //eslint-disable-line
      })}
      id={DIV_IDS.Scroll}>
      {maybeRenderLoadingIndicator()}
      {isInAppState ? <Header /> : <MarketingHeader />}
      <div className="main">
        {isInAppState && <NotificationHandler />}
        {isInAppState && <WelcomeModal />}
        <Switch>
          {/* MARKETING PAGES */}
          <Route component={HomeView} exact path="/" />
          <Route component={PricingView} exact path="/pricing" />
          <Route component={SessionView} exact path="/session" />
          <Route component={PrivacyView} exact path="/privacy" />
          <Route component={TermsView} exact path="/terms" />
          <Route component={DpaView} exact path="/dpa" />
          <Route component={SecurityView} exact path="/security" />
          <Route component={ReferralsView} exact path="/referrals" />
          <Route component={HelpView} exact path="/help" />
          <Route component={BlogView} exact path="/blog" />
          <Route component={BlogView} exact path="/blog/:page" />
          <Route component={FAQView} exact path="/faq" />

          {/* UNAUTH PAGES */}
          <Route component={UnsubscribeView} exact path="/unsubscribe" />
          <Route component={PreventDeclineView} exact path="/prevent-decline" />
          <Route component={UnderMaintenanceView} exact path="/maintenance" />
          <Route
            component={NeedsPermissionView}
            exact
            path="/needs-permission"
          />

          {/* PLAN VIEW */}
          <Route
            component={PlanView}
            exact
            path={[
              URLS.Plan.Default,
              `${URLS.Plan.Meeting}/:id`,
              `${URLS.Plan.ViewBlock}/:blockId`,
              URLS.Plan.NewBlock
            ]}
          />

          {/* REVIEW VIEW */}
          <Route
            component={ReviewView}
            exact
            path={[
              URLS.Review.Default,
              `${URLS.Review.Default}/:view`,
              `${URLS.Review.Default}/:view/:statusFilter`
            ]}
          />

          {/* BALANCE VIEW */}
          <Route
            component={BalanceView}
            exact
            path={[
              URLS.Balance.Default,
              `${URLS.Balance.Default}/:view`,
              `${URLS.Balance.Default}/:view/:subView`,
              `${URLS.Balance.Default}/:view/block/new`,
              `${URLS.Balance.Default}/:view/block/:blockId`
            ]}
          />

          {/* ACCOUNT VIEW */}
          <Route
            component={AccountView}
            exact
            path={[URLS.Account.Default, `${URLS.Account.Default}/:view`]}
          />
          <Route component={TeamMgmtView} exact path={URLS.Account.TeamMgmt} />

          {/* MISSING VIEW */}
          <Route component={MissingView} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
});
