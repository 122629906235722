import React from 'react';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

const ICON_COLOR = '#8a94a4';

export interface IconProps {
  className?: string;
  color?: string;
  rotate?: number;
}

export interface IconWrapperProps extends IconProps {
  which: string;
}

export interface NavIconProps extends IconProps {
  isSelected?: boolean;
}

export interface NavIconWrapperProps extends IconWrapperProps {
  isSelected?: boolean;
}

export const Icon: React.ComponentType<IconWrapperProps> = React.memo(props => {
  const { which, className } = props;
  return (
    <div className={classNames(styles.icon, className)}>
      {which === 'arrow-down' && <ArrowDown {...props} />}
      {which === 'arrow-up' && <ArrowUp {...props} />}
      {which === 'arrow-right' && <ArrowRight {...props} />}
      {which === 'arrow-right-small' && <ArrowRightSmall {...props} />}
      {which === 'arrow-left' && <ArrowRight {...props} rotate={180} />}
      {which === 'comparison-arrows' && <ComparisonArrows {...props} />}
      {which === 'chevron-left' && <ChevronLeft {...props} />}
      {which === 'chevron-right' && <ChevronRight {...props} />}
      {which === 'chevron-down' && <ChevronRight {...props} rotate={90} />}
      {which === 'chevron-up' && <ChevronRight {...props} rotate={-90} />}
      {which === 'description' && <Description />}
      {which === 'location' && <Location {...props} />}
      {which === 'people' && <People {...props} />}
      {which === 'alert' && <Alert />}
      {which === 'tick' && <Tick {...props} />}
      {which === 'question' && <Question {...props} />}
      {which === 'cross' && <Cross color="#8a94a4" />}
      {which === 'drag' && <DragHandle />}
      {which === 'lock' && <Lock {...props} />}
      {which === 'unlock' && <Unlock {...props} />}
      {which === 'help' && <Help {...props} />}
      {which === 'thought' && <Thought {...props} />}
      {which === 'wellness' && <Wellness {...props} />}
      {which === 'settings' && <Settings {...props} />}
      {which === 'refresh' && <Refresh {...props} />}
      {which === 'present' && <Present {...props} />}
      {which === 'add' && <Add {...props} />}
      {which === 'add-small' && <AddSmall {...props} />}
      {which === 'kebab' && <Kebab {...props} />}
    </div>
  );
});

export const ArrowDown: React.ComponentType<IconProps> = React.memo(
  function ArrowDown(props) {
    return (
      <svg
        fill="none"
        height="12"
        viewBox="0 0 10 12"
        width="10"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1 6.875L5 11L9 6.875"
          fill="none"
          stroke={props.color ? props.color : ICON_COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M5 10.125V1"
          fill="none"
          stroke={props.color ? props.color : ICON_COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  }
);

export const ArrowUp: React.ComponentType<IconProps> = React.memo(
  function ArrowUp(props) {
    return (
      <svg
        fill="none"
        height="12"
        viewBox="0 0 12 12"
        width="12"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g
            fill={props.color ? props.color : ICON_COLOR}
            fillRule="nonzero"
            transform="translate(1.000000, 0.000000)">
            <path d="M10.0285714,4.54285714 L5.74285714,0.257142857 C5.57142857,0.0857142857 5.4,0 5.14285714,0 C4.88571429,0 4.71428571,0.0857142857 4.54285714,0.257142857 L0.257142857,4.54285714 C0.0857142857,4.71428571 0,4.88571429 0,5.14285714 C0,5.65714286 0.428571429,6 0.857142857,6 C1.11428571,6 1.28571429,5.91428571 1.45714286,5.74285714 L4.28571429,2.91428571 L4.28571429,11.1428571 C4.28571429,11.6571429 4.62857143,12 5.14285714,12 C5.65714286,12 6,11.6571429 6,11.1428571 L6,2.91428571 L8.82857143,5.74285714 C9,5.91428571 9.17142857,6 9.42857143,6 C9.94285714,6 10.2857143,5.65714286 10.2857143,5.14285714 C10.2857143,4.88571429 10.2,4.71428571 10.0285714,4.54285714 L10.0285714,4.54285714 Z"></path>
          </g>
        </g>
      </svg>
    );
  }
);

export const ComparisonArrows: React.ComponentType<IconProps> = React.memo(
  function ComparisonArrows() {
    return (
      <svg
        fill="none"
        height="10"
        viewBox="0 0 18 10"
        width="18"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.0625 1L1.9375 5L6.0625 9"
          fill="none"
          stroke={ICON_COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M11.9375 9L16.0625 5L11.9375 1"
          fill="none"
          stroke={ICON_COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M3.8125 5L13.9375 5"
          fill="none"
          stroke={ICON_COLOR}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </svg>
    );
  }
);

export const Tick: React.ComponentType<IconProps> = React.memo(function Tick(
  props
) {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.25741 13.6087C5.88451 13.6087 5.53134 13.4549 5.30503 13.1897L2.24217 9.6119C2.14747 9.50141 2.07834 9.3755 2.03872 9.24138C1.9991 9.10725 1.98977 8.96754 2.01127 8.83022C2.03276 8.69291 2.08466 8.56068 2.16399 8.44111C2.24332 8.32154 2.34854 8.21696 2.47362 8.13336C2.59865 8.04941 2.74119 7.9881 2.89308 7.95294C3.04497 7.91778 3.20321 7.90947 3.35873 7.92848C3.51426 7.94749 3.66402 7.99344 3.79941 8.06371C3.93481 8.13398 4.0532 8.22718 4.14778 8.33798L6.16311 10.6903L11.2302 3.49691C11.3982 3.2594 11.666 3.09052 11.9747 3.0273C12.2834 2.96407 12.6078 3.01168 12.8769 3.15967C13.4367 3.46735 13.609 4.11909 13.2592 4.61472L7.27236 13.1101C7.17016 13.2557 7.02929 13.3772 6.86198 13.4638C6.69467 13.5504 6.50598 13.5997 6.31227 13.6072C6.29341 13.6087 6.27626 13.6087 6.25741 13.6087V13.6087Z"
        fill={props.color ? props.color : ICON_COLOR}
      />
    </svg>
  );
});

export const Question: React.ComponentType<IconProps> = React.memo(
  function Question(props) {
    return (
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.03882 13C7.82483 13 7.61565 12.9365 7.43773 12.8177C7.2598 12.6988 7.12113 12.5298 7.03924 12.3321C6.95735 12.1344 6.93592 11.9169 6.97767 11.707C7.01942 11.4971 7.12246 11.3043 7.27377 11.153C7.42509 11.0017 7.61787 10.8987 7.82775 10.8569C8.03762 10.8152 8.25517 10.8366 8.45286 10.9185C8.65056 11.0004 8.81954 11.139 8.93842 11.317C9.05731 11.4949 9.12077 11.7041 9.12077 11.9181C9.12077 12.205 9.00678 12.4802 8.80387 12.6831C8.60097 12.886 8.32577 13 8.03882 13ZM11.5266 6.28836C11.4019 6.72165 11.1588 7.11158 10.8247 7.4143C10.5578 7.66666 10.2723 7.89863 9.97072 8.10826C9.75273 8.26825 9.53874 8.43624 9.32475 8.60423C9.18168 8.7295 9.07292 8.88919 9.00877 9.06821C8.95411 9.20154 8.90078 9.32953 8.84878 9.45219C8.79542 9.59017 8.70151 9.70875 8.57941 9.7923C8.45731 9.87584 8.31276 9.92042 8.16482 9.92016C7.96938 9.94325 7.7718 9.90107 7.60285 9.80017C7.46478 9.7141 7.35263 9.59221 7.27832 9.44748C7.20402 9.30274 7.17034 9.14057 7.18087 8.97821C7.19181 8.57667 7.35228 8.19368 7.63084 7.90427C7.95458 7.57299 8.30656 7.27052 8.68279 7.00032C8.96329 6.79615 9.20507 6.54353 9.39675 6.25436C9.65874 5.81838 9.57674 5.10442 9.12876 4.78844C8.96706 4.67507 8.78176 4.59986 8.58679 4.56845C8.28914 4.51279 7.98244 4.52995 7.69284 4.61845C7.5357 4.67424 7.39173 4.76182 7.26993 4.87571C7.14812 4.9896 7.05109 5.12736 6.98488 5.28041C6.86888 5.5024 6.76689 5.73239 6.6349 5.94438C6.53417 6.1007 6.38611 6.22073 6.21233 6.28693C6.03855 6.35313 5.84815 6.36204 5.66895 6.31236C5.50714 6.28602 5.3568 6.21224 5.23697 6.10037C5.15725 6.02288 5.09496 5.92929 5.05427 5.82584C5.01357 5.72238 4.99541 5.61143 5.00098 5.5004C5.00627 5.1137 5.12749 4.73749 5.34897 4.42046C5.72182 3.88511 6.25142 3.47848 6.86488 3.25652C7.27455 3.0966 7.70914 3.00995 8.14882 3.00053C8.79281 2.99086 9.43195 3.11338 10.0267 3.36051C10.5304 3.56292 10.9573 3.91916 11.2467 4.37846C11.4212 4.66084 11.5361 4.97597 11.5843 5.30444C11.6324 5.63291 11.6128 5.96775 11.5266 6.28836Z"
          fill={props.color ? props.color : ICON_COLOR}
        />
      </svg>
    );
  }
);

export const Cross: React.ComponentType<IconProps> = React.memo(function Cross(
  props
) {
  return (
    <svg
      fill="none"
      height="10"
      viewBox="0 0 10 10"
      width="10"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.34592 5.00048L9.70952 1.63593C9.80039 1.54815 9.87288 1.44316 9.92275 1.32707C9.97262 1.21098 9.99887 1.08612 9.99996 0.959781C10.0011 0.83344 9.97699 0.708145 9.92914 0.591208C9.8813 0.47427 9.81065 0.368032 9.72131 0.278692C9.63197 0.189351 9.52573 0.118699 9.40879 0.0708557C9.29185 0.0230128 9.16656 -0.00106195 9.04022 3.59263e-05C8.91388 0.0011338 8.78902 0.0273825 8.67293 0.0772504C8.55684 0.127118 8.45185 0.199606 8.36408 0.290485L4.99952 3.65408L1.63593 0.290485C1.54815 0.199606 1.44316 0.127118 1.32707 0.0772504C1.21098 0.0273825 1.08612 0.0011338 0.959781 3.59263e-05C0.83344 -0.00106195 0.708145 0.0230128 0.591208 0.0708557C0.47427 0.118699 0.368032 0.189351 0.278692 0.278692C0.189351 0.368032 0.118699 0.47427 0.0708557 0.591208C0.0230128 0.708145 -0.00106195 0.83344 3.59263e-05 0.959781C0.0011338 1.08612 0.0273825 1.21098 0.0772504 1.32707C0.127118 1.44316 0.199606 1.54815 0.290485 1.63593L3.65408 4.99952L0.290485 8.36408C0.199606 8.45185 0.127118 8.55684 0.0772504 8.67293C0.0273825 8.78902 0.0011338 8.91388 3.59263e-05 9.04022C-0.00106195 9.16656 0.0230128 9.29185 0.0708557 9.40879C0.118699 9.52573 0.189351 9.63197 0.278692 9.72131C0.368032 9.81065 0.47427 9.8813 0.591208 9.92914C0.708145 9.97699 0.83344 10.0011 0.959781 9.99996C1.08612 9.99887 1.21098 9.97262 1.32707 9.92275C1.44316 9.87288 1.54815 9.80039 1.63593 9.70952L4.99952 6.34592L8.36408 9.70952C8.45185 9.80039 8.55684 9.87288 8.67293 9.92275C8.78902 9.97262 8.91388 9.99887 9.04022 9.99996C9.16656 10.0011 9.29185 9.97699 9.40879 9.92914C9.52573 9.8813 9.63197 9.81065 9.72131 9.72131C9.81065 9.63197 9.8813 9.52573 9.92914 9.40879C9.97699 9.29185 10.0011 9.16656 9.99996 9.04022C9.99887 8.91388 9.97262 8.78902 9.92275 8.67293C9.87288 8.55684 9.80039 8.45185 9.70952 8.36408L6.34592 4.99952V5.00048Z"
        fill={props.color ? props.color : ICON_COLOR}
      />
    </svg>
  );
});

export const Description: React.ComponentType<IconProps> = React.memo(
  function Description() {
    return (
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <rect fill="white" height="16" width="16" />
        <path
          clipRule="evenodd"
          d="M15 2H1V3H15V2ZM1 1C0.447715 1 0 1.44772 0 2V3C0 3.55228 0.447715 4 1 4H15C15.5523 4 16 3.55228 16 3V2C16 1.44772 15.5523 1 15 1H1ZM15 7H1V8H15V7ZM1 6C0.447715 6 0 6.44772 0 7V8C0 8.55228 0.447715 9 1 9H15C15.5523 9 16 8.55228 16 8V7C16 6.44772 15.5523 6 15 6H1ZM1 12H12V13H1L1 12ZM0 12C0 11.4477 0.447715 11 1 11H12C12.5523 11 13 11.4477 13 12V13C13 13.5523 12.5523 14 12 14H1C0.447715 14 0 13.5523 0 13V12Z"
          fill={ICON_COLOR}
          fillRule="evenodd"
          opacity="0.6"
        />
      </svg>
    );
  }
);

export const Location: React.ComponentType<IconProps> = React.memo(
  function Location() {
    return (
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <rect fill="white" height="16" width="16" />
          <path
            clipRule="evenodd"
            d="M7.94857 6.12972e-08C11.0162 6.12972e-08 13.541 2.32204 13.8642 5.32982L13.8639 5.3301C13.8841 5.53183 13.8948 5.73624 13.8948 5.94334C13.898 7.09778 13.563 8.22788 12.9311 9.19405L8.79627 15.5415L8.79408 15.5452C8.7014 15.6854 8.57535 15.8003 8.42724 15.8797C8.27913 15.9591 8.11361 16.0004 7.94557 16C7.77752 15.9996 7.61222 15.9574 7.46452 15.8772C7.31683 15.7971 7.19137 15.6815 7.09942 15.5408L2.96454 9.19332L2.9784 9.21593C2.38855 8.31906 2.05242 7.27928 2.00565 6.20684C1.95888 5.13441 2.20322 4.06931 2.71276 3.1245C3.2223 2.17968 3.97804 1.39038 4.89984 0.840294C5.82163 0.290208 6.87512 -0.000154028 7.94857 6.12972e-08ZM7.9493 14.8383L12.0156 8.59585C12.619 7.66185 12.885 6.5497 12.7695 5.44379C12.6541 4.33787 12.1642 3.30462 11.3809 2.51536C10.5026 1.63703 9.28942 1.09354 7.9493 1.09354C6.60919 1.09354 5.39601 1.63703 4.51767 2.51536C3.73445 3.30462 3.2445 4.33787 3.12907 5.44379C3.01363 6.5497 3.27964 7.66185 3.883 8.59585L7.9493 14.8383ZM6.73368 4.13101C7.09359 3.89053 7.51672 3.76218 7.94957 3.76218C8.52977 3.76295 9.08598 3.99378 9.49625 4.40404C9.90651 4.8143 10.1373 5.37052 10.1381 5.95072C10.1381 6.38357 10.0098 6.8067 9.76927 7.1666C9.52879 7.52651 9.18699 7.80702 8.78709 7.97266C8.38718 8.13831 7.94714 8.18165 7.52261 8.0972C7.09807 8.01276 6.70811 7.80432 6.40204 7.49825C6.09597 7.19217 5.88753 6.80221 5.80308 6.37768C5.71864 5.95314 5.76198 5.5131 5.92762 5.1132C6.09327 4.7133 6.37378 4.37149 6.73368 4.13101ZM7.34163 6.86057C7.52158 6.98081 7.73314 7.04498 7.94957 7.04498C8.23967 7.0446 8.51778 6.92919 8.72291 6.72405C8.92804 6.51892 9.04345 6.24082 9.04384 5.95072C9.04384 5.73429 8.97966 5.52272 8.85942 5.34277C8.73918 5.16282 8.56828 5.02257 8.36833 4.93974C8.16838 4.85692 7.94836 4.83525 7.73609 4.87747C7.52382 4.9197 7.32884 5.02392 7.1758 5.17695C7.02277 5.32999 6.91855 5.52497 6.87633 5.73724C6.8341 5.9495 6.85577 6.16952 6.9386 6.36947C7.02142 6.56943 7.16167 6.74033 7.34163 6.86057Z"
            fill={ICON_COLOR}
            fillRule="evenodd"
            opacity="0.6"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect fill="white" height="16" width="16" />
          </clipPath>
        </defs>
      </svg>
    );
  }
);

export const People: React.ComponentType<IconProps> = React.memo(
  function People(props) {
    return (
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M10.086 6.51194C9.5013 6.90263 8.81388 7.11116 8.11066 7.11116C7.16767 7.11116 6.26331 6.73656 5.59652 6.06976C4.92973 5.40296 4.55513 4.49858 4.55513 3.55558C4.55513 2.85236 4.76365 2.16492 5.15434 1.58021C5.54503 0.995495 6.10033 0.539768 6.75001 0.270654C7.3997 0.00154057 8.1146 -0.0688718 8.80431 0.0683211C9.49401 0.205514 10.1275 0.54415 10.6248 1.04141C11.122 1.53866 11.4607 2.17221 11.5979 2.86192C11.7351 3.55164 11.6647 4.26655 11.3955 4.91624C11.1264 5.56594 10.6707 6.12125 10.086 6.51194ZM9.52162 1.4439C9.10398 1.16483 8.61296 1.01588 8.11066 1.01588C7.4371 1.01588 6.79112 1.28346 6.31484 1.75974C5.83856 2.23603 5.57099 2.88201 5.57099 3.55558C5.57099 4.05789 5.71994 4.54891 5.999 4.96656C6.27807 5.38422 6.67471 5.70974 7.13877 5.90196C7.60283 6.09418 8.11348 6.14448 8.60612 6.04648C9.09877 5.94849 9.5513 5.70661 9.90647 5.35142C10.2617 4.99624 10.5035 4.54371 10.6015 4.05105C10.6995 3.5584 10.6492 3.04775 10.457 2.58368C10.2648 2.11961 9.93927 1.72296 9.52162 1.4439ZM15.0619 15.8511C14.9593 15.9464 14.8202 15.9999 14.6751 15.9999H1.54701C1.40193 15.9999 1.2628 15.9464 1.16021 15.8511C1.05763 15.7559 1 15.6267 1 15.492C1 13.7407 1.7492 12.0611 3.08278 10.8228C4.41637 9.58443 6.2251 8.88873 8.11107 8.88873C9.99704 8.88873 11.8058 9.58443 13.1394 10.8228C14.4729 12.0611 15.2221 13.7407 15.2221 15.492C15.2221 15.6267 15.1645 15.7559 15.0619 15.8511ZM12.1835 11.3576C11.0696 10.4173 9.61649 9.89883 8.11107 9.90461C6.60565 9.89883 5.15258 10.4173 4.03865 11.3576C2.92472 12.298 2.23086 13.5919 2.09401 14.984H14.1281C13.9913 13.5919 13.2974 12.298 12.1835 11.3576Z"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="evenodd"
          opacity="0.6"
        />
      </svg>
    );
  }
);

export const ChevronLeft: React.ComponentType<IconProps> = React.memo(
  function ChevronLeft(props) {
    return (
      <svg
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            clipRule="evenodd"
            d="M7.41,8l3.29-3.29C10.89,4.53,11,4.28,11,4c0-0.55-0.45-1-1-1
			C9.72,3,9.47,3.11,9.29,3.29l-4,4C5.11,7.47,5,7.72,5,8c0,0.28,0.11,0.53,0.29,0.71l4,4C9.47,12.89,9.72,13,10,13
			c0.55,0,1-0.45,1-1c0-0.28-0.11-0.53-0.29-0.71L7.41,8z"
            fill={props.color ? props.color : ICON_COLOR}
            fillRule="evenodd"
          />
        </g>
      </svg>
    );
  }
);

export const ChevronRight: React.ComponentType<IconProps> = React.memo(
  function ChevronRight(props) {
    return (
      <svg
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <g
          transform={
            props.rotate ? `rotate(${props.rotate}, 8, 8)` : `rotate(0)`
          }>
          <path
            clipRule="evenodd"
            d="M10.71,7.29l-4-4C6.53,3.11,6.28,3,6,3C5.45,3,5,3.45,5,4
			c0,0.28,0.11,0.53,0.29,0.71L8.59,8l-3.29,3.29C5.11,11.47,5,11.72,5,12c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l4-4
			C10.89,8.53,11,8.28,11,8C11,7.72,10.89,7.47,10.71,7.29z"
            fill={props.color ? props.color : ICON_COLOR}
            fillRule="evenodd"
          />
        </g>
      </svg>
    );
  }
);

export const ArrowRight: React.ComponentType<IconProps> = React.memo(props => {
  return (
    <svg
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg">
      <g
        transform={
          props.rotate ? `rotate(${props.rotate}, 8, 8)` : `rotate(0)`
        }>
        <path
          clipRule="evenodd"
          d="M14.7,7.29l-5-5C9.52,2.1,9.27,1.99,8.99,1.99c-0.55,0-1,0.45-1,1
			c0,0.28,0.11,0.53,0.29,0.71l3.29,3.29H1.99c-0.55,0-1,0.45-1,1s0.45,1,1,1h9.59l-3.29,3.29c-0.18,0.18-0.29,0.43-0.29,0.71
			c0,0.55,0.45,1,1,1c0.28,0,0.53-0.11,0.71-0.29l5-5c0.18-0.18,0.29-0.43,0.29-0.71S14.88,7.47,14.7,7.29z"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
});

export const ArrowRightSmall: React.ComponentType<IconProps> = React.memo(
  props => {
    return (
      <svg
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            d="M14.5 8.5H2M14.5 8.5L10.5 4.5L14.5 8.5ZM14.5 8.5L10.5 12.5L14.5 8.5Z"
            stroke={props.color ? props.color : ICON_COLOR}
          />
          <line
            stroke={props.color ? props.color : ICON_COLOR}
            x1="1.5"
            x2="1.5"
            y1="9"
            y2="3"
          />
        </g>
      </svg>
    );
  }
);

export const AddSmall: React.ComponentType<IconProps> = React.memo(props => {
  return (
    <svg
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 4v7M4 7.5h7"
        stroke={props.color ? props.color : ICON_COLOR}></path>
    </svg>
  );
});

export const DragHandle: React.ComponentType<IconProps> = React.memo(
  function DragHandle(props) {
    return (
      <svg
        enableBackground="new 0 0 16 16"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <g id="drag_handle_vertical_4_">
          <g>
            <path
              clipRule="evenodd"
              d="M6,9c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1s1-0.45,1-1C7,9.45,6.55,9,6,9z M10,3
			c0.55,0,1-0.45,1-1c0-0.55-0.45-1-1-1S9,1.45,9,2C9,2.55,9.45,3,10,3z M6,13c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1s1-0.45,1-1
			C7,13.45,6.55,13,6,13z M6,5C5.45,5,5,5.45,5,6c0,0.55,0.45,1,1,1s1-0.45,1-1C7,5.45,6.55,5,6,5z M6,1C5.45,1,5,1.45,5,2
			c0,0.55,0.45,1,1,1s1-0.45,1-1C7,1.45,6.55,1,6,1z M10,9c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1s1-0.45,1-1C11,9.45,10.55,9,10,9z
			 M10,5C9.45,5,9,5.45,9,6c0,0.55,0.45,1,1,1s1-0.45,1-1C11,5.45,10.55,5,10,5z M10,13c-0.55,0-1,0.45-1,1c0,0.55,0.45,1,1,1
			s1-0.45,1-1C11,13.45,10.55,13,10,13z"
              fill={props.color ? props.color : ICON_COLOR}
              fillRule="evenodd"
            />
          </g>
        </g>
      </svg>
    );
  }
);

export const Alert: React.ComponentType<IconProps> = React.memo(function Alert(
  props
) {
  return (
    <svg
      height="16px"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M8,0 C3.58666667,0 0,3.59111111 0,8 C0,12.4088889 3.58666667,16 8,16 C12.4088889,16 16,12.4088889 16,8 C16,3.59111111 12.4088889,0 8,0 Z M8,15.1111111 C4.08,15.1111111 0.888888889,11.92 0.888888889,8 C0.888888889,4.08 4.08,0.888888889 8,0.888888889 C11.92,0.888888889 15.1111111,4.08 15.1111111,8 C15.1111111,11.92 11.92,15.1111111 8,15.1111111 Z"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="nonzero"></path>
        <path
          d="M8,9 C8.275,9 8.5,8.80714286 8.5,8.57142857 L8.5,3.42857143 C8.5,3.19285714 8.275,3 8,3 C7.725,3 7.5,3.19285714 7.5,3.42857143 L7.5,8.57142857 C7.5,8.80714286 7.725,9 8,9 Z"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="nonzero"></path>
        <path
          d="M8,10 C7.175,10 6.5,10.675 6.5,11.5 C6.5,12.325 7.175,13 8,13 C8.825,13 9.5,12.325 9.5,11.5 C9.5,10.675 8.825,10 8,10 Z M8,12 C7.725,12 7.5,11.775 7.5,11.5 C7.5,11.225 7.725,11 8,11 C8.275,11 8.5,11.225 8.5,11.5 C8.5,11.775 8.275,12 8,12 Z"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="nonzero"></path>
      </g>
    </svg>
  );
});

export const Lock: React.ComponentType<IconProps> = React.memo(function Lock(
  props
) {
  return (
    <svg
      height="16px"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="nonzero"
          transform="translate(1.000000, 0.000000)">
          <path d="M12.96,7 L11,7 L11,3.95 C11,1.77 9.21,0 7,0 C4.79,0 3,1.77 3,3.95 L3,7 L0.96,7 C0.41,7 0,7.35 0,7.9 L0,14.81 C0,15.35 0.41,16 0.96,16 L12.96,16 C13.51,16 14,15.35 14,14.81 L14,7.9 C14,7.35 13.51,7 12.96,7 Z M5,7 L5,3.95 C5,2.86 5.9,1.98 7,1.98 C8.1,1.98 9,2.86 9,3.95 L9,7 L5,7 Z"></path>
        </g>
      </g>
    </svg>
  );
});

export const Unlock: React.ComponentType<IconProps> = React.memo(
  function Unlock(props) {
    return (
      <svg
        height="16px"
        viewBox="0 0 16 16"
        width="16px"
        xmlns="http://www.w3.org/2000/svg">
        <g
          clipRule="evenodd"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="evenodd">
          <path
            d="M11.99-0.01c-2.21,0-4,1.79-4,4v3h-7c-0.55,0-1,0.45-1,1v7c0,0.55,0.45,1,1,1h12
			c0.55,0,1-0.45,1-1v-7c0-0.55-0.45-1-1-1h-3v-3c0-1.1,0.9-2,2-2s2,0.9,2,2v1c0,0.55,0.45,1,1,1s1-0.45,1-1v-1
			C15.99,1.78,14.2-0.01,11.99-0.01z"
          />
        </g>
      </svg>
    );
  }
);

export const Help: React.ComponentType<IconProps> = React.memo(function Help(
  props
) {
  return (
    <svg
      height="16px"
      viewBox="0 0 16 16"
      width="16px"
      xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          d="M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z M8,1.06666667 C4.17082573,1.06666667 1.06666667,4.17082573 1.06666667,8 C1.06666667,11.8291743 4.17082573,14.9333333 8,14.9333333 C11.8291743,14.9333333 14.9333333,11.8291743 14.9333333,8 C14.9333333,6.16116567 14.2028592,4.39764521 12.902607,3.09739298 C11.6023548,1.79714076 9.83883433,1.06666667 8,1.06666667 Z M8.53333333,10.1333333 L8.53333333,9.97333333 C8.54581347,9.36997492 8.86564309,8.81479898 9.38133333,8.50133333 C10.3219515,8.00128357 10.8228358,6.94313828 10.6133333,5.89866667 C10.3791753,4.76880385 9.45384386,3.9120155 8.30933333,3.76533333 C7.55576881,3.67731514 6.80038762,3.91444645 6.23232767,4.41735257 C5.66426771,4.9202587 5.33731463,5.64132297 5.33333333,6.4 C5.33333333,6.69455187 5.5721148,6.93333333 5.86666667,6.93333333 C6.16121853,6.93333333 6.4,6.69455187 6.4,6.4 C6.39667584,5.94019226 6.59133608,5.50118012 6.93433296,5.19493291 C7.27732983,4.8886857 7.7355044,4.74480961 8.192,4.8 C8.88325194,4.89396429 9.4383449,5.41690901 9.57333333,6.10133333 C9.616,6.32 9.75466667,6.98666667 8.81066667,7.58933333 C7.98143499,8.09896933 7.47345119,9.00003585 7.46666667,9.97333333 L7.46666667,10.1333333 C7.46666668,10.4278852 7.70544814,10.6666666 8,10.6666666 C8.29455186,10.6666666 8.53333332,10.4278852 8.53333333,10.1333333 Z M8,11.2 C7.70544813,11.2 7.46666667,11.4387815 7.46666667,11.7333333 C7.46666667,12.0278852 7.70544813,12.2666667 8,12.2666667 C8.29455187,12.2666667 8.53333333,12.0278852 8.53333333,11.7333333 C8.53333333,11.4387815 8.29455187,11.2 8,11.2 Z"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="nonzero"></path>
      </g>
    </svg>
  );
});

export const Thought: React.ComponentType<IconProps> = React.memo(
  function Thought(props) {
    return (
      <svg
        height="16px"
        viewBox="0 0 16 16"
        width="16px"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            d="M9.97171717,14.9959596 C9.76161616,14.9959596 9.55151515,14.9313131 9.37373737,14.7858586 L6.00036621,12 L1.93939394,11.989899 C0.872727273,11.989899 0,11.1171717 0,10.0505051 L0,2.93939394 C0,1.87272727 0.872727273,1 1.93939394,1 L14.0606061,1 C15.1272727,1 16,1.87272727 16,2.93939394 L16,10.0505051 C16,11.1171717 15.1272727,11.989899 14.0606061,11.989899 L11,12 L11,14.0262626 C11,14.3979798 10.7313131,14.7373737 10.3919192,14.8989899 C10.2626263,14.9636364 10.1171717,14.9959596 9.97171717,14.9959596 Z M1.93939394,2 C1.58383838,2 1,2.58383838 1,2.93939394 L1,10.0505051 C1,10.4060606 1.58383838,11 1.93939394,11 L6.00036621,11 C6.14582076,11 6.4,11.1030303 6.51313131,11.2 L10,14.0262626 L10,11.3434343 C10,10.9878788 10.3844444,11 10.74,11 L14.0606061,11 C14.4161616,11 15,10.4060606 15,10.0505051 L15,2.93939394 C15,2.58383838 14.4161616,2 14.0606061,2 L1.93939394,2 Z"
            fill={props.color ? props.color : ICON_COLOR}
            fillRule="nonzero"></path>
        </g>
      </svg>
    );
  }
);

export const Wellness: React.ComponentType<IconProps> = React.memo(
  function Wellness(props) {
    return (
      <svg
        height="16px"
        viewBox="0 0 16 16"
        width="16px"
        xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M8 2.5304C8.60496 1.80368 9.64736 1 11.2987 1C14.1109 1 16 3.60496 16 6.03776C16 9.57936 9.19712 14.3259 8.42144 14.8554C8.2944 14.9419 8.1472 14.9853 8 14.9853C7.8528 14.9853 7.7056 14.9419 7.57856 14.8554C6.80288 14.3259 0 9.57936 0 6.03776C0 3.60496 1.8888 1 4.7008 1C6.3528 1 7.39504 1.80368 8 2.5304ZM8 5.76601C8.30248 5.40226 8.82368 5 9.64936 5C11.0554 5 12 6.30385 12 7.52153C12 9.2942 8.59856 11.67 8.21072 11.935C8.1472 11.9783 8.0736 12 8 12C7.9264 12 7.8528 11.9783 7.78928 11.935C7.40144 11.67 4 9.2942 4 7.52153C4 6.30385 4.9444 5 6.3504 5C7.1764 5 7.69752 5.40226 8 5.76601Z"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="evenodd"
        />
      </svg>
    );
  }
);

export const Settings: React.ComponentType<IconProps> = React.memo(
  function Settings(props) {
    return (
      <svg
        height="16px"
        viewBox="0 0 16 16"
        width="16px"
        xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            d="M3,1 C3,0.45 2.55,0 2,0 C1.45,0 1,0.45 1,1 L1,4 L3,4 L3,1 Z M3,5 L1,5 C0.45,5 0,5.45 0,6 L0,8 C0,8.55 0.45,9 1,9 L3,9 C3.55,9 4,8.55 4,8 L4,6 C4,5.45 3.55,5 3,5 Z M15,1 C15,0.45 14.55,0 14,0 C13.45,0 13,0.45 13,1 L13,3 L15,3 L15,1 Z M9,1 C9,0.45 8.55,0 8,0 C7.45,0 7,0.45 7,1 L7,7 L9,7 L9,1 Z M1,15 C1,15.55 1.45,16 2,16 C2.55,16 3,15.55 3,15 L3,10 L1,10 L1,15 Z M15,4 L13,4 C12.45,4 12,4.45 12,5 L12,7 C12,7.55 12.45,8 13,8 L15,8 C15.55,8 16,7.55 16,7 L16,5 C16,4.45 15.55,4 15,4 Z M13,15 C13,15.55 13.45,16 14,16 C14.55,16 15,15.55 15,15 L15,9 L13,9 L13,15 Z M9,8 L7,8 C6.45,8 6,8.45 6,9 L6,11 C6,11.55 6.45,12 7,12 L9,12 C9.55,12 10,11.55 10,11 L10,9 C10,8.45 9.55,8 9,8 Z M7,15 C7,15.55 7.45,16 8,16 C8.55,16 9,15.55 9,15 L9,13 L7,13 L7,15 Z"
            fill={props.color ? props.color : ICON_COLOR}
            fillRule="nonzero"></path>
        </g>
      </svg>
    );
  }
);

export const Refresh: React.ComponentType<IconProps> = React.memo(
  function Refresh(props) {
    return (
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.9997 4.41165C14.0453 2.81171 12.5146 1.6612 10.6958 1.21178C8.87704 0.762356 6.98623 1.03201 5.36553 1.98478C3.96092 2.82969 2.91647 4.08807 2.35823 5.6161L1.69194 4.3757C1.45784 3.94425 0.899596 3.78246 0.46741 4.01616C0.0352228 4.24986 -0.126847 4.80714 0.107254 5.23858L1.79999 8.34858C1.81799 8.38453 1.836 8.42048 1.87202 8.45644C2.05209 8.69013 2.32221 8.81597 2.59233 8.81597C2.73639 8.81597 2.88045 8.78002 3.02451 8.70811L6.13986 7.01829C6.57205 6.78459 6.73412 6.22731 6.50002 5.79587C6.26591 5.36442 5.70767 5.20263 5.27549 5.43633L4.10498 6.06552C4.51916 5.00489 5.27549 4.10604 6.28392 3.51281C7.49044 2.79374 8.89505 2.59599 10.2456 2.93755C11.5962 3.27911 12.7307 4.12402 13.451 5.32847C14.1713 6.53292 14.3694 7.93511 14.0273 9.28337C13.6491 10.7395 12.6767 11.9799 11.3261 12.663C10.9299 12.8608 10.5158 13.0046 10.0836 13.1124C9.65138 13.2023 9.21919 13.2383 8.787 13.2203C8.42685 13.2023 8.04868 13.1484 7.68853 13.0585C7.22033 12.9327 6.75212 12.7529 6.33795 12.5012C5.90576 12.2495 5.36553 12.3934 5.11342 12.8248C4.86131 13.2562 5.00537 13.7956 5.43756 14.0472C5.9958 14.3708 6.60806 14.6225 7.25634 14.7843C7.74255 14.9101 8.22876 14.982 8.73298 15C8.82302 15 8.89505 15 8.98509 15C9.48931 15 9.99352 14.9461 10.4797 14.8382C11.056 14.7124 11.6142 14.5146 12.1545 14.245C13.9732 13.3282 15.2878 11.6743 15.792 9.71481C16.2422 7.91713 15.9541 6.02957 14.9997 4.41165Z"
          fill={props.color ? props.color : ICON_COLOR}
        />
      </svg>
    );
  }
);

export const Present: React.ComponentType<IconProps> = React.memo(
  function Present(props) {
    return (
      <svg
        fill="none"
        height="16"
        viewBox="0 0 16 16"
        width="16"
        xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M15 1H9C9 0.45 8.55 0 8 0C7.45 0 7 0.45 7 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3V11C1 11.55 1.45 12 2 12H5.59L3.3 14.29C3.11 14.47 3 14.72 3 15C3 15.55 3.45 16 4 16C4.28 16 4.53 15.89 4.71 15.71L7 13.41V15C7 15.55 7.45 16 8 16C8.55 16 9 15.55 9 15V13.41L11.29 15.7C11.47 15.89 11.72 16 12 16C12.55 16 13 15.55 13 15C13 14.72 12.89 14.47 12.71 14.29L10.41 12H14C14.55 12 15 11.55 15 11V3C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM13 10H3V3H13V10Z"
          fill={props.color ? props.color : ICON_COLOR}
          fillRule="evenodd"
        />
      </svg>
    );
  }
);

export const Add: React.ComponentType<IconProps> = React.memo(function Add(
  props
) {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M13 7H9V3C9 2.45 8.55 2 8 2C7.45 2 7 2.45 7 3V7H3C2.45 7 2 7.45 2 8C2 8.55 2.45 9 3 9H7V13C7 13.55 7.45 14 8 14C8.55 14 9 13.55 9 13V9H13C13.55 9 14 8.55 14 8C14 7.45 13.55 7 13 7Z"
        fill={props.color ? props.color : ICON_COLOR}
        fillRule="evenodd"
      />
    </svg>
  );
});

export const Kebab: React.ComponentType<IconProps> = React.memo(function Add(
  props
) {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="2"
        cy="8"
        fill={props.color ? props.color : ICON_COLOR}
        r="2"
      />
      <circle
        cx="14"
        cy="8"
        fill={props.color ? props.color : ICON_COLOR}
        r="2"
      />
      <circle
        cx="8"
        cy="8"
        fill={props.color ? props.color : ICON_COLOR}
        r="2"
      />
    </svg>
  );
});

export const NavIcon: React.ComponentType<NavIconWrapperProps> = props => (
  <div className={classNames(styles.navIcon, props.className)}>
    {props.which === 'review' && <NavReview {...props} />}
    {props.which === 'plan' && <NavPlan {...props} />}
    {props.which === 'balance' && <NavBalance {...props} />}
  </div>
);

const navIconProps = (isSelected: boolean) => {
  return {
    fill: isSelected ? `url(#grad1)` : 'url(#grad2)',
    fillOpacity: isSelected ? 1 : 0.6
  };
};

const selectedGradient = (
  <defs>
    <linearGradient id="grad1" x1="50%" x2="50%" y1="0.166187415%" y2="100%">
      <stop offset="0.0554265203%" stopColor="#4BBCFD"></stop>
      <stop offset="100%" stopColor="#1C75F7"></stop>
    </linearGradient>
    <linearGradient id="grad2" x1="50%" x2="50%" y1="0.221521823%" y2="100%">
      <stop offset="0%" stopColor="#C3D7E9"></stop>
      <stop offset="100%" stopColor="#93A5C1"></stop>
    </linearGradient>
  </defs>
);

export const NavReview: React.ComponentType<NavIconProps> = props => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 30 30"
    width="30"
    xmlns="http://www.w3.org/2000/svg">
    {selectedGradient}
    <path
      clipRule="evenodd"
      d="M5.66667 25.1111H25.6667C26.0203 25.1111 26.3594 25.2633 26.6095 25.5342C26.8595 25.8051 27 26.1725 27 26.5556C27 26.9386 26.8595 27.306 26.6095 27.5769C26.3594 27.8478 26.0203 28 25.6667 28H4.33333C3.97971 28 3.64057 27.8478 3.39052 27.5769C3.14048 27.306 3 26.9386 3 26.5556V15C3 14.6169 3.14048 14.2495 3.39052 13.9786C3.64057 13.7077 3.97971 13.5556 4.33333 13.5556C4.68696 13.5556 5.02609 13.7077 5.27614 13.9786C5.52619 14.2495 5.66667 14.6169 5.66667 15V25.1111ZM12.3333 19.3333C12.3333 19.7164 12.1929 20.0838 11.9428 20.3547C11.6928 20.6256 11.3536 20.7778 11 20.7778C10.6464 20.7778 10.3072 20.6256 10.0572 20.3547C9.80714 20.0838 9.66667 19.7164 9.66667 19.3333V6.33333C9.66667 5.95024 9.80714 5.58284 10.0572 5.31196C10.3072 5.04107 10.6464 4.88889 11 4.88889C11.3536 4.88889 11.6928 5.04107 11.9428 5.31196C12.1929 5.58284 12.3333 5.95024 12.3333 6.33333V19.3333ZM15 19.3333V9.22222C15 8.83913 15.1405 8.47173 15.3905 8.20085C15.6406 7.92996 15.9797 7.77778 16.3333 7.77778C16.687 7.77778 17.0261 7.92996 17.2761 8.20085C17.5262 8.47173 17.6667 8.83913 17.6667 9.22222V19.3333C17.6667 19.7164 17.5262 20.0838 17.2761 20.3547C17.0261 20.6256 16.687 20.7778 16.3333 20.7778C15.9797 20.7778 15.6406 20.6256 15.3905 20.3547C15.1405 20.0838 15 19.7164 15 19.3333ZM20.3333 3.44444C20.3333 3.06135 20.4738 2.69395 20.7239 2.42307C20.9739 2.15218 21.313 2 21.6667 2C22.0203 2 22.3594 2.15218 22.6095 2.42307C22.8595 2.69395 23 3.06135 23 3.44444V19.3333C23 19.7164 22.8595 20.0838 22.6095 20.3547C22.3594 20.6256 22.0203 20.7778 21.6667 20.7778C21.313 20.7778 20.9739 20.6256 20.7239 20.3547C20.4738 20.0838 20.3333 19.7164 20.3333 19.3333V3.44444Z"
      fillRule="evenodd"
      {...navIconProps(props.isSelected ? true : false)}
    />
  </svg>
);

export const NavPlan: React.ComponentType<NavIconProps> = props => (
  <svg
    fill="none"
    height="30"
    viewBox="0 0 30 30"
    width="30"
    xmlns="http://www.w3.org/2000/svg">
    {selectedGradient}
    <g {...navIconProps(props.isSelected ? true : false)}>
      <path d="M9.48721 11.1498C9.3163 11.0521 9.12034 11 8.91999 11C8.54214 11 8.19768 11.1783 7.99997 11.4754L5.00705 16.5729L3.95699 14.8325C3.62132 14.4011 2.91614 14.294 2.44866 14.6066C2.21448 14.7629 2.06026 14.9949 2.01413 15.2586C1.968 15.5228 2.03566 15.7878 2.20481 16.0043L3.71577 18.3362C4.04441 18.7578 4.52946 19 5.04703 19L5.10239 18.9992C5.64193 18.9813 6.12698 18.7065 6.43454 18.2446L9.83957 12.5271C10.152 12.0578 9.99423 11.4396 9.48721 11.1498Z" />
      <path d="M9.48721 2.14978C9.3163 2.0521 9.12034 2 8.91999 2C8.54214 2 8.19768 2.17827 7.99997 2.4754L5.00705 7.57295L3.95699 5.83249C3.62132 5.40105 2.91614 5.29401 2.44866 5.6066C2.21448 5.76289 2.06026 5.99489 2.01413 6.25864C1.968 6.5228 2.03566 6.78777 2.20481 7.0043L3.71577 9.33615C4.04441 9.75783 4.52946 10 5.04703 10L5.10239 9.99919C5.64193 9.98128 6.12698 9.70654 6.43454 9.24457L9.83957 3.52714C10.152 3.05784 9.99423 2.43958 9.48721 2.14978Z" />
      <path d="M9.48721 20.1498C9.3163 20.0521 9.12034 20 8.91999 20C8.54214 20 8.19768 20.1783 7.99997 20.4754L5.00705 25.5729L3.95699 23.8325C3.62132 23.4011 2.91614 23.294 2.44866 23.6066C2.21448 23.7629 2.06026 23.9949 2.01413 24.2586C1.968 24.5228 2.03566 24.7878 2.20481 25.0043L3.71577 27.3362C4.04441 27.7578 4.52946 28 5.04703 28L5.10239 27.9992C5.64193 27.9813 6.12698 27.7065 6.43454 27.2446L9.83957 21.5271C10.152 21.0578 9.99423 20.4396 9.48721 20.1498Z" />
      <rect height="2" rx="1" width="15" x="13" y="6" />
      <rect height="2" rx="1" width="15" x="13" y="15" />
      <rect height="2" rx="1" width="15" x="13" y="24" />
    </g>
  </svg>
);

export const NavBalance: React.ComponentType<NavIconProps> = props => (
  <svg
    height="30"
    viewBox="0 0 30 30"
    width="30"
    xmlns="http://www.w3.org/2000/svg">
    {selectedGradient}
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g {...navIconProps(props.isSelected ? true : false)} fillRule="nonzero">
        <g transform="translate(2.000000, 2.000000)">
          <path d="M7.70151515,15.5015152 C6.67727273,16.5257576 6.67727273,18.180303 7.70151515,19.1651515 C8.72575758,20.1893939 10.380303,20.1893939 11.3651515,19.1651515 C12.3893939,18.1409091 12.3893939,16.4863636 11.3651515,15.5015152 C10.380303,14.4772727 8.72575758,14.4772727 7.70151515,15.5015152 Z"></path>
          <path d="M3.80551215,3.80106809 C-1.26850405,8.86915888 -1.26850405,17.0961282 3.80551215,22.1989319 C8.87952834,27.2670227 17.1161163,27.2670227 22.224886,22.1989319 C27.2641487,17.0961282 27.2641487,8.86915888 22.1901325,3.80106809 C17.1161163,-1.2670227 8.87952834,-1.2670227 3.80551215,3.80106809 Z M19.1651515,11.3651515 C18.1409091,12.3893939 16.4863636,12.3893939 15.5015152,11.3651515 C14.4772727,10.3409091 14.4772727,8.68636364 15.5015152,7.70151515 C16.5257576,6.67727273 18.180303,6.67727273 19.1651515,7.70151515 C20.1893939,8.68636364 20.1893939,10.3409091 19.1651515,11.3651515 Z M12.8761849,5.01602136 C10.7214657,7.1682243 10.7214657,10.6742323 12.8761849,12.8611482 C13.0151991,13 13.5365021,13.5206943 13.6755162,13.6595461 C15.8302355,15.811749 15.8302355,19.317757 13.6755162,21.5046729 C12.3201284,22.858478 10.1654092,23.6568758 8.60150006,23.1708945 C7.35037277,22.6154873 6.16875256,21.8518024 5.16090003,20.8451268 C0.816708086,16.506008 0.816708086,9.49399199 5.16090003,5.15487316 C9.15755662,1.16288385 15.413193,0.85046729 19.757385,4.18291055 L19.7226314,4.2176235 C17.5679122,2.89853138 14.7181223,3.14152203 12.8761849,5.01602136 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);
