import React, { useRef } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { ModalDefault } from '../../../components/plan/ModalDefault';
import { useOnScreen } from '../../../hooks/on-screen';
import {
  EXAMPLE_INTENTIONS,
  EXAMPLE_MEETING,
  EXAMPLE_SETTINGS_LABELS,
  EXAMPLE_USER,
  EXAMPLE_USER_SETTINGS
} from '../../../misc/test-data';
import { getAnimationOffset } from '../../../misc/utilities';

import styles from '../../../styles/marketing/how-example-meeting.module.scss';

export const MEETING_DATA = {
  Bad: {
    ...EXAMPLE_MEETING,
    attributes: {
      ...EXAMPLE_MEETING.attributes,
      intention: null,
      score: 68,
      quality_label: `Now ask yourself, 'Is this a good use of my time?'`,
      agenda_items: { data: [] },
      objectives: { data: [] },
      cost: 900,
      duration: 7200,
      description: '',
      ends_at: moment()
        .startOf('isoWeek')
        .add(1, 'week')
        .set('hour', 15)
        .set('minute', 0)
        .set('seconds', 0)
        .format(),
      details_negative: {
        ...EXAMPLE_MEETING.attributes.details_negative,
        required_agenda: 'A time-boxed agenda can keep a meeting on track.',
        required_objectives:
          'An objective is missing. What is the aim of the meeting?'
      },
      status: 'none',
      results: {
        ...EXAMPLE_MEETING.attributes.results,
        desired_duration: {
          points: 1,
          weight: 6,
          score: 5,
          letter_grade: 'F'
        },
        required_agenda: {
          points: 5,
          weight: 21,
          score: 25,
          letter_grade: 'F'
        },
        required_objectives: {
          points: 1,
          weight: 11,
          score: 10,
          letter_grade: 'F'
        }
      }
    }
  },
  Good: {
    ...EXAMPLE_MEETING,
    description: ''
  }
};

interface HowExampleMeetingProps {
  which?: 'good' | 'bad';
}

export const HowExampleMeeting: React.ComponentType<HowExampleMeetingProps> =
  React.memo(({ which = 'good' }) => {
    const ref = useRef<HTMLDivElement>(null);
    const isVisible = useOnScreen<HTMLDivElement>(ref, getAnimationOffset());

    return (
      <div
        ref={ref}
        className={classNames(styles.howExampleMeeting, {
          [styles.isVisible]: isVisible
        })}>
        <div className={styles.meeting}>
          <ModalDefault
            event={which === 'good' ? MEETING_DATA.Good : MEETING_DATA.Bad}
            intentions={EXAMPLE_INTENTIONS}
            isForMarketing={true}
            settings={EXAMPLE_USER_SETTINGS}
            settings_labels={EXAMPLE_SETTINGS_LABELS}
            user={EXAMPLE_USER}
          />
        </div>
        <div className={classNames(styles.meeting, styles.stack)} />
      </div>
    );
  });
