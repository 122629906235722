import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { cloneDeep, isEmpty, sortBy } from 'lodash';

import { DELETE_STATUS, INTENT } from '../../misc/consts';
import { PreworkItem, SectionViewProps } from '../../misc/types';
import { Button, Tag, Tooltip } from '../design-system';

import styles from '../../styles/meeting-modal.module.scss';

export const SectionPreWorkView: React.ComponentType<SectionViewProps> =
  React.memo(({ event, className, settings }) => {
    const [preWorkItems, setPreWorkItems] = useState<PreworkItem[]>([]);

    const SectionHeader = () => {
      const { required_prework } = settings.attributes.evaluation_settings;

      return (
        <div className={styles.header}>
          <h6>Pre-work</h6>
          {required_prework && (
            <Tag
              intent={
                preWorkItems.length === 0 ? INTENT.Danger : INTENT.Success
              }
              small={true}>
              Required
            </Tag>
          )}
          <div className={styles.how}>
            <Tooltip content="Learn how to format your meeting description to correctly parse your pre-work.">
              <Button
                intent="primary"
                minimal={true}
                small={true}
                text="How?"
                onClick={() => window.open('/faq#plan-add-prework', '_blank')}
              />
            </Tooltip>
          </div>
        </div>
      );
    };

    const ListPreWorkItems = () => {
      // Return empty state if none
      if (preWorkItems.length === 0) {
        return <div className={styles.emptyText}>No prework items.</div>;
      }

      // Make sure they're sorted by position
      const sortedPreWorkItems = sortBy(cloneDeep(preWorkItems), [
        function (o) {
          return o.attributes.position;
        }
      ]);

      // Render one item
      const renderItem = (item: PreworkItem, key: number) => {
        if (item.status === DELETE_STATUS) return null;
        return (
          <div key={key} className={styles.preWorkItem}>
            <div className={styles.description}>
              <div className={styles.num}>{key + 1})</div>
              {item.attributes.url ? (
                <a
                  className={styles.text}
                  href={item.attributes.url}
                  target="_new">
                  {item.attributes.description}
                </a>
              ) : (
                <div className={styles.text}>{item.attributes.description}</div>
              )}
            </div>
            {item.attributes.timebox && (
              <div className={styles.duration}>
                {item.attributes.timebox} min
              </div>
            )}
          </div>
        );
      };

      return (
        <div className={styles.preWorkItems}>
          {sortedPreWorkItems.map((item, key) => renderItem(item, key))}
        </div>
      );
    };

    // Initialize
    useEffect(() => {
      const { prework_items } = event.attributes;
      const hasPreWorkItems =
        !isEmpty(prework_items.data) && prework_items.data.length > 0;

      // Set position and meeting_id
      const itemsToShow = hasPreWorkItems ? cloneDeep(prework_items.data) : [];
      for (let i = 0; i < itemsToShow.length; i++) {
        itemsToShow[i].attributes['position'] = i + 1;
        itemsToShow[i].attributes['meeting_id'] = parseInt(event.id);
      }

      setPreWorkItems(itemsToShow);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event]);

    return (
      <div className={classNames(styles.section, className)}>
        <SectionHeader />
        <ListPreWorkItems />
      </div>
    );
  });
