/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { set } from 'lodash';

import { isValidAPICall } from '../misc/utils';

import { metricsEvaluate } from './utils/reports-utils';

const initialState = {
  team: {},
  user: {}
};

export default (state = initialState, action) => {
  ////
  // REPORTS
  ////
  if (isValidAPICall(action, 'reports')) {
    const endpointArray = action.payload.finalEndpoint
      ? action.payload.finalEndpoint.split('/')
      : [];

    // Build name by splitting
    const key = endpointArray[1];
    const value = endpointArray[3].split('?')[0];

    // Add data to state
    return {
      ...state,
      ...set(state, `${key}.${value}`, action.payload.data)
    };
  }

  return state;
};

export { metricsEvaluate };
