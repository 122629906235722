/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { readEndpoint, updateResource } from 'redux-json-api';

import { log } from '../../misc/utils';

export const getGoals = () => {
  log('getGoals()');
  return dispatch => {
    dispatch(readEndpoint('goals'));
  };
};

export const saveGoals = goal_ids => {
  log('saveGoals()');
  return dispatch => {
    const entity = {
      type: `users#update`,
      attributes: {
        goal_ids
      }
    };

    dispatch(updateResource(entity));
  };
};

export const saveGoalsAndToggleOnboarding = goal_ids => {
  log('saveGoalsAndToggleOnboarding()');
  return dispatch => {
    const entity = {
      type: `users#update`,
      attributes: {
        goal_ids,
        onboarded: true
      }
    };

    dispatch(updateResource(entity));
  };
};
