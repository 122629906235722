import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { Meeting, RootState } from '../../misc/types';
import { log } from '../../misc/utils';
import { ModalView } from '../misc/ModalView';

import { ModalDailyReport } from './ModalDailyReport';
import { ModalDefault } from './ModalDefault';
import { ModalWellnessBlock } from './ModalWellnessBlock';

import styles from '../../styles/meeting-modal.module.scss';

interface MeetingModalProps {
  event?: Meeting;
  isVisible: boolean;
  onClose: () => void;
  onViewWellnessBlock: (id: string) => void;
}

export const MeetingModal: React.ComponentType<MeetingModalProps> = React.memo(
  ({ event, isVisible, onClose, onViewWellnessBlock }) => {
    const user = useSelector((state: RootState) => state.user.general);
    const settings = useSelector((state: RootState) => state.user.settings);
    const intentions = useSelector(
      (state: RootState) => state.meeting_info.intentions
    );
    const settings_labels = useSelector(
      (state: RootState) => state.settings_labels
    );
    const refreshes = useSelector(
      (state: RootState) => state.messages.refreshes
    );

    const renderInnards = (
      isWellnessBlock: boolean,
      isDailyReport: boolean
    ) => {
      if (!event) {
        return null;
      }
      if (isWellnessBlock) {
        return (
          <ModalWellnessBlock
            event={event}
            onClose={onClose}
            onViewWellnessBlock={onViewWellnessBlock}
          />
        );
      } else if (isDailyReport) {
        return <ModalDailyReport event={event} />;
      } else if (user && settings) {
        return (
          <ModalDefault
            event={event}
            intentions={intentions}
            refreshes={refreshes}
            settings={settings}
            settings_labels={settings_labels}
            user={user}
          />
        );
      }
      return null;
    };

    if (!event) return null;
    log(event);

    const isWellnessBlock = event.attributes.wellness_block_id !== null;
    const isDailyReport = event.attributes.event_report_card_id !== null;

    return (
      <ModalView
        innerClassName={classNames(styles.eventModalWrapper, {
          [styles.isWellnessBlock]: isWellnessBlock,
          [styles.isDailyReport]: isDailyReport
        })}
        isDismissible={true}
        isVisible={isVisible}
        position="float"
        onClose={onClose}>
        {event && (
          <div
            className={styles.eventModalInner}
            id={`meeting-modal-${event.id}`}>
            {renderInnards(isWellnessBlock, isDailyReport)}
          </div>
        )}
      </ModalView>
    );
  }
);
