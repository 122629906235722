import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePrevious } from '../../hooks/previous';
import { DIV_IDS } from '../../misc/constants';

export const ScrollToTop: React.ComponentType = React.memo(() => {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    const currPath = location ? location.pathname : '';
    const prevPath = prevLocation ? prevLocation.pathname : '';

    if (currPath !== prevPath) {
      const mainScrollDiv = document.getElementById(DIV_IDS.Scroll);
      const secondaryScrollDiv = document.getElementById(
        DIV_IDS.SecondaryScroll
      );

      if (mainScrollDiv) {
        mainScrollDiv.scrollTo(0, 0);
      }

      if (secondaryScrollDiv) {
        secondaryScrollDiv.scrollTo(0, 0);
      }
    }
  }, [location, prevLocation]);

  return null;
});
