/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useMemo } from 'react';
import FrontMatter from 'front-matter';
import { isEqual } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import { Button } from '../../components/design-system';
import { QUERY_PARAMS } from '../../misc/consts';

import { BlogPostItem } from './blog-post-item';

import styles from '../../styles/marketing/misc.module.scss';

const TABS = {
  All: {
    label: 'All',
    value: 'all'
  },
  Posts: {
    label: 'Posts',
    value: 'post'
  },
  Reactions: {
    label: 'Memes',
    value: 'meme'
  }
};

export const BlogList = ({ posts }) => {
  const [selectedView, setSelectedView] = useQueryParam(
    QUERY_PARAMS.BlogFilter,
    StringParam
  );

  const frontMatteredPosts = useMemo(() => {
    return posts.map(item => FrontMatter(item));
  }, [posts]);

  return (
    <>
      <h1>Our blog</h1>
      <div className={styles.filters}>
        {Object.keys(TABS).map(key => {
          const thisItem = TABS[key];
          const isActive =
            isEqual(selectedView, thisItem.value) ||
            (!selectedView && thisItem === TABS.All);
          return (
            <Button
              key={key}
              active={isActive}
              minimal={true}
              text={thisItem.label}
              onClick={() => setSelectedView(thisItem.value)}
            />
          );
        })}
      </div>
      <div className={styles.list}>
        {frontMatteredPosts.length > 0 &&
          frontMatteredPosts.map((fm, index) => {
            if (selectedView && selectedView !== TABS.All.value) {
              if (fm.attributes.type !== selectedView) {
                return null;
              }
            }
            return <BlogPostItem key={index} fm={fm} index={index} />;
          })}
      </div>
    </>
  );
};
