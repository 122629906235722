/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  get,
  includes,
  intersection,
  isArray,
  isEmpty,
  isNumber,
  isObject
} from 'lodash';
import { isEqual } from 'lodash';
import moment from 'moment';
import { rrulestr } from 'rrule';

import { COLORS } from './constants';
import { DATE_FORMAT, INTENT, INTENT_NUM, REVIEW_VIEWS, URLS } from './consts';

export function log(msg) {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    console.log(msg);
  }
}

export function isInApp(location) {
  if (location.pathname.includes(URLS.Sub)) {
    return true;
  }
  return false;
}

export function isEmailValid(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
  return re.test(email);
}

export function isURLValid(url) {
  try {
    new URL(url);
  } catch (_) {
    return false;
  }
  return true;
}

export function isValidAPICall(action, which) {
  if (!isEmpty(action.payload)) {
    const actions = [
      'API_READ',
      'API_UPDATED',
      'API_CREATED',
      'API_WAS_DELETED'
    ];

    // If anything but 'API_DELETED'
    if (includes(actions, action.type)) {
      // If there is data
      if (!isEmpty(action.payload.data)) {
        if (
          (isObject(action.payload.data) &&
            action.payload.data.type === which) ||
          (isArray(action.payload.data) &&
            action.payload.data[0].type === which)
        ) {
          return true;
        }
        // If no data, then check endpoint
      } else if (isEmpty(action.payload.data) && isArray(action.payload.data)) {
        if (action.payload.finalEndpoint === which) return true;

        // Split and see if the last one includes meetings
        const split = action.payload.finalEndpoint.split('/');
        if (split[split.length - 1].includes(which)) return true;

        return false;
      }

      return false;

      // If 'API_DELETED'
    } else if (action.type === 'API_DELETED' && action.payload.type === which) {
      return true;
    }

    return false;
  }

  return false;
}

export function getViewMetric(which, reports, selectedView) {
  const thisMetric = Number(get(reports, `${selectedView.slug}.${which}`));
  return isNumber(thisMetric) ? thisMetric : 0;
}

export function getViewData(which, reports, selectedView) {
  return get(reports, `${selectedView.slug}.${which}`);
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function buildReportingData({
  startDate,
  endDate,
  who,
  user,
  filters = {}
}) {
  const start = moment(startDate).format(DATE_FORMAT.Hash);
  const end = moment(endDate).format(DATE_FORMAT.Hash);

  // Get group id if is a group
  let group_id = '';
  if (who === 'group') {
    group_id = user.relationships.groups.data[0].id;
  }

  // Build status filter
  let byAttendeeStatus = '';
  if (!isEmpty(filters) && !isEmpty(filters.byAttendeeStatus)) {
    // Loop through and flatten to just value
    let tempData = [];
    for (var i = 0; i < filters.byAttendeeStatus.length; i++) {
      tempData.push(filters.byAttendeeStatus[i].value);
    }
    byAttendeeStatus = tempData.join();
  }

  return {
    who,
    start,
    end,
    group_id,
    byAttendeeStatus
  };
}

export function getGradeFromNum(score) {
  if (score >= INTENT_NUM.VeryHigh) {
    if (score >= 97) return 'A+';
    if (score <= 93) return 'A-';
    return 'A';
  } else if (score >= INTENT_NUM.High) {
    if (score >= 87) return 'B+';
    if (score <= 83) return 'B-';
    return 'B';
  } else if (score >= INTENT_NUM.Medium) {
    if (score >= 77) return 'C+';
    if (score <= 73) return 'C-';
    return 'C';
  } else if (score >= INTENT_NUM.MediumLow) {
    if (score >= 67) return 'D+';
    if (score <= 63) return 'D-';
    return 'D';
  } else {
    return 'F';
  }
}

export function getIntentFromNum(score, type) {
  const vars = [
    {
      text: INTENT.Success,
      color: COLORS.success,
      textColor: '#169475',
      bkg: '#E8F7F3',
      emoji: '😀'
    },
    {
      text: INTENT.Caution,
      color: COLORS.caution,
      textColor: '#C7A71F',
      bkg: '#FFF9E1',
      emoji: '👀'
    },
    {
      text: INTENT.Warning,
      color: COLORS.warning,
      textColor: COLORS.warning,
      bkg: '#FFEAD1',
      emoji: '😬'
    },
    {
      text: INTENT.Danger,
      color: COLORS.danger,
      textColor: COLORS.danger,
      bkg: '#FFEAEC',
      emoji: '💔'
    }
  ];

  let intent = vars[0][type]; // default

  if (score >= INTENT_NUM.High) {
    intent = vars[0][type];
  } else if (score >= INTENT_NUM.Medium) {
    intent = vars[1][type];
  } else if (score >= INTENT_NUM.MediumLow) {
    intent = vars[2][type];
  } else {
    intent = vars[3][type];
  }

  return intent;
}

export function dateRangeOverlaps(a_id, a_start, a_end, rrule, data) {
  const todayDayOfYear = moment().format('DDD'); // Get day of year
  const AStart = moment(a_start)
    .set('dayOfYear', todayDayOfYear)
    .set('seconds', 0);
  const AEnd = moment(a_end).set('dayOfYear', todayDayOfYear).set('seconds', 0);

  for (var d = 0; d < data.length; d++) {
    // Check if editing this one
    if (a_id === data[d].id) return false;

    const BStart = moment(data[d].attributes.starts_at)
      .set('dayOfYear', todayDayOfYear)
      .set('seconds', 0)
      .add(1, 'minute'); // Add a minute offset to prevent throwing an error
    const BEnd = moment(data[d].attributes.ends_at)
      .set('dayOfYear', todayDayOfYear)
      .set('seconds', 0)
      .subtract(1, 'minute'); // Subtract a minute offset to prevent throwing an error

    // Get RRules
    const BRRule = data[d].attributes.rrule;
    const BRRuleArr = rrulestr(BRRule);
    const ARRuleArr = rrulestr(rrule);

    // Get the interaction of these blocks
    const thisRRuleDays = BRRuleArr.options.byweekday;
    const ARRuleDays = ARRuleArr.options.byweekday;
    const intersectionArr = intersection(thisRRuleDays, ARRuleDays);

    // Loop through each day that overlaps and look at the times
    for (var o = 0; o < intersectionArr.length; o++) {
      if (AStart <= BStart && BStart <= AEnd) return true; // b starts in a
      if (AStart <= BEnd && BEnd <= AEnd) return true; // b ends in a
      if (BStart < AStart && AEnd < BEnd) return true; // a in b
    }
  }

  return false;
}

export function getDateRangeText(start, end) {
  // Make sure to always set start and end of day
  const startDate = moment(start).startOf('day');
  const endDate = moment(end).endOf('day');

  // This week
  if (
    moment().startOf('week').isSame(moment(startDate)) &&
    moment().endOf('week').isSame(moment(endDate))
  ) {
    return 'This week';
  }

  // Last week
  if (
    moment().subtract(1, 'weeks').startOf('week').isSame(moment(startDate)) &&
    moment().subtract(1, 'weeks').endOf('week').isSame(moment(endDate))
  ) {
    return 'Last week';
  }

  // This month
  if (
    moment().startOf('month').isSame(moment(startDate)) &&
    moment().endOf('month').isSame(moment(endDate))
  ) {
    return 'This month';
  }

  // Last month
  if (
    moment().subtract(1, 'months').startOf('month').isSame(moment(startDate)) &&
    moment().subtract(1, 'months').endOf('month').isSame(moment(endDate))
  ) {
    return 'Last month';
  }

  // Generic number of days
  if (moment(endDate).isSame(new Date(), 'day')) {
    const numOfDays = moment().diff(startDate, 'days');
    return `Last ${numOfDays} days`;
  }

  return '';
}

export function getComparisonText(startDate, endDate, span) {
  const labelText = getDateRangeText(startDate, endDate);
  if (labelText === 'This week') {
    return 'last wk';
  } else if (labelText === 'Last week') {
    return 'prev wk';
  } else if (labelText === 'This month') {
    return 'last mo';
  } else if (labelText === 'Last month') {
    return 'prev mo';
  } else {
    const label = maybePluralize(span, 'day', 'days');
    return `prev. ${span} ${label}`;
  }
}

export function getPlanToSelect(subscription) {
  // Get selected id if subscription exists
  let selectedPlan = '';
  if (!isEmpty(subscription)) {
    selectedPlan = subscription.attributes.plan;
  }
  return selectedPlan;
}

export function maybePluralize(count, single, multiple) {
  return count === 1 ? single : multiple;
}

export function padMins(val) {
  return ('00' + val).slice(-2);
}

export function flattenEmail(val) {
  return val.replace(/\W+/g, '_');
}

export function buildReviewViewText(which, selectedView, capitalize) {
  let toReturn = which;
  if (which === 'your work') {
    toReturn = isEqual(selectedView, REVIEW_VIEWS.Team)
      ? 'the work'
      : 'your work';
  } else if (which === 'of your') {
    toReturn = isEqual(selectedView, REVIEW_VIEWS.Team) ? 'of the' : 'of your';
  } else if (which === 'your') {
    toReturn = isEqual(selectedView, REVIEW_VIEWS.Team) ? 'these' : 'your';
  }

  if (capitalize) {
    toReturn = capitalizeFirstLetter(toReturn);
  }

  return toReturn;
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = '.',
  thousands = ','
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    log(e);
  }
}

export function formatRRule({ rrule, starts_at, ends_at, recurring }) {
  const fromTime = moment(starts_at).format('h:mm a');
  const toTime = moment(ends_at).format('h:mm a');

  let ruleAndTime = `${capitalizeFirstLetter(
    rrule.toText()
  )}, ${fromTime}-${toTime}`;

  // If not recurring, change desc
  if (!recurring) {
    ruleAndTime = `${moment(starts_at).format(
      DATE_FORMAT.Long
    )}, ${fromTime}-${toTime}`;
  }

  return ruleAndTime;
}
