import React, { useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { filter, isEmpty, sortBy } from 'lodash';
import moment from 'moment';

import { PlanViewProps } from '../../containers/app/Plan';
import { DIV_IDS, REVIEW_VIEWS } from '../../misc/constants';
import { Meeting } from '../../misc/types';
import { log } from '../../misc/utils';
import { Quote } from '../design-system';

import { EmptyState } from './EmptyState';
import { MeetingEvent } from './MeetingEvent';
import { MeetingModal } from './MeetingModal';
import { QuickStats } from './QuickStats';
import { SubNav } from './SubNav';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from '../../styles/plan.module.scss';

const localizer = momentLocalizer(moment);

export const BrowserView: React.ComponentType<PlanViewProps> = React.memo(
  ({
    APICallsComplete,
    calendars,
    dateSelection,
    meetings,
    isLoading,
    isSyncingCalendar,
    selectedEvent,
    onCloseMeetingModal,
    onViewWellnessBlock,
    onChangeThePage,
    onSelectEvent
  }) => {
    let meetingsToShow: Meeting[] = [];

    const _onNavigate = useCallback(() => {
      log('_onNavigate');
    }, []);

    // Min and max times
    let minTime = new Date();
    minTime.setHours(7, 0, 0);
    let maxTime = new Date();
    maxTime.setHours(17, 0, 0);

    if (!isEmpty(meetings)) {
      const dailyReports = filter(meetings, function (o) {
        return o.attributes.event_report_card_id !== null;
      });

      const notDailyReports = filter(meetings, function (o) {
        return o.attributes.event_report_card_id === null;
      });

      // Put meetings in order
      meetingsToShow = [...dailyReports, ...notDailyReports];

      const notAllDayMeetings = filter(meetings, function (o) {
        return !o.attributes.all_day;
      });

      // Build min and max times
      if (!isEmpty(notAllDayMeetings)) {
        const sortedStartTimeMeetings = sortBy(notAllDayMeetings, [
          function (o) {
            return moment(o.attributes.starts_at).format('HH');
          }
        ]);

        const sortedEndTimeMeetings = sortBy(notAllDayMeetings, [
          function (o) {
            return moment(o.attributes.ends_at).format('HH');
          }
        ]);

        // Build min/max (Add 1 hr offset/padding) if necessary
        const proposedMinTime = moment(
          sortedStartTimeMeetings[0].attributes.starts_at
        ).toDate();

        const proposedMaxTime = moment(
          sortedEndTimeMeetings[sortedEndTimeMeetings.length - 1].attributes
            .ends_at
        ).toDate();

        // Compare and maybe change min/max times
        if (proposedMinTime.getHours() < minTime.getHours()) {
          minTime = proposedMinTime;
        }

        if (proposedMaxTime.getHours() > maxTime.getHours()) {
          maxTime = proposedMaxTime;
        }
      }
    }

    const noMeetingsAvailable = isEmpty(meetings) && !isLoading;

    return (
      <div className={styles.calendarView}>
        <MeetingModal
          event={selectedEvent}
          isVisible={!isEmpty(selectedEvent)}
          onClose={onCloseMeetingModal}
          onViewWellnessBlock={onViewWellnessBlock}
        />
        <SubNav
          dateSelection={dateSelection}
          onChangeThePage={onChangeThePage}
        />
        <div className={styles.calendarAndStats} id={DIV_IDS.SecondaryScroll}>
          <EmptyState
            APICallsComplete={APICallsComplete}
            calendars={calendars}
            dateSelection={dateSelection}
            isLoading={isLoading}
            isSyncingCalendar={isSyncingCalendar}
            meetings={meetings}
          />
          <div className={styles.quickStatsView}>
            {!noMeetingsAvailable && (
              <QuickStats
                dateSelection={dateSelection}
                selectedView={REVIEW_VIEWS.You}
                onViewWellnessBlock={onViewWellnessBlock}
              />
            )}
          </div>
          <div className={styles.calendar}>
            <Quote text="This weeks evaluations..." />
            <Calendar
              allDayAccessor={event => event.attributes.all_day}
              components={{ event: MeetingEvent }}
              date={dateSelection.visibleDate}
              defaultView="week"
              endAccessor={
                event =>
                  moment(event.attributes.ends_at)
                    .subtract(1, 'minute')
                    .toDate() // Subtract a minute so it doesn't show as allDay
              }
              events={meetingsToShow}
              localizer={localizer}
              max={maxTime}
              min={minTime}
              popup={true}
              startAccessor={event => {
                if (event.attributes.all_day) {
                  const onlyDay = moment
                    .utc(event.attributes.starts_at)
                    .format('YYYY-MM-DD');
                  return moment(onlyDay).toDate();
                } else {
                  return moment(event.attributes.starts_at).toDate();
                }
              }}
              step={30}
              timeslots={4}
              titleAccessor={event => event.attributes.summary}
              toolbar={false}
              views={['week']}
              onNavigate={_onNavigate}
              onSelectEvent={onSelectEvent}
            />
          </div>
        </div>
      </div>
    );
  }
);
