import React, { ReactNode, useCallback, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Portal } from 'react-portal';
import classNames from 'classnames';
import { random } from 'lodash';

import styles from '../../styles/tooltip.module.scss';

const OFFSET = 10;
const MAX_WIDTH = 260;
const MAX_HEIGHT = 260;

export interface TooltipProps {
  children: ReactNode;
  className?: string;
  content: ReactNode;
  isDisabled?: boolean;
}

export const Tooltip: React.ComponentType<TooltipProps> = React.memo(
  function Tooltip({ className, children, content, isDisabled = false }) {
    const [isVisible, setIsVisible] = useState(false);
    const [affix] = useState(random(0, 99999999));
    const [leftPos, setLeftPos] = useState(0);
    const [topPos, setTopPos] = useState(0);

    const notInTopLeft = leftPos !== 0 && topPos !== 0;

    const shouldShowTooltip =
      isVisible && notInTopLeft && !isDisabled && !isMobile;

    const _handleMouseMove = useCallback(
      e => {
        const bodyRect = document.body.getBoundingClientRect();
        let leftPos = e.pageX + OFFSET;
        let topPos = e.pageY + OFFSET;
        const tooltipElement = document.getElementById(`tooltip-${affix}`);
        const tooltipRect = tooltipElement
          ? tooltipElement.getBoundingClientRect()
          : undefined;
        const tooltipWidth = tooltipRect ? tooltipRect.width : MAX_WIDTH;
        const tooltipHeight = tooltipRect ? tooltipRect.height : MAX_HEIGHT;

        // If tooltip is too far to the right
        if (leftPos + tooltipWidth > bodyRect.width) {
          leftPos = leftPos - tooltipWidth - OFFSET;
        }

        // // If tooltip is too far to the bottom
        if (topPos + tooltipHeight > bodyRect.height) {
          topPos = topPos - tooltipHeight - OFFSET;
        }

        setLeftPos(leftPos);
        setTopPos(topPos);
      },
      [affix]
    );

    const _handleMouseEnterOrLeave = useCallback(() => {
      setIsVisible(prevState => !prevState);
    }, []);

    return (
      <span
        className={classNames(styles.tooltipWrapper, className)}
        onMouseEnter={_handleMouseEnterOrLeave}
        onMouseLeave={_handleMouseEnterOrLeave}
        onMouseMove={_handleMouseMove}>
        {children}
        {shouldShowTooltip && (
          <Portal>
            <div
              className={styles.tooltip}
              id={`tooltip-${affix}`}
              style={{ left: leftPos, top: topPos }}>
              <div className={styles.text}>{content}</div>
            </div>
          </Portal>
        )}
      </span>
    );
  }
);
