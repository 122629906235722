import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { isMobile } from 'react-device-detect';
import { HotKeys } from 'react-hotkeys';
import { isEmpty } from 'lodash';

import { INTENT } from '../../../misc/consts';
import { Meeting } from '../../../misc/types';
import { Button } from '../../design-system';
import { MeetingDate } from '../MeetingDate';

import { AgendaItems } from './AgendaItems';

import styles from '../../../styles/present-mode.module.scss';

const PRESENTATION_START = 0;

interface PresentInProgressProps {
  event: Meeting;
  meetingHasCompleted: boolean;
  onEndMeeting: () => void;
  setMeetingHasCompleted: () => void;
}

export const PresentInProgress: React.ComponentType<PresentInProgressProps> = React.memo(
  ({ event, meetingHasCompleted, setMeetingHasCompleted, onEndMeeting }) => {
    const inProgressEl = useRef<HTMLDivElement>(null);
    const [discussingAgendaItem, setDiscussingAgendaItem] = useState(0);

    ///////////
    // Focus for keyboard events
    useEffect(() => {
      inProgressEl.current && inProgressEl.current.focus();
    }, []);

    ///////////
    // Callbacks
    ///////////

    const prevItemCallback = useCallback(() => {
      setDiscussingAgendaItem(prevItem => {
        // If not the first item
        if (prevItem !== PRESENTATION_START) {
          return prevItem - 1;
        }
        return prevItem;
      });
    }, []);

    const nextItemCallback = useCallback(() => {
      setDiscussingAgendaItem(prevItem => {
        // If not the last item
        if (prevItem <= event.attributes.agenda_items.data.length) {
          return prevItem + 1;
        }
        return prevItem;
      });
    }, [event]);

    const endMeetingCallback = useCallback(() => {
      nextItemCallback();
      setMeetingHasCompleted();
    }, [nextItemCallback, setMeetingHasCompleted]);

    ///////////
    // Divs
    ///////////

    const preWorkItemsList = useMemo(() => {
      if (!isEmpty(event.attributes.prework_items.data)) {
        const preWorkItems = event.attributes.prework_items.data;
        return (
          <div className={styles.preWorkItems}>
            <h5>Pre-work reminder</h5>
            {preWorkItems.map((item, index) => (
              <div key={index} className={styles.item}>
                <span>{index + 1})</span>{' '}
                <a
                  className={styles.text}
                  href={item.attributes.url?.toString()}
                  target="_new">
                  {item.attributes.description}
                </a>
              </div>
            ))}
          </div>
        );
      }
      return [];
    }, [event]);

    const keyMap = {
      NEXT_ITEM: ['down', 'right'],
      PREV_ITEM: ['left', 'up']
    };

    const handlers = {
      NEXT_ITEM: nextItemCallback,
      PREV_ITEM: prevItemCallback
    };

    const isBeginning = discussingAgendaItem === PRESENTATION_START;
    const isBeginningOrEnd = isBeginning || meetingHasCompleted;
    const isLastItem =
      discussingAgendaItem !== event.attributes.agenda_items.data.length;

    return (
      <HotKeys
        className={styles.viewInProgress}
        handlers={handlers}
        innerRef={inProgressEl}
        keyMap={keyMap}>
        {isBeginningOrEnd && (
          <>
            <div className={styles.title}>
              <h1>{event.attributes.summary}</h1>
            </div>
            <div className={styles.miscInfo}>
              <MeetingDate
                allDay={event.attributes.all_day}
                endDate={event.attributes.ends_at}
                startDate={event.attributes.starts_at}
              />
            </div>
            {isBeginning && !isMobile && preWorkItemsList}
          </>
        )}
        <AgendaItems
          discussingAgendaItem={discussingAgendaItem}
          event={event}
          meetingHasCompleted={meetingHasCompleted}
        />
        {!meetingHasCompleted ? (
          <div className={styles.prevNextBtns}>
            <Button
              disabled={isBeginning}
              large={true}
              shouldLookDisabled={true}
              text="Back"
              onClick={prevItemCallback}
            />
            {isLastItem ? (
              <Button
                large={true}
                shouldLookDisabled={true}
                text="Next"
                onClick={nextItemCallback}
              />
            ) : (
              <Button
                intent={INTENT.Primary}
                large={true}
                text="End meeting"
                onClick={endMeetingCallback}
              />
            )}
          </div>
        ) : (
          <Button
            intent={INTENT.Primary}
            large={true}
            text="Close presentation"
            onClick={onEndMeeting}
          />
        )}
      </HotKeys>
    );
  }
);
