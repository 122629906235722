import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import * as htmlToImage from 'html-to-image';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { DIV_IDS } from '../../misc/constants';
import { QUERY_PARAMS } from '../../misc/consts';
import { DateSelectionProps, Reports, SelectedView } from '../../misc/types';
import { getViewData } from '../../misc/utils';

import { LeaderBoard } from './LeaderBoard';
import { ROIChart } from './ROIChart';

import styles from '../../styles/organizer-stats.module.scss';

interface OrganizerStatsProps {
  dateSelection: DateSelectionProps;
  isForMarketing?: boolean;
  reports: Reports;
  selectedView: SelectedView;
}

export const OrganizerStats: React.ComponentType<OrganizerStatsProps> =
  React.memo(
    ({ dateSelection, selectedView, reports, isForMarketing = false }) => {
      const [shouldDownloadParam] = useQueryParam(
        QUERY_PARAMS.DownloadOrganizerStats,
        BooleanParam
      );

      const API_CHECK = useRef<number>(0);

      const _downloadImage = useCallback(() => {
        const organizerData = getViewData(
          'duration_and_cost_details.attributes.rows.organizers',
          reports,
          selectedView
        );
        const averageScoresData = getViewData(
          'average_scores_by.attributes.rows',
          reports,
          selectedView
        );

        // Check for organizers, average scores, and that we've only checked twice
        if (organizerData && averageScoresData) {
          const screenshotElem = document.getElementById(
            DIV_IDS.ROILeaderBoard
          );
          if (screenshotElem) {
            htmlToImage.toPng(screenshotElem).then(function (dataUrl) {
              const rootElem = document.getElementById('root');
              const img = new Image();
              img.id = 'screenshot';
              img.src = dataUrl;
              if (rootElem) {
                rootElem.replaceWith(img);
              }
            });
          }
        } else if (API_CHECK.current < 2) {
          API_CHECK.current = API_CHECK.current + 1;
          setTimeout(_downloadImage, 1000);
        }
      }, [reports, selectedView]);

      useEffect(() => {
        if (shouldDownloadParam) {
          _downloadImage();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [shouldDownloadParam]);

      return (
        <div
          className={classNames(styles.organizerStats, {
            [styles.isDownloading]: shouldDownloadParam,
            [styles.isForMarketing]: isForMarketing
          })}
          id={DIV_IDS.ROILeaderBoard}>
          <ROIChart
            dateSelection={dateSelection}
            reports={reports}
            selectedView={selectedView}
          />
          <LeaderBoard
            isForMarketing={isForMarketing}
            reports={reports}
            selectedView={selectedView}
          />
        </div>
      );
    }
  );
