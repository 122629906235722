import React, { useMemo } from 'react';
import classNames from 'classnames';
import { get, round } from 'lodash';

import { COLORS, INTENT } from '../../misc/constants';
import { Icon } from '../design-system';

import styles from '../../styles/review-delta.module.scss';

interface DeltaProps {
  className?: string;
  comparisonText: string;
  data: number;
  prevSpan: number;
  type: string;
  whatsGood?: string;
}

export const Delta: React.ComponentType<DeltaProps> = React.memo(
  ({ data, prevSpan, comparisonText, type, className, whatsGood }) => {
    const delta = useMemo(() => Math.abs(data - prevSpan), [data, prevSpan]);

    const goodBadScale = useMemo(() => {
      if (whatsGood && whatsGood === 'up') {
        if (prevSpan < data) return INTENT.Success;
        if (prevSpan > data) return INTENT.Danger;
      } else {
        if (prevSpan > data) {
          return INTENT.Success;
        }
      }
      return INTENT.Danger;
    }, [data, prevSpan, whatsGood]);

    const arrowIcon = useMemo(() => {
      const color = get(COLORS, goodBadScale);
      if (prevSpan < data) {
        return (
          <Icon className={styles.deltaIcon} color={color} which="arrow-up" />
        );
      } else {
        return (
          <Icon className={styles.deltaIcon} color={color} which="arrow-down" />
        );
      }
    }, [data, goodBadScale, prevSpan]);

    if (!prevSpan) return null;

    return (
      <div
        className={classNames(styles.delta, className, styles[goodBadScale])}>
        {arrowIcon}
        {type === 'num' && <Number delta={delta} />}
        {type === 'time' && <Time delta={delta} />}
        {comparisonText && (
          <div className={styles.compLabel}>({comparisonText})</div>
        )}
      </div>
    );
  }
);

interface DeltaSubRenderProps {
  delta: number;
}

const Number: React.ComponentType<DeltaSubRenderProps> = React.memo(
  ({ delta }) => {
    return (
      <div className={classNames(styles.deltaNum, styles.number)}>
        {round(delta, 1)}
      </div>
    );
  }
);

const Time: React.ComponentType<DeltaSubRenderProps> = React.memo(
  ({ delta }) => {
    const days = Math.floor(delta / (3600 * 24));
    const hours = Math.floor((delta % (3600 * 24)) / 3600);
    let mins: string | number = Math.floor((delta % 3600) / 60);
    if (hours > 0) {
      mins = ('0' + mins).slice(-2);
    }

    return (
      <div className={classNames(styles.deltaNum, styles.time)}>
        {days > 0 && <div className={styles.number}>{days}</div>}
        {days > 0 && hours > 0 && <span>|</span>}
        {hours > 0 && <div className={styles.number}>{hours}</div>}
        {hours > 0 && mins > 0 && <span>:</span>}
        {mins > 0 && <div className={styles.number}>{mins}</div>}
      </div>
    );
  }
);
