import React from 'react';
import { useSelector } from 'react-redux';

import { DateSelectionProps, RootState, SelectedView } from '../../misc/types';
import { ReportCardStats } from '../review/ReportCardStats';
import { SummaryStats } from '../review/SummaryStats';

import { ToutWellnessBlocks } from './ToutWellnessBlocks';

interface QuickStatsProps {
  dateSelection: DateSelectionProps;
  onViewWellnessBlock: (id: string) => void;
  selectedView: SelectedView;
}

export const QuickStats: React.ComponentType<QuickStatsProps> = React.memo(
  ({ selectedView, onViewWellnessBlock, dateSelection }) => {
    const user = useSelector((state: RootState) => state.user.general);
    const memberships = useSelector(
      (state: RootState) => state.teams.memberships.data
    );
    const settings_labels = useSelector(
      (state: RootState) => state.settings_labels
    );
    const reports = useSelector((state: RootState) => state.reports);

    if (!user) {
      return null;
    }

    return (
      <>
        <SummaryStats
          dateSelection={dateSelection}
          isColumnLayout={true}
          memberships={memberships}
          reports={reports}
          selectedView={selectedView}
          user={user}
        />
        <ReportCardStats
          dateSelection={dateSelection}
          isColumnLayout={true}
          reports={reports}
          selectedView={selectedView}
          settingsLabels={settings_labels}
          user={user}
        />
        <ToutWellnessBlocks
          dateSelection={dateSelection}
          onViewWellnessBlock={onViewWellnessBlock}
        />
      </>
    );
  }
);
