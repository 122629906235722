import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { filter, get, isEmpty } from 'lodash';

import { ReviewFilterInfo } from '../../containers/app/Review';
import { INTENT, URLS } from '../../misc/consts';
import {
  CompliNonCompliMeeting,
  Reports,
  SelectedView,
  User
} from '../../misc/types';
import { buildReportingData, getViewData } from '../../misc/utils';
import * as API from '../../modules/api';
import { metricsEvaluate } from '../../modules/reports';
import { Button } from '../design-system';
import { ModalView } from '../misc/ModalView';
import { MeetingCost } from '../plan/MeetingCost';
import { MeetingDate } from '../plan/MeetingDate';

import styles from '../../styles/compli-non-meetings.module.scss';

const VIEWS = {
  good: {
    title: 'Good',
    description: 'Lorem ipsum sit dolor.',
    compliant: true
  },
  bad: {
    title: 'Bad',
    description: 'Lorem ipsum sit dolor.',
    compliant: false
  }
};

interface CompliantNonCompliantMeetingsProps {
  filterInfo?: ReviewFilterInfo;
  isVisible: boolean;
  onClose: () => void;
  reports: Reports;
  selectedView: SelectedView;
  user: User;
  which: string;
}

export const CompliantNonCompliantMeetings: React.ComponentType<CompliantNonCompliantMeetingsProps> =
  React.memo(
    ({
      filterInfo,
      which,
      isVisible,
      onClose,
      reports,
      selectedView,
      user
    }) => {
      const dispatch = useDispatch();

      const viewData = useMemo(() => {
        return get(VIEWS, which);
      }, [which]);

      const compliNonMeetings: CompliNonCompliMeeting[] = useMemo(() => {
        return getViewData('compliant_meetings', reports, selectedView);
      }, [reports, selectedView]);

      const filteredMeetings = useMemo(() => {
        return filter(compliNonMeetings, o => {
          return o.attributes.compliant === viewData.compliant;
        });
      }, [compliNonMeetings, viewData]);

      useEffect(() => {
        if (filterInfo && isVisible) {
          const { startDate, endDate } = filterInfo.dateSelection;
          const data = buildReportingData({
            startDate,
            endDate,
            who: 'user',
            user,
            filters: {
              byAttendeeStatus: filterInfo.statusFilterData
            }
          });

          API.makeRequest(() => {
            dispatch(
              metricsEvaluate({
                ...data,
                which: 'compliant_meetings'
              })
            );
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [isVisible]);

      if (isEmpty(filteredMeetings)) {
        return null;
      }

      return (
        <ModalView
          className={styles.compliNonMeetingsOuterModal}
          innerClassName={styles.compliNonMeetingsModal}
          isDismissible={true}
          isVisible={isVisible}
          position="float"
          onClose={onClose}>
          <div className={styles.compliNonMeetings}>
            <div className={styles.header}>
              <h1>
                The <span>{viewData.title}</span> ones
              </h1>
            </div>
            <div className={styles.list}>
              {filteredMeetings.map((item, index) => (
                <CompliNonMeeting key={index} meeting={item} />
              ))}
            </div>
          </div>
        </ModalView>
      );
    }
  );

interface CompliNonMeetingProps {
  meeting: CompliNonCompliMeeting;
}

const CompliNonMeeting: React.ComponentType<CompliNonMeetingProps> = React.memo(
  ({ meeting }) => {
    const history = useHistory();

    const _goToMeeting = useCallback(() => {
      history.push(`${URLS.Plan.Meeting}/${meeting.id}`);
    }, [history, meeting]);

    return (
      <div className={styles.item}>
        <div className={styles.info}>
          <div className={styles.title}>{meeting.attributes.summary}</div>
          <MeetingDate
            allDay={false}
            endDate={meeting.attributes.ends_at}
            startDate={meeting.attributes.starts_at}
          />
        </div>
        <div className={styles.deets}>
          <div className={styles.score}>
            <span>{meeting.attributes.score}</span> /100
          </div>
          <MeetingCost
            attendees_count={meeting.attributes.attendees_count}
            className={styles.cost}
            cost={meeting.attributes.cost}
            duration={meeting.attributes.duration}
          />
          <Button
            className={styles.viewAll}
            intent={INTENT.Primary}
            minimal={true}
            text="View meeting"
            onClick={_goToMeeting}
          />
        </div>
      </div>
    );
  }
);
