import React from 'react';

import { Fathom, GOALS } from './fathom';

export const COOKIE_NAME = 'MWToken';
export const REFERRAL_COOKIE_NAME = 'MWReferralToken';

export const API_REQ_DELAY = 500;

const URL_SUBDOMAIN = '/my';
export const URLS = {
  Sub: URL_SUBDOMAIN,
  Review: {
    Default: `${URL_SUBDOMAIN}/review`,
    You: `${URL_SUBDOMAIN}/review/you`,
    Team: `${URL_SUBDOMAIN}/review/team`
  },
  Onboarding: {
    Default: `${URL_SUBDOMAIN}/review/you/?onboarding=`
  },
  Plan: {
    Default: `${URL_SUBDOMAIN}/plan`,
    Meeting: `${URL_SUBDOMAIN}/plan/meeting`,
    ViewBlock: `${URL_SUBDOMAIN}/plan/block`,
    NewBlock: `${URL_SUBDOMAIN}/plan/block/new`
  },
  Balance: {
    Default: `${URL_SUBDOMAIN}/balance`,
    Evaluation: `${URL_SUBDOMAIN}/balance/evaluation`,
    Action: `${URL_SUBDOMAIN}/balance/action`,
    PlanChooser: `${URL_SUBDOMAIN}/balance/action/choose-plan`,
    WellnessBlocks: `${URL_SUBDOMAIN}/balance/wellness-blocks`,
    ViewBlock: `${URL_SUBDOMAIN}/balance/wellness-blocks/block`,
    NewBlock: `${URL_SUBDOMAIN}/balance/wellness-blocks/block/new`
  },
  Account: {
    Default: `${URL_SUBDOMAIN}/account`,
    TeamMgmt: `${URL_SUBDOMAIN}/team-management`
  }
};

export const WELLNESS_BLOCKS_ALIAS = 'focus_blocks'; // From old API
export const IGNORE_VAR = 'Ignore';

export const SETTINGS_BUCKETS = {
  Action: 'action_settings',
  Evaluation: 'evaluation_settings'
};

export const INTENT = {
  Primary: 'primary',
  Success: 'success',
  Caution: 'caution',
  Warning: 'warning',
  Danger: 'danger'
};

export const CALENDAR_SOURCES = [
  {
    auth: 'google_oauth2',
    label: 'Google',
    slug: 'google',
    url: 'google_calendar'
  },
  {
    auth: 'microsoft_graph',
    slug: 'outlook',
    label: 'Outlook',
    url: 'microsoft_outlook'
  }
];

export const DATE_FORMAT = {
  Default: 'MMMM Do YYYY',
  Long: 'dddd, MMMM Do YYYY',
  Short: 'M/D/YY',
  NoYear: 'MMMM Do',
  Time: 'h:mm a',
  Hash: 'YYYY-MM-DD'
};

export const INTENT_NUM = {
  Perfect: 100,
  VeryHigh: 90,
  High: 80,
  Medium: 70,
  MediumLow: 60,
  Low: 50
};

export const OPERATORS = {
  LessThan: {
    label: 'Less than',
    symbol: '<'
  },
  LessThanEqualTo: {
    label: 'Less than or equal to',
    symbol: '≤'
  },
  GreaterThan: {
    label: 'Greater than',
    symbol: '>'
  },
  GreaterThanEqualTo: {
    label: 'Greater than or equal to',
    symbol: '≥'
  }
};

export const QUERY_PARAMS = {
  Token: 'token',
  Login: 'login',
  Onboarding: 'onboarding',
  BlogFilter: 'filter',
  SkipEval: 'skipEval',
  ConvertWB: 'convertwb',
  Week: 'w',
  Start: 's',
  End: 'e',
  DownloadOrganizerStats: 'dwnldStats',
  PreviewReminder: 'previewReminder',
  Impersonation: 'imp',
  WBLabel: 'wbLabel',
  AddVIP: 'addVIP',
  LinkedInRef: 'ref',
  Referral: 'referral'
};

export const ONBOARDING_STEPS = {
  Review: {
    tag: 'view-review',
    childId: 'tip-view-review',
    headerText: 'Introducing',
    steps: ['4', '9'],
    isLarge: true,
    isForView: true,
    nextStep: {
      label: 'What about my meetings?',
      URL: `${URLS.Plan.Default}?${QUERY_PARAMS.Onboarding}=view-plan`
    },
    content: (
      <>
        <p>Monitor meeting health and behaviors for any time period.</p>
        <ul>
          <li>Time spent in meetings</li>
          <li>Average meeting scores</li>
          <li>Meeting intentions/organizers</li>
          <li>Additional meeting attributes and guidance</li>
        </ul>
      </>
    )
  },
  Plan: {
    tag: 'view-plan',
    childId: 'tip-view-plan',
    headerText: 'Introducing',
    steps: ['5', '9'],
    isLarge: true,
    isForView: true,
    nextStep: {
      label: 'How are meetings scored?',
      URL: `${URLS.Plan.Meeting}/123?${QUERY_PARAMS.Onboarding}=view-meeting`
    },
    content: (
      <>
        <p>Featuring your week's meetings and more!</p>
        <ul>
          <li>Track the quality of individual meetings</li>
          <li>Create Wellness blocks to preserve your personal time</li>
          <li>Instantly improve meeting habits with suggested actions</li>
        </ul>
      </>
    )
  },
  Meeting: {
    headerText: 'Introducing',
    tag: 'agenda-parser',
    childId: 'tip-agenda-parser',
    steps: ['6', '9'],
    nextStep: {
      label: 'How do I take action?',
      URL: `${URLS.Balance.Default}?${QUERY_PARAMS.Onboarding}=view-balance`
    }
  },
  Balance: {
    tag: 'view-balance',
    childId: 'tip-view-balance',
    headerText: 'Introducing',
    steps: ['7', '9'],
    isLarge: true,
    isForView: true,
    nextStep: {
      label: 'What does MeetWell do automatically?',
      URL: `${URLS.Balance.Action}?${QUERY_PARAMS.Onboarding}=non-compliance-action`
    },
    content: (
      <>
        <p>
          Featuring your meeting preferences that are{' '}
          <strong>pre-optimized based on research from meeting experts</strong>.
        </p>
        <p>
          <u>For best results:</u> start with our recommendations and configure
          them as-needed.
        </p>
      </>
    )
  },
  NonComplianceAction: {
    headerText: 'Introducing',
    nextStep: {
      label: 'Who gets enforced?',
      URL: `${URLS.Balance.Action}?${QUERY_PARAMS.Onboarding}=ignore-enforce`
    },
    tag: 'non-compliance-action',
    childId: 'tip-non-compliance-action',
    isLarge: true,
    steps: ['8', '9'],
    content: (
      <>
        <p>
          By default, MeetWell{' '}
          <strong>automatically interacts with meetings</strong> that don't
          match your preferences.
        </p>
        <p>
          You’ll be notified before any action is taken. For best results, we
          recommend a <strong>Decline</strong> action.
        </p>
      </>
    )
  },
  IgnoreEnforce: {
    headerText: 'Introducing',
    nextStep: {
      label: 'All done!',
      URL: URLS.Balance.Action,
      // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
      onClick: () => {
        Fathom.trackGoal(GOALS.General.CompletedOnboarding);
      }
    },
    tag: 'ignore-enforce',
    childId: 'tip-ignore-enforce',
    isLarge: true,
    steps: ['9', '9'],
    content: (
      <>
        <p>
          Should MeetWell ignore your boss? Or only enforce people at your
          company? <strong>Add people and domains here</strong> for MeetWell to
          specifically ignore or enforce.
        </p>
      </>
    )
  }
};

export const REVIEW_VIEWS = {
  Team: {
    label: 'Your Team',
    slug: 'team',
    url: 'team',
    emoji: ':factory:',
    display: true
  },
  Groups: {
    label: 'Groups',
    slug: 'group',
    url: 'groups',
    emoji: ':people_with_bunny_ears:',
    display: false
  },
  You: {
    label: 'Just you',
    slug: 'user',
    url: 'you',
    emoji: ':crown:',
    display: true
  }
};

export const ROLES = {
  User: { label: 'User', value: 'user' },
  Admin: { label: 'Admin', value: 'billing_admin' }
};

export const PLAN_TAGS = {
  Required: ['true'],
  NotRequired: ['false'],
  Default: ['do nothing', 'ignored', 'not ignored'],
  Success: ['allow'],
  Warning: ['remind', 'remind of conflicts'],
  Danger: ['decline', 'decline conflicts', 'block']
};

export const PLAN_TOOLTIPS = {
  Action: `When meetings don't match your preferences by the deadline, MeetWell:`,
  Deadline: 'Hours ahead of a meeting when MeetWell will take action.',
  Domains: 'Email domains that will be enforced (e.g. mycompany.com).',
  People:
    'Specific people that MeetWell will not take action on, treated as a VIP (e.g. your boss).',
  Intentions: 'What is the reason for this meeting?'
};

export const PLAN_ACTIONS = {
  RemindConflicts: {
    type: 'Auto-reminds',
    value: 'remind of conflicts',
    label: 'Calendar conflicts only',
    description: `MeetWell emails organizers when a meeting overlaps with a Wellness block or meeting you've accepted.`
  },
  RemindAll: {
    type: 'Auto-reminds',
    value: 'remind',
    label: 'All violations',
    description: `MeetWell emails organizers when a meeting clashes with any of your preferences.`
  },
  DeclineConflicts: {
    type: 'Auto-declines',
    value: 'decline conflicts',
    label: 'Calendar conflicts only',
    description: `MeetWell declines meetings when they overlap with a Wellness block or meeting you've accepted.`
  },
  DeclineAll: {
    type: 'Auto-declines',
    value: 'decline',
    label: 'All violations',
    description: `MeetWell declines meetings when they clash with any of your preferences.`
  },
  DoNothing: {
    type: '',
    value: 'do nothing',
    label: 'Does nothing',
    description: `MeetWell won't notify organizers of any conflicts and will not decline meetings.`
  }
};

export const PLAN_HELPER_TIPS = {
  desired_duration:
    'Meetings can often drag on, MeetWell will hold a standard for a desired meeting length.',
  focus_block_conflict:
    'Set up your wellness blocks and MeetWell will preserve this time.',
  meeting_conflict: `Paid plans will not allow a meeting you've already accepted to be scheduled over.`,
  last_minute_schedule:
    'Last minute meetings can be surprising and frustrating, MeetWell will help you understand how often this happens.',
  ignore_all_hands_meetings: '',
  required_agenda: `These can be added directly via MeetWell's meeting detail window or your existing calendar.`,
  required_objectives: `These can be added directly via MeetWell's meeting detail window or your existing calendar.`,
  required_intention: '',
  required_prework: '',
  required_outcomes: '',
  max_attendees:
    'Smaller is often better, MeetWell will help you track how big your meetings are.',
  ignore_all_day_meetings:
    'Meetings at least 6 hours in length or marked as "all day".',
  required_attendee_roles: '',
  required_conference_info: ''
};

export const INVITE_STATUS_OPTIONS = {
  All: {
    label: 'All',
    value: '',
    url: ''
  },
  Accepted: {
    label: 'Accepted',
    value: 'confirmed',
    url: 'accepted',
    icon: 'tick',
    intent: INTENT.Success
  },
  Tentative: {
    label: 'Tentative',
    value: 'tentative',
    url: 'tentative',
    icon: 'question',
    disabled: true,
    intent: INTENT.Caution
  },
  NoResponse: {
    label: 'Unresponded',
    value: 'none',
    url: 'unresponded',
    icon: 'question',
    disabled: true,
    intent: INTENT.Warning
  },
  Declined: {
    label: 'Declined',
    value: 'declined',
    url: 'declined',
    disabled: true,
    intent: INTENT.Danger
  }
};

export const WELLNESS_BLOCKS = {
  Focus: {
    value: 'focus',
    label: 'Focus block (focus at work)',
    description: 'This is a focus (on your work) block'
  },
  Life: {
    value: 'life',
    label: 'Life block (personal time)',
    description: 'This is a (take care of your) life block'
  }
};

export const PREFERENCE_ROWS = {
  TimeAndDuration: [
    {
      which: 'desired_duration',
      type: 'stat',
      statType: 'mins',
      operator: OPERATORS.LessThanEqualTo
    },
    {
      which: 'focus_block_conflict'
    },
    {
      which: 'meeting_conflict'
    },
    {
      which: 'last_minute_schedule',
      type: 'stat',
      statType: 'hours',
      operator: OPERATORS.GreaterThan
    }
  ],
  Content: [
    {
      which: 'required_agenda',
      example: 'Review draft quarterly roadmap'
    },
    {
      which: 'required_objectives',
      example: 'Decide on roadmap priorities'
    },
    {
      which: 'required_intention',
      example: 'Brainstorming'
    },
    {
      which: 'required_prework',
      example: 'Links to a doc'
    },
    {
      which: 'required_outcomes',
      example: 'Share with rest of the team.'
    },
    {
      which: 'required_conference_info',
      example: 'Brainstorming'
    }
  ],
  Attendees: [
    {
      which: 'max_attendees',
      type: 'stat',
      statType: 'people',
      operator: OPERATORS.LessThan
    },
    {
      which: 'required_attendee_roles'
    }
  ]
};

export const NEW_STATUS = 'new';
export const DELETE_STATUS = 'delete';

export const ADMIN_EMAILS = [
  'chuck@meetwell.app',
  // 'adamstorr@gmail.com',
  'adam@meetwell.app',
  'brian@meetwell.app',
  'elliscstorr@gmail.com'
  // 'joey.kamir@gmail.com'
];

export const SOCIAL_LINKS = {
  Twitter: 'https://twitter.com/meetwellapp',
  LinkedIn: 'https://www.linkedin.com/company/meetwell/'
};
