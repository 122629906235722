/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { set } from 'lodash';

import { isValidAPICall } from '../misc/utils';

import {
  deleteAccount,
  generallyUpdateUser,
  getEmailSubscriptionStatus,
  getSubscription,
  getSynchronizations,
  getUser,
  getUserSettings,
  logoutUser,
  saveUserDenylistOrganizers,
  setEmailSubscriptionStatus,
  setTimeZone,
  setTokenAndConfig,
  setUserCommunicationsPreference,
  toggleAPIError,
  toggleOnboarding,
  updateUserSettings
} from './utils/user-utils';

const initialState = {
  general: undefined,
  status: undefined,
  settings: undefined,
  synchronizations: undefined
};

export default (state = initialState, action) => {
  if (isValidAPICall(action, 'users')) {
    return {
      ...state,
      ...set(state, 'general', action.payload.data)
    };
  } else if (isValidAPICall(action, 'user_settings')) {
    return {
      ...state,
      ...set(state, 'settings', action.payload.data)
    };
  } else if (isValidAPICall(action, 'synchronization')) {
    return {
      ...state,
      ...set(state, 'synchronizations', action.payload.data)
    };
  } else if (action.type === 'API_READ_FAILED') {
    const { response } = action.payload;
    const code = response ? response.status : 0;
    console.error({ response });
    return {
      ...state,
      ...set(state, 'status', code)
    };
  }

  return state;
};

export {
  setUserCommunicationsPreference,
  getEmailSubscriptionStatus,
  setEmailSubscriptionStatus,
  setTimeZone,
  setTokenAndConfig,
  toggleOnboarding,
  getUser,
  getUserSettings,
  getSubscription,
  getSynchronizations,
  saveUserDenylistOrganizers,
  generallyUpdateUser,
  updateUserSettings,
  logoutUser,
  deleteAccount,
  toggleAPIError
};
