/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { SEO } from '../../components/misc/SEO';

import styles from '../../styles/marketing/misc.module.scss';

export default class HelpView extends Component {
  render() {
    return (
      <>
        <SEO title="Help" />
        <div
          className={classNames(
            styles.subpageView,
            styles.runningText,
            styles.blogPost
          )}>
          <div className={styles.inner}>
            <h1>Help &amp; support</h1>
            <p>Looking for some assistance with MeetWell?</p>
            <p>
              If you haven't already, check out the <Link to="/faq">FAQ</Link>,
              or feel free to email us at{' '}
              <a href="mailto:support@meetwell.app">support@meetwell.app</a>, we
              will answer right away!.
            </p>
          </div>
        </div>
      </>
    );
  }
}
