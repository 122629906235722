/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';

import styles from '../../styles/social-post.module.scss';

interface SocialEmbed {
  id?: string;
  src?: string;
}

const SOCIAL_EMBEDS: Record<string, SocialEmbed> = {
  Reddit: {
    id: 'reddit-script',
    src: '//embed.redditmedia.com/widgets/platform.js'
  },
  Twitter: {
    id: 'twitter-script',
    src: 'https://platform.twitter.com/widgets.js'
  },
  TikTok: {
    id: 'tiktok-script',
    src: 'https://www.tiktok.com/embed.js'
  },
  Giphy: {},
  LocalImage: {}
};

interface SocialPostProps {
  content: string;
  media: string;
  mediaMisc?: string;
  mediaUrl: string;
  slug: string;
}

export const SocialPost: React.ComponentType<SocialPostProps> = React.memo(
  ({ media, mediaUrl, content, slug, mediaMisc }) => {
    const thisEmbed = SOCIAL_EMBEDS[media];

    useEffect(() => {
      if (!isEmpty(thisEmbed)) {
        const scriptId = `${thisEmbed.id}-${slug}`;
        const existingScript = document.getElementById(scriptId);

        // Create script if not already there
        if (existingScript === null && thisEmbed.src) {
          const script = document.createElement('script');
          script.async = true;
          script.id = scriptId;
          script.src = thisEmbed.src;
          script.charset = 'utf-8';

          const elemNode = document.getElementById(
            `${thisEmbed.id}-${slug}-div`
          );
          if (elemNode) {
            elemNode.appendChild(script);
          }
        }
      }
    }, [slug, thisEmbed]);

    const innerContent = useMemo(() => {
      if (media === 'Reddit') {
        return (
          <blockquote className="reddit-card">
            <a href={mediaUrl}>{content}</a>
          </blockquote>
        );
      } else if (media === 'Twitter') {
        return (
          <blockquote className="twitter-tweet">
            <a href={mediaUrl}>{content}</a>
          </blockquote>
        );
      } else if (media === 'TikTok') {
        return (
          <blockquote
            cite={mediaUrl}
            className="tiktok-embed"
            data-video-id={mediaMisc}>
            <section></section>
          </blockquote>
        );
      } else if (media === 'Giphy') {
        return (
          <div className={styles.giphy}>
            <iframe
              allowFullScreen
              className="giphy-embed"
              frameBorder="0"
              height="100%"
              src="https://giphy.com/embed/WSsUvNnZsrvNy0AWDi"
              title={mediaUrl}
              width="100%"></iframe>
          </div>
        );
      } else if (media === 'LocalImage') {
        return <img alt={mediaUrl} src={`/blog/${mediaUrl}`} />;
      }
      return null;
    }, [content, media, mediaMisc, mediaUrl]);

    return (
      <div className={styles.social} id={`${thisEmbed.id}-${slug}-div`}>
        {innerContent}
      </div>
    );
  }
);
