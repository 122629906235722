import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { find, isEmpty, isEqual, isNumber } from 'lodash';
import moment from 'moment';
import { StringParam, useQueryParam } from 'use-query-params';

import { SEO } from '../../components/misc/SEO';
import { SyncingIndicator } from '../../components/misc/SyncingIndicator';
import { WellnessBlockModal } from '../../components/misc/WellnessBlockModal';
import { BrowserView } from '../../components/plan/BrowserView';
import { MobileView } from '../../components/plan/MobileView';
import { usePrevious } from '../../hooks/previous';
import {
  API_REQ_DELAY,
  DATE_FORMAT,
  QUERY_PARAMS,
  REVIEW_VIEWS,
  URLS
} from '../../misc/consts';
import {
  APIResponse,
  Calendar,
  DateSelectionProps,
  Meeting,
  RootState
} from '../../misc/types';
import { buildReportingData } from '../../misc/utils';
import * as API from '../../modules/api';
import { getCalendars } from '../../modules/calendars';
import { getMeetings, getOneMeeting } from '../../modules/meetings';
import { metricsEvaluate } from '../../modules/reports';
import { getWellnessBlocks } from '../../modules/wellness-blocks';

import 'react-big-calendar/lib/css/react-big-calendar.css';

export interface ChangePageProps {
  clearView?: boolean;
  date?: moment.Moment;
  meeting?: string;
  wellnessBlock?: string;
}

export interface PlanViewProps {
  APICallsComplete: boolean;
  calendars: Calendar[];
  dateSelection: DateSelectionProps;
  isLoading: boolean;
  isSyncingCalendar: boolean;
  meetings: Meeting[];
  onChangeThePage: (data: ChangePageProps) => void;
  onCloseMeetingModal: () => void;
  onSelectEvent: (event: Meeting) => void;
  onViewWellnessBlock: (id: string) => void;
  selectedEvent?: Meeting;
}

export const PlanView: React.ComponentType = React.memo(() => {
  const dispatch = useDispatch();
  const history = useHistory();

  const location = useLocation();
  const prevLocation = usePrevious(location);

  const APICallsToMake = useRef({
    meetings: false
  });

  const [weekParam] = useQueryParam(QUERY_PARAMS.Week, StringParam);
  const [onboardingStep] = useQueryParam(QUERY_PARAMS.Onboarding, StringParam);

  const user = useSelector((state: RootState) => state.user.general);
  const refreshes = useSelector((state: RootState) => state.messages.refreshes);
  const prevRefreshes = usePrevious(refreshes);
  const meetings = useSelector((state: RootState) => state.meetings);
  const calendars = useSelector((state: RootState) => state.calendars.list);
  const prevMeetings = usePrevious(meetings);
  const areWellnessBlocksLoading = useSelector(
    (state: RootState) => state.loading.wellness_blocks
  );
  const isSyncingCalendar = useSelector(
    (state: RootState) => state.meeting_info.isSyncing
  );
  const isLoading = useSelector((state: RootState) => {
    return state.api.isCreating === 0 &&
      state.api.isReading === 0 &&
      state.api.isDeleting === 0 &&
      state.api.isUpdating === 0
      ? false
      : true;
  });

  const [APICallsComplete, setAPICallsComplete] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Meeting | undefined>(
    undefined
  );

  const [dateSelection, setDateSelection] = useState<DateSelectionProps>({
    // Make default "This week"
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate(),
    visibleDate: new Date(),
    key: 'selection'
  });
  const prevDateSelection = usePrevious(dateSelection);

  const match = useRouteMatch<{
    id: string;
  }>();

  const checkForDeepLink = useCallback(() => {
    // If sub view (params) or query params (parsed)
    if (location.pathname !== URLS.Plan.Default || !isEmpty(match.params)) {
      // Maybe show a meeting deep link
      let meetingId = match.params.id;

      if (!isEmpty(meetingId)) {
        if (!isEmpty(meetings)) {
          // If Onboarding step, pull meetingId from list of meetings
          if (onboardingStep) {
            meetingId = meetings[0].id;
          }

          const thisMeetingInMeetings = find(meetings, function (mtg) {
            return mtg.id === meetingId;
          });

          // First see if meeting is in redux
          if (!isEmpty(thisMeetingInMeetings)) {
            setSelectedEvent(thisMeetingInMeetings);
          } else {
            // If not, then get that meeting from getMeeting()
            API.makeRequest(() => {
              dispatch(
                getOneMeeting(meetingId, (event: APIResponse) => {
                  if (event.response && event.response.status === 404) {
                    history.push(URLS.Plan.Default);
                  } else if (event.body?.data) {
                    setSelectedEvent(event.body.data);
                  } else {
                    history.push(URLS.Plan.Default);
                  }
                })
              );
            });
          }
        } else {
          setTimeout(checkForDeepLink, API_REQ_DELAY);
        }
      }
    }
  }, [
    dispatch,
    history,
    location.pathname,
    match.params,
    meetings,
    onboardingStep
  ]);

  const makeMeetingsAPICalls = useCallback(
    callback => {
      const start = moment(dateSelection.startDate).format(DATE_FORMAT.Hash);
      const end = moment(dateSelection.endDate).format(DATE_FORMAT.Hash);

      // Go!
      dispatch(getMeetings({ start, end }, callback));
      dispatch(getCalendars());
    },
    [dateSelection, dispatch]
  );

  const makeReportingAPICalls = useCallback(() => {
    const who = REVIEW_VIEWS.You.slug;
    const data = buildReportingData({
      startDate: dateSelection.startDate,
      endDate: dateSelection.endDate,
      who,
      user
    });

    ////// Go!
    ////

    // [Time spent] Total time
    // [Time spent] Meeting cost
    dispatch(
      metricsEvaluate({
        ...data,
        which: 'duration_and_costs'
      })
    );

    // [Time spent] Total # of meetings
    // [Report card] Overall number
    dispatch(
      metricsEvaluate({
        ...data,
        which: 'averages',
        shouldGetTrends: true
      })
    );
  }, [dateSelection, dispatch, user]);

  const makeWellnessBlocksAPICalls = useCallback(() => {
    if (areWellnessBlocksLoading === 0) {
      const start = moment(dateSelection.startDate).format(DATE_FORMAT.Hash);
      const end = moment(dateSelection.endDate).format(DATE_FORMAT.Hash);

      dispatch(getWellnessBlocks({ start, end }));
    }
  }, [areWellnessBlocksLoading, dateSelection, dispatch]);

  const checkIfCallsHaveCompleted = useCallback(which => {
    // Mark call as 'true'
    if (which) {
      APICallsToMake.current = { ...APICallsToMake.current, [which]: true };

      // Check if all calls have been completed
      const isComplete =
        Object.values(APICallsToMake.current).indexOf(false) === -1;
      if (isComplete) {
        setAPICallsComplete(true);
      }
    }
  }, []);

  const makeAPICalls = useCallback(() => {
    API.makeRequest(() => {
      makeMeetingsAPICalls(() => checkIfCallsHaveCompleted('meetings'));
      makeReportingAPICalls();
      makeWellnessBlocksAPICalls();
    });
  }, [
    checkIfCallsHaveCompleted,
    makeMeetingsAPICalls,
    makeReportingAPICalls,
    makeWellnessBlocksAPICalls
  ]);

  const maybeSetDateAndMakeAPICalls = useCallback(() => {
    if (weekParam) {
      // Check if valid date
      const thisDate = moment(weekParam);
      if (thisDate.isValid()) {
        setDateSelection({
          visibleDate: thisDate.toDate(),
          startDate: thisDate.startOf('week').toDate(),
          endDate: thisDate.endOf('week').toDate()
        });
      } else {
        setAPICallsComplete(false);
        history.push(URLS.Plan.Default);
      }
    } else {
      setAPICallsComplete(false);
      makeAPICalls();
    }
  }, [history, makeAPICalls, weekParam]);

  const _changeThePage = useCallback(
    ({ meeting, wellnessBlock, date, clearView = false }: ChangePageProps) => {
      const currentView = match.params.id ? `meeting/${match.params.id}` : '';
      const currentDate = !isEmpty(weekParam) ? `?w=${weekParam}` : '';

      let nextView = currentView;
      if (!isEmpty(meeting) || isNumber(meeting)) {
        nextView = `meeting/${meeting}`;
      } else if (!isEmpty(wellnessBlock) || isNumber(wellnessBlock)) {
        nextView = `block/${wellnessBlock}`;
      }

      const nextDate = !isEmpty(date)
        ? `?w=${moment(date).format('YYYY-MM-DD')}`
        : currentDate;

      if (clearView) {
        nextView = '';
      }

      history.push(`${URLS.Plan.Default}/${nextView}${nextDate}`);
    },
    [history, match, weekParam]
  );

  const _selectEvent = useCallback(
    event => {
      _changeThePage({ meeting: event.id });
    },
    [_changeThePage]
  );

  const _handleCloseMeetingModal = useCallback(() => {
    setSelectedEvent(undefined);
    _changeThePage({ clearView: true });
  }, [_changeThePage]);

  const _handleViewWellnessBlock = useCallback(
    (id: string) => {
      _changeThePage({ wellnessBlock: id });
    },
    [_changeThePage]
  );

  const _onCloseWellnessBlockModal = useCallback(() => {
    _changeThePage({ clearView: true });
  }, [_changeThePage]);

  // On mount
  useEffect(() => {
    maybeSetDateAndMakeAPICalls();
    checkForDeepLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // On meeting changes
  useEffect(() => {
    checkForDeepLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetings]);

  // Update selectedEvent if there is one
  useEffect(() => {
    if (selectedEvent && !isEqual(meetings, prevMeetings)) {
      // Find in meetings where selectedEvent id is equal, and update the event
      const thisEvent = find(meetings, function (mtg) {
        return mtg.id === selectedEvent.id;
      });
      if (!isEmpty(thisEvent)) {
        setSelectedEvent(thisEvent);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvent]);

  // Check if location params have changed
  useEffect(() => {
    if (location && prevLocation && !isEqual(location, prevLocation)) {
      // Make API Calls if prev location isn't Plan, or if changing weeks
      if (
        !prevLocation.pathname.includes(URLS.Plan.Default) ||
        !isEqual(prevLocation.search, location.search)
      ) {
        maybeSetDateAndMakeAPICalls();
      }

      checkForDeepLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Whenever dateSelection changes
  useEffect(() => {
    if (
      prevDateSelection &&
      dateSelection &&
      !isEqual(prevDateSelection, dateSelection)
    ) {
      setAPICallsComplete(false);
      makeAPICalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateSelection]);

  // Check for websocket update
  // Make API calls directly since state should be the same
  useEffect(() => {
    if (
      refreshes &&
      refreshes.length > 0 &&
      !isEqual(refreshes, prevRefreshes)
    ) {
      setAPICallsComplete(false);
      makeAPICalls();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshes]);

  const viewProps = useMemo(() => {
    return {
      APICallsComplete,
      calendars,
      dateSelection,
      isLoading,
      isSyncingCalendar,
      meetings,
      selectedEvent,
      onCloseMeetingModal: _handleCloseMeetingModal,
      onChangeThePage: _changeThePage,
      onSelectEvent: _selectEvent,
      onViewWellnessBlock: _handleViewWellnessBlock
    };
  }, [
    _changeThePage,
    _handleCloseMeetingModal,
    _handleViewWellnessBlock,
    _selectEvent,
    APICallsComplete,
    calendars,
    dateSelection,
    isLoading,
    isSyncingCalendar,
    meetings,
    selectedEvent
  ]);

  return (
    <>
      <SEO title="Plan" />
      <WellnessBlockModal onClose={_onCloseWellnessBlockModal} />
      <SyncingIndicator isVisible={isSyncingCalendar} />
      {isMobile && <MobileView {...viewProps} />}
      {isBrowser && <BrowserView {...viewProps} />}
    </>
  );
});
