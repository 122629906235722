import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { DATE_FORMAT } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { Meeting } from '../../misc/types';
import {
  generallyUpdateMeeting,
  makeWellnessBlock
} from '../../modules/meetings';
import { Button, Tooltip } from '../design-system';

import styles from '../../styles/meeting-modal.module.scss';

interface SectionActionsMaybeWBViewProps {
  actionBeingMade: string;
  event: Meeting;
  onUpdateAction: (action: string) => void;
}

export const SectionActionsMaybeWBView: React.ComponentType<SectionActionsMaybeWBViewProps> =
  React.memo(({ actionBeingMade, event, onUpdateAction }) => {
    const dispatch = useDispatch();

    const _handleMakeWellnessBlock = useCallback(() => {
      onUpdateAction('wellness block'); // Set spinner

      const start = moment(event.attributes.starts_at).format(DATE_FORMAT.Hash);
      const end = moment(event.attributes.ends_at).format(DATE_FORMAT.Hash);

      // Make API calls
      dispatch(makeWellnessBlock(event.id, start, end));

      // Track goal
      Fathom.trackGoal(GOALS.WellnessBlocks.ConvertedMeeting);
    }, [dispatch, event, onUpdateAction]);

    const _handleIgnoreConvertWellnessBlock = useCallback(() => {
      onUpdateAction('ignore wellness block'); // Set spinner

      // Make API calls
      dispatch(
        generallyUpdateMeeting(
          event.id,
          'maybe_wellness_block',
          'verified_false'
        )
      );
    }, [dispatch, event.id, onUpdateAction]);

    return (
      <div className={styles.sectionActions}>
        <h5>Is this a Wellness block?</h5>
        <Button
          intent="primary"
          isLoading={actionBeingMade === 'wellness block'}
          minimal
          shouldLookDisabled
          text="Convert to a Wellness block"
          onClick={_handleMakeWellnessBlock}
        />
        <Button
          intent="danger"
          isLoading={actionBeingMade === 'ignore wellness block'}
          minimal
          shouldLookDisabled
          text="Ignore"
          onClick={_handleIgnoreConvertWellnessBlock}
        />
        <div className={styles.explainerTip}>
          <Tooltip content="Wellnesss blocks are chunks of untouchable time reserved just for you. If you'd like, MeetWell can protect this time from scheduling conflicts.">
            <div className={styles.explainerText}>What's a Wellness block?</div>
          </Tooltip>
        </div>
      </div>
    );
  });
