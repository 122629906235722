/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { connect } from '@giantmachines/redux-websocket';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from 'moment';
import {
  createResource,
  readEndpoint,
  setAxiosConfig,
  updateResource
} from 'redux-json-api';
import Cookies from 'universal-cookie';

import { COOKIE_NAME } from '../../misc/consts';
import { log } from '../../misc/utils';

export const setUserCommunicationsPreference = (which, preference) => {
  log(`setUserPreference(${preference})`);
  return dispatch => {
    const entity = {
      type: `users#update`,
      attributes: {
        [which]: preference
      }
    };

    dispatch(updateResource(entity));
  };
};

export const getEmailSubscriptionStatus = (email, callback) => {
  log(`getEmailSubscriptionStatus(${email})`);

  return dispatch => {
    dispatch(
      readEndpoint(
        `${process.env.REACT_APP_BASE_URL}/api/v1/communications/email/opted-out?email=${email}`
      )
    )
      .then(response => {
        callback(response);
      })
      .catch(response => {
        callback(response);
      });
  };
};

export const setEmailSubscriptionStatus = (email, direction) => {
  log(`setEmailSubscriptionStatus(${email}, ${direction})`);

  return dispatch => {
    dispatch(
      readEndpoint(
        `${process.env.REACT_APP_BASE_URL}/api/v1/communications/email/opt-${direction}?email=${email}`
      )
    );
  };
};

export const setTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  log(`setTimeZone(${timeZone})`);
  return dispatch => {
    const entity = {
      type: `users#update`,
      attributes: {
        time_zone: timeZone
      }
    };

    dispatch(updateResource(entity));
  };
};

export const setTokenAndConfig = (token, callback) => {
  log('setTokenAndConfig()');
  return dispatch => {
    // Set cookies
    const cookies = new Cookies();
    cookies.set(COOKIE_NAME, token, {
      path: '/',
      expires: moment().add(25, 'days').toDate(),
      sameSite: 'lax',
      ...(process.env.REACT_APP_ENVIRONMENT === 'production' && {
        secure: true
      })
    });

    // Set up default Axios
    axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}/api/v1`;
    axios.defaults.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/vnd.api+json'
    };

    // Setup websocket
    const WS_URL = process.env.REACT_APP_WEBSOCKET_URL;
    dispatch(connect(`${WS_URL}?token=${token}`));

    // Setup json-api config
    dispatch(
      setAxiosConfig({
        baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    // Fire off the rest
    callback && callback();
  };
};

export const toggleOnboarding = (flag, callback) => {
  log('toggleOnboarding()');
  return dispatch => {
    const entity = {
      type: `users#update`,
      attributes: {
        onboarded: flag
      }
    };

    dispatch(updateResource(entity)).then(() => {
      if (callback) callback();
    });
  };
};

export const getUser = () => {
  log('getUser()');
  return dispatch => {
    dispatch(readEndpoint('users'));
  };
};

export const getUserSettings = () => {
  log('getUserSettings()');
  return dispatch => {
    dispatch(readEndpoint('users/settings'));
  };
};

export const getSubscription = () => {
  log('getSubscription()');
  return dispatch => {
    dispatch(readEndpoint('subscription'));
  };
};

export const saveUserDenylistOrganizers = (emails, callback) => {
  log('saveUserDenylistOrganizers()');
  return dispatch => {
    const entity = {
      type: `users/denylisted_organizers/bulk`,
      attributes: {
        email_list: emails
      }
    };

    dispatch(createResource(entity)).then(callback).catch(callback);
  };
};

export const generallyUpdateUser = ({ which, value, callback = () => {} }) => {
  log('generallyUpdateUser()');
  return dispatch => {
    const entity = {
      type: `users#update`,
      attributes: {
        [which]: value
      }
    };

    dispatch(updateResource(entity)).then(callback).catch(callback);
  };
};

export const updateUserSettings = ({ which, value, callback = () => {} }) => {
  log('updateUserSettings()');
  return dispatch => {
    const entity = {
      type: `users/settings#update`,
      attributes: {
        [which]: value
      }
    };

    dispatch(updateResource(entity)).then(callback).catch(callback);
  };
};

export const logoutUser = data => {
  log('logoutUser()');
  return () => {
    const cookies = new Cookies();
    const token = cookies.get(COOKIE_NAME);

    // HACK HACK
    // Create a new instance since the baseURL is different
    const instance = axios.create({
      baseURL: `${process.env.REACT_APP_BASE_URL}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/vnd.api+json'
      }
    });

    // Make request
    const request = instance.delete(`/logout`);
    request.then(() => {});

    // Clear cookies
    cookies.remove(COOKIE_NAME, { path: '/' });

    // Redirect
    const url = !isEmpty(data) && !isEmpty(data.url) ? data.url : '/';
    window.location.href = url;
  };
};

export const deleteAccount = () => {
  log('deleteAccount()');
  return () => {
    const request = axios.delete('users?confirm=true');
    request.then(async () => {
      // Clear cookies
      const cookies = new Cookies();
      cookies.remove(COOKIE_NAME, { path: '/' });

      // Redirect
      window.location.href = '/';
    });
  };
};

export const getSynchronizations = () => {
  log('getSynchronizations()');
  return dispatch => {
    dispatch(readEndpoint('synchronizations'));
  };
};

export const toggleAPIError = () => {
  log('toggleAPIError()');
  return dispatch => {
    dispatch(readEndpoint('thisisntarealendpoint'));
  };
};
