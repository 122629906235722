import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { isEmpty } from 'lodash';

import { URLS } from '../../misc/consts';
import { User } from '../../misc/types';
import { Button, Icon } from '../design-system';
import { ModalView } from '../misc/ModalView';

import { ChoosePlan } from './ChoosePlan';

import styles from '../../styles/balance.module.scss';

interface ChoosePlanModalProps {
  isVisible: boolean;
  onClose: () => void;
  user: User;
}

export const ChoosePlanModal: React.ComponentType<ChoosePlanModalProps> =
  React.memo(({ isVisible, user, onClose }) => {
    const history = useHistory();

    const _handleGoToAccount = useCallback(() => {
      history.push(URLS.Account.Default);
    }, [history]);

    const hasDeactivated = !isEmpty(user?.attributes.deactivated_at);

    return (
      <ModalView
        className={styles.choosePlanModal}
        innerClassName={styles.choosePlanModalInner}
        isDismissible={!hasDeactivated}
        isVisible={isVisible}
        miscActions={
          <div className={styles.goToAccount}>
            <Button
              iconRight={<Icon color="white" which="arrow-right" />}
              intent="primary"
              text="Manage your account"
              onClick={_handleGoToAccount}
            />
          </div>
        }
        onClose={onClose}>
        <ChoosePlan />
      </ModalView>
    );
  });
