import React from 'react';
import { isBrowser } from 'react-device-detect';
import Moment from 'react-moment';
import moment from 'moment';

import { DATE_FORMAT } from '../../misc/consts';

import styles from '../../styles/meeting-date.module.scss';

interface MeetingDateProps {
  allDay: boolean;
  endDate: string;
  startDate: string;
}

export const MeetingDate: React.ComponentType<MeetingDateProps> = React.memo(
  ({ allDay, endDate, startDate }) => {
    const dateFormat = isBrowser ? DATE_FORMAT.Long : DATE_FORMAT.Short;

    if (allDay) {
      const startsAt = moment.utc(startDate);
      const endsAt = moment(endDate);
      const isSameDay = moment(startsAt.format('YYYY-MM-DD')).isSame(
        moment(endsAt).format('YYYY-MM-DD')
      );

      return (
        <div className={styles.dateDetails}>
          <Moment className={styles.dateText} format={dateFormat}>
            {startsAt}
          </Moment>
          {!isSameDay && (
            <>
              <span>-</span>
              <Moment className={styles.dateText} format={dateFormat}>
                {endsAt}
              </Moment>
            </>
          )}
        </div>
      );
    }

    return (
      <div className={styles.dateDetails}>
        <Moment className={styles.dateText} format={dateFormat}>
          {startDate}
        </Moment>
        <div className={styles.timeRange}>
          <Moment format={DATE_FORMAT.Time}>{startDate}</Moment>
          <span>-</span>
          <Moment format={DATE_FORMAT.Time}>{endDate}</Moment>
        </div>
      </div>
    );
  }
);
