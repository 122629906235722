import React from 'react';
import { isBrowser } from 'react-device-detect';
import classNames from 'classnames';
import { isEmpty, isEqual } from 'lodash';

import { REVIEW_VIEWS } from '../../misc/constants';
import { ROLES } from '../../misc/consts';
import { Reports, SelectedView, User } from '../../misc/types';
import { formatMoney, getViewMetric } from '../../misc/utils';
import { Dollar } from '../design-system';

import { EditSalaryButton } from './EditSalaryButton';
import { Metric } from './Metric';

import styles from '../../styles/review-summary-stats.module.scss';

interface SummaryCostsProps {
  isColumnLayout: boolean;
  isForMarketing?: boolean;
  onMakeAPICalls?: () => void;
  reports: Reports;
  selectedView: SelectedView;
  user: User;
}

export const SummaryCosts: React.ComponentType<SummaryCostsProps> = React.memo(
  ({
    user,
    reports,
    selectedView,
    isColumnLayout,
    isForMarketing = false,
    onMakeAPICalls
  }) => {
    // Get costs
    const totalMeetingsCost = getViewMetric(
      'duration_and_costs.attributes.rows.total.meeting_cost_total',
      reports,
      selectedView
    );

    const avgCostPerMeeting = getViewMetric(
      'duration_and_costs.attributes.rows.total.ave_cost_per_meeting',
      reports,
      selectedView
    );

    // Null if the numbers aren't valid
    if (Number.isNaN(totalMeetingsCost) || Number.isNaN(avgCostPerMeeting)) {
      return null;
    }

    const isAdminOfTeam = !isEmpty(user.attributes.company)
      ? user.attributes.company.roles.includes(ROLES.Admin.value)
      : false;

    const dontShowEditButton =
      isForMarketing ||
      (isColumnLayout && isBrowser) || // If is Plan view
      (isEqual(selectedView, REVIEW_VIEWS.Team) && !isAdminOfTeam); // If on Team view, but is not an admin

    return (
      <div className={classNames(styles.statWrapper, styles.overallCosts)}>
        <div className={styles.stat}>
          <div className={styles.totals}>
            <Metric
              className={styles.meetingCost}
              data={totalMeetingsCost}
              type="dollar"
            />
            <div className={styles.avgCost}>
              <div className={styles.num}>
                <Dollar />
                {formatMoney(avgCostPerMeeting, 0)}
              </div>
              <div className={styles.label}>AVG/MTG</div>
            </div>
            {!dontShowEditButton && (
              <EditSalaryButton
                reports={reports}
                selectedView={selectedView}
                user={user}
                // Arrow function which preserves parameters within (calls the right dates)
                onMakeAPICalls={() => onMakeAPICalls && onMakeAPICalls()}
              />
            )}
          </div>
          <div className={styles.basedOnSalary}>
            (based on a <Dollar />
            {formatMoney(
              getViewMetric(
                'duration_and_costs.attributes.rows.annual_salary',
                reports,
                selectedView
              ),
              0
            )}{' '}
            avg. salary)
          </div>
        </div>
      </div>
    );
  }
);
