/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isValidAPICall } from '../misc/utils';

import { getCalendarEventColors, getCalendars } from './utils/calendars-utils';

const initialState = {
  list: [],
  colors: []
};

export default (state = initialState, action) => {
  if (isValidAPICall(action, 'calendars')) {
    return {
      ...state,
      list: action.payload.data || []
    };
  }

  if (isValidAPICall(action, 'event_colors')) {
    return {
      ...state,
      colors: action.payload.data || []
    };
  }

  return state;
};

export { getCalendars, getCalendarEventColors };
