import React, { useMemo } from 'react';
import { isEmpty } from 'lodash';

import { Reports, SelectedView, User } from '../../misc/types';
import { flattenEmail, getViewData } from '../../misc/utils';

import {
  HandleSortType,
  RowData,
  SortDataType,
  SortingData
} from './DetailedStats';
import { DetailRow } from './DetailRow';
import { TableHeaderColumn } from './TableHeaderColumn';

import styles from '../../styles/review-detailed-stats.module.scss';

interface OrganizerSectionProps {
  onHandleSort: HandleSortType;
  onSortData: SortDataType;
  reports: Reports;
  selectedView: SelectedView;
  sorting: SortingData;
  user: User;
}

export const OrganizerSection: React.ComponentType<OrganizerSectionProps> =
  React.memo(
    ({ reports, selectedView, user, sorting, onSortData, onHandleSort }) => {
      const data = getViewData(
        'duration_and_cost_details.attributes.rows.organizers',
        reports,
        selectedView
      );

      const columns = useMemo(() => {
        return [
          {
            className: styles.type,
            slug: 'name',
            text: 'EMPLOYEE'
          },
          {
            className: styles.count,
            slug: 'count',
            text: '#'
          },
          {
            className: styles.goodBad,
            slug: 'goodBad',
            text: 'GOOD/BAD'
          },
          {
            className: styles.nudges,
            slug: 'nudges',
            text: 'NUDGES'
          },
          {
            className: styles.cost,
            slug: 'cost',
            text: 'COST'
          },
          {
            className: styles.hours,
            slug: 'hours',
            text: 'HOURS'
          }
        ];
      }, []);

      // Turn into array + sort the data
      const sortedData: RowData[] = [];
      for (const obj in data) {
        sortedData.push({
          slug: obj,
          regexedEmail: flattenEmail(obj),
          data: data[obj]
        });
      }
      const { where: sortBy, isDesc } = sorting.organizers;
      sortedData.sort((a, b) => {
        return onSortData(sortBy, a, b, isDesc);
      });

      return (
        <div className={styles.section}>
          <h5>
            <span>All organizers</span>
          </h5>
          <div className={styles.description}>
            The people who are scheduling the meetings.
          </div>
          <div className={styles.card}>
            <div className={styles.header}>
              {columns.map((item, index) => (
                <TableHeaderColumn
                  key={index}
                  className={item.className}
                  isDesc={isDesc}
                  showSort={sortBy === item.slug}
                  text={item.text}
                  onClick={() => onHandleSort('organizers', item.slug)}
                />
              ))}
            </div>
            <div className={styles.rows}>
              {!isEmpty(sortedData) &&
                sortedData.map((item, key) => {
                  return (
                    <DetailRow
                      key={key}
                      email={item.slug}
                      hoursCount={item.data.total.hours}
                      label={
                        <>
                          {item.data.name ? item.data.name : item.slug}{' '}
                          {user.attributes.email === item.slug && (
                            <label>(you)</label>
                          )}
                        </>
                      }
                      nudgesCount={item.data.past_actions || 0}
                      numOfBadMtgs={item.data.bad.count}
                      numOfGoodMtgs={item.data.good.count}
                      showMiscActions={user.attributes.email !== item.slug}
                      tooltip={item.slug}
                      totalCost={item.data.total.cost}
                      totalMtgsCount={item.data.total.count}
                    />
                  );
                })}
            </div>
            <div className={styles.empty}>
              Your meeting organizers will appear here.
            </div>
          </div>
        </div>
      );
    }
  );
