import React from 'react';

import { ErrorState, Loading } from '../design-system';

import { ArticleView } from './ArticleView';

import styles from '../../styles/syncing-indicator.module.scss';

interface SyncingIndicatorProps {
  isVisible?: boolean;
}

export const SyncingIndicator: React.ComponentType<SyncingIndicatorProps> = React.memo(
  ({ isVisible = false }) => {
    if (!isVisible) {
      return null;
    }

    return (
      <div className={styles.syncingIndicator}>
        <div className={styles.inner}>
          <ErrorState
            description={<ArticleView order="random" renderBy="pull_quote" />}
            inline={true}
            title={
              <>
                <Loading inline small />
                <div className={styles.title}>
                  Assessing the health of your calendar...
                </div>
              </>
            }
          />
        </div>
      </div>
    );
  }
);
