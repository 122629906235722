import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { find, isEqual } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';

import {
  INTENT,
  ONBOARDING_STEPS,
  PLAN_ACTIONS,
  PLAN_TOOLTIPS,
  QUERY_PARAMS
} from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';
import { Settings } from '../../misc/types';
import { renderPlanTag } from '../../misc/utilities';
import { getFutureActions, getPastActions } from '../../modules/actions';
import { updateUserSettings } from '../../modules/user';
import {
  Callout,
  Checkbox,
  EditingPopover,
  Tag,
  TextLink
} from '../design-system';
import { PreviewReminderParams } from '../misc/PreviewReminderModal';
import { Tip } from '../misc/Tip';

import 'react-tagsinput/react-tagsinput.css';
import styles from '../../styles/action-info-item.module.scss';
import popoverStyles from '../../styles/popover.module.scss';

interface PlanAction {
  description: string;
  label: string;
  type: string;
  value: string;
}

interface InfoPlanActionProps {
  settings?: Settings;
}

export const InfoPlanAction: React.ComponentType<InfoPlanActionProps> =
  React.memo(({ settings }) => {
    // State
    const [isEditing, setIsEditing] = useState(false);
    const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);
    const [existingAction, setExistingAction] = useState('');
    const [stagedAction, setStagedAction] = useState('');

    const [onboardingStep] = useQueryParam(
      QUERY_PARAMS.Onboarding,
      StringParam
    );

    // Dispatch and selectors
    const dispatch = useDispatch();

    // Load
    useEffect(() => {
      if (settings) {
        const existingAction =
          settings.attributes.action_settings.non_compliance_action;

        setExistingAction(existingAction);
        setStagedAction(existingAction);
        setIsWaitingOnAPI(false);
      }
    }, [settings]);

    // Actions
    const _toggleEditMode = useCallback(() => {
      setIsEditing(prevState => !prevState);
    }, []);

    const _handleSelectAction = useCallback((e, action) => {
      e.stopPropagation();
      setStagedAction(action);
    }, []);

    const _save = useCallback(async () => {
      // Show spinner
      setIsWaitingOnAPI(true);

      // Post to API
      dispatch(
        updateUserSettings({
          which: 'action_settings',
          value: {
            non_compliance_action: stagedAction
          },
          callback: () => {
            dispatch(getFutureActions());
            dispatch(getPastActions());

            _toggleEditMode();
          }
        })
      );

      // Track goal
      Fathom.trackGoal(GOALS.Balance.ModifiedAction);
    }, [_toggleEditMode, dispatch, stagedAction]);

    const [, setIsPreviewReminderOpen] = useQueryParam(
      QUERY_PARAMS.PreviewReminder,
      StringParam
    );

    const _togglePreviewReminderModal = useCallback(
      param => {
        setIsPreviewReminderOpen(param);
      },
      [setIsPreviewReminderOpen]
    );

    if (!settings) {
      return null;
    }

    // Renderers
    const renderInfo = () => {
      const chosenComplianceAction =
        settings.attributes.action_settings.non_compliance_action;

      const chosenPlanActionDescription: PlanAction = find(
        PLAN_ACTIONS,
        function (o) {
          return o.value === chosenComplianceAction;
        }
      ) as PlanAction;

      if (!chosenPlanActionDescription) {
        return null;
      }

      return (
        <div className={styles.text}>
          <div className={styles.title}>
            {chosenPlanActionDescription.type &&
              renderPlanTag({
                value: chosenComplianceAction,
                size: 'regular',
                overrideValue: chosenPlanActionDescription.type
              })}
            <div
              className={styles.actionLabel}
              title={chosenPlanActionDescription.label}>
              {chosenPlanActionDescription.label}
            </div>
          </div>
          <div className={styles.description}>
            {chosenPlanActionDescription.description}
          </div>
        </div>
      );
    };

    const renderEditButton = () => {
      function Option(props: { action: PlanAction; selected?: boolean }) {
        return (
          <div
            className={classNames(popoverStyles.option, {
              [popoverStyles.selected]: props.selected
            })}
            onClick={e => _handleSelectAction(e, props.action.value)}>
            <div className={popoverStyles.content}>
              <Checkbox selected={stagedAction === props.action.value} />
              <div className={popoverStyles.text}>{props.action.label}</div>
              {existingAction === props.action.value && (
                <Tag intent={INTENT.Primary} small={true}>
                  Current
                </Tag>
              )}
            </div>
            <div className={popoverStyles.description}>
              {props.action.description}
            </div>
          </div>
        );
      }

      return (
        <EditingPopover
          className={styles.editWrapper}
          content={
            <>
              <div className={popoverStyles.options}>
                <div className={popoverStyles.tagHeader}>
                  <Tag intent={INTENT.Warning}>Auto-reminds</Tag>
                  <div
                    className={popoverStyles.viewExample}
                    onClick={() =>
                      _togglePreviewReminderModal(
                        PreviewReminderParams.Reminder
                      )
                    }>
                    View example reminder
                  </div>
                </div>
                <Option action={PLAN_ACTIONS.RemindConflicts} />
                <Option action={PLAN_ACTIONS.RemindAll} />
                <div className={popoverStyles.tagHeader}>
                  <Tag intent={INTENT.Danger}>Auto-declines</Tag>
                  <div
                    className={popoverStyles.viewExample}
                    onClick={() =>
                      _togglePreviewReminderModal(PreviewReminderParams.Warning)
                    }>
                    View example warning
                  </div>
                </div>
                <Option action={PLAN_ACTIONS.DeclineConflicts} />
                <Option action={PLAN_ACTIONS.DeclineAll} />
                <div className={popoverStyles.divider} />
                <Option action={PLAN_ACTIONS.DoNothing} />
              </div>
              <Callout className={popoverStyles.callout}>
                No matter what you choose, you'll be able to manually remind or
                decline individual meetings.
              </Callout>
            </>
          }
          description={PLAN_TOOLTIPS.Action}
          innerClassName={classNames(
            popoverStyles.editingPlan,
            popoverStyles.editPlanAction
          )}
          isLoading={isWaitingOnAPI}
          isSaveButtonDisabled={isEqual(existingAction, stagedAction)}
          isVisible={isEditing}
          onCancel={_toggleEditMode}
          onSave={_save}
          onToggle={_toggleEditMode}>
          <TextLink
            className={styles.edit}
            isDisabled={isEditing}
            text="Edit"
            onClick={_toggleEditMode}
          />
        </EditingPopover>
      );
    };

    return (
      <div className={styles.item}>
        <Tip
          {...ONBOARDING_STEPS.NonComplianceAction}
          criteria={
            onboardingStep === ONBOARDING_STEPS.NonComplianceAction.tag
          }>
          <div
            className={classNames(styles.section, styles.enforcementAction)}
            id={ONBOARDING_STEPS.NonComplianceAction.childId}>
            <div className={styles.instruction}>
              When meetings don't match these preferences by the deadline,
              MeetWell:
            </div>
            <div className={styles.content}>
              {renderInfo()}
              {renderEditButton()}
            </div>
          </div>
        </Tip>
      </div>
    );
  });
