import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { filter, isEmpty } from 'lodash';
import moment from 'moment';

import { ErrorState } from '../../components/design-system';
import { URLS } from '../../misc/consts';
import {
  Calendar,
  DateSelectionProps,
  Meeting,
  RootState
} from '../../misc/types';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from '../../styles/plan.module.scss';

interface EmptyStateProps {
  APICallsComplete: boolean;
  calendars: Calendar[];
  dateSelection: DateSelectionProps;
  isLoading: boolean;
  isSyncingCalendar: boolean;
  meetings: Meeting[];
}

export const EmptyState: React.ComponentType<EmptyStateProps> = React.memo(
  ({
    dateSelection,
    meetings,
    calendars,
    APICallsComplete,
    isLoading,
    isSyncingCalendar
  }) => {
    const { startDate, endDate } = dateSelection;

    const areCalendarsLoading = useSelector(
      (state: RootState) => state.loading.calendars
    );

    // Filter to just the date range
    const thisWeeksMeetings = filter([...meetings], function (item) {
      return moment(item.attributes.starts_at).isBetween(
        startDate,
        endDate,
        null,
        '[]' // Brackets mean "inclusive"
      );
    });

    // Don't show if meetings are available, or is loading or syncing
    const meetingsAreAvailable = !isEmpty(thisWeeksMeetings) && !isLoading;
    if (
      areCalendarsLoading ||
      meetingsAreAvailable ||
      isLoading ||
      isSyncingCalendar ||
      !APICallsComplete
    ) {
      return null;
    }

    // Check if calendars are connected
    let isWatchingAtLeastOneCal = false;
    for (let i = 0; i < calendars.length; i++) {
      if (calendars[i].attributes.watching) {
        isWatchingAtLeastOneCal = true;
      }
    }

    if (!isWatchingAtLeastOneCal) {
      return (
        <ErrorState
          className={styles.missingMeetings}
          description={
            <>
              <Link to={URLS.Account.Default}>Connect a calendar</Link> to view
              your meetings.
            </>
          }
          title="No meetings this week."
        />
      );
    }

    return (
      <ErrorState
        className={styles.missingMeetings}
        description="Check another week."
        title="No meetings this week."
      />
    );
  }
);
