import React, { Ref, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { includes } from 'lodash';

import { Goal, RootState } from '../../misc/types';
import * as API from '../../modules/api';
import {
  getGoals,
  saveGoals,
  saveGoalsAndToggleOnboarding
} from '../../modules/goals';

import styles from '../../styles/choose-goals.module.scss';

export interface ChooseGoalsHandle {
  _saveGoals: () => void;
  _saveGoalsAndToggleOnboarding: () => void;
}

export const ChooseGoals = React.forwardRef(
  (props, forwardedRef: Ref<ChooseGoalsHandle>) => {
    const dispatch = useDispatch();

    const user = useSelector((state: RootState) => state.user.general);
    const goals = useSelector((state: RootState) => state.goals);

    const [chosenGoals, setChosenGoals] = useState(
      user?.attributes.goal_ids || []
    );

    const _makeAPICalls = useCallback(() => {
      API.makeRequest(() => {
        dispatch(getGoals());
      });
    }, [dispatch]);

    const _toggleGoal = useCallback(
      id => {
        if (includes(chosenGoals, id)) {
          // Remove goal
          setChosenGoals(prevState => prevState.filter(item => item !== id));
        } else {
          // Add goal
          setChosenGoals(prevState => [...prevState, id]);
        }
      },
      [chosenGoals]
    );

    React.useImperativeHandle(forwardedRef, () => ({
      _saveGoals: () => {
        dispatch(saveGoals(chosenGoals));
      },
      _saveGoalsAndToggleOnboarding: () => {
        dispatch(saveGoalsAndToggleOnboarding(chosenGoals));
      }
    }));

    useEffect(() => {
      _makeAPICalls();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!goals) {
      return (
        <div className={styles.chooseGoals}>
          <div className={styles.loading}>Loading goals...</div>
        </div>
      );
    }

    return (
      <div className={styles.chooseGoals}>
        {goals.map((item, key) => (
          <GoalItem
            key={key}
            isSelected={includes(chosenGoals, parseInt(item.id))}
            item={item}
            onToggleGoal={() => _toggleGoal(parseInt(item.id))}
          />
        ))}
      </div>
    );
  }
);

interface GoalItemProps {
  isSelected: boolean;
  item: Goal;
  onToggleGoal: (id: string) => void;
}

const GoalItem: React.ComponentType<GoalItemProps> = React.memo(
  ({ isSelected, item, onToggleGoal }) => {
    const [isThisSelected, setIsThisSelected] = useState<boolean>(
      isSelected || false
    );

    const _toggle = useCallback(() => {
      setIsThisSelected(prevState => !prevState);
      onToggleGoal(item.id);
    }, [item, onToggleGoal]);

    return (
      <div
        className={classNames(styles.goal, {
          [styles.isSelected]: isThisSelected
        })}
        onClick={_toggle}>
        <div className={styles.inner}>{item.attributes.name}</div>
      </div>
    );
  }
);
