import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { isEqual } from 'lodash';

import { useTeamSubscription } from '../../hooks/team-subscription';
import { REVIEW_VIEWS } from '../../misc/consts';
import {
  DateSelectionProps,
  InviteStatus,
  Member,
  Reports,
  SelectedView,
  User
} from '../../misc/types';
import { getViewMetric } from '../../misc/utils';
import { InviteMembers } from '../account/InviteMembers';
import { Alert, Button, ErrorState } from '../design-system';

import { SummaryCard } from './SummaryCard';

import styles from '../../styles/review-summary-stats.module.scss';

interface SummaryStatsProps {
  dateSelection: DateSelectionProps;
  isColumnLayout?: boolean;
  isForMarketing?: boolean;
  memberships: Member[];
  onMakeAPICalls?: () => void;
  reports: Reports;
  selectedView: SelectedView;
  statusFilterData?: InviteStatus[];
  user: User;
}

export const SummaryStats: React.ComponentType<SummaryStatsProps> = React.memo(
  ({
    dateSelection,
    memberships,
    user,
    statusFilterData,
    reports,
    selectedView,
    isColumnLayout = false,
    isForMarketing = false,
    onMakeAPICalls
  }) => {
    const [, isAdminOfTeam] = useTeamSubscription();
    const [isInvitingMembers, setIsInvitingMembers] = useState(false);

    const totalMeetingsCount = getViewMetric(
      'duration_and_costs.attributes.rows.total.meetings',
      reports,
      selectedView
    );

    const _toggleInviteDialog = useCallback(() => {
      setIsInvitingMembers(prevState => !prevState);
    }, []);

    if (!user || !reports) {
      return null;
    }

    return (
      <div
        className={classNames(styles.section, styles.summaryStats, {
          [styles.isColumnLayout]: isColumnLayout,
          [styles.isForMarketing]: isForMarketing
        })}>
        <h5>TIME SPENT</h5>
        <div className={styles.description} data-label="description">
          How much time and money is spent in meetings.
        </div>
        {isEqual(selectedView, REVIEW_VIEWS.Team) && isAdminOfTeam && (
          <div className={styles.inviteMembers}>
            <Alert
              content={<InviteMembers makeAPICalls={onMakeAPICalls} />}
              isVisible={isInvitingMembers}
              shouldHideConfirm={true}
              text="Add emails below to invite people to your team."
              title="Invite team members"
            />
            <Button text="Invite team members" onClick={_toggleInviteDialog} />
          </div>
        )}
        {totalMeetingsCount === 0 ? (
          <div className={styles.card}>
            <ErrorState
              description="You don't have any meetings to evaluate for this week."
              inline={true}
              title="No stats available."
            />
          </div>
        ) : (
          <SummaryCard
            dateSelection={dateSelection}
            isColumnLayout={isColumnLayout}
            isForMarketing={isForMarketing}
            memberships={memberships}
            reports={reports}
            selectedView={selectedView}
            statusFilterData={statusFilterData}
            user={user}
            onMakeAPICalls={onMakeAPICalls}
          />
        )}
      </div>
    );
  }
);
