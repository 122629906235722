import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { INTENT } from '../../misc/consts';

import { Loading } from './Loading';

import styles from '../../styles/design-system.module.scss';

export interface ButtonProps {
  active?: boolean;
  centered?: boolean;
  className?: string;
  dataMisc?: string;
  dataType?: string;
  dataValue?: string;
  disabled?: boolean;
  fill?: boolean;
  icon?: ReactNode;
  iconRight?: ReactNode;
  intent?: string;
  isLoading?: boolean;
  large?: boolean;
  minimal?: boolean;
  onClick?: (evt?: React.MouseEvent<HTMLDivElement>) => void;
  shouldLookDisabled?: boolean;
  small?: boolean;
  text?: string | ReactNode;
}

export const Button: React.ComponentType<ButtonProps> = React.memo(
  ({
    minimal = false,
    intent,
    className,
    centered = false,
    fill = false,
    small = false,
    large = false,
    active = false,
    disabled = false,
    shouldLookDisabled = true,
    isLoading = false,
    dataMisc,
    dataType,
    dataValue,
    onClick,
    icon,
    text,
    children,
    iconRight
  }) => {
    return (
      <div
        className={classNames(styles.button, className, {
          [styles.minimal]: minimal,
          [styles.primary]: intent === INTENT.Primary,
          [styles.success]: intent === INTENT.Success,
          [styles.warning]: intent === INTENT.Warning,
          [styles.danger]: intent === INTENT.Danger,
          [styles.centered]: centered,
          [styles.fill]: fill,
          [styles.small]: small,
          [styles.large]: large,
          [styles.active]: active,
          [styles.disabled]: disabled,
          [styles.shouldLookDisabled]: disabled && shouldLookDisabled,
          [styles.isLoading]: isLoading
        })}
        data-misc={dataMisc}
        data-type={dataType}
        data-value={dataValue}
        onClick={!disabled ? onClick : undefined}>
        {isLoading && <Loading className={styles.loader} small={true} />}
        {icon && <div className={styles.icon}>{icon}</div>}
        {text && <div className={styles.text}>{text}</div>}
        {children && children}
        {iconRight && (
          <div className={classNames(styles.icon, styles.iconRight)}>
            {iconRight}
          </div>
        )}
      </div>
    );
  }
);
