import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { BooleanParam, useQueryParam } from 'use-query-params';

import { SEO } from '../../components/misc/SEO';
import { ChoosePlan } from '../../components/onboarding/ChoosePlan';
import { QUERY_PARAMS } from '../../misc/consts';
import { Fathom, GOALS } from '../../misc/fathom';

import styles from '../../styles/marketing/misc.module.scss';

export const PricingView: React.ComponentType = React.memo(() => {
  const [, setIsSignUpFormOpen] = useQueryParam(
    QUERY_PARAMS.Login,
    BooleanParam
  );

  const _onSignUpFormToggle = useCallback(() => {
    setIsSignUpFormOpen(prevState => {
      if (!prevState) {
        Fathom.trackGoal(GOALS.Marketing.LoginSignupButton);
      }
      return !prevState;
    });
  }, [setIsSignUpFormOpen]);

  return (
    <>
      <SEO title="Pricing" />
      <div
        className={classNames(
          styles.subpageView,
          styles.runningText,
          styles.pricingPage
        )}>
        <div className={styles.inner}>
          <h1>
            Adopt us, <span>then drop us.</span>
          </h1>
          <div className={styles.subtitle}>
            Cancel your subscription once the bad meetings on your calendar go away for good.
          </div>
          <p>
            Our plans are both accessible and flexible, so
            when bad meetings disappear off your calendar, we can too! Don't
            believe us? <Link to="/blog/manifesto">Read our manifesto.</Link>
          </p>
          <ChoosePlan
            isForMarketing={true}
            onGetStarted={_onSignUpFormToggle}
          />
        </div>
      </div>
    </>
  );
});
