import React, { useCallback } from 'react';
import { isBrowser } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { COLORS } from '../../misc/constants';
import { URLS } from '../../misc/consts';
import { RootState } from '../../misc/types';
import { Button, Icon } from '../design-system';

import { MaybeWellnessBlocks } from './MaybeWellnessBlocks';
import { WellnessBlocksList } from './WellnessBlocksList';

import styles from '../../styles/balance.module.scss';

export const BalanceViewWellnessBlocks: React.ComponentType = React.memo(() => {
  const history = useHistory();
  const wellnessBlocks = useSelector(
    (state: RootState) => state.wellness_blocks
  );

  const _handleViewWellnessBlockClick = useCallback(
    block_id => {
      history.push(`${URLS.Balance.ViewBlock}/${block_id}`);
    },
    [history]
  );

  const _handleNewWellnessBlockClick = useCallback(() => {
    history.push(URLS.Balance.NewBlock);
  }, [history]);

  return (
    <div className={classNames(styles.column, styles.wellnessDenyList)}>
      <div className={styles.header}>
        <div className={styles.text}>
          <h3>Wellness blocks</h3>
          <div className={styles.desc}>
            Chunks of untouchable time for you to do with as you please.
          </div>
        </div>
        <Button
          icon={<Icon color={COLORS.primary} which="wellness" />}
          text={isBrowser ? 'Add a Wellness block' : 'Add'}
          onClick={_handleNewWellnessBlockClick}
        />
      </div>
      <div className={styles.card}>
        <WellnessBlocksList
          wellnessBlocks={wellnessBlocks}
          onNewWellnessBlock={_handleNewWellnessBlockClick}
          onViewWellnessBlock={_handleViewWellnessBlockClick}
        />
      </div>
      <MaybeWellnessBlocks />
    </div>
  );
});
