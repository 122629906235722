import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { isEqual, isNumber, round } from 'lodash';

import { formatMoney, padMins } from '../../misc/utils';
import { Dollar } from '../design-system';

import { Delta } from './Delta';

import styles from '../../styles/review-metric.module.scss';

interface MetricProps {
  article?: ReactNode;
  bottomLabel?: string;
  className?: string;
  comparisonText?: string;
  data?: number;
  descriptor?: string;
  label?: string;
  prevSpan?: number;
  title?: string;
  type: string;
}

export const Metric: React.ComponentType<MetricProps> = React.memo(
  ({
    className,
    type,
    data,
    title,
    descriptor,
    label,
    bottomLabel,
    prevSpan,
    comparisonText,
    article
  }) => {
    if (Number.isNaN(data)) return null;
    const hasDelta = !Number.isNaN(prevSpan) && !isEqual(data, prevSpan);

    const renderNum = () => {
      if (data === undefined) return null;
      const numToShow = round(data, 1);
      const splitNum = numToShow.toString().split('.');
      return (
        <div className={styles.number}>
          {splitNum[0]}
          {splitNum[1] && (
            <span className={styles.decimal}>.{splitNum[1]}</span>
          )}
        </div>
      );
    };

    const renderDollar = () => {
      if (data === undefined) return null;
      return (
        <div className={styles.number}>
          <Dollar className={styles.dollarSign} />
          {formatMoney(data, 0)}
        </div>
      );
    };

    const renderTime = () => {
      if (data === undefined) return null;
      const days = Math.floor(data / (3600 * 24));
      const hours = Math.floor((data % (3600 * 24)) / 3600);
      const mins = Math.floor((data % 3600) / 60);

      if (data === 0) {
        return (
          <div className={styles.time}>
            <div className={styles.numWrapper}>
              <div className={styles.number}>{data}</div>
              <div className={styles.text}>
                <span>hours</span>
              </div>
            </div>
          </div>
        );
      }

      return (
        <div className={styles.time}>
          {days > 0 && (
            <div className={styles.numWrapper}>
              <div className={styles.number}>{days}</div>
              <div className={styles.text}>
                <span>days</span>
              </div>
            </div>
          )}
          {days > 0 && hours > 0 && (
            <div className={styles.divider} data-type="divider" />
          )}
          {hours > 0 && (
            <div className={styles.numWrapper}>
              <div className={styles.number}>{hours}</div>
              <div className={styles.text}>
                <span>{hours > 1 ? 'hrs' : 'hr'}</span>
              </div>
            </div>
          )}
          {hours > 0 && mins > 0 && <div className={styles.colon}>:</div>}
          {mins > 0 && (
            <div className={styles.numWrapper}>
              <div className={styles.number}>{padMins(mins)}</div>
              <div className={styles.text}>
                <span>mins</span>
              </div>
            </div>
          )}
        </div>
      );
    };

    return (
      <div className={classNames(styles.metric, className)}>
        {title && <div className={styles.title}>{title}</div>}
        <div
          className={classNames(styles.data, {
            [styles.hasDelta]: hasDelta
          })}>
          {type === 'num' && renderNum()}
          {type === 'dollar' && renderDollar()}
          {type === 'time' && renderTime()}
          {(label || hasDelta) && (
            <div className={styles.details}>
              {label && (
                <div className={styles.label} title={label}>
                  {label}
                </div>
              )}
              {hasDelta &&
                comparisonText &&
                isNumber(data) &&
                isNumber(prevSpan) && (
                  <Delta
                    comparisonText={comparisonText}
                    data={data}
                    prevSpan={prevSpan}
                    type={type}
                  />
                )}
            </div>
          )}
        </div>
        {bottomLabel && (
          <div
            className={classNames(styles.label, styles.bottomLabel)}
            title={bottomLabel}>
            {bottomLabel}
          </div>
        )}
        {(descriptor || article) && (
          <>
            {descriptor && (
              <div
                className={styles.descriptor}
                dangerouslySetInnerHTML={{ __html: descriptor }}
              />
            )}
            {article && <div className={styles.descriptor}>{article}</div>}
          </>
        )}
      </div>
    );
  }
);
