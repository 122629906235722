import React, { ReactNode, useCallback, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useToasts } from 'react-toast-notifications';
import { isEmpty } from 'lodash';
import RemoveMarkdown from 'remove-markdown';

import { INTENT } from '../../misc/consts';
import { Button, Tooltip } from '../design-system';
import { MenuItem } from '../design-system/Menu';

import { ModalView } from './ModalView';

import styles from '../../styles/share-text-modal.module.scss';

interface ShareTextModalProps {
  buttonIcon?: ReactNode;
  buttonText?: string;
  buttonTooltip?: string;
  descriptor: string;
  onOpen?: () => void;
  sendTo?: string;
  small?: boolean;
  subjectLine: string;
  textToShare: string;
  type?: 'menuItem' | 'button';
}

export const ShareTextModal: React.ComponentType<ShareTextModalProps> =
  React.memo(
    ({
      buttonText,
      buttonIcon,
      buttonTooltip,
      descriptor,
      small = false,
      textToShare,
      subjectLine,
      sendTo,
      type = 'button',
      onOpen
    }) => {
      const { addToast } = useToasts();
      const [isModalVisible, setModalVisible] = useState(false);

      const _toggleModal = useCallback(() => {
        setModalVisible(prevState => {
          if (prevState === false) {
            onOpen && onOpen();
          }
          return !prevState;
        });
      }, [onOpen]);

      const _copyToClipboard = useCallback(() => {
        addToast('Copied to clipboard!', {
          appearance: 'success',
          autoDismiss: true
        });
        navigator.clipboard.writeText(RemoveMarkdown(textToShare));
      }, [addToast, textToShare]);

      const _openInEmail = useCallback(() => {
        const maybeSendTo = sendTo ? `&to=${encodeURIComponent(sendTo)}` : '';
        const mailToLink =
          'mailto:?subject=' +
          encodeURIComponent(subjectLine) +
          maybeSendTo +
          '&body=' +
          encodeURIComponent(RemoveMarkdown(textToShare));
        window.location.href = mailToLink;
      }, [sendTo, subjectLine, textToShare]);

      ///////////
      // Render
      ///////////

      return (
        <>
          <Tooltip content={buttonTooltip} isDisabled={isEmpty(buttonTooltip)}>
            {type === 'menuItem' ? (
              <MenuItem icon={buttonIcon} onClick={_toggleModal}>
                {buttonText}
              </MenuItem>
            ) : (
              <Button
                icon={buttonIcon}
                small={small}
                text={buttonText}
                onClick={_toggleModal}
              />
            )}
          </Tooltip>
          <ModalView
            className={styles.shareTextOuterModal}
            innerClassName={styles.shareTextModal}
            isDismissible={true}
            isVisible={isModalVisible}
            position="float"
            onClose={_toggleModal}>
            <div className={styles.descriptor}>{descriptor}</div>
            <div className={styles.textToShare}>
              <ReactMarkdown children={textToShare} />
            </div>
            <div className={styles.actions}>
              <Button
                centered={true}
                fill={true}
                large={true}
                text="Copy to clipboard"
                onClick={_copyToClipboard}
              />
              <Button
                centered={true}
                fill={true}
                intent={INTENT.Primary}
                large={true}
                text="Open in email"
                onClick={_openInEmail}
              />
            </div>
          </ModalView>
        </>
      );
    }
  );
