import React from 'react';
import classNames from 'classnames';
import { find, isArray, isEmpty, sortBy } from 'lodash';
import { rrulestr } from 'rrule';

import { COLORS } from '../../misc/constants';
import { WELLNESS_BLOCKS } from '../../misc/consts';
import { WellnessBlock, WellnessBlockCategory } from '../../misc/types';
import { formatRRule } from '../../misc/utils';
import { Button, ErrorState, Icon, TextLink, Tooltip } from '../design-system';
import { ArticleView } from '../misc/ArticleView';

import styles from '../../styles/wellness-blocks.module.scss';

interface WellnessBlocksListProps {
  onNewWellnessBlock: () => void;
  onViewWellnessBlock: (id: string) => void;
  wellnessBlocks: WellnessBlock[];
}

export const WellnessBlocksList: React.ComponentType<WellnessBlocksListProps> =
  React.memo(({ onNewWellnessBlock, onViewWellnessBlock, wellnessBlocks }) => {
    // Build blocks to show
    let wellnessBlocksToShow: WellnessBlock[] = [];
    if (isEmpty(wellnessBlocks) || !isArray(wellnessBlocks)) {
      return <EmptyState onNewWellnessBlock={onNewWellnessBlock} />;
    } else {
      wellnessBlocksToShow = wellnessBlocks;
    }

    // Group blocks by category
    const groupedBlocks = sortBy(wellnessBlocksToShow, 'attributes.category');

    // Return
    return (
      <>
        {groupedBlocks.map((item, key) => (
          <Block
            key={key}
            item={item}
            onViewWellnessBlock={onViewWellnessBlock}
          />
        ))}
      </>
    );
  });

interface BlockProps {
  item: WellnessBlock;
  onViewWellnessBlock: (id: string) => void;
}

const Block: React.ComponentType<BlockProps> = React.memo(
  ({ item, onViewWellnessBlock }) => {
    const rrule = rrulestr(item.attributes.rrule);
    const blockCategory = item.attributes.category;
    const thisCategory = find(WELLNESS_BLOCKS, function (o) {
      return o.value === blockCategory;
    }) as WellnessBlockCategory;
    const tooltipContent = thisCategory.description;

    const ruleAndTime = formatRRule({
      rrule,
      starts_at: item.attributes.starts_at,
      ends_at: item.attributes.ends_at,
      recurring: item.attributes.recurring
    });

    return (
      <div className={styles.block}>
        <div className={styles.icon}>
          <Tooltip
            className={classNames(styles.iconWrapper, {
              [styles.focus]: blockCategory === WELLNESS_BLOCKS.Focus.value,
              [styles.life]: blockCategory === WELLNESS_BLOCKS.Life.value
            })}
            content={tooltipContent}>
            <Icon
              color={
                blockCategory === 'life' ? COLORS.darkPurple : COLORS.primary
              }
              which="wellness"
            />
          </Tooltip>
        </div>
        <div className={styles.info}>
          <div className={styles.label}>
            {item.attributes.label || 'No label'}
          </div>
          <div className={styles.ruleAndTime}>{ruleAndTime}</div>
        </div>
        {!item.attributes.read_only && (
          <div className={styles.edit}>
            <TextLink
              className={styles.edit}
              text="Edit"
              onClick={() => onViewWellnessBlock(item.id)}
            />
          </div>
        )}
      </div>
    );
  }
);

interface EmptyStateProps {
  onNewWellnessBlock: () => void;
}

const EmptyState: React.ComponentType<EmptyStateProps> = React.memo(
  ({ onNewWellnessBlock }) => {
    return (
      <div className={styles.missingWellnessBlocks}>
        <ErrorState
          action={
            <Button text="Add a Wellness block" onClick={onNewWellnessBlock} />
          }
          description={
            <>
              <div className={styles.descText}>
                Place a hold on your calendar to save some time for yourself.
              </div>
              <ArticleView
                order="random"
                renderBy="pull_quote"
                tagList="focus-block"
              />
            </>
          }
          inline={true}
          title="Add your 1st Wellness block"
        />
      </div>
    );
  }
);
