/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { log } from './utils';

const isOnProd = process.env.REACT_APP_ENVIRONMENT === 'production';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fathom: any;
  }
}

export const Fathom = {
  trackGoal: (which: string) => {
    log({ Fathom: 'goal', which });
    // if (isOnProd && window.fathom) {
    //   window.fathom.trackGoal(which, 0);
    // }
  }
};

export const GOALS = {
  WellnessBlocks: {
    Created: '8CS01VWT',
    Edited: 'NAUPDF9A',
    ConvertedMeeting: '4KT3KANV'
  },
  General: {
    CompletedOnboarding: 'UJEK80NQ'
  },
  Meeting: {
    SetIntention: '0VA7MG0J',
    StartedMeetingPresentation: 'FJCCQI8Y',
    EndedMeetingPresentation: 'ZYQUUHZ5',
    ModifiedAgenda: 'OSKKNNLY',
    ModifiedObjectives: 'IBGSXSWJ',
    ModifiedPrework: 'XR8NWYNG',
    SkippedEvaluation: 'CGIQVOL9'
  },
  Review: {
    ChangedSalary: 'LDHV4EZV',
    ChangedFilter: '8DXZZ4YJ'
  },
  Teams: {
    NavigatedToStripe: 'SGLYDO7H',
    InvitedMember: 'Z7PXWRL2',
    PlanUpdated: 'LJHFFND0'
  },
  Balance: {
    ModifiedEvaluationSetting: '9FXUW8Q0',
    ModifiedActionSetting: 'OGJLSZ7L',
    ModifiedAction: 'SAR8XDVV',
    ModifiedDeadline: '4NVGOT4L',
    AddedPeopleToIgnore: 'LHTUVSZH',
    AddedDomainsToEnforce: 'IENNMN23',
    OpenedShareMeetingCulture: 'FXRWQEGK'
  },
  Account: {
    ModifiedEmailCommsPref: 'DPY9KUX7',
    ModifiedCalendarPreference: '5UZIIWBA',
    ModifiedWBCategoryColor: 'F2QMOFOK'
  },
  Marketing: {
    CTAButton: 'OQ4O6HJJ',
    LoginSignupButton: 'HRCW4N6A',
    GoogleSignInClicked: 'IS1GNEWN',
    OutlookSignInClicked: 'KGAIQ6ET',
    LinkedIn: {
      'linkedin-mkr1': 'JSIAZHXT',
      'linkedin-mkr2': 'P8LVXJJS',
      'linkedin-mgr1': 'AFQCGJVZ',
      'linkedin-mgr2': 'JV2V4CWW'
    }
  }
};
