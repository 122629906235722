import React, { ReactNode, useCallback } from 'react';
import classNames from 'classnames';

import { IGNORE_VAR } from '../../misc/consts';

import { Checkbox, Tag, Tooltip } from '.';

import styles from '../../styles/design-system.module.scss';

export interface SegmentedControlData {
  id?: string;
  isDisabled?: boolean;
  isRecommended?: boolean;
  isSelected: boolean;
  label: string | ReactNode;
  value: string | number;
}

export interface SegTagProps {
  check: 'isDisabled' | 'isRecommended' | 'isSelected';
  text: string;
}

export interface SegmentedControlProps {
  className?: string;
  data?: SegmentedControlData[];
  disabledText?: string;
  isDisabled?: boolean;
  leftLabel?: string;
  onClick?: (key: number) => void;
  rightLabel?: string;
  tag?: SegTagProps;
  vertical?: boolean;
}

export const SegmentedControl: React.ComponentType<SegmentedControlProps> = React.memo(
  function SegmentedControl({
    className,
    data,
    isDisabled = false,
    disabledText,
    leftLabel,
    onClick,
    rightLabel,
    tag,
    vertical = false
  }) {
    const onClickCalback = useCallback(
      key => {
        onClick && onClick(key);
      },
      [onClick]
    );

    return (
      <div
        className={classNames(styles.segmentedControl, className, {
          [styles.vertical]: vertical,
          [styles.disabled]: isDisabled
        })}>
        {data &&
          data.map((item, key) => {
            const children =
              item.label !== IGNORE_VAR ? (
                <>
                  {leftLabel && (
                    <span className={styles.leftLabel}>{leftLabel}</span>
                  )}
                  {item.label}
                  {rightLabel && (
                    <span className={styles.rightLabel}>{rightLabel}</span>
                  )}
                </>
              ) : (
                <Tag>{item.label}</Tag>
              );
            return (
              <Tooltip
                key={key}
                content={disabledText || 'This is disabled'}
                isDisabled={!item.isDisabled}>
                <div
                  className={classNames(styles.option, {
                    [styles.selected]: item.isSelected,
                    [styles.disabled]: item.isDisabled
                  })}
                  onClick={() => onClickCalback(key)}>
                  <Checkbox selected={item.isSelected} />
                  <div className={styles.text}>
                    {children}
                    {tag && item[tag.check] && (
                      <Tag className={styles.descriptorTag} minimal small>
                        {tag.text}
                      </Tag>
                    )}
                  </div>
                </div>
              </Tooltip>
            );
          })}
      </div>
    );
  }
);
