import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filter, get, isEmpty } from 'lodash';

import { Fathom, GOALS } from '../../misc/fathom';
import { CalendarSource, RootState, User } from '../../misc/types';
import { getCalendarSource } from '../../misc/utilities';
import * as API from '../../modules/api';
import { getCalendarEventColors } from '../../modules/calendars';
import {
  generallyUpdateUser,
  setUserCommunicationsPreference
} from '../../modules/user';
import {
  Button,
  EditingPopover,
  Icon,
  RadioGroupForm,
  Tooltip
} from '../design-system';
import { SegmentedControlData } from '../design-system/SegmentedControl';

import styles from '../../styles/account.module.scss';
import popoverStyles from '../../styles/popover.module.scss';

export interface CalendarPreferencesProps {
  user: User;
}

export const CalendarPreferences: React.ComponentType<CalendarPreferencesProps> =
  React.memo(function CalendarPreferences({ user }) {
    const [
      dailyReportCardEventsPreference,
      setDailyReportCardEventsPreference
    ] = useState('true');
    const [isWaitingOnAPI, setIsWaitingOnAPI] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [segData, setSegData] = useState<SegmentedControlData[]>([]);
    const [stagedValue, setStagedValue] = useState('');

    const dispatch = useDispatch();

    const calendarSource: CalendarSource = useMemo(
      () => getCalendarSource(user),
      [user]
    );

    const calendarColors = useSelector(
      (state: RootState) => state.calendars.colors
    );

    const availableColors = useMemo(() => {
      if (calendarSource) {
        return get(calendarColors, `attributes.${calendarSource.slug}`);
      }
      return [];
    }, [calendarColors, calendarSource]);

    const WBColorPreference = useMemo(() => {
      if (!isEmpty(user) && !isEmpty(segData)) {
        return filter(segData, o => {
          return o.value === user.attributes.wellness_block_color_preference;
        })[0].label;
      }
      return '';
    }, [user, segData]);

    // Make API calls
    useEffect(() => {
      API.makeRequest(() => {
        dispatch(getCalendarEventColors());
      });
    }, [dispatch]);

    // Do stuff with `user`
    useEffect(() => {
      setIsWaitingOnAPI(false);
      setIsEditing(false);

      if (!isEmpty(user)) {
        setDailyReportCardEventsPreference(
          user.attributes.daily_report_card_events.toString()
        );

        if (!isEmpty(availableColors)) {
          setSegData(
            Object.keys(availableColors).map(key => {
              const thisItem = availableColors[key];
              return {
                value: key,
                label: (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span
                      style={{
                        backgroundColor: `#${thisItem.hex}`,
                        width: '16px',
                        height: '16px',
                        borderRadius: '2px',
                        marginRight: '5px',
                        border: '1px solid rgba(0,0,0,0.1)'
                      }}
                    />
                    <span style={{ paddingTop: '3px' }}>{thisItem.label}</span>
                  </div>
                ),
                id: thisItem.id,
                isSelected:
                  key === user.attributes.wellness_block_color_preference
              };
            })
          );
        }
      }
    }, [user, availableColors]);

    const _setDailyReportCardEventsPreference = useCallback(
      preference => {
        // Set locally
        setDailyReportCardEventsPreference(preference);

        // Post to API
        dispatch(
          setUserCommunicationsPreference(
            'daily_report_card_events',
            preference
          )
        );

        // Track goal
        Fathom.trackGoal(GOALS.Account.ModifiedCalendarPreference);
      },
      [dispatch]
    );

    const _toggleEditMode = useCallback(() => {
      setIsEditing(!isEditing);
    }, [isEditing]);

    const _selectValue = useCallback(
      which => {
        const tempData = [...segData];
        for (let i = 0; i < tempData.length; i++) {
          if (i === which) {
            tempData[i].isSelected = true;
          } else {
            tempData[i].isSelected = false;
          }
        }

        setSegData(tempData);
        setStagedValue(tempData[which].value as string);
      },
      [segData]
    );

    const _saveWBColor = useCallback(() => {
      // Show spinner
      setIsWaitingOnAPI(true);

      // Post to API
      dispatch(
        generallyUpdateUser({
          which: 'wellness_block_color_preference',
          value: stagedValue
        })
      );

      // Track goal
      Fathom.trackGoal(GOALS.Account.ModifiedWBCategoryColor);
    }, [dispatch, stagedValue]);

    return (
      <>
        <RadioGroupForm
          desc={
            <>
              Sync a daily report card to your connected{' '}
              {calendarSource && calendarSource.label} calendars, allowing you
              to view your scores on your calendar.{' '}
              <span className={styles.example}>
                <Tooltip
                  content={<div className="exampleScreenshot dailyReports" />}>
                  View example
                </Tooltip>
              </span>
            </>
          }
          options={[
            {
              label: (
                <>
                  <strong>Show</strong> daily report cards on my calendar
                </>
              ),
              value: 'true'
            },
            {
              label: (
                <>
                  <strong>Hide</strong> daily report cards from my calendar
                </>
              ),
              value: 'false'
            }
          ]}
          selectedValue={dailyReportCardEventsPreference}
          title="Daily report card events"
          onChange={_setDailyReportCardEventsPreference}
        />
        <RadioGroupForm
          desc={`The color MeetWell will assign to meetings marked as Wellness blocks. Assigning this color from ${
            calendarSource && calendarSource.label
          } will auto-mark the meeting as a Wellness block.`}
          miscContent={
            <EditingPopover
              innerClassName={popoverStyles.editingPlanSetting}
              isLoading={isWaitingOnAPI}
              isVisible={isEditing}
              segData={segData}
              onCancel={_toggleEditMode}
              onSave={_saveWBColor}
              onSelectValue={_selectValue}
              onToggle={_toggleEditMode}>
              <Button
                iconRight={<Icon which="chevron-down" />}
                onClick={_toggleEditMode}>
                {WBColorPreference}
              </Button>
            </EditingPopover>
          }
          title="Wellness block category color"
        />
      </>
    );
  });
