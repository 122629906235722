import React, { ReactNode } from 'react';
import classNames from 'classnames';

import {
  SegmentedControlData,
  SegmentedControlProps
} from './SegmentedControl';
import { Button, Popover, SegmentedControl, Tooltip } from '.';

import styles from '../../styles/popover.module.scss';

export interface EditingPopoverProps {
  children: ReactNode;
  className?: string;
  content?: ReactNode;
  description?: string;
  disabledText?: string;
  innerClassName?: string;
  isLoading?: boolean;
  isSaveButtonDisabled?: boolean;
  isVisible: boolean;
  onCancel: () => void;
  onRefreshPosition?: number;
  onSave: () => void;
  onSelectValue?: (key: number) => void;
  onToggle: () => void;
  segData?: SegmentedControlData[];
  segProps?: SegmentedControlProps;
}

export const EditingPopover: React.ComponentType<EditingPopoverProps> =
  React.memo(function EditingPopover({
    children,
    className,
    content,
    description,
    disabledText,
    innerClassName,
    isLoading = false,
    isSaveButtonDisabled = false,
    isVisible = false,
    onCancel,
    onRefreshPosition,
    onSave,
    onSelectValue,
    onToggle,
    segData,
    segProps
  }) {
    const renderEditMode = (
      <div className={classNames(styles.editMeeting, innerClassName)}>
        <div className={styles.scroll}>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          {segData && (
            <SegmentedControl
              data={segData}
              vertical={true}
              onClick={onSelectValue}
              {...segProps}
            />
          )}
          {content && content}
        </div>
        <div className={styles.actionButtons}>
          <Button centered fill text="Cancel" onClick={onCancel} />
          <Tooltip
            content={disabledText || 'This is already selected'}
            isDisabled={!isSaveButtonDisabled}>
            <Button
              centered={true}
              className={styles.saveBtn}
              disabled={isSaveButtonDisabled}
              fill={true}
              intent="primary"
              isLoading={isLoading}
              shouldLookDisabled={true}
              text="Save"
              onClick={onSave}
            />
          </Tooltip>
        </div>
      </div>
    );

    return (
      <Popover
        className={className}
        content={renderEditMode}
        isVisible={isVisible}
        onRefreshPosition={onRefreshPosition}
        onToggle={onToggle}>
        {children}
      </Popover>
    );
  });
