/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';
import Emoji from 'react-emoji-render';
import classNames from 'classnames';

import { SEO } from '../../components/misc/SEO';

import styles from '../../styles/marketing/misc.module.scss';

export const ReferralsView: React.ComponentType = React.memo(() => {
  return (
    <>
      <SEO title="Referral Program" />
      <div
        className={classNames(
          styles.subpageView,
          styles.runningText,
          styles.blogPost
        )}>
        <div className={styles.inner}>
          <h1>
            Earn <Emoji text=":money_bag:" /> through our referral program
          </h1>
          <p>
            For every paying customer you refer with your unique url, you'll get
            a <strong>$2 / month commission</strong>.
          </p>
          <p>
            That means if you send 10 paying customers our way and they all sign
            up ($5/month), you’ll get paid $20/month every single month as long
            as they stay customers.
          </p>
          <h2>Details</h2>
          <p>
            We take privacy seriously (obviously). That includes this affiliate
            program, which we created from scratch to protect the privacy of
            referrals. So you won’t see any names or personal information of
            people you send our way, just the date and plan for each new signup.
          </p>
          <h2>Get started today</h2>
          <p>
            If you’re already a MeetWell user, you can access your referral URL
            in <a href="/my/account">Account</a>. If you aren’t a MeetWell user,
            you'll need to sign up to get started with this program.
          </p>
          <p>
            We look forward to having you as a partner in ending bad meetings!
          </p>
        </div>
      </div>
    </>
  );
});
