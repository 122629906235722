import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { User } from '../../misc/types';
import { updateTeamInviteSetting } from '../../modules/teams';
import { RadioGroupForm } from '../design-system';

interface InviteSettingsProps {
  user: User;
}

export type InviteSettingValue = 'domain' | 'invite';

interface InviteSetting {
  label: string;
  value: InviteSettingValue;
}

export const InviteSettings: React.ComponentType<InviteSettingsProps> = React.memo(
  ({ user }) => {
    const dispatch = useDispatch();

    const inviteSettingsOptions: InviteSetting[] = [
      { value: 'domain', label: 'Anyone with the same domain' },
      { value: 'invite', label: 'Invite only' }
    ];

    const [
      inviteSettingsSelectedValue,
      setInviteSettingsSelectedValue
    ] = useState<InviteSettingValue>('domain');

    const _handleChangeInviteSettings = useCallback(
      value => {
        setInviteSettingsSelectedValue(value);
        dispatch(updateTeamInviteSetting(user.attributes.company.id, value));
      },
      [dispatch, user]
    );

    useEffect(() => {
      setInviteSettingsSelectedValue(user.attributes.company.license_by);
    }, [user]);

    return (
      <RadioGroupForm
        desc="How would you like for members to be added?"
        options={inviteSettingsOptions}
        selectedValue={inviteSettingsSelectedValue}
        title="How will members be added?"
        onChange={_handleChangeInviteSettings}
      />
    );
  }
);
