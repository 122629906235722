import React from 'react';
import classNames from 'classnames';

import styles from '../../styles/design-system.module.scss';

export interface InputProps {
  className?: string;
  max?: string;
  min?: string;
  onChange?: (evt: React.FormEvent<HTMLInputElement>) => void;
  onClick?: (evt: React.FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (evt: React.FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value?: string | number;
}

export const Input: React.ComponentType<InputProps> = React.memo(
  ({
    className,
    max,
    min,
    onChange,
    onClick,
    onKeyDown,
    placeholder,
    type,
    value
  }) => {
    return (
      <div className={classNames(styles.input, className)}>
        <input
          max={max}
          min={min}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
        />
      </div>
    );
  }
);
